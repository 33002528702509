const BasicTableData = () => {
  // const header = [
  //   { id: 1, title: '#' },
  //   { id: 2, title: 'First Name' },
  //   { id: 3, title: 'Last Name' },
  //   { id: 4, title: 'Username' },
  //   { id: 5, title: 'Status' },
  // ];

  const headerResponsive = [
    { id: 1, title: '고유값' },
    { id: 2, title: '상태' },
    { id: 3, title: '게시판' },
    { id: 4, title: '댓글 내용' },
    { id: 5, title: '작성자(닉네임)' },
    { id: 7, title: '작성일' },
    { id: 8, title: '작성아이피' },
    { id: 9, title: '글 이동' },
    { id: 10, title: '삭제'}
  ];
 
  const rows = [];
  for (let i = 0; i < 20; i++) {
      rows.push({
      id: (i + 1),
      status: ['삭제', '정상'][Math.floor((Math.random() * 2))],
      board1: ['자유게시판', '다폴더'][Math.floor((Math.random() * 2))],
      replyContent: "가나다라"+i,
      writer: '홍길동'+i,
      regDate: '2021-10-1',
      regIp: i,
    });
  }

  const basicTableData = { tableHeaderResponsiveData: headerResponsive, tableRowsData: rows };
  return basicTableData;
};

export default BasicTableData;
