import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Table, Button } from "reactstrap";

import BasicTableData from "./BasicTableData";

import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import Pagination from "./Pagination";

const { tableHeaderResponsiveData } = BasicTableData();

const ResponsiveTable = ({ isClicked }) => {
  // const { t } = useTranslation('common');
  // const waiting = useSelector(state => state.searchList.data)
  let payStatus = "결제완료";
  const [loading, setLoading] = useState(false);
  const [waiting, setWaiting] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [total, setTotal] = useState(0);
  const [onOff, setOnOff] = useState(false);

  const handleOption = (e) => {
    payStatus = e.target.value;
  };

  const handleFetch = (item) => {
    if (window.confirm("변경 하시겠습니까?")) {
      axios
        .post(`${process.env.REACT_APP_API_URL}/delwaiting`, {
          ...item,
          pay_status: payStatus,
        })
        .then(() => setOnOff((pre) => !pre))
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    // console.log(onOff)
    axios
      .get(`${process.env.REACT_APP_API_URL}/infowaiting?pagenum=${pageNum}`)
      .then((res) => {
        // console.log(res.data)
        setTotal(res.data.total);
        setWaiting(res.data.data);
      })
      .then(() => setLoading(true))
      .catch((err) => console.log(err));
  }, [pageNum, onOff, isClicked]);

  if (!loading) return <div></div>;

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <Table responsive className="table--bordered">
            <thead>
              <tr>
                {tableHeaderResponsiveData.map((item) => (
                  <th key={item.id}>{item.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {waiting.map((item) => (
                <tr key={uuidv4()}>
                  <td>{item.c_date}</td>
                  <td>{item.payer}</td>
                  <td>{item.pay_contents}</td>
                  <td>{item.money}</td>
                  <td>{item.pay_status}</td>
                  <td>
                    {/* <Form.Select aria-label="Default select example" onChange={handleOption}> */}
                    {/* <option value="결제완료" selected={ payStatus === "결제완료" ? "selected" : null}>결제완료</option>
                      <option value="결제대기" selected={ payStatus === "결제대기" ? "selected" : null}>결제대기</option>
                      <option value="결제취소" selected={ payStatus === "결제취소" ? "selected" : null}>결제취소</option> */}
                    <select onChange={handleOption}>
                      <option value="결제완료">결제완료</option>
                      <option value="결제대기">결제대기</option>
                      <option value="결제취소">결제취소</option>
                    </select>
                    {/* </Form.Select> */}
                    <Button color="primary" onClick={() => handleFetch(item)}>
                      확인
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination setPageNum={setPageNum} total={total} />
        </CardBody>
      </Card>
    </Col>
  );
};

export default ResponsiveTable;
