import React, { useEffect, useState } from "react"
import styled, { css } from "styled-components";

import { useHistory } from "react-router-dom";

import Banner from './components/Banner';
import axios from "axios"

import {mobile} from "../../lib/viewport"

import VideoList from "../../components/VideoList"
import searchIcon from "../../../../shared/img/board-selectbox-search.png"
import SkeletonVideo from '../../SkeletonVideo'


//#region styles
const VideoWrapper = styled.div`
    width: 945px;
    margin: 50px auto 200px auto;
    margin-top: 50px;
    ${mobile(css`
        width:100%;
    `)}
`

const VideoSearch = styled.div`
    width:100%;
    height: 100%;
    display:flex;
    justify-content: flex-start;
    margin-bottom: 16px;

    ${mobile(css`
        display:none;
    `)}

`

const VideoMobileSearch = styled.div`
    width: calc( 100% - 30px );
    height: 100%;
    margin: 0 auto 16px auto;
    display:none;

    ${mobile(css`
        display:block;
    `)}

`

const SearchInputWrapper = styled.div`
    width: 160px;
    height: 33px;
    margin: 0px 10px 0px 10px;
    padding: 0px 10px;
    border-radius: 7px;
    border: solid 0.7px #e0e1e5;
    background-color: #fff;

    display:flex;
    justify-content:space-between;
    align-items:center;

    & > img{
        cursor:pointer;
    }

    ${mobile(css`
        width:100%;
        margin: 0px;
    `)}
`
const SearchInput = styled.input`
    width:100%;
    height:100%;
    outline: none;
    border: 0px;
`
const SearchSelectWrapper = styled.div`
    width: 120px;
    border: solid 0.7px #e0e1e5;
    border-radius: 7px;
    padding: 0px 10px;

    display:flex;
    align-items:center;

    background-color: #ffffff;

    & > select{
        width:100%;
        
        outline: none;
        border: 0px;
    }

`

const TypeSelectWrapper = styled.div`
    width:100%;
    height:100%;
    display:flex;
    flex-wrap:wrap;
    ${mobile(css`
        width: calc(100% - 30px);
        margin: 0 auto;
    `)}

`

const Line = styled.div`
    width: 100%;
    height:1px;
    background-color: black;
    margin: 0 auto 0px auto;

    ${mobile(css`
        width: calc(100% - 30px);
    `)}


`

const TypeSelectButton = styled.div`
    width:fit-content;
    min-width:fit-content;
    border-radius: 26px;
    box-shadow: 0px;
    border: solid 0.7px #e0e1e5;
    background-color: #fff;

    padding: 6px 18px;
    color: #888c93;

    cursor:pointer;

    ${({ selected }) => {
      return selected ? `box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.1); color: #000; border: solid 0.7px #000;` : '';
    }}

    margin-bottom: 10px;
    margin-right: 8px;

`
//#endregion

function Video() {
    // const history = useHistory()
    const [searchText, setSearchText] = useState("")
    const [searchKeyword, setSearchKeyword] = useState("")
    const [totalPage, setTotalPage] = useState(1);
    const [category, setCategory] = useState([])
    const [nowCategory, setNowCategory] = useState("전체")
    const [select, setSelect] = useState("제목");

    const [pageNumber, setPageNumber] = useState(1)
    const [resData, setResData] = useState([])
    const [loading, setLoading] = useState(false)
    
    useEffect(()=>{
        setLoading(false)
        axios.get(`${process.env.REACT_APP_API_URL}/folderType?num=3`).then((data) => {
            setCategory(data.data)
        }).catch((err) => console.log(err))

        if (searchKeyword === "") {
            axios.post(`${process.env.REACT_APP_API_URL}/videoList`, {
                folder: nowCategory,
                page: pageNumber
            }).then((data)=>{
                setTotalPage(data.data.count)
                //setResData 저장
                setResData(data.data.data2)
            }).then(() => setLoading(true))
        } else {
            // console.log("start")
            axios.post(`${process.env.REACT_APP_API_URL}/videoSearch`, {
                select: select,
                keyword: searchText,
                folder:nowCategory,
                page: pageNumber
            }).then((data)=>{
                setResData(data.data.searchDiary)
                setTotalPage(data.data.count)
            }).then(()=> setLoading(true))
            .catch((e)=>{
                console.log(e.response)
            })
        }
    },[pageNumber, nowCategory])

    function searchEvent(folder) {
        setSearchKeyword(searchText)
        setLoading(false)

        if (searchKeyword === "") {
            axios.post(`${process.env.REACT_APP_API_URL}/videoList`, {
                folder: folder ? folder:nowCategory,
                page: pageNumber
            }).then((data)=>{
                setTotalPage(data.data.count)
                //setResData 저장
                setResData(data.data.data2)
            }).then(() => setLoading(true))
        } else {
            axios.post(`${process.env.REACT_APP_API_URL}/videoSearch`, {
                select: select,
                keyword:searchText,
                page: pageNumber,
                folder:folder ? folder : nowCategory
                //folder 추가 예정
            }).then((data)=>{
                setResData(data.data.searchDiary)
                setTotalPage(data.data.count)
            }).then(() => setLoading(true))
            .catch((e)=>{
                console.log(e.response)
            })
        }
    }
            
   
    return (
        <>
            <Banner title="video"></Banner>
            <VideoWrapper>
                <TypeSelectWrapper>
                    <TypeSelectButton selected={nowCategory === "전체"} onClick={() => {setNowCategory("전체"); searchEvent("전체")}}>전체보기</TypeSelectButton>  
                    {
                        category.map((value)=>{
                            return <TypeSelectButton key={value.folder_name} selected={nowCategory === value.folder_name} onClick={() => {setNowCategory(value.folder_name); searchEvent(value.folder_name);}}>{value.folder_name}</TypeSelectButton>
                        })
                    }                  
                </TypeSelectWrapper>
                <VideoSearch>
                    <SearchSelectWrapper>
                        <select name="serach" onChange={(e)=>{ setSelect(e.target.value); }}>
                            <option value="제목">제목</option>
                            <option value="내용">내용</option>
                            <option value="제목+내용">제목+내용</option>
                        </select>
                    </SearchSelectWrapper>
                    <SearchInputWrapper>
                        <SearchInput value={searchText} onChange={(e)=>{setSearchText(e.target.value)}} type="text" placeholder="검색" onKeyPress={(e)=>{e.key === "Enter" ? searchEvent() : null}} />
                        <img src={searchIcon} alt="serach_icon" onClick={(e) => { searchEvent()}} />
                    </SearchInputWrapper>
                </VideoSearch>
                <VideoMobileSearch>
                    <SearchInputWrapper>
                        <SearchInput value={searchText} onChange={(e)=>{setSearchText(e.target.value)}} type="text" placeholder="제목" onKeyPress={(e)=>{e.key === "Enter" ? searchEvent() : null}} />
                        <img src={searchIcon} alt="serach_icon" onClick={(e) => { searchEvent()}}/>
                    </SearchInputWrapper>
                </VideoMobileSearch>
                <Line />
                {loading ? <VideoList data={resData} slide={15} nowPage={pageNumber} length={totalPage} /> : <SkeletonVideo />}

            </VideoWrapper>
        </>
    )
}

export default Video