import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Members from '../../../Members/index';

export default () => (
  <Switch>
    <Route exact path="/admin/members" component={Members} />
    <Route exact path="/admin/members/detail" component={Members} />
  </Switch>
);
