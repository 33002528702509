/* eslint-disable */
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
// import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
// import renderIntervalDatePickerField from '@/shared/components/form/IntervalDatePicker';
import renderRadioButtonField from '@/shared/components/form/RadioButton';
import {
  Card, CardBody, Col, Button, ButtonToolbar,
} from 'reactstrap';

import ResponsiveTable from "./ResponsiveTable";
import TextField from '@material-ui/core/TextField';
// import MenuItem from '@material-ui/core/MenuItem';
import DatePicker from "react-datepicker";
// import styled from "styled-components"
import axios from "axios"

export const StartDay = ({setStart}) => {
    const [startDate, setStartDate] = useState(null);
    return (
      <DatePicker
       selected={startDate}
       placeholderText="작성기간을 택하세요"
       onChange={(date) => {
         setStartDate(date)
         setStart(date)
      }}
    />
  );              
};

export const EndDay = ({setEnd, start}) => {
  const [startDate, setStartDate] = useState(null);
  return (
    <DatePicker
      selected={startDate}
      placeholderText="작성기간을 택하세요"
      minDate={start}
      onChange={(date) => {
        setStartDate(date)
        setEnd(date)
      }} 
    />
  );
};

const renderTextField = ({
  input, label, meta: { touched, error }, children, select, multiline, handleChange
}) => (
  <TextField
    className="material-form__field"
    label={label}
    type={input.type}
    error={touched && error}
    value={input.value}
    select={select}
    multiline={multiline}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
      handleChange(e, label)
    }}
  >
    {children}
  </TextField>
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  select: PropTypes.bool,
  children: PropTypes.node,
  multiline: PropTypes.bool,
};

renderTextField.defaultProps = {
  meta: null,
  select: false,
  children: [],
  multiline: false,
};
const AnimatedLineFormWithLabels = ({ onSubmit }) => {
  const [start, setStart] = useState(null)
  const [end, setEnd] = useState(null) 
  const [replyData, setReplyData] = useState([])
  const [loading, setLoading] = useState(false)
  const [listNum, setListNum] = useState(20)
  const [pageNum, setPageNum] = useState(1)
  const [replyContent, setReplyContent] = useState("")
  const [status, setStatus] = useState("")
  const [writer, setWriter] = useState("")
  const [onOff, setOnoff] = useState(false)
  const [delRender, setDelRender] = useState(false)


  // const { t } = useTranslation('common');
  const [searchClick,setSearchClick] = useState(false)

  const handleChange = (e, label) => {
    // console.log(label)
    // console.log(e.target.value)
    if (label === "댓글 내용"){
      setReplyContent(e.target.value)
    } else if (label === "작성자") {
      setWriter(e.target.value)
    }
  }

  const handleClick = (value) => {
    setStatus(value)
  }

  useEffect(() => {
    const startDay = start ? start : null
    const endDay = end ? end : null
  
    const data = {replyContent, status, writer, start: startDay, end: endDay, limit: listNum, pageNum}
   
    axios.post(`${process.env.REACT_APP_API_URL}/replysearch`, data)
      .then(res => setReplyData(res.data))
      .then(() => setLoading(true))
      .catch(err => console.log(err))
  }, [pageNum, listNum, delRender])

  if(!loading) return null

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h1>댓글조회</h1>
            <h5 className="subhead">조회할 회원정보를 입력하세요.</h5>
          </div>
          <Form onSubmit={(e) => onSubmit(e, start, end, setReplyData, setLoading, setOnoff)}>
            {({ handleSubmit, form }) => (
              <form className="material-form" onSubmit={handleSubmit}>
                
                <div className="row">
                  <div className="col-6">
                    <div className="form__form-group">
                      <span className="form__form-group-label">댓글등록일</span>
                      <div className="form__form-group-field">
                        <StartDay setStart={setStart}/>
                        <EndDay setEnd={setEnd} start={start}/>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form__form-group">
                      <span className="form__form-group-label">상태</span>
                      <div className="form__form-group-field">
                        <p className="form__form-group-input-wrap" onClick={() => handleClick("all")}>
                          <Field
                            name="status"
                            component={renderRadioButtonField}
                            label="전체"
                            radioValue="all"
                          />
                        </p>
                        <p className="form__form-group-input-wrap" onClick={() => handleClick("normal")}>
                          <Field
                            name="status"
                            component={renderRadioButtonField}
                            label="정상"
                            radioValue="normal"
                            handleChange={handleChange}
                          />
                        </p>
                        <p className="form__form-group-input-wrap" onClick={() => handleClick("del")}>
                          <Field
                            name="status"
                            component={renderRadioButtonField}
                            label="삭제"
                            radioValue="del"
                            handleChange={handleChange}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className="col-6">
                    <Field
                      name="replyContent"
                      component={renderTextField}
                      placeholder="댓글 내용"
                      label="댓글 내용"
                      handleChange={handleChange}
                    />
                  </div>
                  <div className="col-6">
                    <Field
                      name="writer"
                      component={renderTextField}
                      placeholder="작성자"
                      label="작성자"
                      handleChange={handleChange}
                    />
                  </div>
                </div>
                  
                  
                  
                    
                  <div className="col-3">
                    <ButtonToolbar className="form__button-toolbar">
                    <Button color="primary" type="submit" onClick={()=>{setSearchClick(true)}}>조회</Button>
                    <Button type="button" color="danger" onClick={() => { setSearchClick(false) }}>취소</Button>
                    </ButtonToolbar>
                  </div>
                    
               <br />
                {searchClick ? <hr/> : null}
                {searchClick && loading ? <ResponsiveTable replyData={replyData} setListNum={setListNum} listNum={listNum} setPageNum={setPageNum} setOnoff={setOnoff} onOff={onOff} setDelRender={setDelRender}/> : null}
              </form>
            )}
          </Form>
        </CardBody>
      </Card>
    </Col>
  );
};

AnimatedLineFormWithLabels.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default AnimatedLineFormWithLabels;
