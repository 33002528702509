// import React, {useState, useEffect} from 'react';
// import { useTranslation } from 'react-i18next';
// import { Col, Container, Row } from 'reactstrap';
// import showResults from './showResults';
// import Sechdules from './components/Sechdules';
// import axios from "axios";

// const Members = () => {
//   const { t } = useTranslation('common');
//   const [eventList, setEventList] = useState({})
//   const [totalList, setTotalList] = useState([])
//   const [category, setCategory] = useState(1)
//   const [totalCount, setTotalCount] = useState(0)
//   const [pageNum, setPageNum] = useState("")
//   useEffect(() => {
//     axios.get(`${process.env.REACT_APP_API_URL}/showschedule?category=${category}&page=${pageNum}`)
//       .then((data) => {
//         setTotalCount(data.data.count)
//         return data
//     })
//     .then(res => setTotalList(res.data.data2))
//     .catch(err => console.log(err))
//   }, [eventList, category, pageNum])

//   return (
//     <Container>
//       <Sechdules onSubmit={showResults} category={category} setCategory={setCategory} setEventList={setEventList} eventList={eventList} totalList={totalList} setPageNum={setPageNum} totalCount={totalCount}/>
//       <Row>
//         <Col md={12}>
//           <hr />
//         </Col>
//       </Row>
//     </Container>
//   );
// };

// export default Members;


import React, {useState, useEffect} from 'react';
import { Col, Container, Row } from 'reactstrap';
import showResults from './showResults';
import Sechdules from './components/Sechdules';
import axios from "axios";
import { useDispatch } from "react-redux";
// import {getList} from "../../redux/actions/searchList"


const Members = () => {
  const dispatch = useDispatch();
  const [eventList, setEventList] = useState({})
  const [totalList, setTotalList] = useState([])
  const [category, setCategory] = useState(1)
  const [totalNum, setTotalNum] = useState(0)
  const [pageNum, setPageNum] = useState(1)
  const [readNum, setReadNum] = useState(0)
  const [oneData, setOneData] = useState("")
  const [loading, setLoading] = useState(false)
  const [updateAmount, setUpdateAmount] = useState([])
  const [update, setUpdate] = useState(false)

  useEffect(() => {
    let isMount = true;
    // setLoading(false)
    // axios.get(`${process.env.REACT_APP_API_URL}/scheduleread?readnum=${readNum}`)
    // .then(res=> {
    //   // console.log(dispatch(getList({hi: "hi"})))
    //   setOneData(res.data)
    //   // dispatch(getList(res.data))
    // })
    // .then(() => setLoading(true))
    // .catch(err=>console.log(err))

    axios.get(`${process.env.REACT_APP_API_URL}/showschedule?category=${category}&pagenum=${pageNum}`)
    .then(res => {
      if (isMount){
        setTotalList(res.data.data2)
        setTotalNum(res.data.count)
      }
    })
    .then(() => {
      axios.get(`${process.env.REACT_APP_API_URL}/loadamount`)
      .then((res) => setUpdateAmount(res.data.data))
      .then(() => setLoading(true))
      .catch(err => console.log(err.response))
    })
    .catch(err => console.log(err))
    return () => { isMount = false}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventList, category, pageNum, readNum, update])

  if (!loading) return null;

  return (
    <Container>
      <Sechdules 
        onSubmit={showResults} 
        totalNum={totalNum} 
        category={category} 
        setCategory={setCategory} 
        setEventList={setEventList} 
        eventList={eventList} 
        totalList={totalList}
        setPageNum={setPageNum}
        setReadNum={setReadNum}
        oneData={oneData}
        setOneData={setOneData}
        loading={loading}
        setLoading={setLoading}
        updateAmount={updateAmount}
        setUpdate={setUpdate}
      />
      <Row>
        <Col md={12}>
          <hr />
        </Col>
      </Row>

    </Container>
  );
};

export default Members;
          
            
    

          
    
    
  