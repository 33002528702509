import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Field, Form } from 'react-final-form'
import { Card, CardBody, Col, Button, ButtonToolbar, Table } from 'reactstrap'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Write from './Write'
import showResults from './showResults'
import { BroserRouter, useHistory } from 'react-router-dom'
import axios from 'axios'
import ModifyWrite from './ModifyWrite'
import Pagination from './Pagination'
import moment from "moment"

const renderTextField1 = ({ input, label, meta: { touched, error }, children, select, multiline, history, disableset }) => (
  <TextField
    className='material-form__field'
    label={label}
    type={input.type}
    error={touched && error}
    value={input.value}
    select={select}
    multiline={multiline}
    disabled={disableset}
    onChange={(e) => {
      e.preventDefault()
      input.onChange(e.target.value)
      if (e.target.value === '2') {
        history.push('/admin/managements/schedules')
      } else if (e.target.value === '3') {
        history.push('/admin/managements/videos')
      } else if (e.target.value === '4') {
        history.push('/admin/managements/academics')
      }
    }}
  >
    {children}
  </TextField>
)
const renderTextField2 = ({
  input,
  label,
  meta: { touched, error },
  children,
  select,
  multiline,
  setSelectNum,
  setBoardList,
  setSearchListSet,
  disableset,
}) => (
  <TextField
    className='material-form__field'
    label={label}
    type={input.type}
    error={touched && error}
    value={input.value}
    select={select}
    multiline={multiline}
    disabled={disableset}
    onChange={(e) => {
      e.preventDefault()
      input.onChange(e.target.value)
      setSelectNum(parseInt(e.target.value))
      if (e.target.value === '1') {
        const newBoardList = []
        setBoardList(newBoardList)
        setSearchListSet(1)
      } else if (e.target.value === '2') {
        const newBoardList = []
        setBoardList(newBoardList)
        setSearchListSet(2)
      } else if (e.target.value === '3') {
        const newBoardList = []
        setBoardList(newBoardList)
        setSearchListSet(3)
      } else if (e.target.value === '4') {
        const newBoardList = []
        setBoardList(newBoardList)
        setSearchListSet(4)
      } else if (e.target.value === '5') {
        const newBoardList = []
        setBoardList(newBoardList)
        setSearchListSet(5)
      } else if (e.target.value === '6') {
        const newBoardList = []
        setBoardList(newBoardList)
        setSearchListSet(6)
      }
    }}
  >
    {children}
  </TextField>
)
const headerResponsive = [
  { id: 1, title: '번호' },
  { id: 2, title: '제목' },
  { id: 3, title: '작성자' },
  { id: 4, title: '작성일' },
  { id: 5, title: '작성IP' },
  { id: 7, title: '첨부' },
  { id: 8, title: 'url' },
  { id: 9, title: '수정' },
  { id: 10, title: '상태변경' },
]
const AnimatedLineFormWithLabels = ({ onSubmit, setRendChange, rendChange }) => {
  const { t } = useTranslation('common')
  const [write, setWrite] = useState(false)
  const [showModifyWrite, setShowModifyWrite] = useState(false)
  const [selectNum, setSelectNum] = useState('')
  const [boardList, setBoardList] = useState([])
  const [boardReadVal, setBoardReadVal] = useState([])
  const [rend, setRend] = useState(false)
  const [test, setTest] = useState(0)
  const [statusChange, setStatusChange] = useState('')
  const [totalCount, setTotalCount] = useState(0)
  const [pageNum, setPageNum] = useState('')
  const [searchListSet, setSearchListSet] = useState(0)
  const [disableset, setDisableset] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const [loading3, setLoading3] = useState(false)
  // const my_tbody = document.getElementById('table--bordered')
  const searchList = (number) => {
    const value = {
      num: number,
      page: pageNum,
    }
    axios
      .post(`${process.env.REACT_APP_API_URL}/boardList`, value)
      .then((data) => {
        setTotalCount(data.data.count)
        return data
      })
      .then((data2) => setBoardList(data2.data.data2))
      .then(() => setLoading(true))
      .catch((err) => console.log(err))
  }
  const boardRead = (num) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/boardRead`, {
        data: num,
      })
      .then((data) => setBoardReadVal(data.data.data))
      .then(() => setLoading2(true))
      .catch((err) => console.log(err))
  }
  const history = useHistory()

  useEffect(() => {
    if (searchListSet === 0) {
      searchList(1)
    } else {
      searchList(searchListSet)
    }
  }, [pageNum, searchListSet, loading, rend, loading3])
  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className='card__title'>
            <h1>게시판관리</h1>
          </div>
          <Form onSubmit={onSubmit}>
            {({ handleSubmit }) => (
              <form className='material-form' onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-4'>
                    <Field
                      name='select'
                      component={renderTextField1}
                      select
                      label='구분'
                      defaultValue={1}
                      history={history}
                      disableset={disableset}
                    >
                      <MenuItem className='material-form__option' value='1'>
                        게시판관리
                      </MenuItem>
                      <MenuItem className='material-form__option' value='2'>
                        일정관리
                      </MenuItem>
                      <MenuItem className='material-form__option' value='3'>
                        동영상관리
                      </MenuItem>
                      <MenuItem className='material-form__option' value='4'>
                        학술상관리
                      </MenuItem>
                    </Field>
                  </div>
                  <div className='col-4'>
                    <Field
                      name='select2'
                      component={renderTextField2}
                      select
                      label='하위 구분'
                      defaultValue={1}
                      setSelectNum={setSelectNum}
                      setSearchListSet={setSearchListSet}
                      setBoardList={setBoardList}
                      disableset={disableset}
                    >
                      <MenuItem className='material-form__option' value='1'>
                        공지사항
                      </MenuItem>
                      <MenuItem className='material-form__option' value='2'>
                        소식지
                      </MenuItem>
                      <MenuItem className='material-form__option' value='3'>
                        학술지
                      </MenuItem>
                      <MenuItem className='material-form__option' value='4'>
                        회원게시판
                      </MenuItem>
                      <MenuItem className='material-form__option' value='5'>
                        질의응답
                      </MenuItem>
                      <MenuItem className='material-form__option' value='6'>
                        보도자료
                      </MenuItem>
                    </Field>
                  </div>
                  <div className='col-3'>
                    <ButtonToolbar className='form__button-toolbar'>
                      <Button
                        color='success'
                        onClick={() => {
                          setWrite((e) => !e)
                          setShowModifyWrite(false)
                          setDisableset((e) => !e)
                        }}
                      >
                        글쓰기
                      </Button>
                    </ButtonToolbar>
                  </div>
                </div>
                <br />
              </form>
            )}
          </Form>
          <br />
          {write ? (
            <Write
              onSubmit={showResults}
              selectNum={selectNum}
              cancelClick={setWrite}
              setRend={setRend}
              setDisableset={setDisableset}
              setLoading={setLoading}
              setRendChange={setRendChange}
            ></Write>
          ) : null}
          <hr />
          <Table responsive className='table--bordered' id='table--bordered'>
            <thead>
              <tr>
                {headerResponsive.map((item) => (
                  <th key={item.id}>{item.title}</th>
                ))}
              </tr>
            </thead>
            <tbody id='table--bordered'>
              {loading ? boardList.map((item, idx) => (
                <>
                  <tr key={item.id_notice}>
                    <td>{item.id_notice}</td>
                    <td>{item.title}</td>
                    <td>{item.writer}</td>
                    <td>{moment(item.c_date).format('YYYY-MM-DD / HH:mm:ss')}</td>
                    <td>{item.ip}</td>
                    {item.file_link ? <td>O</td> : <td>X</td>}
                    <td>
                      {/* <a href={`${process.env.REACT_APP_URL}/app/notification/notice/${item.board_num}/${item.type}/${item.id_notice}`}></a>   */}
                      {item.status === "2" ? <Button onClick={()=> window.alert("삭제된 게시물입니다.")}>이동</Button>
                          :<Button onClick={() => {
                            if (item.type === "1") {
                              history.push(`/app/notification/notice/${item.board_num}/${item.type}/${item.id_notice}`)
                            } else if (item.type === "2") {
                              history.push(`/app/notification/newsletter/${item.board_num}/${item.type}/${item.id_notice}`)
                            } else if (item.type === "3") {
                              history.push(`/app/academic/journal/${item.board_num}/${item.type}/${item.id_notice}`)
                            } else if (item.type === "4") {
                              history.push(`/app/community/userboard/${item.board_num}/${item.type}/${item.id_notice}`)
                            } else if (item.type === "5") {
                              history.push(`/app/community/qna/${item.board_num}/${item.type}/${item.id_notice}`)
                            }  else if (item.type === "6") {
                              history.push(`/app/community/data/${item.board_num}/${item.type}/${item.id_notice}`)
                            } 
                          }}>이동</Button>}
                    </td>
                    <td>
                      <Button
                        color='warning'
                        onClick={() => {
                          // console.log('수정눌림')
                          setShowModifyWrite((e) => !e)
                          setWrite(false)
                          boardRead(item.id_notice)
                          setDisableset((e) => !e)
                          setRend(!rend)
                          setTest(idx)
                          setLoading2(false)
                          // console.log(idx)
                          // console.log(boardRead)
                        }}
                      >
                        수정
                      </Button>
                    </td>
                    <td>
                      <div className='row'>
                        <TextField
                          className='material-form__field'
                          id='status'
                          defaultValue={item.status}
                          select
                          onChange={(e) => {
                            setStatusChange(e.target.value)
                          }}
                        >
                          <MenuItem className='material-form__option' value='1'>
                            확인
                          </MenuItem>
                          <MenuItem className='material-form__option' value='2'>
                            삭제
                          </MenuItem>
                        </TextField>
                        &nbsp;&nbsp;
                        <Button
                          color='primary'
                          onClick={() => {
                            // console.log(item.id_notice)
                            // console.log(statusChange)
                            if (window.confirm('변경하시겠습니까?')) {
                              const values = {
                                id_notice: item.id_notice,
                                status: statusChange,
                                num: 1,
                              }
                              axios
                                .post(`${process.env.REACT_APP_API_URL}/noticesStatusUpdate`, values)
                                .then((data) => {
                                  if (searchListSet === 0) {
                                    searchList(1)
                                  } else {
                                    searchList(searchListSet)
                                  }
                                })
                                .catch((err) => console.log(err))
                              window.alert("변경되었습니다.")
                            }
                          }}
                        >
                          확인
                        </Button>
                      </div>
                    </td>
                  </tr>
                  <td colSpan='9'>
                    {showModifyWrite && loading2 ? (
                      idx === test ? (
                        <ModifyWrite
                          onSubmit={showResults}
                          setDisableset={setDisableset}
                          boardReadVal={boardReadVal}
                          setShowModifyWrite={setShowModifyWrite}
                          setLoading3={setLoading3}
                          setLoading={setLoading}
                          boardRead={boardRead}
                        />
                      ) : null
                    ) : null}
                  </td>
                </>
              )) : null}
            </tbody>
          </Table>
          <Pagination total={totalCount} setPageNum={setPageNum} />
        </CardBody>
      </Card>
    </Col>
  )
}

export default AnimatedLineFormWithLabels
