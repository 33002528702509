import React from 'react';
import styled, { css } from "styled-components"

import Banner from './components/Banner';
import Dropdown from "../../components/DropDown";

import { mobile } from '../../lib/viewport';


const ContentsTitle = styled.div`
    font-size: 14px;
    font-weight: bold;
`

const ContentsText = styled.div`
    font-size: 12px;
    word-break:keep-all;
    margin-bottom: 20px;
`

const DropdownWrapper = styled.div`
    margin: 50px auto 20px auto;
    padding: 0px 16px;
    ${mobile(css`
        margin: 20px auto 40px auto;
    `)}
`


function Rule() {
    return ( 
        <>            
            <Banner title="rule"></Banner>
            <DropdownWrapper>
                <Dropdown title="임평의원회 운영규정">
                    <ContentsTitle>제 1조(명칭)</ContentsTitle>
                    <ContentsText>본 회의 명칭은 누가통증연구회(Luke Medical Association, 이하 본 ”회”)라 칭한다.</ContentsText>
                    {/* <ContentsTitle>제 2조(명칭)</ContentsTitle>
                    <ContentsText>본 회는 의학의 발전을 위하여 학술활동을 지원하고, 회원 상호 간 친목 도모 및 권익을 증진시키며, 국민보건 향상에 기여함에 있다</ContentsText>
                    <ContentsTitle>제 3조(명칭)</ContentsTitle>
                    <ContentsText>본 회의 사무실은 서울시 금천구 가산디지털로1로 5 대륭테크노타운 1013호에 두고, 필요에 따라 지부를 설립할 수 있다. </ContentsText> */}
                </Dropdown>
                <Dropdown title="회장 선출 규정"></Dropdown>
                <Dropdown title="명예회원 선출 규정"></Dropdown>
                <Dropdown title="해외회원 선출 규정"></Dropdown>
                <Dropdown title="산하 학회 운영규정"></Dropdown>
            </DropdownWrapper>
        </>
    );
}

export default Rule;
