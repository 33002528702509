import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ArticlesManagements from '../../../Managements/ArticlesIndex';
import SechdulesManagements from '../../../Managements/SechdulesIndex';
import VideoManagements from '../../../Managements/VideoIndex';
import AcademicManagements from '../../../Managements/AcademicsIndex';

export default () => (
  <Switch>
    <Route exact path="/admin/Managements/articles" component={ArticlesManagements} />
    <Route exact path="/admin/Managements/schedules" component={SechdulesManagements} />
    <Route exact path="/admin/Managements/videos" component={VideoManagements} />
    <Route exact path="/admin/Managements/academics" component={AcademicManagements} />
  </Switch>
);
