/* eslint-disable no-alert */
import axios from "axios"

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));


export default (async function showResults(values, startTime, endTime, setReplyData, setLoading, setOnoff) {
  await sleep(500); // simulate server latency

  // console.log(values)

  const start = startTime ? startTime : null
  const end = endTime ? endTime : null
  const limit = 20
  const pageNum = 1

  const data = {...values, start, end, limit, pageNum}

  setOnoff(pre => !pre)
 
  axios.post(`${process.env.REACT_APP_API_URL}/replysearch`, data)
    .then(res => setReplyData(res.data))
    .then(() => setLoading(true))
    .catch(err => console.log(err))
});
