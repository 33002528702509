const BasicTableData = () => {
  // const header = [
  //   { id: 1, title: '#' },
  //   { id: 2, title: 'First Name' },
  //   { id: 3, title: 'Last Name' },
  //   { id: 4, title: 'Username' },
  //   { id: 5, title: 'Status' },
  // ];

  const headerResponsive = [
    { id: 1, title: '번호' },
    { id: 2, title: '상태' },
    { id: 3, title: '게시판' },
    { id: 4, title: '제목' },
    { id: 5, title: '조회수' },
    { id: 7, title: '댓글수' },
    { id: 8, title: '작성시간' },
    { id: 9, title: '작성IP' },
    { id: 10, title: 'url' },
    { id: 11, title: '삭제' },
  ];
 
  const rows = [];
  for (let i = 0; i < 10; i++) {
      rows.push({
      id: i,
        no: i,
        status: ['게시', '삭제'][Math.floor((Math.random() * 2))],
        board: ['회원게시판', '질의응답'][Math.floor((Math.random() * 2))],
        title: '가나다라마바사',
        views: parseInt(Math.random() * 1000),
        replyCount: parseInt(Math.random() * 1000),
        regTime: '2020-01-01',
        ip: parseInt(Math.random() * 1000),
        url: '',
        del: '',
    });
  }

  const basicTableData = { tableHeaderResponsiveData: headerResponsive, tableRowsData: rows };
  return basicTableData;
};

export default BasicTableData;
