import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import showResults from './showResults';
import Articles from './components/Articles';

const Members = () => {
  const { t } = useTranslation('common');
    const [rendChange,setRendChange] = useState(false)
  return (
    <Container>
      <Articles onSubmit={showResults} setRendChange={setRendChange} rendChange={rendChange} />
      <Row>
        <Col md={12}>
          <hr />
        </Col>
      </Row>
   
    </Container>
  );
};

export default Members;
