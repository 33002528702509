/* eslint-disable */
import React from 'react'
import styled, {css, keyframes} from 'styled-components'
import { mobile } from './lib/viewport'
import {v4 as uuidv4} from 'uuid'

const SkeletonVideo = () => {
    return (
        <>
            {Array(2).fill(0).map(el => (
                <ListItem key={uuidv4()}>
                    <Image></Image>
                    <ListItemInfo>
                        <div style={{width: "70%"}}>&nbsp;</div>
                        <ListItemTitle>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </ListItemTitle>
                    </ListItemInfo>
                </ListItem>
            ))}
        </>
    )
}

export default SkeletonVideo

const placeHolderShimmer = keyframes`
    0% {
      background-position: -468px 0
    }
    100% {
      background-position: 468px 0
    }
  `


const ListItem = styled.div`
    width:100%;
    height: 160px;
    padding: 20px 0px;
    border: solid 0px;
    display:flex;
    align-items:center;

    border-bottom: solid 0.7px #e0e1e5;

    cursor:pointer;

    &:hover{
        /* border: solid 0.7px #000; */
        background-color: #eaebed;
    }

    ${mobile(css`
        padding: 4px 12px;
        height:100%;
    `)}
`

const Image = styled.div`
    width: 135px;
    height:95px;
    margin-right: 20px;

    border-radius: 5px;
   -webkit-animation-duration: 0.5s;
   -webkit-animation-fill-mode: forwards;
   -webkit-animation-iteration-count: infinite;
   -webkit-animation-name: ${placeHolderShimmer};
   -webkit-animation-timing-function: linear;
   background: #d8d8d8;
   background-image: linear-gradient(to right, #d8d8d8 0%, #bdbdbd 20%, #d8d8d8 40%, #d8d8d8 100%);
   background-repeat: no-repeat;
   position: relative

    ${mobile(css`
        width: 120px;
        height: 80px;
    `)}
`

const ListItemInfo = styled.div`
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    padding: 20px 0px;
    > div {
        border-radius: 5px;
        -webkit-animation-duration: 0.5s;
        -webkit-animation-fill-mode: forwards;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-name: ${placeHolderShimmer};
        -webkit-animation-timing-function: linear;
        background: #d8d8d8;
        background-image: linear-gradient(to right, #d8d8d8 0%, #bdbdbd 20%, #d8d8d8 40%, #d8d8d8 100%);
        background-repeat: no-repeat;
    }
`

const ListItemTitle = styled.div`
    font-size: 20px;
    margin: 2px 0px 10px 0px;
    width: 100%;
    border-radius: 5px;
    -webkit-animation-duration: 0.5s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: ${placeHolderShimmer};
    -webkit-animation-timing-function: linear;
    background: #d8d8d8;
    background-image: linear-gradient(to right, #d8d8d8 0%, #bdbdbd 20%, #d8d8d8 40%, #d8d8d8 100%);
    background-repeat: no-repeat;

    ${mobile(css`
        font-size: 16px;
        word-break:keep-all;
        margin: 2px 0px 4px 0px;
    `)}
`

