import React, { useEffect } from 'react';
import PropTypes, { arrayOf } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import {
  Card, CardBody, Col, Button, ButtonToolbar, Row, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, ButtonGroup,
} from 'reactstrap';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import MemberTable from './MemberTable';
import CreateTableData from '../CreateData';
import { useState, useReducer } from 'react'
import memberSearch from '../memberSearch';
import MemberDetail from './MemberDetail';
import MemberSearchData from './MemberSearchData';
import showResults from '@/utils/showResults';


const renderTextField = ({
  input, label, meta: { touched, error }, children, select, multiline,
}) => (
  <TextField
    className="material-form__field"
    label={label}
    type={input.type}
    error={touched && error}
    value={input.value}
    select={select}
    multiline={multiline}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
  >
    {children}
  </TextField>
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  select: PropTypes.bool,
  children: PropTypes.node,
  multiline: PropTypes.bool,
};

renderTextField.defaultProps = {
  meta: null,
  select: false,
  children: [],
  multiline: false,
};

const AnimatedLineFormWithLabels = ({ onSubmit, userList, total, searchList, setListNum, listNum, searchValue }) => {
  const { tableHeaderResponsiveData, tableRowsData } = MemberSearchData();
  const { t } = useTranslation('common');
  const reactTableData = CreateTableData();
  const [memberTable, setMemberTable] = useState(false);
  const [memberDetail, setMemberDetail] = useState(false);
  const [userInfo, setUserInfo] = useState({
    address_detail: "",
    address_main: "",
    advertisement_agree: "",
    birthday: "",
    cellphone: "",
    email: "",
    gender: "",
    id_user_info: "",
    license_number: "",
    member_type: "",
    name: "",
    profile_image_link: "",
    specialized_field: "",
    user_id: "",
    userid: "",
    password: "",
    zipcode: "",
    ip: "",
    join_date: "",
    memo: "",
    last_date: ""
  })

  const memberDetailFunc = (result) => {
    setMemberDetail(result)
  }
  const memberInfo = (result) => {
    //여기작업
    setUserInfo({...userInfo, ...result})
  }

  // const [any, forceUpdate] = useReducer(num => num + 1, 0);
  // function handleChange(){
  //   forceUpdate();
  // }


  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h1>회원검색</h1>
            <h5 className="subhead">조회할 회원정보를 입력하세요.</h5>
          </div>
          <Form onSubmit={(e) => onSubmit(e, 20, 1)}>
            {({ handleSubmit, form }) => (
              <form className="material-form" onSubmit={handleSubmit}>
                
                <div className="row">
                  <div className="col-3">
                    <Field
                      name="select"
                      component={renderTextField}
                      select
                      label="정렬순"
                      defaultValue={1}
                    >
                      <MenuItem className="material-form__option" value="1">번호</MenuItem>
                      <MenuItem className="material-form__option" value="2">성함</MenuItem>
                      <MenuItem className="material-form__option" value="3">아이디</MenuItem>
                      <MenuItem className="material-form__option" value="4">면허번호</MenuItem>
                      <MenuItem className="material-form__option" value="5">전화번호</MenuItem>
                      <MenuItem className="material-form__option" value="6">가입일</MenuItem>
                      <MenuItem className="material-form__option" value="7">마지막 접속</MenuItem>
                      <MenuItem className="material-form__option" value="8">메모</MenuItem> */
                    </Field>
                  </div>
                  <div className="col-3">
                    <Field
                      name="select1"
                      label="정렬순"
                      component={renderTextField}
                      select
                      defaultValue={1}
                    >
                      <MenuItem className="material-form__option" value="1">내림차순</MenuItem>
                      <MenuItem className="material-form__option" value="2">오름차순</MenuItem>
                    </Field>
                  </div>
                  <div className="col-3">
                  <Field
                      name="username"
                      component={renderTextField}
                      placeholder="성함"
                      label="성함"
                    />
                  </div>
                  <div className="col-3">
                    <Field
                      name="phoneNum"
                      component={renderTextField}
                      placeholder="전화번호"
                      label="전화번호"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-3">
                    <Field
                      name="select2"
                      component={renderTextField}
                      select
                      label="상태"
                      defaultValue={1}
                    >
                      <MenuItem className="material-form__option" value="1">전체</MenuItem>
                      <MenuItem className="material-form__option" value="2">정회원</MenuItem>
                      <MenuItem className="material-form__option" value="3">준회원</MenuItem>
                      <MenuItem className="material-form__option" value="4">승인대기</MenuItem>
                      <MenuItem className="material-form__option" value="5">탈퇴</MenuItem>
                    </Field>
                  </div>
                  <div className="col-3">
                    <Field
                      name="licenseNum"
                      component={renderTextField}
                      placeholder="면허번호"
                      label="면허번호"
                    />
                  </div>
                  <div className="col-3">
                    <Field
                      name="memo"
                      component={renderTextField}
                      placeholder="메모"
                      label="메모"
                    />
                  </div>
                                
                  <div className="col-3">
                    <ButtonToolbar className="form__button-toolbar">
                      <Button color="primary" type="submit" onClick={()=>{setMemberTable(true)}}>조회</Button>
                      <Button type="button" color="danger" onClick={()=>{setMemberTable(false)}}>취소</Button>
                    </ButtonToolbar>
                  </div>
                    
                </div>
              </form>
            )}
          </Form>
          <br />
          {memberTable ? <hr/> : null}
          {memberTable ? <MemberTable userList={userList} searchValue={searchValue} setListNum={setListNum} listNum={listNum} searchList={searchList} total={total} reactTableData={reactTableData} memberDetailFunc={memberDetailFunc} memberInfo={memberInfo}/> : null}
          <br />
          {memberDetail ? <hr/> : null}
          {memberDetail ? <MemberDetail onSubmit={memberSearch} setUserInfo={setUserInfo} memberInfo={userInfo} /> : null}
        </CardBody>
      </Card>
    </Col>
  );
};

AnimatedLineFormWithLabels.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default AnimatedLineFormWithLabels;
