 /* eslint-disable */
import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Field, Form } from 'react-final-form'
import { Button, ButtonToolbar } from 'reactstrap'
import TextField from '@material-ui/core/TextField'
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon'
// import renderDatePickerField from '@/shared/components/form/DatePicker'
import JustifyTabsBorderedTop from './JustifyTabsBorderedTop'
import SummerNote from './SummerNote.jsx'
import renderFileInputField from '@/shared/components/form/FileInput'
import DatePicker from 'react-datepicker'
import MenuItem from '@material-ui/core/MenuItem'
import styled from "styled-components"

const CalendarInput = styled.input`
 outline: none;
 border: none;
 margin-top: 13px;
 border-bottom: 1px solid #f2f4f7;
 cursor: pointer;
 :focus {
   border-bottom: 1px solid #4ce1b6;
 }
 :hover {
   border-bottom: 1px solid #4ce1b6;
 }
`


export const Sdate = ({handleDate}) => {
  const [startDate, setStartDate] = useState(new Date(Date.now()));
  return (
    <DatePicker 
      selected={startDate} 
      onChange={(date) => {
        setStartDate(date)
        // setPreStart(date)
        handleDate(date, "start")
      }}
      customInput={<CalendarInput />}
      placeholderText="시작일"
      dateFormat="yyyy-MM-dd"
      // minDate={new Date()}
    />
  );
}

export const Edate = ({handleDate, start}) => {
  const [startDate, setStartDate] = useState(null);

  useEffect(() => {
    setStartDate(start ? new Date(start) : null) 
  }, [start])

  return (
    <DatePicker 
      selected={startDate} 
      onChange={(date) => {
        if (new Date(start).getTime() > new Date(date).getTime()){
          setStartDate(start)
          window.alert("일정 시작 날짜보다 이전 날짜를 선택할 수 없습니다.")
        } else {
          setStartDate(date)
          // setPreStart(date)
          handleDate(date, "end")
        }
      }} 
      customInput={<CalendarInput />}
      placeholderText="종료일"
      dateFormat="yyyy-MM-dd"
      // minDate={new Date()}
    />
  );
}

const renderTextField = ({ input, label, meta: { touched, error }, children, select, multiline, disabled }) => (
  <TextField
    className='material-form__field'
    label={label}
    type={input.type}
    error={touched && error}
    value={input.value}
    select={select}
    multiline={multiline}
    onChange={(e) => {
      e.preventDefault()
      input.onChange(e.target.value)
    }}
    disabled={disabled}
  >
    {children}
  </TextField>
)

const renderTextField2 = ({ input, label, meta: { touched, error }, children, handleSelect, select, multiline }) => (
  <TextField
    className='material-form__field'
    label={label}
    type={input.type}
    error={touched && error}
    value={input.value}
    select={select}
    multiline={multiline}
    onChange={(e) => {
      e.preventDefault()
      input.onChange(e.target.value)
      handleSelect(e.target.value)
    }}
  >
    {children}
  </TextField>
)


renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  select: PropTypes.bool,
  children: PropTypes.node,
  multiline: PropTypes.bool,
}

renderTextField.defaultProps = {
  meta: null,
  select: false,
  children: [],
  multiline: false,
}

const ScheduleWrite = (props) => {
  const [contents, setContents] = useState("")
  const [preRegi, setPreRegi] = useState("X")
  const [amount, setAmount] = useState([])
  const [preStart, setPreStart] = useState("")
  const [preEnd, setPreEnd] = useState("")
  const [start, setStart] = useState(new Date(Date.now()))
  const [end, setEnd] = useState(new Date(Date.now()))
  const [count, setCount] = useState((props.category === "1" || props.category === 1) ? "정기행사" : "학술대회")



  const handleOption = (e) => {
    setCount(e.target.value)
  }


  const handleDate = (date, type) => {
    if (type === "start") {
      setStart(date)
      setEnd(date)
    // } else if (new Date(start).getTime() > new Date(date).getTime()) {
    //   window.alert("일정 시작 날짜보다 이전 날짜를 선택할 수 없습니다.")
    } else {
      setEnd(date)
    }
  }
  useEffect(() => {
    props.setFetchData({select2:props.category, contents, preRegi, amount, preStart, preEnd, start, end, count})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contents, preRegi, amount, preStart, preEnd, start, end, count])

  // console.log("카운트", count)
  // console.log("카테고리", typeof props.category)
  return (
    <Fragment>
      <div className='card__title'>
        <h1>일정쓰기</h1>
      </div>
      <hr />
      <br />
      <br />
      <Form onSubmit={(e) => props.onSubmit(e, props.fetchData, props.setEventList)}>
        {({ handleSubmit, form }) => (
          <form className='material-form' onSubmit={handleSubmit}>
            <div className='row'>
              <div className='col-6'>
                <span className='form__form-group-label'>시작일</span>
                <div className='form__form-group-field'>
                  {/* <Field name='start_date' component={renderDatePickerField} /> */}
                  <Sdate handleDate={handleDate}/>
                  <div className='form__form-group-icon' style={{marginLeft: "10px", marginBottom: "10px"}}>
                    <CalendarBlankIcon />
                  </div>
                </div>
                <span className='form__form-group-label' style={{marginTop: "13px"}}>종료일</span>
                <div className='form__form-group-field'>
                  {/* <Field name='end_date' component={renderDatePickerField} /> */}
                  <Edate handleDate={handleDate} start={start} />
                  <div className='form__form-group-icon' style={{marginLeft: "10px", marginBottom: "10px"}}>
                    <CalendarBlankIcon />
                  </div>
                </div>
                <br />
                {/* <select onChange={handleOption}> */}
                  {props.category === 1 || props.category === "1"  
                  ? 
                  <>
                     <TextField className='material-form__field' id='status' defaultValue="정기행사" select onChange={handleOption}>
                         <MenuItem className='material-form__option' value='정기행사'>
                           정기행사
                         </MenuItem>
                         <MenuItem className='material-form__option' value='연수강좌'>
                           연수강좌
                         </MenuItem>  
                     </TextField>
                  {/* <option value="정기행사">정기행사</option>
                  <option value="연수강좌">연수강좌</option> */}
                  </>
                  :
                  <>
                   <TextField className='material-form__field' id='status' defaultValue="학술대회" select onChange={handleOption}>
                      <MenuItem className='material-form__option' value='학술대회'>
                        학술대회
                      </MenuItem>
                      <MenuItem className='material-form__option' value='학술집담회'>
                        학술집담회
                      </MenuItem>
                      <MenuItem className='material-form__option' value='워크숍 및 세미나'>
                        워크숍 및 세미나
                      </MenuItem>
                    </TextField>
                  {/* <option value="학술대회">학술대회</option>
                  <option value="학술잡담회">학술잡담회</option>
                  <option value="워크숍 및 세미나">워크숍 및 세미나</option> */}
                    </>}
                {/* </select> */}
                {/* 장소 */}
                <PlaceField></PlaceField>
                {/* 접수 */}
                <RegisterField></RegisterField>
                <span className='form__form-group-label'>사전등록</span>
                <div className='form__form-group-field'>
                  {/* <Field name='status' component={renderRadioButtonField} label='X' radioValue='x' /> ????*/}
                  <div className="form__form-group-input-wrap">
                    <label className="radio-btn">
                      <input className="radio-btn__radio" name="status" type="radio" value="x" checked={preRegi === "X" ? "checked" : null} onClick={() => setPreRegi("X")}/>
                        <span className="radio-btn__radio-custom"></span>
                        <span className="radio-btn__label">X</span>
                    </label>
                  </div>
                  <div className="form__form-group-input-wrap">
                  <label className="radio-btn">
                      <input className="radio-btn__radio" name="status" type="radio" value="o" checked={preRegi === "O" ? "checked" : null} onClick={() => setPreRegi("O")}/>
                        <span className="radio-btn__radio-custom"></span>
                        <span className="radio-btn__label">O</span>
                    </label>
                  </div>
                  {/* <Field name='status' component={renderRadioButtonField} label='O' radioValue='o' /> */}
                </div>
              </div>
              <div className='col-6'>
                <JustifyTabsBorderedTop preRegi={preRegi} setAmount={setAmount} amount={amount} setPreStart={setPreStart} setPreEnd={setPreEnd} preStart={preStart}></JustifyTabsBorderedTop>
              </div>
            </div>
            <br />
            <div className='row'>
              <div className='col-12'>
                <Field name='title' component={renderTextField} label="제목"/>
              </div>
            </div>
            <SummerNote setContents={setContents}></SummerNote>
            <br />
           <div className='form__form-group'>
              <span className='form__form-group-label'>첨부파일</span>
              <div className='form__form-group-field'>
                <Field name='file' component={renderFileInputField} />
              </div>
            </div>
            <ButtonToolbar className='form__button-toolbar'>
              <Button
                color='primary'
                type="submit"
                onClick={() => {
                  if (window.confirm('등록 하시겠습니까?')) {
                    window.alert('등록되었습니다.')
                    form.submit()
                    props.setWrite(pre => !pre)
                  }
                }}
              >
                확인
              </Button>
              <Button
                type='button'
                color='danger'
                onClick={() => {
                  props.setWrite(pre => !pre) 
                }}
              >
                취소
              </Button>
            </ButtonToolbar>
          </form>
              )}
      </Form>
    </Fragment>
  )
}

function PlaceField() {
  return <Field name='place' component={renderTextField} placeholder=' 입력해 주세요' label='장소' />
}
function RegisterField() {
  return <Field name='register' component={renderTextField} placeholder=' 입력해 주세요' label='접수' />
}

ScheduleWrite.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default ScheduleWrite
