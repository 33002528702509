import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useHistory } from "react-router"

import Award from './Award'
import Journal from './Journal';
import DetailJournal from "./DetailJournal"
import CalendarPage from './Calendar';
import Introduce from "./Schedule/Introduce"
import Registration from './Schedule/Registration';
import Confirm from './Schedule/Confirm';
import { useSelector } from "react-redux";

function RouteAcademic(){
  const history = useHistory()
  let adminState = null
  useSelector(state => { adminState = state.admin.admin }) 
  if (!adminState) {
    if (!localStorage.getItem("accessToken")) {
      history.push("/app/auth/signin")
      window.alert("로그인 후, 이용 가능합니다.")
    }
  }
  return (
    <>
  <Switch>
    <Route exact path="/app/academic/journal" component={Journal} />
    <Route exact path="/app/academic/journal/:number/:type/:length" component={DetailJournal} />
    <Route exact path="/app/academic/award" component={Award} />
    <Route exact path="/app/academic/calendar" component={CalendarPage} />
    <Route exact path="/app/academic/schedule/:number/introduce" component={Introduce} />
    <Route exact path="/app/academic/schedule/:number/registration" component={Registration} />
    <Route exact path="/app/academic/schedule/:number/confirm" component={Confirm} />
  </Switch>
    </>
  );
}

export default RouteAcademic