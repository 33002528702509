import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

import styled from "styled-components"

import "./MobileCalendar.scss"

import bnPrev from '../../../../../shared/img/bn-prev.svg'
import bnNext from '../../../../../shared/img/bn-next.svg'

const localizer = momentLocalizer(moment);

const formats = {
  dayFormat: (date, culture) => localizer.format(date, 'DD', culture),
};

const Img = styled.img`
  width: 7px;
`
const CalenderType = styled.div`
  display:flex;

  margin-top: 10px;
`
const CalenderTypeItem = styled.div`
  margin-right: 16px;

  display:flex;
  align-items:center;
`
const CalenderTypeItemColorBox = styled.div`
  width:14px;
  height:14px;
  background-color: ${props => props.bgColor};
  margin-right: 6px;
`
const MobileCalendar = ({ onClick, data }) => {
  const eventStyleGetter = (event) => {
    let color;

    switch (event.type) {
      case "contest":
        color = '#d763e5';
        break;
      case "group":
        color = '#fc9317';
        break;
      case "seminar":
        color = '#fc177d';
          break;
      default:
        color = '#fc177d';
    }
   

    const style = {
      backgroundColor: color,
      borderRadius: '100%',
      border: 'none',

      width: '8px',
      height: '8px',

      padding: '0px',
      margin: '0px auto 0px auto',

      display: 'flex'
    };

    return {
      style,
    };
  };

 
  return (
      <div className="myCustomMobile">
        <Calendar
          style={{ width: "100%" }}
          localizer={localizer}
          events={data}
          views={['month']}
          formats={formats}
          step={60}
          timeslots={1}
          showMultiDayTimes
          defaultDate={new Date(moment().format('MMMM DD YY'))}
          eventPropGetter={eventStyleGetter}
          messages={{
            previous: <Img src={bnPrev} alt="bnPrevImage" />,
            next: <Img src={bnNext} alt="bnNextImage" />,
            today: <></>,
          }}
          onSelectEvent={event => onClick(event.id)}

        />
        <CalenderType>
          <CalenderTypeItem>
            <CalenderTypeItemColorBox bgColor="#d763e5" />
            <b>학술대회</b>
          </CalenderTypeItem>
          <CalenderTypeItem>
            <CalenderTypeItemColorBox bgColor="#fc9317" />
            <b>학술집담회</b>
          </CalenderTypeItem>
          <CalenderTypeItem>
            <CalenderTypeItemColorBox bgColor="#fc177d" />
            <b>워크숍 및 세미나</b>
          </CalenderTypeItem>
        </CalenderType>
      </div>
  );
};


export default MobileCalendar;
