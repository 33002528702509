import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useHistory } from "react-router"
import UserBoard from './UserBoard';
import DetailUserBoard from './DetailUserBoard';
import QnA from './QnA';
import Write from './Write'
import Update from './Update'
import Video from './Video'
import Data from './Data'
import DetailData from './DetailData'
import DetailQnA from './DetailQnA'
import DetailVideo from './DetailVideo'
import { useSelector } from "react-redux"

function RouteCommunity(){
  const history = useHistory()
  let adminState = null
  useSelector(state => { adminState = state.admin.admin }) 
  if (!adminState) {
    if (!localStorage.getItem("accessToken")) {
      history.push("/app/auth/signin")
      window.alert("로그인 후, 이용 가능합니다.")
    }
  }
  return (
    <>
    <Switch>
      <Route exact path="/app/community/userboard" component={UserBoard} />
      <Route exact path="/app/community/qna" component={QnA} />
      <Route exact path="/app/community/write/:type" component={Write} />
      <Route exact path="/app/community/update/:number/:type/:length" component={Update} />
      <Route exact path="/app/community/userboard/:number/:type/:length" component={DetailUserBoard} />
      <Route exact path="/app/community/video" component={Video} />
      <Route exact path="/app/community/data" component={Data} />
      <Route exact path="/app/community/qna/:number/:type/:length" component={DetailQnA} />
      <Route exact path="/app/community/data/:number/:type/:length" component={DetailData} />
      <Route exact path="/app/community/video/:number/:length" component={DetailVideo} />
    </Switch>
    </>
  );
}

export default RouteCommunity