const BasicTableData = () => {
  // const header = [
  //   { id: 1, title: '#' },
  //   { id: 2, title: 'First Name' },
  //   { id: 3, title: 'Last Name' },
  //   { id: 4, title: 'Username' },
  //   { id: 5, title: 'Status' },
  // ];

  const headerResponsive = [
    { id: 1, title: '결제요청시간' },
    { id: 2, title: '입금자' },
    { id: 3, title: '구분' },
    { id: 4, title: '면허번호' },
    { id: 5, title: '금액' },
    { id: 6, title: '현황' },
    { id: 7, title: '상세' },
    { id: 8, title: '승인' },
  ];
 
  const rows = [];
  for (let i = 0; i < 10; i++) {
    rows.push({
        paymentRequestTime: "2021-01-01 / 21:30:34",
        payer: "홍길동" + i,
        membership: ['정회원', '비회원'][Math.floor((Math.random() * 2))],
        licenseNum: i,
        amount: i + 100000,
      status: ['결제대기', '결제취소', "결제완료"][Math.floor((Math.random() * 3))],
        
    });
  }

  const basicTableData = { tableHeaderResponsiveData: headerResponsive, tableRowsData: rows };
  return basicTableData;
};

export default BasicTableData;
