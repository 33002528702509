import React, { useState, useEffect } from 'react';
import styled, { css } from "styled-components";
import Banner from './components/Banner';
import axios from "axios"
import moment from "moment"
import { mobile } from '../../lib/viewport';
import Calendar from './components/Calendar';
import MobileCalendar from "./components/MobileCalendar"
import events from "./components/events";
import List from "../../components/List";
import { useHistory } from 'react-router';

const Navigation = styled.div`
    width: 180px;
    height: 40px;

    border-radius: 20px;
    border: solid 0.5px #d6d6d6;
    background-color: #f2f3f5;

    display:flex;
    position : relative;

    margin: 50px auto 30px auto;

    & > p{
        width: 50%;
        height: 39px;
        color: #0e0e0e;
        font-size: 12px;

        display:flex;
        justify-content:center;
        align-items:center;

        position:relative;
        z-index: 2;

        cursor:pointer;
    }
    ${mobile(css`
        margin: 20px auto 20px auto;
    `)}
`
const NavigationCricle = styled.div`
    width: 90px;
    height: 39px;

    border-radius: 20px;
    border: solid 0.5px #d6d6d6;
    background-color: #fff;

    position: absolute;

    ${({ state }) => {
      return state === 'calendar' ? `left: 0px;` : 'left: 50%;';
    }}
    transition: 0.2s;
`

const Image = styled.img`
    width:fit-content;

    margin: 0 auto;
    padding: 0px 10px 40px 10px;

    display:block;

    ${mobile(css`
        width:100%;
    `)}
`
const CalendarWrapper = styled.div`
    width: 945px;
    margin: 50px auto 200px auto;
    margin-top: 50px;
    display:block;
    ${mobile(css`
        display:none;
    `)}
`
const MobileCalendarWrapper = styled.div`
    width: calc( 100% - 20px );
    margin: 30px auto 100px auto;

    display:none;
    ${mobile(css`
        display:block;
    `)}
`

const ListWrapper = styled.div`
    width: 945px;
    margin: 50px auto 80px auto;

    ${mobile(css`
        width: calc( 100% - 40px );
    `)}
`

function CalendarPage() {
    const [state, setState] = useState('calendar')
    const [calendarData, setCalendarData] = useState([])
    const [scheduleList, setScheduleList] = useState([])

    const [totalPageLength, setTotalPageLength] = useState(1)
    const [pageNumber, setPageNumber] = useState(1)
    const history = useHistory()
    function NavigationChange(subject) {
        if (subject === 'calendar') {
            setState('calendar')
        } else {
            setState('list')
        }
    }
    function changePageEvent(index){
        setPageNumber(index);
    }
    useEffect(async () => { 
        if (state === "calendar") {
            axios.get(`${process.env.REACT_APP_API_URL}/research-schedule?eventType=2`).then((response)=>{
                let arr = []
                for (let i = 0; i < response.data.data.length; i++) {
                    let start = response.data.data[i].startdate;
                    let end = response.data.data[i].enddate
                    if (moment(start).format("YYYY-MM-DD") !== moment(end).format("YYYY-MM-DD")) {
                        arr.push({
                            id: response.data.data[i].id_event,
                            start: new Date(moment(start).format("YYYY"), moment(start).format("MM") -1, moment(start).format("DD")),
                            end: new Date( moment(start).format("YYYY"), moment(start).format("MM") - 1, moment(start).format("DD")),
                            title: response.data.data[i].title,
                            count: response.data.data[i].count
                        })
                        arr.push({
                            id: response.data.data[i].id_event,
                            start: new Date(moment(end).format("YYYY"), moment(end).format("MM") -1, moment(end).format("DD")),
                            end: new Date( moment(end).format("YYYY"), moment(end).format("MM") - 1, moment(end).format("DD")),
                            title: response.data.data[i].title,
                            count: response.data.data[i].count
                        })
                    } else {
                        arr.push({
                            id: response.data.data[i].id_event,
                            start: new Date(moment(start).format("YYYY"), moment(start).format("MM") -1, moment(start).format("DD")),
                            end: new Date( moment(end).format("YYYY"), moment(end).format("MM") - 1, moment(end).format("DD")),
                            title: response.data.data[i].title,
                            count: response.data.data[i].count
                        })
                    }
                }
                setCalendarData(arr)
            })
        } else {
            axios.get(`${process.env.REACT_APP_API_URL}/research-schedule?eventType=2&page=${pageNumber}`).then((data)=>{
                setScheduleList(data.data.data)
                setTotalPageLength(data.data.count)
            })
        }
    }, [state, pageNumber]);

    return ( 
        <>            
            <Banner title="calendar"></Banner>
            <Navigation>
                <NavigationCricle state={state} />
                <p onClick={() => NavigationChange('calendar')}>달력</p>
                <p onClick={() => NavigationChange('list')}>목록</p>
            </Navigation>
            {
                state === "calendar" ? (
                    <>
                        <CalendarWrapper >
                            <Calendar data={calendarData} onClick={(index)=>{history.push(`/app/academic/schedule/${index}/introduce`)}}></Calendar>
                        </CalendarWrapper>
                        <MobileCalendarWrapper>
                            <MobileCalendar data={calendarData} onClick={(index)=>{history.push(`/app/academic/schedule/${index}/introduce`)}}></MobileCalendar>
                        </MobileCalendarWrapper>
                    </>
                ) : <ListWrapper>
                        <List changePageEvent={changePageEvent} data={scheduleList} slide={5} nowPage={pageNumber} length={totalPageLength} onClick={(index)=>{history.push(`/app/academic/schedule/${index}/introduce`)}} />
                    </ListWrapper>
            }
        </>
    );
}

export default CalendarPage;
