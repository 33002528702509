import React, {useState} from "react"
import styled from "styled-components"
import { useHistory } from "react-router-dom";
import axios from "axios"

import Banner from "./components/Banner"
import Alert from "../../components/Alert"

const FindNavigation = styled.div`
    width: 300px;
    height: 50px;

    display:flex;

    margin: 50px auto 0px auto;

`

const NavItem = styled.div`
    width: 50%;

    display:flex;
    justify-content:center;
    align-items:center;

    font-weight: 500;

    cursor:pointer;

    ${({ selected }) => {
      return selected ? `border-left: 1px solid black; border-top: 1px solid black; border-right: 1px solid black; background-color: #f2f3f5;` : 'border-bottom: 1px solid black; background-color: #e3e5e8;';
    }}
`
const InputText = styled.input`
    width: 100%;
    height: 50px;

    border: solid 0.7px #e0e1e5;
    background-color: #fff;

    padding: 0px 20px;
    font-size: 15px;

    outline:none;

    &:focus{
        position:relative;
        box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.1);
        border: solid 0.6px rgba(0, 0, 0);
    }
`
const InputButton = styled.div`
    min-width: 100%;
    height: 50px;

    border: solid 0.5px #000;
    background-color: #474747;

    color: #fff;
    font-size: 15px;

    display:flex;
    justify-content:center;
    align-items:center;

    margin: 20px 0px 16px 0px;

    cursor:pointer;
`

const Form = styled.div`
    width: 300px;
    margin: 24px auto 160px auto;
`
const PasswordWrapper = styled.div`
    height: 100px;

    display:flex;
    justify-content:center;
    align-items:center;

    flex-direction:column;


    & > p {
        font-size: 12px;
        text-align:center;
        line-height: 1.2;
    }
    & > div {
        font-size: 22px;
    }
`
function FindPassword() {
    const history = useHistory();
    const [form, setForm] = useState({userid:"", name:"", license_number:""})
    const [errorText, setErrorText] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [newPasswordR, setNewPasswordR] = useState("")
    const [findPasswordText, setFindPassword] = useState("")
    const [confirm, setConfirm] = useState(false)
    function setupErrorText() {
        setErrorText("")
        if (confirm === true) {
            history.push("/app/auth/signin")
        }
    }
    const inputOnChange = (e) => {
        const { name, value } = e.target   
        const nextInputs = {            
            ...form,  
            [name]: value,
        }
        setForm(nextInputs)       
    }
    function passwordChange() {
        const regPassword =  /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,16}$/;
        if (newPassword === "") {
            setErrorText("새 비밀번호를 입력해주세요.")
        } else if (!regPassword.test(newPassword)) {
            setErrorText("패스워드는 영문, 숫자 조합 8자 이상 16자 이하로 작성해주세요.")
        } else if (newPasswordR === "") {
            setErrorText("새 비밀번호를 재입력해주세요.")
        } else if (newPassword !== newPasswordR) {
            setErrorText("비밀번호가 일치하지 않습니다.")
        } else {
            axios.post(`${process.env.REACT_APP_API_URL}/newpassword`, {userid: form.userid, newPassword: newPassword}).then(data => {
                    // if (data.statusText === "OK") {
                        setConfirm(true)
                        setErrorText("성공적으로 변경이 완료됐습니다.")
                    // }
            }).catch(err => {
                if (err.response.data){
                    setErrorText(err.response.data)
                } else {
                    console.log(err.response)
                }
            })
        }
    }
    function passwordFind() {
        if (form.userid === "") {
            setErrorText("아이디를 입력해 주세요.")
        } else if (form.name === "") {
            setErrorText("성함을 입력해 주세요.")
        } else if (form.license_number === "") {
            setErrorText("면허번호를 입력해 주세요")
        } else {
            axios.post(`${process.env.REACT_APP_API_URL}/findpassword`, form).then(data => {
                    // if (data.statusText === "OK") {
                        setFindPassword(data.data.password)
                    // }
            }).catch(err => {
                if (err.response.data){
                    setErrorText(err.response.data)
                } else {
                    console.log(err.response)
                }
            })
        }
    }
    return (
        <>
            <Alert openState={errorText === "" ? false: true } text={errorText} setupErrorText={()=>setupErrorText()}/>
            <Banner title="아이디/비밀번호 찾기" subTitle="find ID/PASS"></Banner>
            <FindNavigation>
                <NavItem selected={false} onClick={() => { history.push(`/app/auth/find/id`) }}>
                    <span>아이디 찾기</span>
                </NavItem>
                <NavItem selected={true}>
                    <span>비밀번호 찾기</span>
                </NavItem>
            </FindNavigation>
            <Form>
                {
                    findPasswordText === "" ? <>
                            <InputText value={form.userid} onChange={inputOnChange} type="text" name="userid" placeholder="아이디를 입력해주세요" />
                            <InputText value={form.name} onChange={inputOnChange} type="text" name="name" placeholder="성함을 입력해주세요" />
                            <InputText value={form.license_number} onChange={inputOnChange} type="text" name="license_number" placeholder="면허번호를 입력해주세요" />
                            <InputButton onClick={() => passwordFind()}>
                                <span>비밀번호를 찾기</span>
                            </InputButton>
                        </> : <>
                            <InputText value={newPassword} onChange={(e) => {setNewPassword(e.target.value)}} type="password" name="newPassword" placeholder="새 비밀번호 입력" />
                            <InputText value={newPasswordR} onChange={(e) => {setNewPasswordR(e.target.value)}} type="password" name="newPasswordR" placeholder="새 비밀번호 재입력" />
                            <InputButton onClick={() => passwordChange()}>
                                <span>완료</span>
                            </InputButton>
                        </>
                }      
            </Form> 
        </>
    )
}

export default FindPassword
