import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import Video from './components/Video';

const Members = () => {
  const { t } = useTranslation('common');
  return (
    <Container>
      <Video />
      <Row>
        <Col md={12}>
          <hr />
        </Col>
      </Row>
    </Container>
  );
};

export default Members;
