import React from 'react'
import { useTranslation } from 'react-i18next'
import { Card, CardBody, Col } from 'reactstrap'
import VideoTabs from './VideoTabs'
import showResults from '@/utils/showResults'

const JustifyTabsBorderedTop = ({ setRadioVal, radioVal, setFolderSta }) => {
  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className='tabs tabs--justify tabs--bordered-top'>
            <VideoTabs onSubmit={showResults} setRadioVal={setRadioVal} radioVal={radioVal} setFolderSta={setFolderSta} />
          </div>
        </CardBody>
      </Card>
    </Col>
  )
}

export default JustifyTabsBorderedTop
