import React, { useState } from "react"
import styled, {css} from "styled-components"
import {mobile} from "../lib/viewport"

const ModalWrapper = styled.div`
    width:100vw;
    height:100vh;

    display: flex;
    justify-content:center;
    align-items:center;

    position:fixed;
    top:0; left:0; right:0; bottom:0;

    z-index: 300;
`
const ModalShadow = styled.div`
    width:100vw;
    height:100vh;

    position:absolute;
    top:0; left:0; right:0; bottom:0;

    background-color: black;
    opacity:0.6;
`

const ModalBox = styled.div`
    max-width: 660px;
    width: 70%;
    height: 70vh;
    margin: 0 auto;
    border-radius: 15px;
    box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.3);

    background-color: #fff;

    position:relative;
    ${
        mobile(css`
            width: calc( 100% - 30px );
        `)
    }
    z-index: 2;

    & > p{
        font-size: 12px;
    }
`

const ModalTextWrapper = styled.div`
    width:100%;

    height: calc( 100% - 120px );

    display:flex;
    justify-content:flex-start;
    align-items:flex-start;
    padding: 16px 20px;

    word-break:break-all;

    overflow-x: hidden;
    overflow-y: scroll;

    white-space:pre-line;

`
const ModalBottom = styled.div`
    width:100%;
    height: 40px;
    display:flex;
    align-items:center;
    justify-content:center;
    color: white;

    border-radius: 0px 0px 15px 15px;
    border: solid 0.5px #000;
  background-color: #474747;

  cursor:pointer;

  position:absolute;
  bottom:0px;
  left:0px;
  right:0px;
`

const ModalTitle = styled.div`
    width:100%;
    height: 40px;

    display:flex;
    justify-content:center;
    align-items:center;

    font-weight:bold;

`

const ModalSubTitle = styled.div`
    width:100%;
    height: 40px;

    display:flex;
    justify-content:center;
    align-items:center;

    font-weight:500;

    color: #36aa3a;
    box-shadow: 1px 8px 22px 2px rgba(0, 0, 0, 0.1);


`
function Modal({title, subTitle, openState, closeEvent, children}) {
    function Confirm() {
        closeEvent(!openState)
    }
    return (
        ( openState ) && <ModalWrapper>
           <ModalBox>
                <ModalTitle>{title}</ModalTitle>
                <ModalSubTitle>{subTitle}</ModalSubTitle>
                <ModalTextWrapper>
                    {children}
                </ModalTextWrapper>
                <ModalBottom onClick={() => Confirm()}>확 인</ModalBottom>
           </ModalBox>
           <ModalShadow onClick={() => Confirm()}></ModalShadow>
       </ModalWrapper>
    )   
}

export default Modal;
