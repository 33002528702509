import React from 'react'
import styled, { css } from 'styled-components'
import { useHistory } from "react-router-dom";
import { mobile } from "../../../lib/viewport";

const BannerWrapper = styled.div`
    width:100%;
    height: 100%;

    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;

    position:relative;

    background-color: white;

    & > div:nth-child(1){
        display:flex;
        justify-content:center;
        align-items:center;

        flex-direction:column;

        margin: 50px 0px;
        z-index:1;
    }

`

const BannerNav = styled.div`
    overflow-x: auto;

    display:flex;
    justify-content:center;
    margin: 0 auto;

    width:100%;

    box-sizing:border-box;
    ${mobile(css`
        display: -webkit-box;
    `)}

    &::-webkit-scrollbar {
        display: none; 
    }
    position: relative;

    z-index: 1;

`
const BannerNavItem = styled.div`
    min-width: 140px;
    height: 40px;

    border: solid 0.5px #e0e1e5;
    background-color: #fff;
    color: #8a8c91;
    font-size: 12px;
    font-weight: bold;

    display:flex;
    justify-content:center;
    align-items:center;

    cursor:pointer;
   
    ${({ selected }) => {
      return selected ? `box-shadow: 1px 8px 22px 2px rgba(0, 0, 0, 0.1); border: solid 0.5px #000; color: #000;` : '';
    }}
`

const BannerTitle = styled.div`
    font-size: 46px;
    font-weight: 200;

    line-height: 1.4em;

    
`
const BannerBackground = styled.img`
    position: absolute;
    bottom:0px;
    left:0px;
    right:0px;


    width:100%;
    height:100%;
    
    object-fit:cover;
`

const BannerSubTitle = styled.p`
    font-size: 14px;
    font-weight: 200;
`
function Banner(props) {
    const history = useHistory();
    
    function RouteChange(title) {
        history.push(`/app/academic/${title}`)
    }

    return (
        <BannerWrapper>
            <div>
                <BannerTitle>학술한마당</BannerTitle>
                <BannerSubTitle>ACADEMIC INFORMATION</BannerSubTitle>
            </div>
            <BannerNav>
                <BannerNavItem onClick={() => RouteChange('journal')} selected={ props.title === 'journal' ? true : false }>학술지</BannerNavItem>
                <BannerNavItem onClick={() => RouteChange('award')} selected={ props.title === 'award' ? true : false }>학술상현황</BannerNavItem>
                <BannerNavItem onClick={() => RouteChange('calendar')} selected={ props.title === 'calendar' ? true : false }>학술행사일정</BannerNavItem>
            </BannerNav>
            <BannerBackground src={"/img/banner/academic.png"} alt="banner" />
        </BannerWrapper>
    )    
}

export default Banner;
