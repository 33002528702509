import React from 'react'
import styled from "styled-components"
import Icon from "../../../../../shared/img/login-auto-login-check@2x.png"

const CheckboxWrapper = styled.div`
    width: 24px;
    height: 24px;
    border: solid 0.5px #e0e1e5;
    border-radius: 100%;

    display: flex;
    justify-content:center;
    align-items:center;

    background-color: white;

    ${({ state }) => {
      return state ? `background-color: #474747` : 'background-color: #fff';
    }}
`

const Img = styled.img`
    width:24px;
    height:24px;

    background-color: white;

    border-radius:100%;
`
function Checkbox({ state, onChange }) {
    return (
        <CheckboxWrapper state={state} onClick={() => onChange(!state)}>
            {
                state && <Img src={Icon} width="24px" height="24px" alt="check_icon" />
            }
        </CheckboxWrapper>
    )
}

export default Checkbox;
