const BasicTableData = () => {
  // const header = [
  //   { id: 1, title: '#' },
  //   { id: 2, title: 'First Name' },
  //   { id: 3, title: 'Last Name' },
  //   { id: 4, title: 'Username' },
  //   { id: 5, title: 'Status' },
  // ];

  const headerResponsive = [
    { id: 1, title: '번호' },
    { id: 2, title: '제목' },
    { id: 3, title: '작성자' },
    { id: 4, title: '작성일' },
    { id: 5, title: '작성IP' },
    { id: 7, title: '첨부' },
    { id: 8, title: 'url' },
    { id: 9, title: '수정' },
    { id: 10, title: '삭제' },
  ]
  var rows = []
  for (let i = 0; i < 10; i++) {
    rows.push({
      id: i + 1,
      content: '가나다라마바사 아자차카타파하',
      writer: '홍길동' + i,
      regDate: '2021-10-10',
      regIp: i,
      attachment: ['O', 'X'][Math.floor(Math.random() * 2)],
      url: '',
      update: '',
      del: '',
    })
  }

  const basicTableData = { tableHeaderResponsiveData: headerResponsive, tableRowsData: rows }
  return basicTableData
}

export default BasicTableData
