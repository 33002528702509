import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Field, Form } from 'react-final-form'
import { Button, ButtonToolbar } from 'reactstrap'
import TextField from '@material-ui/core/TextField'
import SummerNote from './SummerNote.jsx'
import CheckBoxGroup from './CheckboxGroup'
import renderFileInputField from '@/shared/components/form/FileInput'
import { BroserRouter, useHistory } from 'react-router-dom'
import axios from 'axios'

const renderTextField = ({ input, label, meta: { touched, error }, children, select, multiline, disabled, checkBox, setTitle }) => (
  <TextField
    className='material-form__field'
    label={label}
    type={input.type}
    error={touched && error}
    value={input.value}
    select={select}
    multiline={multiline}
    checkBox={checkBox}
    onChange={(e) => {
      e.preventDefault()
      input.onChange(e.target.value)
      setTitle(e.target.value)
    }}
    disabled={disabled}
  >
    {children}
  </TextField>
)

const MemberDetail = (props) => {
  const { t } = useTranslation('common')
  const [contents, setContents] = useState('')
  const [title, setTitle] = useState('')
  const selectNumber = `1, ${props.selectNum}`
  const history = useHistory()
  return (
    <>
      <div className='card__title'>
        <h1>글쓰기</h1>
      </div>
      <hr />
      <br />
      <br />
      <Form onSubmit={(e) => props.onSubmit(e, props.setRendChange)}>
        {({ handleSubmit, form }) => (
          <form className='material-form' onSubmit={handleSubmit} id='frm'>
            <div className='row'>
              <div className='col-4'>
                <Field
                  name='selectNum'
                  someArbitraryOtherProp={selectNumber}
                  hidden
                  render={(prop) => {
                    prop.input.onChange(prop.someArbitraryOtherProp)
                    return <input {...prop.input} hidden />
                  }}
                />
                <Field setTitle={setTitle} name='title' component={renderTextField} label='제목' />
                <Field
                  name='contents'
                  someArbitraryOtherProp={contents}
                  render={(prop) => {
                    prop.input.onChange(prop.someArbitraryOtherProp)
                    return <input {...prop.input} hidden />
                  }}
                />
              </div>
              <div className='col-1' style={{ paddingTop: '25px' }}>
                <CheckBoxGroup name='button' />
              </div>
            </div>
            <SummerNote setContents={setContents} />
            <br />
            <div className='form__form-group'>
              <span className='form__form-group-label'>첨부파일</span>
              <div className='form__form-group-field'>
                <Field name='file' component={renderFileInputField} />
              </div>
            </div>
            <ButtonToolbar className='form__button-toolbar'>
              <Button
                color='primary'
                type='button'
                onClick={() => {
                  // props.setRend(!true)
                  // if (title === '' || null) window.alert('제목을 입력해주시기 바랍니다.')
                  // else if (contents === '' || null) window.alert('내용을 입력해주시기 바랍니다.')
                  if (window.confirm('등록 하시겠습니까?')) {
                    window.alert('등록되었습니다.')
                    props.setDisableset((e) => !e)
                    props.setRend((e) => !e)
                    props.cancelClick((e) => !e)
                    props.setLoading(false)
                    // setLoading(true)
                    form.submit()
                    const count = 1
                    const num = 160
                    axios
                      .get(`${process.env.REACT_APP_API_URL}/noticesCountUpdate?count=${count}&num=${num}`)
                      // .then((data) => console.log(data))
                      .catch((err) => console.log(err))
                  }
                }}
              >
                확인
              </Button>
              <Button
                type='button'
                color='danger'
                onClick={() => {
                  if (!title && !contents) {
                    props.cancelClick(false)
                    props.setDisableset((e) => !e)
                  } else {
                    if (window.confirm('입력하신 내용이 저장되지 않습니다. 취소하시겠습니까?')) {
                      props.cancelClick(false)
                      props.setDisableset((e) => !e)
                    }
                  }
                }}
              >
                취소
              </Button>
            </ButtonToolbar>
          </form>
        )}
      </Form>
    </>
  )
}

export default MemberDetail
