import React from 'react'
import styled, {css} from 'styled-components'
import { mobile } from './lib/viewport'
import {v4 as uuidv4} from 'uuid'


export const TableSample = () => {
    return (
        <>
            <TableColumn>
                <TableColumnItem width={"10%"} position={"center"}>
                    <div style={{width: "50%"}}>&nbsp;</div>
                </TableColumnItem>
                <TableColumnItem width={"52%"} position={"flex-start"} point={true}>
                    <div style={{width: "100%"}}>&nbsp;</div>
                </TableColumnItem>
                <TableColumnItem width={"12%"} position={"center"}>
                    <div style={{width: "70%"}}>&nbsp;</div>
                </TableColumnItem>
                <TableColumnItem width={"12%"} position={"center"}>
                    <div style={{width: "70%"}}>&nbsp;</div>
                </TableColumnItem>
                <TableColumnItem width={"12%"} position={"center"}>
                    <div style={{width: "50%"}}>&nbsp;</div>
                </TableColumnItem>
                <TableColumnItem width={"12%"} position={"center"}>
                    <div style={{width: "50%"}}>&nbsp;</div>
                </TableColumnItem>
            </TableColumn>
        </>
    )
}

const Skeleton = () => {
    const columnInfo = { 
        id_notice: { name:"No.", width: "10%", position: "center" }, 
        title: { name:"제 목", width: "52%", position: "flex-start" }, 
        writer: { name:"작성자", width: "12%", position: "center" }, 
        c_date: { name:"작성일", width: "12%", position: "center" }, 
        file_link: { name:"첨부파일", width: "12%", position: "center" }, 
        count: { name:"조회수", width: "12%", position: "center" } 
    } 

    return (
        <>
            <TableHeader>
                {Object.keys(columnInfo).map((value, index) => {
                    return <TableHeaderColumn key={uuidv4()} width={columnInfo[value].width}>{columnInfo[value].name}</TableHeaderColumn>
                })}
            </TableHeader>
            {Array(6).fill(0).map(el => {
                return <TableSample key={uuidv4()}/>
            })}
             {/* <TableSample /> */}
            <TableMobileWrapper height={20 * 70 + 'px'}></TableMobileWrapper>
        </>
    )
}

export default Skeleton;

const TableHeader = styled.div`
  width: 100%;
  height: 48px;
  background-color: #ffffff;
  display: flex;
  border-bottom: 1.3px solid #e0e1e5;
`

const TableMobileWrapper = styled.div`
  width: calc(100% - 30px);
  max-height: ${(props) => props.height};
  height: 100%;
  overflow: hidden;
  display: none;
  margin: 0 auto;
  border-top: 1px solid black;
  ${mobile(css`
    display: block;
  `)}
`


const TableColumn = styled.div`
  width: 100%;
  height: 48px;
  background-color: #f2f3f5;
  display: flex;
  border-bottom: 1.3px solid #e0e1e5;

  cursor:pointer;
  &:hover {
    background-color: #eaebed;
  }
`

const TableHeaderColumn = styled.div`
  width: ${(props) => props.width};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const TableColumnItem = styled.div`
  width: ${(props) => props.width};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.position};
  @keyframes placeHolderShimmer {
    0% {
      background-position: -468px 0
    }
    100% {
      background-position: 468px 0
    }
  }
  
  > div {
   border-radius: 5px;
   -webkit-animation-duration: 0.5s;
   -webkit-animation-fill-mode: forwards;
   -webkit-animation-iteration-count: infinite;
   -webkit-animation-name: placeHolderShimmer;
   -webkit-animation-timing-function: linear;
   background: #d8d8d8;
   background-image: linear-gradient(to right, #d8d8d8 0%, #bdbdbd 20%, #d8d8d8 40%, #d8d8d8 100%);
   background-repeat: no-repeat;
   position: relative
  }
`