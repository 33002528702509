import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import TopbarLanguage from '../components/topbar/TopbarLanguage';
import TopbarMail from '../components/topbar/TopbarMail';
import TopbarProfile from '../components/topbar/TopbarProfile';
import TopbarNotification from '../components/topbar/TopbarNotification';
import TopbarSearch from '../components/topbar/TopbarSearch';
import TopbarSidebarButton from '../components/topbar/TopbarSidebarButton';
import LogoImg from "./luke_logo.png"

const Topbar = ({
  changeMobileSidebarVisibility, changeSidebarVisibility,
}) => (

  <div className="topbar">
    <div className="topbar__left">
      <TopbarSidebarButton
        onClickMobile={changeMobileSidebarVisibility}
        onClickDesktop={changeSidebarVisibility}
      />
      <div >
        <Link to="/">
          <img className="logo_img" alt="lukepainLogo" src={LogoImg} style={{width:"auto",height:"auto",maxWidth:"150px",maxHeight:"150px",padding:"15px 0px"}} />
        </Link>
        
      </div>
    </div>
    <div className="topbar__right">
      <div className="topbar__right-search">
        <TopbarSearch />
      </div>
      <div className="topbar__right-over">
        <span style={{textAlign:"center",padding:"20px 0px"}}>admin님 환영합니다.</span>
        {/* <TopbarMail new /> */}
        {/* <TopbarProfile /> */}
        {/* <TopbarLanguage /> */}
      </div>
    </div>
  </div>
);

Topbar.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
};

export default Topbar;
