import React, {useEffect, useState} from "react"
import Banner from './components/Banner';

import NoticeBoard from "../../components/NoticeBoard"
import { useHistory, useParams } from "react-router-dom";

import axios from "axios"

function DetailNotice() {
    const history = useHistory();
    const params = useParams()
    const [boardData, setBoardData] = useState({});
    const [prev, setPrev] = useState({});
    const [next, setNext] = useState({});

    const [change, setChange] = useState(false);

    function onChangePage(value) { history.replace(`/app/notification/notice/${value.board_num}/1/${params.length}`); setChange(!change)}

    useEffect(() => { 
        // console.log("렌더링이 완료될 때마다 실행합니다.", params.number); 
        axios.get(`${process.env.REACT_APP_API_URL}/boardNextPrev?boardNum=${Number(params.number)}&type=${params.type}`).then((data)=>{
            // console.log("ㅁㄹㅁㄹ",data.data.data)

            if (Number(params.number) === 1) {
                setPrev(false)
            } else {
                axios.get(`${process.env.REACT_APP_API_URL}/boardNextPrev?boardNum=${Number(params.number) - 1}&type=${params.type}`).then(data2=>{
                    setPrev(data2.data.data)
                    // console.log("prev", data2.data)
                }).catch((err) => console.log(err))
            }
            if (Number(params.number) === Number(params.length)) {
                setNext(false)
            } else {
                axios.get(`${process.env.REACT_APP_API_URL}/boardNextPrev?boardNum=${Number(params.number) + 1}&type=${params.type}`).then(data2=>{
                    setNext(data2.data.data)
                    // console.log("prev", data2.data)
                }).catch((err) => console.log(err))
            }
            let count = data.data.data.count === null ? 1 : data.data.data.count + 1;
            // console.log(count, data.data.data.count)

            axios.get(`${process.env.REACT_APP_API_URL}/noticesCountUpdate?count=${count}&num=${data.data.data.id_notice}`).then(data2 => {
                    // console.log(data2)
            })
            
            setBoardData(data.data.data)
        })
    }, [change]);

    return (
        <>
            <Banner title="notice"></Banner>
            <NoticeBoard onClick={(value) => onChangePage(value)} data={boardData} nextData={next} prevData={prev} pagination={true} comment={false} listButtonClick={() => { history.push("/app/notification/notice")}} />
        </>
    )
}

export default DetailNotice