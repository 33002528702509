import React from 'react';
import styled, { css } from 'styled-components';

import Banner from './components/Banner';
import { mobile, tablet } from "../../lib/viewport";

import bgIcon from "../../../../shared/img/introduce/about-icon.png"
import bg from "../../../../shared/img/introduce/about-img-01@2x.png"

const GreetingWrapper = styled.div`
    padding: 80px 0;
    margin: 0 auto;

    display:flex;
    justify-content:center;
    align-items:flex-start;

    ${mobile(css`
        align-items:center;
        flex-direction:column;

        padding: 2em;
    `)}
`

const Image = styled.img`
    border-radius: 80px 0px 80px 0px;
    width:640px;
    height: 400px;
    ${tablet(css`
        width: 500px;
        height: 340px;
    `)}
    ${mobile(css`
        width: 100%;
        height: 300px;
    `)}
`
const GreetingImageWrapper = styled.div`
    margin-right: 30px;

    ${tablet(css`
        width: 500px;
    `)}
    ${mobile(css`
        width: 100%;
        margin-right: 0px;
    `)}

`
const ImageExplanationWrapper = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;
    width: 380px;
    height: 150px;

    padding: 0px 30px;

    background-color: #fff;

    box-shadow: 1px 8px 22px 2px rgba(0, 0, 0, 0.1);

    margin-top: -50px;
    margin-left: 15%;

    ${tablet(css`
        width: 360px;
        height: 140px;
    `)}
    
    ${mobile(css`
        justify-content:center;

        width:100%;
        margin-top: 16px;
        margin-bottom: 32px;
        margin-left: 0;

        & > div:nth-child(1){
            margin-right: 14px;
        }   
    `)}

    z-index: 3;
    position:relative;

    & > div:nth-child(1){
        display:flex;
        flex-direction:column;
        align-items:center;
    }
`
const ImageExplanationTitle = styled.div`
    width:fit-content;
    line-height: 1.33;
    text-align:center;
    font-size: 12px;
    font-weight: bold;

    margin-top: 6px;
`
const ImageExplanationText = styled.div`
    width: 240px;
    word-break:keep-all;
`

const GreetingContents = styled.div`
    width:540px;
    height:100%;

    margin-left: 20px;

    display:flex;
    justify-content:flex-start;
    align-items:flex-start;
    flex-direction: column;

    ${tablet(css`
        width: 400px;
    `)}
    ${mobile(css`
        width: 100%;
        margin-left: 0;
    `)}

`
const GreetingTitle = styled.div`
    font-size: 30px;
    font-weight: bold;

    line-height: normal;

    margin-bottom: 16px;

    ${tablet(css`
        font-size: 20px;
    `)}
`

const GreetingText = styled.div`
    word-break:keep-all;
    font-size: 16px;

    ${tablet(css`
        font-size: 12px;
    `)}
    ${mobile(css`
        margin-bottom: 32px;
    `)}
`
function Greeting() {
    return (
        <>
            <Banner title="greeting"></Banner>
            <GreetingWrapper>
                <GreetingImageWrapper>
                    <Image src={bg} alt="image" />
                    <ImageExplanationWrapper>
                        <div>
                            <img src={bgIcon} width="36px" height="42px" alt="icon" />
                            <ImageExplanationTitle>아시아<br /> 30장 26절</ImageExplanationTitle>
                        </div>
                        <ImageExplanationText>
                            여호와께서 자기 백성의 상처를
                            싸매시며 그들의 맞은 자리를 고치시는 날에는
                            달빛은 햇빛 같겠고 햇빛은 일곱 배가 되어
                            일곱 날의 빛과 같으리라
                        </ImageExplanationText>
                    </ImageExplanationWrapper>
                </GreetingImageWrapper>
                <GreetingContents>
                    <GreetingTitle>
                        존경하는 <br /> 누가통증연구회 회원 여러분,
                    </GreetingTitle>
                    <GreetingText>
                        누가통증연구회 제 01회 회장 홍길동입니다.<br />
                        누가통증연구회는 30년 이상의 역사와 전통과 함께 5000명 이상의 회원이 활동하는 
                        국내 최고 권위의 통증학회로 발전하였습니다. 어려운 환경에서도 학회를 유지 발전시켜 오신
                        선배님들과 모든 회원님들의 헌신적인 노력에 무한한 감사를 드립니다.<br /><br /><br />


                        현재 우리 연구회는 급변하는 의료 환경과 코로나 사태 등으로 예측이 어려운 많은 어려움과 
                        도전에 직면하고 있는 상황입니다. 저는 우리 학회 발전의 가장 큰 목표를 전문성 강화와 이를 
                        홍보하는 것으로 생각하고, 재정 안정 그리고 내부 소통과 대외 협력을 통해 궁극적으로 회원의 
                        이익과 국민의 신뢰를 추구하는 것으로 생각하였습니다<br /><br /><br />


                        존경하는 누가통증연구회 회원 여러분
                        저와 제 01대 임원진들은 연구회의 기반 마련과 전문성 강화를 위해 열심히 일하도록 하겠습니다. 
                        앞으로도 회원 여러분들의 애정 어린 관심과 성원, 아울러 조언과 질책을 부탁드립니다.<br /><br /><br />


                        누가통증연구회 회원 여러분들의 안녕과 발전을 진심으로 기원합니다.
                    </GreetingText>
                </GreetingContents>
            </GreetingWrapper>
        </>
    );
}

export default Greeting;
