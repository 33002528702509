/* eslint-disable */
import React, { useEffect, useState, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import {
 Card, CardBody, Col, Button, ButtonToolbar, 
} from 'reactstrap';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import renderRadioButtonField from '@/shared/components/form/RadioButton';
import Tabs from './Tabs';
import axios from 'axios'
import styled from "styled-components"

const renderTextField = ({
  input, label, meta: { touched, error }, children, select, multiline, disabled,
}) => (
  
  <TextField
    className="material-form__field"
    label={label}
    type={input.type}
    error={touched && error}
    value={input.value}
    select={select}
    multiline={multiline}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
    disabled={disabled}
  >
    {children}
  </TextField>

);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  select: PropTypes.bool,
  children: PropTypes.node,
  multiline: PropTypes.bool,
};

renderTextField.defaultProps = {
  meta: null,
  select: false,
  children: [],
  multiline: false,
};

export const TestInput = ({name, value, disable, setTempValue, type, handleModify}) => {
  const [val, setVal] = useState(value)
  const handleChange = (e) => {
    setVal(e.target.value)
    setTempValue({[type]: e.target.value})
  }
  useEffect(()=>{
    setVal(value)
  },[value])

  return (
    <>
      <div className="MuiFormControl-root MuiTextField-root material-form__field">
        <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink Mui-disabled Mui-disabled MuiFormLabel-filled" data-shrink="true">{name}</label>
        <div className="MuiInputBase-root MuiInput-root MuiInput-underline Mui-disabled Mui-disabled MuiInputBase-formControl MuiInput-formControl">
          <input aria-invalid="false" disabled={disable ? "disabled" : null} type="text" className="MuiInputBase-input MuiInput-input Mui-disabled Mui-disabled" value={val} onChange={handleChange} />
        </div>
      </div>
    </>
  )
}
// const fontStyle = { textAlign: 'center', fontWeight: 'bold' };


const Libox = styled.div`
  display: ${({onOff}) => onOff ? "none": "block"};
`

const Container = styled.div``

export const TestSelect = ({userType, setTempValue, type, changeValue}) => {
  const handleChange = (e) => {
    setTempValue({[type]: e.target.value})
  }
  return (
    <select onChange={handleChange}>
      <option value="정회원" selected={userType === "정회원" ? "selected" : null}>정회원</option>
      <option value="준회원" selected={userType === "준회원" ? "selected" : null}>준회원</option>
      <option value="승인대기" selected={userType === "승인대기" ? "selected" : null}>승인대기</option>
      <option value="탈퇴" selected={userType === "탈퇴" ? "selected" : null}>탈퇴</option>
      {/* <option value="정회원">정회원</option>
      <option value="준회원">준회원</option>
      <option value="승인대기" >승인대기</option>
      <option value="탈퇴">탈퇴</option> */}
    </select>
  )
}

const MemberDetail = (props) => {
  const { t } = useTranslation('common');
  const [buttonChange, setButtonChange] = useState(false);
  const [disabledChange, setDisabledChange] = useState(true);
  const [changeValue, setChangeValue] = useState(props.memberInfo)
  const [tempValue, setTempValue] = useState({})
  const [loading, setLoading] = useState(false)
  const [onOff, setOnOff] = useState(false)


  useEffect(() => {
    // console.log("!")
    setLoading(false)
    axios.get(`${process.env.REACT_APP_API_URL}/userinfo?id=${props.memberInfo.id_user_info}`)
    .then(res => setChangeValue({...res.data.data, password: ""}))
    .then(() => setLoading(true))
    .catch(err => console.log(err))
  }, [onOff, props.memberInfo])




  const handleAdvertisement = (ad) => {
    setChangeValue((pre) => {
      return {...pre, ...ad}
    })
  }


  const handleGender = (value) => {
    setChangeValue((pre) => {
      return {...pre, ...value}
    })
  }

  const handleModify = (value) => {
    // if (radioValue){
    //   setTempValue(radioValue)
    // }

    // setChangeValue((pre) => {
    //   return {...pre, ...tempValue}
    // })
    // console.log(value)
    function confirm(){
      if (!window.confirm("변경 하시겠습니까?")){
        setChangeValue({...props.memberInfo, password: ""})
      } else {
        axios.post(`${process.env.REACT_APP_API_URL}/modify-user`,
         {value, id_user_info: props.memberInfo.id_user_info, userid: props.memberInfo.userid})
         .then(() => setOnOff(pre => !pre))
          // .then((data) => {
          //   setChangeValue((pre) => {
          //     return {...pre, ...value}
          //   })
          // })
          // .then(() =>  props.setUserInfo(changeValue))
          .catch(err => console.log(err.response))
      }
    }
    setTimeout(confirm, 500)
 }

 const handlePost = () => {
    new daum.Postcode({
      oncomplete: function(data) {
        setChangeValue((pre) => {
          return {...pre, address_main: data.address}
        })
      }
    }).open();
 }

// const reqNewData = () => {
//   console.log(changeValue)
//   axios.post(`${process.env.REACT_APP_API_URL}/modify-user`, changeValue)
//   .then(data => console.log(data))
//   .catch(err => console.log(err.response))
// }

// const handleReset = () => {
//   setChangeValue(props.memberInfo)
// }
  
if(!loading) return null;
  return (
    <>
          <div className="card__title">
            <h1>회원정보</h1>
            <h5 className="subhead">?님의 회원정보 입니다.</h5>
          </div>
      <Form onSubmit={props.onSubmit}>
            {({ handleSubmit, form }) => (
              <form className="material-form" onSubmit={handleSubmit}>   
                <div className="row">
                  <div className="col-6">
                    {/* <Field
                    <TextField
                      className="material-form__field"
                      type='text'
                      name="no"
                      // component={renderTextField}
                      value={props.memberInfo.id_user_info}
                      label="번호"
                      disabled
                    /> */}
                    <TestInput name="번호" value={changeValue.id_user_info} disable={true} />
                  </div>
                  <div className="col-5">
                    <div>상태</div>
                    {/* <Field
                      name="status"
                      component={renderTextField}
                      select
                      label="상태"
                      // defaultValue={1}
                      id="abcd"
                    >   
                      <MenuItem className="material-form__option" value="1">정회원</MenuItem>
                      <MenuItem className="material-form__option" value="2">준회원</MenuItem>
                      <MenuItem className="material-form__option" value="3">승인대기</MenuItem>
                      <MenuItem className="material-form__option" value="4">탈퇴</MenuItem> 
                    </Field> */}
                     <TestSelect userType={changeValue.member_type} changeValue={changeValue} setTempValue={setTempValue} type="member_type"/>
                  </div>  
                  <Button type="button" onClick={() => handleModify({member_type: tempValue.member_type})}>변경</Button> 
                </div>
                <div className="row">
                  <div className="col-6">
                    {/* <Field
                      name="username"
                      component={renderTextField}
                      label="성함"
                      defaultValue={changeValue.name}
                      disabled
                    /> */}
                    <TestInput name="성함" value={changeValue.name} disable={true} />
                  </div>
                  <div className="col-6">
                    {/* <Field
                      name="regDate"
                      component={renderTextField}
                      label="가입일자"
                      // defaultValue={changeValue[0].regDate}
                      disabled
                    /> */}
                     <TestInput name="가입일자" value={changeValue.join_date} disable={true}/>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    {/* <Field
                      name="userId"
                      component={renderTextField}
                      label="ID"
                      defaultValue={changeValue.userid}
                      disabled
                    /> */}
                     <TestInput name="ID" value={changeValue.userid ? changeValue.userid : ""} disable={true}/>
                  </div>
                  <div className="col-5">
                    {/* <Field
                      name="licenseNum"
                      component={renderTextField}
                      label="면허번호"
                      defaultValue={changeValue.license_number}
                      disabled
                    /> */}
                    <TestInput name="면허번호" value={changeValue.license_number} setTempValue={setTempValue} type="license_number" />
                  </div>
                  <Button type="button" onClick={() => handleModify({license_number: tempValue.license_number})}>변경</Button>
                </div>
                <div className="row">
                  <div className="col-5">
                    {/* <Field
                      name="password"
                      component={renderTextField}
                      label="비밀번호"
                      // defaultValue="비밀번호"
                      disabled
                    /> */}
                    {/* 추후 type 지정 */}
                    <TestInput name="비밀번호" value={changeValue.password} setTempValue={setTempValue} type="password" />
                  </div>
                  <div className='col-1'>
                    <Button type="button" style={{ float: 'right' }} onClick={() => handleModify({password: tempValue.password})}>변경</Button>
                  </div>
                  
                  <div className="col-5">
                    {/* <Field
                      name="birthday"
                      component={renderTextField}
                      label="생년월일"
                      defaultValue={changeValue.birthday}
                      disabled
                    /> */}
                    <TestInput name="생년월일" value={changeValue.birthday} setTempValue={setTempValue} type="birthday"/>
                  </div>
                  <Button type="button" onClick={() => handleModify({birthday: tempValue.birthday})}>변경</Button>
                </div>
                <div className="row">
                  <div className="col-5">
                    {/* <Field
                      name="email"
                      component={renderTextField}
                      label="이메일"
                      defaultValue={changeValue.email}
                      disabled
                    /> */}
                    <TestInput name="이메일" value={changeValue.email} setTempValue={setTempValue} type="email"/>
                  </div>
                  <div className='col-1'>
                    <Button type="button" style={{ float: 'right' }} onClick={() => handleModify({email: tempValue.email})}>변경</Button>
                  </div>
                  <div className="col-5">
                    {/* <Field
                      name="phoneNumber"
                      component={renderTextField}
                      label="전화번호"
                      defaultValue={changeValue.cellphone}
                      // disabled
                    /> */}
                    <TestInput name="전화번호" value={changeValue.cellphone} setTempValue={setTempValue} type="cellphone" />
                  </div>
                  <Button type="button" onClick={() => handleModify({cellphone: tempValue.cellphone})}>변경</Button>
                </div>
                <div className="row">
                  <div className="col-5">
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        {/* <Field
                          name="gender"
                          component={renderRadioButtonField}
                          label="남성"
                          radioValue="mail"
                          checked="checked"
                          type="radio"
                          // disabled
                        />
                        <Field
                          name="gender"
                          component={renderRadioButtonField}
                          label="여성"
                          radioValue="femail"
                          type="radio"
                          // disabled
                        /> */}
                        <div className="form__form-group">
                          <span className="form__form-group-label">성별</span>
                          <div className="form__form-group-field">
                            <div className="form__form-group-input-wrap">
                              <label className="radio-btn">
                                <input className="radio-btn__radio" name="gender" type="radio" onClick={()=> handleGender({gender: "남성"})} checked={changeValue.gender === "남성" ? "checked" : null} />
                                  <span className="radio-btn__radio-custom"></span>
                                  <span className="radio-btn__label">남성</span>
                              </label>
                            </div>
                            <div className="form__form-group-input-wrap">
                              <label className="radio-btn">
                                <input className="radio-btn__radio" name="gender" type="radio" onClick={()=> handleGender({gender: "여성"})} checked={changeValue.gender === "여성" ? "checked" : null} />
                                <span className="radio-btn__radio-custom"></span>
                                <span className="radio-btn__label">여성</span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-1'>
                    <Button type="button" style={{ float: 'right' }} onClick={() => handleModify({gender: changeValue.gender})}>변경</Button>
                  </div>
                  <div className="col-5">
                    <div className="form__form-group">
                        <span className="form__form-group-label">광고성 메시지 수신 동의</span>
                          <div className="form__form-group-field">
                            <div className="form__form-group-input-wrap">
                              <label className="radio-btn">
                                <input className="radio-btn__radio" name="advertisement" type="radio" onClick={()=> handleAdvertisement({advertisement_agree: "1"})} checked={changeValue.advertisement_agree === "1" ? "checked" : null} />
                                  <span className="radio-btn__radio-custom"></span>
                                  <span className="radio-btn__label">동의</span>
                              </label>
                            </div>
                            <div className="form__form-group-input-wrap">
                              <label className="radio-btn">
                                <input className="radio-btn__radio" name="advertisement" type="radio" onClick={()=> handleAdvertisement({advertisement_agree: "0"})} checked={changeValue.advertisement_agree === "0" ? "checked" : null} />
                                <span className="radio-btn__radio-custom"></span>
                                <span className="radio-btn__label">비동의</span>
                              </label>
                            </div>
                          </div>
                        </div>
                  </div>
                  <Button type="button" onClick={() => handleModify({advertisement_agree: changeValue.advertisement_agree})}>변경</Button>
                </div>
                <div className="row">
                  <div className="col-5">
                    {/* <Field
                      name="specialization"
                      component={renderTextField}
                      label="전문분야"
                      defaultValue={changeValue.specialized_field}
                      disabled
                    /> */}
                    <TestInput name="전문분야" value={changeValue.specialized_field} setTempValue={setTempValue} type="specialized_field"/>
                  </div>
                  <div className='col-1'>
                    <Button type="button" style={{ float: 'right' }} onClick={() => handleModify({specialized_field: tempValue.specialized_field})}>변경</Button>
                  </div>
                  
                  <div className="col-6">
                    <div className="row">
                      <div className='col-6'>
                        {/* <Field
                          name="address"
                          component={renderTextField}
                          label="주소"
                          defaultValue={changeValue.adress_main}
                          disabled
                        /> */}
                        <TestInput name="주소" value={changeValue.address_main} setTempValue={setTempValue} type="address_main"/>
                      </div>
                      <div className='col-2'><Button type="button" onClick={handlePost}>찾기</Button></div>
                      <div className='col-4'>
                        {/* <Field
                          name="zipcode"
                          component={renderTextField}
                          label="우편번호"
                          defaultValue={changeValue.zipcode}
                          disabled
                        /> */}
                        {/* <TestInput name="우편번호" value={changeValue.zipcode} setTempValue={setTempValue} type="zipcode"/> */}
                      </div>  
                      <div className='col-10'>
                        {/* <Field
                          name="address2"
                          component={renderTextField}
                          label="상세"
                          defaultValue={changeValue.adress_detail}
                          disabled
                        /> */}
                        <TestInput name="상세" value={changeValue.address_detail} setTempValue={setTempValue} type="address_detail" />
                      </div>
                      <div className='col-2' style={{ float: 'left', paddingLeft:"0px" }}><Button type="button" onClick={() => handleModify({address_main: changeValue.address_main, address_detail: tempValue.address_detail})} >변경</Button></div>
                  </div>
                </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    {/* <Field
                      name="lastContactIp"
                      component={renderTextField}
                      label="마지막 접속 IP"
                      // defaultValue="마지막 접속 IP"
                      disabled
                    /> */}
                    <TestInput name="마지막 접속 IP" value={changeValue.ip} disable={true}/>
                  </div>
                  <div className="col-6">
                    {/* <Field
                      name="lastConDate"
                      component={renderTextField}
                      label="마지막 접속 일자"
                      // defaultValue="마지막 접속 일자"
                      disabled
                    /> */}
                    <TestInput name="마지막 접속 일자" value={changeValue.last_date} disable={true} />
                  </div>
                </div>
                <div className="col-12">
                  {/* <Field
                    name="textarea"
                    component={renderTextField}
                    label="memo"
                    // defaultValue="memo"
                    type="textarea"

                  /> */}
                  <TestInput name="memo" value={changeValue.memo} setTempValue={setTempValue} type="memo" />
                    <Button type="button" style={{ float: 'right' }} onClick={() => handleModify({memo: tempValue.memo})}>변경</Button>
                </div> 
                <div className="col-3">
                  {/* <ButtonToolbar className="form__button-toolbar">
                    <Button color="primary" onClick={reqNewData}>저장</Button>
                    <Button type="button" color="danger" onClick={handleReset}>취소</Button>
                  </ButtonToolbar> */}
                </div>            
              </form>
            )}
          </Form>
          <div className="card__title">
            <h1>활동내역</h1>
          </div>
          <div className="tabs tabs--justify tabs--bordered-top">
            <Tabs userid={props.memberInfo.userid} />
          </div>
    </>
  );
};

MemberDetail.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default MemberDetail;
