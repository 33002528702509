import React from "react";
import styled, { css } from "styled-components";
import { mobile } from "./viewport";
import ReactSummernote from "react-summernote";
import axios from "axios";
import "react-summernote/dist/react-summernote.css"; // import styles
import "react-summernote/lang/summernote-ru-RU"; // you can import any other locale

// Import bootstrap(v3 or v4) dependencies
import "bootstrap/js/src/modal";
import "bootstrap/js/src/dropdown";
import "bootstrap/js/src/tooltip";
import "bootstrap/dist/css/bootstrap.css";

const WriteWrapper = styled.div`
  max-width: 1100px;

  margin: 0px 0px;
  width: 120%;

  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;

  ${mobile(css`
    margin: 20px auto 100px auto;
    max-width: 100%;
    width: calc(100% - 20px);
  `)}
`;
function Write(props) {
  function onChange(content) {
    // console.log('onChange', content);
    props.setContents(content);
  }
  const onImageUpload = (fileList) => {
    const formData = new FormData();
    formData.append("sm_img", fileList[0]);

    axios
      .post(`${process.env.REACT_APP_API_URL}/uploadimage`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          ReactSummernote.insertImage(
            `${process.env.REACT_APP_API_URL}/${res.data.path}`
          );
        };
        reader.readAsDataURL(fileList[0]);
      })
      .catch((err) => console.log(err.response));
  };

  return (
    <>
      <WriteWrapper>
        <ReactSummernote
          value="Default value"
          options={{
            lang: "ko-KR",
            height: 350,
            dialogsInBody: true,
            toolbar: [
              ["style", ["style"]],
              ["font", ["bold", "underline", "clear"]],
              // ["fontname", ["SUIT"]],
              ["para", ["ul", "ol", "paragraph"]],
              ["table", ["table"]],
              ["insert", ["link", "picture", "video"]],
            ],
          }}
          onChange={onChange}
          onImageUpload={onImageUpload}
        />
      </WriteWrapper>
    </>
  );
}

export default Write;
