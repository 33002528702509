/* eslint-disable */
import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import moment from 'moment'
import axios from 'axios'
import { mobile } from '../lib/viewport'
import fileIcon from '../../../shared/img/board-file-icon_2.png'
import next from '../../../shared/img/board-page-arrow-next.png'
import prev from '../../../shared/img/board-page-arrow-prev.png'
import { v4 as uuidv4 } from 'uuid'

const TableWrapper = styled.div`
  width: 100%;
  max-height: ${(props) => props.height};
  height: 100%;
  border-top: 1px solid black;
  overflow: hidden;
  ${mobile(css`
    display: none;
  `)}
`

const TableMobileWrapper = styled.div`
  width: calc(100% - 30px);
  max-height: ${(props) => props.height};
  height: 100%;
  overflow: hidden;
  display: none;
  margin: 0 auto;
  border-top: 1px solid black;
  ${mobile(css`
    display: block;
  `)}
`
const TableHeader = styled.div`
  width: 100%;
  height: 48px;
  background-color: #ffffff;
  display: flex;
  border-bottom: 1.3px solid #e0e1e5;
`
const TableHeaderColumn = styled.div`
  width: ${(props) => props.width};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const TableColumn = styled.div`
  width: 100%;
  height: 48px;
  background-color: #f2f3f5;
  display: flex;
  border-bottom: 1.3px solid #e0e1e5;

  &:hover {
    background-color: #eaebed;

    & > div > .comment_number {
      display: flex;
    }
  }
`

const TableColumnItem = styled.div`
  width: ${(props) => props.width};
  height: 100%;
  display: flex;
  justify-content: ${(props) => props.position};
  align-items: center;
  color: ${(props) => props.color};
`

const TableColumnCursorItem = styled.div`
  width: ${(props) => props.width};
  height: 100%;
  display: flex;
  justify-content: ${(props) => props.position};
  align-items: center;
  color: ${(props) => props.color};

  cursor: pointer;
  z-index: 1;
`

const TablePaginationWrapper = styled.div`
  width: fit-content;
  max-width: 400px;
  margin: 36px auto 0px auto;
  display: flex;
  & > div {
    margin: 0px 2px;
  }
`
const TablePaginationArrow = styled.div`
  width: 33px;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: solid 0.7px #ccced7;
  background-color: #f2f3f5;
  cursor: pointer;
`

const TablePaginationItem = styled.div`
  width: 27px;
  height: 27px;
  border-radius: 4px;
  ${({ selected }) => {
    return selected ? `color: #000000; border: solid 0.7px #000000;` : 'color: #8e909c; border: solid 0.7px #ccced7;'
  }}
  display:flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  cursor: pointer;
`

const TableNotice = styled.div`
  width: 50px;
  height: 32px;
  border-radius: 20px;
  border: solid 0.7px #e0e1e5;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`
const TableMobileColumn = styled.div`
  width: 100%;
  height: 70px;
  border-bottom: 1.2px solid #e0e1e5;
  padding: 12px 10px;

  display: flex;
  justify-content: space-between;
  flex-direction: column;

  cursor: pointer;
`
const Line = styled.div`
  background-color: #c3c8d2;

  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin: ${(props) => props.margin};
  background-color: ${(props) => props.color};
`
const TableMobileColumnTitle = styled.div`
  height: 38px;
  color: black;
  font-size: 13px;
  word-break: keep-all;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`
const TableMobileColumnInfo = styled.div`
  color: #828895;
  font-size: 12px;
  display: flex;
  align-items: center;
`
const FileIconImage = styled.img`
  width: 16px;
  height: 20px;
`

const DataNone = styled.div`
  width: 100%;
  min-height: 310px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 26px;
`

const MobileDataNone = styled.div`
  width: 100%;
  min-height: 140px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 22px;
`

const CommentNumber = styled.div`
  min-width: 24px;

  font-size: 10px;
  height: 14px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: white;
  border-radius: 20px;

  border: solid 1px #cdd0d8;

  margin-left: 6px;

  display: none;
  margin-top: 1px;

  color: #287eff;
`

function Table({ comment, changePageEvent, clickEvent, columnInfo, data, length, nowPage, slide, mobileData }) {
  const [commentData, setCommentData] = useState([])
  if (data.length > 0) {
    console.log(data[0].type)
  }
  function download(url) {
    var filename = url.substring(url.lastIndexOf('/') + 1).split('?')[0]
    var xhr = new XMLHttpRequest()
    xhr.responseType = 'blob'
    xhr.onload = function () {
      var a = document.createElement('a')
      a.href = window.URL.createObjectURL(xhr.response) // xhr.response is a blob
      a.download = filename // Set the file name.
      a.style.display = 'none'
      document.body.appendChild(a)
      a.click()
      a = null
    }
    xhr.open('GET', url)
    xhr.send()
  }
  return (
    <>
      {/* height={slide * 48 + 'px'} */}
      <TableWrapper>
        <TableHeader>
          {Object.keys(columnInfo).map((value, index) => {
            return (
              <TableHeaderColumn key={uuidv4()} width={columnInfo[value].width}>
                {columnInfo[value].name}
              </TableHeaderColumn>
            )
          })}
        </TableHeader>
        {data.length === 0 ? (
          <DataNone>게시물이 없습니다.</DataNone>
        ) : (
          data.map((value) => {
            return (
              <TableColumn key={uuidv4()}>
                {Object.keys(columnInfo).map((item, index) => {
                  if (columnInfo[item].name === '년도') {
                    return (
                      <TableColumnItem
                        key={uuidv4()}
                        onClick={() => {
                          return clickEvent && clickEvent(value.board_num, value.type)
                        }}
                        width={columnInfo[item].width}
                        position={columnInfo[item].position}
                      >
                        {value.c_date.slice(0, 4)}
                      </TableColumnItem>
                    )
                  }
                  if (columnInfo[item].name === '납부일') {
                    return (
                      <TableColumnItem
                        key={uuidv4()}
                        onClick={() => {
                          return clickEvent && clickEvent(value.board_num, value.type)
                        }}
                        width={columnInfo[item].width}
                        position={columnInfo[item].position}
                      >
                        {value.c_date.slice(0, 10)}
                      </TableColumnItem>
                    )
                  }
                  if (columnInfo[item].name === '현황') {
                    return (
                      <TableColumnItem
                        key={uuidv4()}
                        onClick={() => {
                          return clickEvent && clickEvent(value.board_num, value.type)
                        }}
                        color={value[item] === '결제취소' ? '#ff0101' : value[item] === '결제완료' ? '#287eff' : '#000000'}
                        width={columnInfo[item].width}
                        position={columnInfo[item].position}
                      >
                        {value[item]}
                      </TableColumnItem>
                    )
                  }
                  if (columnInfo[item].name === 'No.' && value.is_important === '1') {
                    return (
                      <TableColumnItem
                        key={uuidv4()}
                        onClick={() => {
                          return clickEvent && clickEvent(value.board_num, value.type)
                        }}
                        width={columnInfo[item].width}
                        position={columnInfo[item].position}
                      >
                        <TableNotice>공지</TableNotice>
                      </TableColumnItem>
                    )
                  }
                  if (columnInfo[item].name === '첨부파일' && value[item] !== null) {
                    return (
                      <TableColumnCursorItem
                        key={uuidv4()}
                        width={columnInfo[item].width}
                        position={columnInfo[item].position}
                        onClick={() => {
                          return clickEvent && clickEvent(value.board_num, value.type)
                        }}
                      >
                        <FileIconImage src={fileIcon} alt='fileIcon' />
                      </TableColumnCursorItem>
                    )
                  }
                  if (columnInfo[item].name === '조회수' || columnInfo[item].name === '금액') {
                    return (
                      <TableColumnItem
                        key={uuidv4()}
                        onClick={() => {
                          return clickEvent && clickEvent(value.board_num, value.type)
                        }}
                        width={columnInfo[item].width}
                        position={columnInfo[item].position}
                      >
                        {value[item] === null ? 0 : value[item].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </TableColumnItem>
                    )
                  }
                  if (columnInfo[item].name === '작성일') {
                    return (
                      <TableColumnItem
                        key={uuidv4()}
                        onClick={() => {
                          return clickEvent && clickEvent(value.board_num, value.type)
                        }}
                        width={columnInfo[item].width}
                        position={columnInfo[item].position}
                      >
                        {moment(value[item]).format('YYYY-MM-DD')}
                      </TableColumnItem>
                    )
                  }
                  if (columnInfo[item].name === '제 목') {
                    return (
                      <TableColumnItem
                        onClick={() => {
                          return clickEvent && clickEvent(value.board_num, value.type)
                        }}
                        width={columnInfo[item].width}
                        position={columnInfo[item].position}
                      >
                        {value[item]}
                        {comment === true ? (
                          <CommentNumber className='comment_number'>{value.is_news === null ? 0 : value.is_news}</CommentNumber>
                        ) : (
                          <></>
                        )}
                      </TableColumnItem>
                    )
                  }
                  if (columnInfo[item].name === 'No.') {
                    return (
                      <TableColumnItem
                        onClick={() => {
                          return clickEvent && clickEvent(value.board_num, value.type)
                        }}
                        width={columnInfo[item].width}
                        position={columnInfo[item].position}
                      >
                        {/* {test2[value.board_num - 1]} */}
                      </TableColumnItem>
                    )
                  }
                  return (
                    <TableColumnItem
                      key={uuidv4()}
                      onClick={() => {
                        return clickEvent && clickEvent(value.board_num, value.type)
                      }}
                      width={columnInfo[item].width}
                      position={columnInfo[item].position}
                    >
                      {value[item]}
                    </TableColumnItem>
                  )
                })}
              </TableColumn>
            )
          })
        )}
      </TableWrapper>
      <TableMobileWrapper height={slide * 70 + 'px'}>
        {data.length === 0 ? (
          <MobileDataNone>게시물이 없습니다.</MobileDataNone>
        ) : (
          data.map((value) => {
            console.log(value)
            console.log('####',value[mobileData])
            return (
              <TableMobileColumn
                key={uuidv4()}
                onClick={() => {
                  return clickEvent && clickEvent(value.board_num, value.type)
                }}
              >
                <TableMobileColumnTitle>{`${value.pay_contents} ${value.money}원 ${value.pay_status}`}</TableMobileColumnTitle>
                <TableMobileColumnInfo>
                  <div>{moment(value[mobileData[1]]).format('YYYY-MM-DD')}</div>
                  <Line width='0.7px' height='8px' margin='0px 6px'></Line>
                  <div>{value[mobileData[2]] ? value[mobileData[2]] : 0}</div>
                </TableMobileColumnInfo>
              </TableMobileColumn>
            )
          })
        )}
      </TableMobileWrapper>
      <TablePaginationWrapper>
        <TablePaginationArrow
          onClick={() => {
            nowPage > 1 ? changePageEvent(nowPage - 1) : null
          }}
        >
          <img src={prev} alt='pagination arrow' />
        </TablePaginationArrow>
        {[...Array(Math.ceil(length / slide)).keys()].map((item) => {
          return (
            <TablePaginationItem
              key={uuidv4()}
              selected={item + 1 === nowPage}
              onClick={() => {
                changePageEvent(item + 1)
              }}
            >
              {item + 1}
            </TablePaginationItem>
          )
        })}
        <TablePaginationArrow
          onClick={() => {
            Math.ceil(length / slide) > nowPage ? changePageEvent(nowPage + 1) : null
          }}
        >
          <img src={next} alt='pagination arrow' />
        </TablePaginationArrow>
      </TablePaginationWrapper>
    </>
  )
}

export default Table
