import React, {useState, useEffect} from 'react';
import styled, {css} from 'styled-components';
import Logo from '../../../shared/img/footer-logo.png'
import {mobile} from "../lib/viewport";
import axios from "axios"
import { useHistory, useParams } from "react-router-dom";

import Modal from "./Modal"

const FooterContainer = styled.footer`
	height: 8em;
	background-color: #dfe1e6;
	display: flex;

	position: absolute;
	bottom: 0px;
	left: 0px;
	right: 0px;

	${mobile(css`
		height: fit-content;
	`)}

	& > div {
		max-width: 1300px;
		width: 1300px;
		margin: 0 auto;
		

		${mobile(css`
			width:100%;
			padding: 20px;
			height:100%;
			align-items: flex-start;
		`)}

		display: flex;
		justify-content: space-between;
		align-items: center;
	 
		& > div {
			display: flex;
			align-items:center;
			flex-wrap: wrap;
		}
		
	}
`;

const InfoWrapper = styled.div`
	& > * {
		margin-bottom: 6px;

		font-size: 12px;
		color: #000;

		${mobile(css`
			margin: 6px 0px;
			color: #3e4145;
		`)}

	}
	& > div {
		display: flex;
		align-items: center;
		flex-wrap:wrap;
		& > * {
			margin-right: 8px;
		}

	}
	& > p {
		${mobile(css`
			margin: 10px 0px 0px 0px;
		`)}
	}
`;



const PrivacyStatementButton = styled.button`
	width: 107px;
	height: 27px;

	border-radius: 13.5px;
	border: solid 0.5px #d6d6d6;
	background-color: #f2f3f5;

	font-size: 11px;

	cursor: pointer;

	${mobile(css`

		padding: 0 10px;
		width:fit-content;
		min-width: 127px;
		height: 28px;
		margin-top: 4px;
	`)}
`;
const Line = styled.div`
	width: 0.5px;
	height: 7px;

	background-color: #b5bac6;
`;

const FooterW = styled.div`
	width: 100%;
	height: 8em;

	${mobile(css`
		height: 236px;
	`)}
`;

const Img = styled.img`
	width: 240px;
	height: 52px;
	margin-right: 28px;

	${mobile(css`
		width: 160px;
		height: 36px;
	`)}

`

function Footer() {
	const history = useHistory();
	// const [id, setId] = useState("");
	// useEffect(()=>{
	// 	if (localStorage.getItem("accessToken")) {
	// 		axios.post(`${process.env.REACT_APP_API_URL}/verification`, {
	// 			token: localStorage.getItem("accessToken")
	// 		})
	// 		.then(data => {
	// 			if (data.data.data.userid) {
	// 				setId(data.data.data.userid)
	// 			}
	// 		})
	// 	}
	// },[])
	const [modalState, setModalState] = useState(false)
	function closeEvent(state) {
		setModalState(state)
	}
	return (
		<>
		<Modal openState={modalState} closeEvent={(state)=>closeEvent(state)} title="약관 / 정책" subTitle="개인정보처리방침">
			{/* //개인정보처리방침 text 넣는 곳입니다. */}
		</Modal>
		<FooterW>
			<FooterContainer>
				<div>
					<div>
						<Img src={Logo} alt="logo" />
						<InfoWrapper>
							<p>대전광역시 중구 산성로 130(문화동)</p>
							<div>
								<p>Tel : 010-2163-7460</p>
								<Line />
								<p>Fax : 042-585-0129</p>
								<Line />
								<p>E-mail : luke@lukemedicalassociation.com</p>
							</div>
							<p>© 2022 LUKE MEDICAL SOCIETY OF PAIN RESEARCH. All rights reserved</p>
						</InfoWrapper>
					</div>
					<div>
					{/* <PrivacyStatementButton onClick={() => history.push(`/adminlogin`)}>관리자 페이지</PrivacyStatementButton> */}
					<PrivacyStatementButton onClick={() => setModalState(true)}>개인정보취급방침</PrivacyStatementButton>
					</div>
				</div>
			</FooterContainer>
		</FooterW>
		</>
	);
}

export default Footer;
