// import React, { useState } from 'react';
// import PropTypes, { arrayOf } from 'prop-types';
// import { useTranslation } from 'react-i18next';
// import { Field, Form } from 'react-final-form';
// import {
//   Card, CardBody, Col, Button, ButtonToolbar, Table, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, ButtonGroup,
// } from 'reactstrap';
// import TextField from '@material-ui/core/TextField';
// import MenuItem from '@material-ui/core/MenuItem';
// import BasicTableData from './BasicTableData';
// import ScheduleWrite from "./ScheduleWrite"
// import ModifyScheduleWrite from "./ModifyScheduleWrite"
// import showResults from '../showResults';
// import { BroserRouter, useHistory } from 'react-router-dom';
// import Pagination from './Pagination';

// const renderTextField1 = ({
//   input, label, meta: { touched, error }, children, select, multiline, history,
// }) => (
//   <TextField
//     className="material-form__field"
//     label={label}
//     type={input.type}
//     error={touched && error}
//     value={input.value}
//     select={select}
//     multiline={multiline}
//     onChange={(e) => {
//       e.preventDefault();
//       input.onChange(e.target.value);
//       if (e.target.value === "1") {
//         history.push("/managements/articles")
//       } else if (e.target.value === "3") {
//         history.push("/managements/videos")
//       } else if (e.target.value === "4") {
//         history.push("/managements/academics")
//       }
//     }}
//   >
//     {children}
//   </TextField>
// );
// const renderTextField2 = ({
//   input, label, meta: { touched, error }, children, handleSelect, select, multiline, history,
// }) => (
//   <TextField
//     className="material-form__field"
//     label={label}
//     type={input.type}
//     error={touched && error}
//     value={input.value}
//     select={select}
//     multiline={multiline}
//     onChange={(e) => {
//       e.preventDefault();
//       input.onChange(e.target.value);
//       handleSelect(e.target.value)
//     }}
//   >
//     {children}
//   </TextField>
// );

// const { tableHeaderResponsiveData, tableRowsData } = BasicTableData();
// const AnimatedLineFormWithLabels = ({ onSubmit, setEventList, totalList, category, setCategory,totalCount, setPageNum }) => {
//   const [write, setWrite] = useState(false);
//   const [modifyWrite, setModiyWrite] = useState(false);
//   const { t } = useTranslation('common');
//   const history = useHistory();
//   const [fetchData, setFetchData] = useState({})
//   const [idxChange, setIdxChange] = useState("")
//   const handleSelect = (value) => {
//     setCategory(value)
//   }

//   return (
//     <Col md={12} lg={12}>
//       <Card>
//         <CardBody>
//           <div className="card__title">
//             <h1>일정관리</h1>
//           </div>
//           <Form onSubmit={(e) => setFetchData(e)}>
//             {({ handleSubmit, form }) => (
//               <form className="material-form" onSubmit={handleSubmit}>

//                 <div className="row">
//                   <div className="col-4">
//                     <Field
//                       name="select"
//                       component={renderTextField1}
//                       select
//                       label="구분"
//                       defaultValue={2}
//                       history={history}
//                     >
//                       <MenuItem className="material-form__option" value="1">게시판관리</MenuItem>
//                       <MenuItem className="material-form__option" value="2">일정관리</MenuItem>
//                       <MenuItem className="material-form__option" value="3">동영상관리</MenuItem>
//                       <MenuItem className="material-form__option" value="4">학술상관리</MenuItem>
//                     </Field>
//                   </div>
//                   <div className="col-4">
//                     <Field
//                       name="select2"
//                       component={renderTextField2}
//                       select
//                       label="하위 구분"
//                       defaultValue={1}
//                       handleSelect={handleSelect}
//                     >
//                       <MenuItem className="material-form__option" value="1">연구회일정</MenuItem>
//                       <MenuItem className="material-form__option" value="2">학술행사일정</MenuItem>
//                     </Field>
//                   </div>
//                   <div className="col-3">
//                     <ButtonToolbar className="form__button-toolbar">
//                       <Button color="success" type="submit" onClick={() => {setWrite((pre) => !pre)}}>글쓰기</Button>
//                     </ButtonToolbar>
//                   </div>
//                 </div>
//               </form>
//             )}
//           </Form>
//           <br/>
//           <hr/>
//           {write ? <ScheduleWrite onSubmit={onSubmit} setWrite={setWrite} category={category} setEventList={setEventList} fetchData={fetchData} setFetchData={setFetchData}></ScheduleWrite> : null}
//           <br/>
//           <hr/>
//           <Table responsive className="table--bordered">
//             <thead>
//               <tr>
//                 {tableHeaderResponsiveData.map(item => (
//                   <th key={item.id}>{item.title}</th>
//                 ))}
//               </tr>
//             </thead>
//             <tbody>
//               {totalList.map((item, idx) => (
//                  <>
//                 <tr key={item.id_event}>
//                   <td>{item.id_event}</td>
//                   <td>{item.title}</td>
//                   <td>관리자</td>
//                   <td>{String(item.c_date).split("T")[0]}</td>
//                   <td>{item.ip}</td>
//                   <td>{item.file_link ? "o" : "x"}</td>
//                   <td><Button>이동</Button></td>
//                   <td><Button color="warning" onClick={() => {
//                       setModiyWrite((e) => !e)
//                       setIdxChange(idx)
//                   }}>수정</Button></td>
//                     <td>
//                     <div className='row'>
//                             <TextField
//                               className='material-form__field'
//                               id='status'
//                               defaultValue={1}
//                               select
//                               onChange={(e) => {}}
//                             >
//                               <MenuItem className='material-form__option' value='1'>
//                                 확인
//                               </MenuItem>
//                               <MenuItem className='material-form__option' value='2'>
//                                 삭제
//                               </MenuItem>
//                             </TextField>
//                             &nbsp;&nbsp;
//                             <Button
//                               color='primary'
//                               onClick={() => {
//                                 (window.confirm("변경하시겠습니까?"))
//                               }}
//                             >
//                               확인
//                             </Button>
//                     </div>
//                   </td>
//                   </tr>
//                   <td colSpan="9">
//                     {(modifyWrite ? idx === idxChange ? <ModifyScheduleWrite onSubmit={onSubmit} setWrite={setWrite} category={category} setEventList={setEventList} fetchData={fetchData} setFetchData={setFetchData}></ModifyScheduleWrite> : null : null)}
//                   </td>
//                   </>
//               ))}
//             </tbody>
//           </Table>
//           <Pagination total={totalCount} setPageNum={setPageNum} />
//         </CardBody>
//       </Card>
//     </Col>
//   );
// };

// AnimatedLineFormWithLabels.propTypes = {
//   onSubmit: PropTypes.func.isRequired,
// };
// AnimatedLineFormWithLabels.defaultProps = {
//   onClick: () =>{},
// }
// export default AnimatedLineFormWithLabels;
 /* eslint-disable */
import React, { useState } from 'react'
// import PropTypes from 'prop-types'
import { Field, Form } from 'react-final-form'
import { Card, CardBody, Col, Button, ButtonToolbar, Table } from 'reactstrap'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import BasicTableData from './BasicTableData'
import ScheduleWrite from './ScheduleWrite'
import { useHistory } from 'react-router-dom'
import Pagination from './Pagination'
import ModifyScheduleWrite from './ModifyScheduleWrite'
import axios from 'axios'
// import axios from "axios"
import moment from 'moment'

const renderTextField1 = ({ input, label, meta: { touched, error }, children, select, multiline, history }) => (
  <TextField
    className='material-form__field'
    label={label}
    type={input.type}
    error={touched && error}
    value={input.value}
    select={select}
    multiline={multiline}
    onChange={(e) => {
      e.preventDefault()
      input.onChange(e.target.value)
      if (e.target.value === '1') {
        history.push('/admin/managements/articles')
      } else if (e.target.value === '3') {
        history.push('/admin/managements/videos')
      } else if (e.target.value === '4') {
        history.push('/admin/managements/academics')
      }
    }}
  >
    {children}
  </TextField>
)
const renderTextField2 = ({ input, label, meta: { touched, error }, children, handleSelect, select, multiline }) => (
  <TextField
    className='material-form__field'
    label={label}
    type={input.type}
    error={touched && error}
    value={input.value}
    select={select}
    multiline={multiline}
    onChange={(e) => {
      e.preventDefault()
      input.onChange(e.target.value)
      handleSelect(e.target.value)
    }}
  >
    {children}
  </TextField>
)

const { tableHeaderResponsiveData } = BasicTableData()
const AnimatedLineFormWithLabels = ({
  onSubmit,
  setEventList,
  totalNum,
  totalList,
  category,
  setCategory,
  setPageNum,
  setReadNum,
  oneData,
  loading,
  setLoading,
  setOneData,
  updateAmount,
  setUpdate
}) => {
  const [write, setWrite] = useState(false)
  const [modifyWrite, setModifyWrite] = useState(false)
  const [idxChange, setIdxChange] = useState('')
  const history = useHistory()
  const [fetchData, setFetchData] = useState({})
  const [oneAmount, setOneAmount] = useState([])
  // const [loading, setLoading] = useState(false)
  const handleSelect = (value) => {
    setPageNum(1)
    setCategory(value)
  }
  const [isDel, setIsDel] = useState(false)
  // const [searchInfo, setSearchInfo] = useState({})

  // const handleFetch = (id) => {
  //   axios.get(`${process.env.REACT_APP_API_URL}/scheduleread?readnum=${id}`)
  //   .then(res=> {
  //     setSearchInfo(res.data)
  //   })
  //   .then(() => setModifyWrite(pre => !pre))
  //   .catch(err=>console.log(err))
  // }

  const handleDel = (id) => {
    axios.get(`${process.env.REACT_APP_API_URL}/delevent?id=${id}`)
      .then(() => setUpdate(pre => !pre))
      .catch(err => console.log(err))
  }

  const handleCertain = (id) => {
    console.log("hihi")
    axios.get(`${process.env.REACT_APP_API_URL}/delevent?id=${id}&certain=certain`)
    .then(() => setUpdate(pre => !pre))
    .catch(err => console.log(err))
  }

  const handleTest = (id) => {
    setOneData(totalList.find(el => el.id_event === id))
    setOneAmount(updateAmount.filter(el => el.event_id === id))
  }
  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className='card__title'>
            <h1>일정관리</h1>
          </div>
          <Form onSubmit={(e) => setFetchData(e)}>
            {({ handleSubmit, form }) => (
              <form className='material-form' onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-4'>
                    <Field name='select' component={renderTextField1} select label='구분' defaultValue={2} history={history}>
                      <MenuItem className='material-form__option' value='1'>
                        게시판관리
                      </MenuItem>
                      <MenuItem className='material-form__option' value='2'>
                        일정관리
                      </MenuItem>
                      <MenuItem className='material-form__option' value='3'>
                        동영상관리
                      </MenuItem>
                      <MenuItem className='material-form__option' value='4'>
                        학술상관리
                      </MenuItem>
                    </Field>
                  </div>
                  <div className='col-4'>
                    <Field
                      name='select2'
                      component={renderTextField2}
                      select
                      label='하위 구분'
                      defaultValue={1}
                      handleSelect={handleSelect}
                    >
                      <MenuItem className='material-form__option' value='1'>
                        연구회일정
                      </MenuItem>
                      <MenuItem className='material-form__option' value='2'>
                        학술행사일정
                      </MenuItem>
                    </Field>
                  </div>
                  <div className='col-3'>
                    <ButtonToolbar className='form__button-toolbar'>
                      <Button
                        color='success'
                        type='submit'
                        onClick={() => {
                          setWrite((pre) => !pre)
                        }}
                      >
                        글쓰기
                      </Button>
                    </ButtonToolbar>
                  </div>
                </div>
              </form>
            )}
          </Form>
          <br />
          <hr />
          {write ? (
            <ScheduleWrite
              onSubmit={onSubmit}
              setWrite={setWrite}
              category={category}
              setEventList={setEventList}
              fetchData={fetchData}
              setFetchData={setFetchData}
              loading={loading}
              setLoading={setLoading}
            ></ScheduleWrite>
          ) : null}
          <br />
          <hr />
          <Table responsive className='table--bordered'>
            <thead>
              <tr>
                {tableHeaderResponsiveData.map((item) => (
                  <th key={item.id}>{item.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {totalList.map((item, idx) => (
                <>
                  <tr key={item.id_event}>
                    <td>{item.id_event}</td>
                    <td>{item.title}</td>
                    <td>관리자</td>
                    <td>{moment(item.c_date).format('YYYY-MM-DD / HH:mm:ss')}</td>
                    <td>{item.ip}</td>
                    <td>{item.file_link ? 'O' : 'X'}</td>
                    <td>
                    {/* {item.event_type === "1" ?  */}
                      {item.del === 'yes' ? <Button onClick={() => window.alert("삭제된 게시물입니다.")}>이동</Button> 
                        : <Button onClick={() => {
                            history.push(`/app/notification/schedule/${item.id_event}/introduce`)
                          }}>이동</Button>}  
                    {/* : <a href={`${process.env.REACT_APP_URL}/lukepain/app/notification/schedule${item.id_event}/introduce`}><Button>이동</Button></a>} */}
                    </td>
                    <td>
                      <Button
                        color='warning'
                        onClick={() => {
                          setModifyWrite((e) => !e)
                          handleTest(item.id_event)
                          setIdxChange(idx)
                          setReadNum(item.id_event)
                          // console.log('이것은?', oneData)
                          // handleFetch(item.id_event)
                        }}
                      >
                        수정
                      </Button>
                    </td>
                    <td>
                      <div className='row'>
                        <TextField className='material-form__field' id='status' defaultValue={item.del === 'yes' ? '2' : '1'} select onChange={(e) => e.target.value === '2' ? setIsDel(true) : setIsDel(false)}>
                          <MenuItem className='material-form__option' value='1'>
                            확인
                          </MenuItem>
                          <MenuItem className='material-form__option' value='2'>
                            삭제
                          </MenuItem>
                        </TextField>
                        &nbsp;&nbsp;
                        <Button color='primary' 
                          onClick={() => {
                            if(window.confirm("변경하시겠습니까?")){
                              if(isDel) {
                                handleDel(item.id_event)
                              }
                              else handleCertain(item.id_event)
                            }
                          }
                        }>
                          확인
                        </Button>
                      </div>
                    </td>
                  </tr>
                  <td colSpan='9'>
                    {modifyWrite ? (
                      idx === idxChange ? (
                        <ModifyScheduleWrite
                          onSubmit={onSubmit}
                          setWrite={setModifyWrite}
                          category={category}
                          setEventList={setEventList}
                          fetchData={fetchData}
                          oneData={oneData}
                          setFetchData={setFetchData}
                          loading={loading}
                          setLoading={setLoading}
                          oneAmount={oneAmount}
                          setOneAmount={setOneAmount}
                          setUpdate={setUpdate}
                          // searchInfo={searchInfo}
                        ></ModifyScheduleWrite>
                      ) : null
                    ) : null}
                  </td>
                </>
              ))}
            </tbody>
          </Table>
          <Pagination total={totalNum} setPageNum={setPageNum} category={category} />
        </CardBody>
      </Card>
    </Col>
  )
}

export default AnimatedLineFormWithLabels
