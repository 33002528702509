import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Field, Form } from 'react-final-form'
import { Card, CardBody, Col, Button, ButtonToolbar, Table } from 'reactstrap'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import { useHistory } from 'react-router-dom'
import VideoWrite from './VideoWrite'
import ModifyVideoWrite from './ModifyVideoWrite'
import showResults from '@/utils/showResults'
import Pagination from './Pagination'
import axios from 'axios'
import moment from "moment"

const renderTextField = ({ input, label, meta: { touched, error }, children, select, multiline, history, disabled }) => (
  <TextField
    className='material-form__field'
    label={label}
    type={input.type}
    error={touched && error}
    value={input.value}
    select={select}
    multiline={multiline}
    disabled={disabled}
    onChange={(e) => {
      e.preventDefault()
      input.onChange(e.target.value)
      if (e.target.value === '1') {
        history.push('/admin/managements/articles')
      } else if (e.target.value === '2') {
        history.push('/admin/managements/schedules')
      } else if (e.target.value === '4') {
        history.push('/admin/managements/academics')
      }
    }}
  >
    {children}
  </TextField>
)
const headerResponsive = [
  { id: 1, title: '번호' },
  { id: 2, title: '제목' },
  { id: 3, title: '카테고리' },
  { id: 4, title: '작성일' },
  { id: 5, title: '작성IP' },
  { id: 7, title: '첨부' },
  { id: 8, title: 'url' },
  { id: 9, title: '수정' },
  { id: 10, title: '삭제' },
]
// const { tableHeaderResponsiveData, tableRowsData } = BasicTableData()

const AnimatedLineFormWithLabels = () => {
  const history = useHistory()
  const [showWrite, setShowWrite] = useState(false)
  const [videoRead, setVideoRead] = useState(0)
  const [statusChange, setStatusChange] = useState(0)
  const [videoReadVal, setVideoReadVal] = useState('')
  const [radioVal, setRadioVal] = useState('')
  const [videoList, setVideoList] = useState([])
  const [showModifyWrite, setShowModifyWrite] = useState(false)
  const [totalCount, setTotalCount] = useState(0)
  const [pageNum, setPageNum] = useState('')
  const [onOff, setOnOff] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const boardOneData = (num) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/videoRead?num=${num}&sta=1`)
      .then((data) => setVideoReadVal(data.data.data))
      .then(()=>setLoading2(true))
      .catch((err) => console.log(err))
  }
  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/videoList`, {
        page: pageNum,
        folder: '관리자',
      })
      .then((data) => {
        setVideoList(data.data.data2)
        setTotalCount(data.data.count)
      })
      .then(() => setLoading(true))
      // .then((data) => console.log(data.data))
      .catch((err) => console.log(err))
  }, [pageNum, onOff,loading])
  const dummySubmit = () => {}
  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className='card__title'>
            <h1>동영상관리</h1>
          </div>
          <Form onSubmit={dummySubmit}>
            {({ handleSubmit, form }) => (
              <form className='material-form' onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-4'>
                    <Field name='select' component={renderTextField} select label='구분' defaultValue={3} history={history}>
                      <MenuItem className='material-form__option' value='1'>
                        게시판관리
                      </MenuItem>
                      <MenuItem className='material-form__option' value='2'>
                        일정관리
                      </MenuItem>
                      <MenuItem className='material-form__option' value='3'>
                        동영상관리
                      </MenuItem>
                      <MenuItem className='material-form__option' value='4'>
                        학술상관리
                      </MenuItem>
                    </Field>
                  </div>
                  <div className='col-4'>
                    <Field name='select2' component={renderTextField} select label='하위 구분' defaultValue={1} disabled>
                      <MenuItem className='material-form__option' value='1'>
                        동영상강의
                      </MenuItem>
                    </Field>
                  </div>
                  <div className='col-3'>
                    <ButtonToolbar className='form__button-toolbar'>
                      <Button
                        color='success'
                        onClick={() => {
                          setShowWrite((e) => !e)
                          setShowModifyWrite(false)
                        }}
                      >
                        글쓰기
                      </Button>
                    </ButtonToolbar>
                  </div>
                </div>
              </form>
            )}
          </Form>
          <br />
          <hr />
          {showWrite ? <VideoWrite setShowWrite={setShowWrite} setVideoList={setVideoList} setOnOff={setOnOff} setLoading={setLoading}/> : null}
          <Table responsive className='table--bordered'>
            <thead>
              <tr>
                {headerResponsive.map((item) => (
                  <th key={item.id}>{item.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {loading ? videoList.map((item, idx) => (
                <>
                  <tr key={item.id_video}>
                    <td>{item.id_video}</td>
                    <td>{item.title}</td>
                    <td>{item.folder_name}</td>
                    <td>{moment(item.c_date).format('YYYY-MM-DD / HH:mm:ss')}</td>
                    <td>{item.ip}</td>
                    {item.file_link ? <td>O</td> : <td>X</td>}
                    <td>
                      {item.status === 2 ? <Button onClick={() => window.alert("삭제된 게시물입니다.")}>이동</Button>
                        : <Button onClick={() => {
                          history.push(`/app/community/video/${item.id_video}/${totalCount}`)
                        }}>이동</Button>}
                    </td>
                    <td>
                      <Button
                        color='warning'
                        onClick={() => {
                          setShowModifyWrite((e) => !e)
                          setShowWrite(false)
                          // setWrite(false)
                          setVideoRead(idx)
                          boardOneData(item.id_video)
                          // setDisableset((e) => !e)
                        }}
                      >
                        수정
                      </Button>
                    </td>
                    <td>
                      <div className='row'>
                        <TextField
                          className='material-form__field'
                          id='status'
                          defaultValue={item.status}
                          select
                          onChange={(e) => {
                            setStatusChange(e.target.value)
                          }}
                        >
                          <MenuItem className='material-form__option' value='1'>
                            확인
                          </MenuItem>
                          <MenuItem className='material-form__option' value='2'>
                            삭제
                          </MenuItem>
                        </TextField>
                        &nbsp;&nbsp;
                        <Button
                          color='primary'
                          onClick={() => {
                            if (window.confirm('변경하시겠습니까?')) {
                              const values = {
                                id_video: item.id_video,
                                status: statusChange,
                                num: 2,
                              }
                              axios
                                .post(`${process.env.REACT_APP_API_URL}/noticesStatusUpdate`, values)
                                // .then((data) => console.log(data))
                                .catch((err) => console.log(err))
                              window.alert("변경되었습니다.")
                              axios
                                .post(`${process.env.REACT_APP_API_URL}/videoList`, {
                                  page: pageNum,
                                  folder: '관리자',
                                })
                                .then((data) => {
                                  setVideoList(data.data.data2)
                                  setTotalCount(data.data.count)
                                })
                                .then(() => setLoading(true))
                                // .then((data) => console.log(data.data))
                                .catch((err) => console.log(err))
                            }
                          }}
                        >
                          확인
                        </Button>
                      </div>
                    </td>
                  </tr>
                  <td colSpan='9'>
                    {showModifyWrite && loading2 ? (
                      idx === videoRead ? (
                        <ModifyVideoWrite
                          onSubmit={showResults}
                          // setDisableset={setDisableset}
                          videoReadVal={videoReadVal}
                          setShowModifyWrite={setShowModifyWrite}
                          showModifyWrite={showModifyWrite}
                          setRadioVal={setRadioVal}
                          radioVal={radioVal}
                          setShowWrite={setShowWrite}
                          setVideoList={setVideoList}
                          setLoading={setLoading}
                          setLoading2={setLoading2}
                          boardOneData={boardOneData}
                        />
                      ) : null
                    ) : null}
                  </td>
                </>
              )) : null}
            </tbody>
          </Table>
          <Pagination total={totalCount} setPageNum={setPageNum} />
        </CardBody>
      </Card>
    </Col>
  )
}

export default AnimatedLineFormWithLabels
