 /* eslint-disable */
import React, { Fragment, useEffect, useState } from 'react'
// import PropTypes from 'prop-types'
import { Field, Form } from 'react-final-form'
import { Button, ButtonToolbar } from 'reactstrap'
import TextField from '@material-ui/core/TextField'
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon'
// import renderDatePickerField from './DatePicker'
// import renderDatePickerField2 from './DatePickerEnd'
// import Rend from './DatePicker'
import JustifyTabsBorderedTop from './JustifyTabsBorderedTop'
import ModifySummerNote from './ModifySummerNote.jsx'
import renderFileInputField from '@/shared/components/form/FileInput'
import styled from "styled-components"
// import DatePickerField from "./DatePicker"
// import { useSelector } from "react-redux"
import MenuItem from '@material-ui/core/MenuItem'
import DatePicker from 'react-datepicker'
import axios from "axios"
// import { isMobileOnly } from 'react-device-detect'
// import { renderComponentField } from './FormField'

// const DatePickerField = ({ onChange }) => {
//   const [startDate, setStartDate] = useState(null)
//   const handleChange = (date) => {
//     setStartDate(date)
//     onChange(date)
//   }

//   return (
//     <div className='date-picker'>
//       <DatePicker
//         className='material-form__field'
//         selected={startDate}
//         onChange={handleChange}
//         dateFormat='yyyy/MM/dd'
//         dropDownMode='select'
//         popperPlacement='center'
//         withPortal={isMobileOnly}
//       />
//     </div>
//   )
// }

const CalendarInput = styled.input`
 outline: none;
 border: none;
 margin-top: 13px;
 border-bottom: 1px solid #f2f4f7;
 cursor: pointer;
 :focus {
   border-bottom: 1px solid #4ce1b6;
 }
 :hover {
   border-bottom: 1px solid #4ce1b6;
 }
`

export const Sdate = ({start, handleModifyInfo}) => {
    const [startDate, setStartDate] = useState(start ? new Date(start) : null);
    return (
      <DatePicker 
        selected={startDate}
        onChange={(date) => {
          setStartDate(date)
          handleModifyInfo(date, "start")
        }} 
        dateFormat="yyyy-MM-dd"
        minDate={new Date()}
        customInput={<CalendarInput />}
      />
    );
}


export const Edate = ({end, handleModifyInfo, start, mod, setMod}) => {
  const [startDate, setStartDate] = useState(end ? new Date(end) : null);

  useEffect(() => {
    if (mod){
      setStartDate(end ? new Date(end) : null)
      setMod(false)
    } else {
      setStartDate(start ? new Date(start) : null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start])

  return (
    <DatePicker 
      selected={startDate} 
      onChange={(date) => {
        if (new Date(start).getTime() > new Date(date).getTime()){
          setStartDate(start)
          window.alert("일정 시작 날짜보다 이전 날짜를 선택할 수 없습니다.")
        } else {
          setStartDate(date)
          // setPreStart(date)
          handleModifyInfo(date, "end")
        }
        // setStartDate(date)
        // handleModifyInfo(date, "end")
      }} 
      dateFormat="yyyy-MM-dd"
      minDate={new Date()}
      customInput={<CalendarInput />}
    />
  );
}

const renderTextField = ({ input, label, meta: { touched, error }, children, select, multiline, disabled }) => (
  <TextField
    className='material-form__field'
    label={label}
    type={input.type}
    error={touched && error}
    value={input.value}
    select={select}
    multiline={multiline}
    onChange={(e) => {
      e.preventDefault()
      input.onChange(e.target.value)
    }}
    disabled={disabled}
  >
    {children}
  </TextField>
)

const renderTextField2 = ({ input, label, meta: { touched, error }, children, handleSelect, select, multiline }) => (
  <TextField
    className='material-form__field'
    label={label}
    type={input.type}
    error={touched && error}
    value={input.value}
    select={select}
    multiline={multiline}
    onChange={(e) => {
      e.preventDefault()
      input.onChange(e.target.value)
      handleSelect(e.target.value)
    }}
  >
    {children}
  </TextField>
)

const ScheduleWrite = (props) => {
  // console.log("oneAmount", props.oneData)
  // console.log("props.oneData", props.oneData)
  // const modifyResearch = useSelector((state) => state.searchList)
  const [mod, setMod] = useState(true)
  const [contents, setContents] = useState(props.oneData.contents)
  const [preRegi, setPreRegi] = useState(props.oneData.will_receive_pre_reg)
  const [amount, setAmount] = useState(props.oneAmount)
  // const [value, setValue] = useState(props.oneData.title)
  const [preStart, setPreStart] = useState(props.oneData.pre_reg_startdate)
  const [preEnd, setPreEnd] = useState(props.oneData.pre_reg_enddate)
  const [count, setCount] = useState(props.oneData.count)
  const [modify, setModify] = useState(true)
  const [modifyInfo, setModifyInfo] = useState({
    start: props.oneData.startdate,
    end: props.oneData.enddate,
    // preStart : "",
    // preEnd: "",
    place: props.oneData.place,
    reg_method: props.oneData.reg_method,
    title: props.oneData.title,
  })

  const formData = new FormData()

  useEffect(() => {
    // console.log("카테고리", props.category)
    props.setFetchData({ select2: props.category, contents, preRegi, amount, preStart, preEnd, count}) 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contents, preRegi, amount, preStart, preEnd, count, props.category])



  const handleModifyInfo = (dateValue, type) => {
    setModifyInfo(pre => {
      return {...pre, [type]: dateValue}
    })
  }

  const handleValue = (e, type) => {
    setModifyInfo(pre => {
      return {...pre, [type]: e.target.value}
    })
  }


  const handleOption = (e) => {
    setCount(e.target.value)
  }
  

  const handleFetchUpdate = () => {
    const data = {
      event_type: props.oneData.event_type,
      event_type_detail: props.oneData.event_type_detail,
      title: modifyInfo.title,
      startdate: modifyInfo.start,
      enddate: modifyInfo.end,
      place: modifyInfo.place,
      reg_method: modifyInfo.reg_method,
      contents: contents, //서머노트 contents
      will_receive_pre_reg: preRegi,
      pre_reg_startdate: preStart,
      pre_reg_enddate: preEnd,
      count
    }
  
    // console.log("변경될 데이터", modifyInfo)
    // console.log("사전등록", preRegi)
    // console.log("내용", contents)
    // console.log("amount", amount)
    // console.log("사전등록기간", preStart)
    // console.log("사전끝", preEnd)
    axios.post(`${process.env.REACT_APP_API_URL}/modify-research`, {
      data, amount, id: props.oneData.id_event
    })
    .then(() => {
      axios.post(`${process.env.REACT_APP_API_URL}/modify-research-file?id=${props.oneData.id_event}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    })
    .then(() => props.setUpdate(pre => !pre))
    .catch(err => console.log(err))
  }


  const handleFile = (e) => {
    formData.append("modify", e.target.files[0])
  }

  return (
    <Fragment>
      <div className='card__title'>
        <h1>수정하기</h1>
      </div>
      <hr />
      <br />
      <br />
      <Form onSubmit={(e) => props.onSubmit(e, props.fetchData, props.setEventList)}>
        {({ handleSubmit, form }) => (
          <form className='material-form' onSubmit={handleSubmit}>
            <div className='row'>
              <div className='col-6'>
                <span className='form__form-group-label'>시작일</span>
                <div className='form__form-group-field'>
                  <Sdate start={props.oneData.startdate} handleModifyInfo={handleModifyInfo} />
                  {/* <Field name='start_date' component={renderDatePickerField} data={props.oneData}/> */}
                  <div className='form__form-group-icon' style={{marginLeft: "10px", marginBottom: "10px"}}>
                    <CalendarBlankIcon />
                  </div>
                </div>
                {/* <StartDate start={props.oneData.startdate}></StartDate> */}
                <span className='form__form-group-label'>종료일</span>
                <div className='form__form-group-field'>
                  <Edate end={props.oneData.enddate} handleModifyInfo={handleModifyInfo} start={modifyInfo.start} mod={mod} setMod={setMod}/>
                  {/* <Field name='end_date' component={renderDatePickerField} /> */}
                  <div className='form__form-group-icon' style={{marginLeft: "10px", marginBottom: "10px"}}>
                    <CalendarBlankIcon />
                  </div>
                </div>
                <br />

                {/* <select onChange={handleOption}> */}
                  {props.category === 1 || props.category === "1"
                  ? 
                  <>
                    <TextField className='material-form__field' id='status' defaultValue={props.oneData.count} select onChange={handleOption}>
                      <MenuItem className='material-form__option' value='정기행사' >
                        정기행사
                      </MenuItem>
                      <MenuItem className='material-form__option' value='연수강좌' >
                        연수강좌
                      </MenuItem>  
                     </TextField>
                  {/* <option value="정기행사" selected={props.oneData.count === "정기행사" ? "selected" : null}>정기행사</option>
                  <option value="연수강좌" selected={props.oneData.count === "연수강좌" ? "selected" : null}>연수강좌</option> */}
                  </>
                  :
                  <>
                    <TextField className='material-form__field' id='status' defaultValue={props.oneData.count} select onChange={handleOption}>
                      <MenuItem className='material-form__option' value='학술대회' >
                        학술대회
                      </MenuItem>
                      <MenuItem className='material-form__option' value='학술집담회' >
                        학술집담회
                      </MenuItem>
                      <MenuItem className='material-form__option' value='워크숍 및 세미나' >
                        워크숍 및 세미나
                      </MenuItem>
                    </TextField>
                  {/* <option value="학술대회" selected={props.oneData.count === "학술대회" ? "selected" : null}>학술대회</option>
                  <option value="학술집담회" selected={props.oneData.count === "학술잡담회" ? "selected" : null}>학술잡담회</option>
                  <option value="워크숍 및 세미나" selected={props.oneData.count === "워크숍 및 세미나" ? "selected" : null}>워크숍 및 세미나</option> */}
                  </>}
                  {/* </select> */}

                {/* 장소 */}
                <div className="MuiFormControl-root MuiTextField-root material-form__field">
                  <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled" data-shrink="true">장소</label>
                  <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl">
                    <input aria-invalid="false" type="text" className="MuiInputBase-input MuiInput-input" value={modifyInfo.place} onChange={(e) => handleValue(e, "place")} />
                  </div>
                </div>
                {/* 접수방법 */}
                <div className="MuiFormControl-root MuiTextField-root material-form__field">
                  <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled" data-shrink="true">접수</label>
                  <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl">
                    <input aria-invalid="false" type="text" className="MuiInputBase-input MuiInput-input" value={modifyInfo.reg_method} onChange={(e) => handleValue(e, "reg_method")} />
                  </div>
                </div>
                {/* <RegisterField></RegisterField> */}
                <span className='form__form-group-label'>사전등록</span>
                <div className='form__form-group-field'>
                  {/* <Field name='status' component={renderRadioButtonField} label='X' radioValue='x' /> ????*/}
                  <div className='form__form-group-input-wrap'>
                    <label className='radio-btn'>
                      <input
                        className='radio-btn__radio'
                        name='status'
                        type='radio'
                        value='x'
                        checked={preRegi === 'X' ? 'checked' : null}
                        onClick={() => setPreRegi('X')}
                      />
                      <span className='radio-btn__radio-custom'></span>
                      <span className='radio-btn__label'>X</span>
                    </label>
                  </div>
                  <div className='form__form-group-input-wrap'>
                    <label className='radio-btn'>
                      <input
                        className='radio-btn__radio'
                        name='status'
                        type='radio'
                        value='o'
                        checked={preRegi === 'O' ? 'checked' : null}
                        onClick={() => setPreRegi('O')}
                      />
                      <span className='radio-btn__radio-custom'></span>
                      <span className='radio-btn__label'>O</span>
                    </label>
                  </div>
                  {/* <Field name='status' component={renderRadioButtonField} label='O' radioValue='o' /> */}
                </div>
              </div>
              <div className='col-6'>
                <JustifyTabsBorderedTop preRegi={preRegi} setAmount={setAmount} amount={amount} setPreStart={setPreStart} setPreEnd={setPreEnd} preStart={preStart} preEnd={preEnd} modify={modify} setModify={setModify}></JustifyTabsBorderedTop>
              </div>
            </div>
            <br />
            <div className='row'>
              <div className='col-12'>
                {/* <Field name='title' component={renderTextField} label='제목' /> */}
                <div className="MuiFormControl-root MuiTextField-root material-form__field">
                  <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled" data-shrink="true">제목</label>
                  <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl">
                    <input aria-invalid="false" type="text" className="MuiInputBase-input MuiInput-input" value={modifyInfo.title} onChange={(e) => handleValue(e, "title")} />
                  </div>
                </div>
              </div>
            </div>
            <ModifySummerNote boardReadVal={contents} setContents={setContents}></ModifySummerNote>
            <br />
            <div className='form__form-group'>
              <span className='form__form-group-label'>첨부파일</span>
              <div className='form__form-group-field'>
                <input type="file" onChange={handleFile}/>
              </div>
            </div>
            <ButtonToolbar className='form__button-toolbar'>
              <Button
                color='primary'
                type='button'
                onClick={() => {
                  handleFetchUpdate()
                  if (window.confirm('등록 하시겠습니까?')) {
                    window.alert('등록되었습니다.')
                    // form.submit()
               
                    props.setWrite((pre) => !pre)
                  }
                }}
              >
                확인
              </Button>
              <Button
                type='button'
                color='danger'
                onClick={() => {
                  props.setWrite((pre) => !pre)
                }}
              >
                취소
              </Button>
            </ButtonToolbar>
          </form>
        )}
      </Form>
    </Fragment>
  )
}

// const TitleBox = styled.div`

// `

// function PlaceField() {
  // return <Field name='place' component={renderTextField} placeholder=' 입력해 주세요' label='장소' />
// }
function RegisterField() {
  return <Field name='register' component={renderTextField} placeholder=' 입력해 주세요' label='접수' />
}

// function StartDate(props) {
//   return (
//     <div className='form__form-group-field'>
//       <div className='form__form-group-input-wrap'>
//         <div className='date-picker'>
//           <div className='react-datepicker-wrapper'>
//             <div className='react-datepicker__input-container'>
//               <input type='text' className='material-form__field' value={props.start} />
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className='form__form-group-icon'>
//         <svg className='mdi-icon ' width='24' height='24' fill='currentColor' viewBox='0 0 24 24'>
//           <path d='M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1'></path>
//         </svg>
//       </div>
//     </div>
//   )
// }

export default ScheduleWrite
