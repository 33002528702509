import React, { useEffect } from "react";
import styled, { css } from "styled-components";

import { useHistory } from "react-router-dom";

import Banner from "./components/Banner";
import Table from "../../components/Table";

import { mobile } from "../../lib/viewport";
import searchIcon from "../../../../shared/img/board-selectbox-search.png";

import axios from "axios";
import { useState } from "react";
import Skeleton from "../../Skeleton";

const NoticeWrapper = styled.div`
	width: 945px;
	margin: 50px auto 200px auto;
	margin-top: 50px;
	${mobile(css`
		width: 100%;
	`)}
`;

const NoticeSearch = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: flex-start;
	margin-bottom: 16px;

	${mobile(css`
		display: none;
	`)}
`;

const NoticeMobileSearch = styled.div`
	width: calc(100% - 30px);
	height: 100%;
	margin: 0 auto 16px auto;
	display: none;

	${mobile(css`
		display: block;
	`)}
`;

const SearchInputWrapper = styled.div`
	width: 160px;
	height: 33px;
	margin: 0px 10px 0px 10px;
	padding: 0px 10px;
	border-radius: 7px;
	border: solid 0.7px #e0e1e5;
	background-color: #fff;

	display: flex;
	justify-content: space-between;
	align-items: center;

	& > img {
		cursor: pointer;
	}

	${mobile(css`
		width: 100%;
		margin: 0px;
	`)}
`;
const SearchInput = styled.input`
	width: 100%;
	height: 100%;
	outline: none;
	border: 0px;
`;
const SearchSelectWrapper = styled.div`
	width: 120px;
	border: solid 0.7px #e0e1e5;
	border-radius: 7px;
	padding: 0px 10px;

	display: flex;
	align-items: center;

	background-color: #ffffff;

	& > select {
		width: 100%;

		outline: none;
		border: 0px;
	}
`;
function Notice() {
	const history = useHistory();

	const [pageNumber, setPageNumber] = useState(1);
	const [totalPage, setTotalPage] = useState(1);
	const [tableData, setTableData] = useState([]);
	const [resData, setResData] = useState([]);

	const [select, setSelect] = useState("제목");
	const [keyword, setKeyword] = useState("");
	const [searchKeyword, setSearchKeyword] = useState("");
	const [loading, setLoading] = useState(false);

	function ClickEvent(index, type) {
		history.push(
			`/app/notification/notice/${index}/${type}/${resData.length}`
		);
	}

	function changePageEvent(index) {
		// console.log(index);
		setPageNumber(index);
	}
	useEffect(() => {
		let isMount = true;
		setLoading(false);
		// console.log("렌더링이 완료될 때마다 실행합니다.", searchKeyword);
		if (searchKeyword === "") {
			axios
				.post(`${process.env.REACT_APP_API_URL}/boardList`, {
					status: 1,
					num: 1,
					page: pageNumber,
				})
				.then((data) => {
					if (isMount) {
						// console.log("호출")
						setResData(data.data.data2);
						setTotalPage(data.data.count);
						setTableData(
							data.data.data2.slice(
								(pageNumber - 1) * 20,
								20 * pageNumber
							)
						);
					}
				});
		} else {
			// console.log("start")
			axios
				.post(`${process.env.REACT_APP_API_URL}/boardsearch`, {
					num: 1,
					select,
					keyword: searchKeyword,
					page: pageNumber,
				})
				.then((data) => {
					if (isMount) {
						setResData(data.data.searchDiary);
						setTotalPage(data.data.count);
						// console.log(data)
						setTableData(data.data.searchDiary);
					}
				});
		}
		setLoading(true);
		return () => {
			isMount = false;
		};
	}, [pageNumber]);

	function searchEvent(keywordText, inputText) {
		// console.log(keywordText, inputText)
		setSearchKeyword(keyword);
		setLoading(false);
		if (keyword === "") {
			setPageNumber(1);
			axios
				.post(`${process.env.REACT_APP_API_URL}/boardList`, {
					status: 1,
					num: 1,
					page: 1,
				})
				.then((data) => {
					setResData(data.data.data2);
					setTotalPage(data.data.count);
					// console.log(data)
					setTableData(
						data.data.data2.slice(
							(pageNumber - 1) * 20,
							20 * pageNumber
						)
					);
				})
				.then(() => setLoading(true));
		} else {
			setPageNumber(1);
			axios
				.post(`${process.env.REACT_APP_API_URL}/boardsearch`, {
					num: 1,
					select,
					keyword,
					page: 1,
				})
				.then((data) => {
					setResData(data.data.searchDiary);
					setTotalPage(data.data.count);
					// console.log(data)
					setTableData(data.data.searchDiary);
				})
				.then(() => setLoading(true));
		}
	}

	return (
		<>
			<Banner title="notice"></Banner>
			<NoticeWrapper>
				<NoticeSearch>
					<SearchSelectWrapper>
						<select
							name="serach"
							onChange={(e) => {
								setSelect(e.target.value);
								searchEvent(e.target.value, keyword);
							}}
						>
							<option value="제목">제목</option>
							<option value="내용">내용</option>
							<option value="제목+내용">제목+내용</option>
						</select>
					</SearchSelectWrapper>
					<SearchInputWrapper>
						<SearchInput
							type="text"
							placeholder="검색"
							onChange={(e) => {
								setKeyword(e.target.value);
							}}
							onKeyPress={(e) => {
								e.key === "Enter"
									? searchEvent(select, keyword)
									: null;
							}}
						/>
						<img
							src={searchIcon}
							alt="serach_icon"
							onClick={(e) => {
								searchEvent(select, keyword);
							}}
						/>
					</SearchInputWrapper>
				</NoticeSearch>
				<NoticeMobileSearch>
					<SearchInputWrapper>
						<SearchInput
							type="text"
							placeholder="제목+내용"
							onChange={(e) => {
								setKeyword(e.target.value);
							}}
							onKeyPress={(e) => {
								e.key === "Enter"
									? searchEvent(select, keyword)
									: null;
							}}
						/>
						<img
							src={searchIcon}
							alt="serach_icon"
							onClick={(e) => {
								searchEvent("제목+내용", keyword);
							}}
						/>
					</SearchInputWrapper>
				</NoticeMobileSearch>
				{loading ? (
					<Table
						clickEvent={ClickEvent}
						changePageEvent={changePageEvent}
						length={totalPage}
						nowPage={pageNumber}
						slide={20}
						data={tableData}
						columnInfo={{
							board_num: {
								name: "No.",
								width: "10%",
								position: "center",
							},
							title: {
								name: "제 목",
								width: "52%",
								position: "flex-start",
							},
							writer: {
								name: "작성자",
								width: "12%",
								position: "center",
							},
							c_date: {
								name: "작성일",
								width: "12%",
								position: "center",
							},
							file_link: {
								name: "첨부파일",
								width: "12%",
								position: "center",
							},
							count: {
								name: "조회수",
								width: "12%",
								position: "center",
							},
						}}
						mobileData={["title", "c_date", "count"]}
					></Table>
				) : (
					<Skeleton />
				)}
			</NoticeWrapper>
		</>
	);
}

export default Notice;
