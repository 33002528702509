import { css } from "styled-components";

function makeViewport(condition) {
    return (style) => css`
    @media (${condition}) {
      ${style}
    }
  `;
}

export const mobile = makeViewport("max-width: 780px");
export const mobileNav = makeViewport("max-width: 1020px");

export const tablet = makeViewport("max-width: 1300px");
