import React, { useMemo } from 'react';

const CreateTableData = () => {
  const columns = useMemo(
    () => [
      {
        Header: '번호',
        accessor: 'id',
        Footer: 'Middle age:',
        disableGlobalFilter: true,
        width: 90,
      },
      {
        Header: '상태',
        accessor: 'status',
      },
      {
        Header: '성함',
        accessor: 'name',
        disableGlobalFilter: true,
      },
      {
        Header: '아이디',
        accessor: 'userId',
        disableGlobalFilter: true,
      },
      {
        Header: '면허번호',
        accessor: 'licenseNum',
        disableGlobalFilter: true,
      },
      {
        Header: '전화번호',
        accessor: 'phoneNum',
        disableGlobalFilter: true,
      },
      {
        Header: '가입일',
        accessor: 'regDate',
        disableGlobalFilter: true,
      },
      {
        Header: '마지막 접속',
        accessor: 'lastCon',
        disableGlobalFilter: true,
      },
      {
        Header: '메모',
        accessor: 'memo',
        disableGlobalFilter: true,
      },
      {
        Header: '상세',
        accessor: 'detail',
        disableGlobalFilter: true,
      },
    ],
    [],
  );
  const data = [];
  const rows = () => {
    for (let i = 1; i < 1000; i++) {
      data.push({
        id: i,
        status: ['정회원', '승인대기  ', '준회원', '탈퇴'][Math.floor((Math.random() * 4))],
        name: `홍길동${ i}`,
        userId: ['@dragon', '@hamster', '@cat'][Math.floor((Math.random() * 3))],
              replyContent: '??????????????????',

        phoneNum: `010-1234-${parseInt(Math.random() * 1000 + 1000)}`,
        regDate: '2000-01-01',
        lastCon: '2000-01-01',
        memo: '',
        detail: '',
      });
    }
  };

  rows();
  const reactTableData = { tableHeaderData: columns, tableRowsData: data };
  return reactTableData;
};

export default CreateTableData;
