import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Field, Form } from 'react-final-form'
import { Card, CardBody, Col, Button, ButtonToolbar } from 'reactstrap'
import TextField from '@material-ui/core/TextField'
import SummerNote from './SummerNote.jsx'
import renderFileInputField from '@/shared/components/form/FileInput'
import imageThumbnail from './FileInputImage'
import VideoTapsBorderd from './VideoTapsBorderd'
import axios from 'axios'

const renderTextField = ({ input, label, meta: { touched, error }, children, select, multiline, disabled, setTitle }) => (
  <TextField
    className='material-form__field'
    label={label}
    type={input.type}
    error={touched && error}
    value={input.value}
    select={select}
    multiline={multiline}
    onChange={(e) => {
      e.preventDefault()
      input.onChange(e.target.value)
      setTitle(e.target.value)
    }}
    disabled={disabled}
  >
    {children}
  </TextField>
)

const VideoWrite = ({ setShowWrite, setVideoList, setOnOff,setLoading }) => {
  const [upload, setUpload] = useState('')
  const [contents, setContents] = useState('')
  const [title, setTitle] = useState('')
  const [radioVal, setRadioVal] = useState('')
  const [imageSrc, setImageSrc] = useState('')
  const [folderSta, setFolderSta] = useState('')
  // console.log(radioVal)
  // const [, setContents] = useState('')
  const onSubmit = (values) => {
    values.thumbnail = imageSrc
    values.folder_name = radioVal
    values.status = 1
    values.folder_status = folderSta === '1' ? 1 : 0

    if (values.file) {
      let formData = new FormData()
      axios
        .post(`${process.env.REACT_APP_API_URL}/createVideo`, values)
        // .then((data) => {
        //   return data
        // })
        .then((data2) => {
          formData.append('file', values.file.file)
          axios.post(`${process.env.REACT_APP_API_URL}/fileUpload?num=2&no=${data2.data.id_video}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
        })
        .catch((err) => console.log(err))
    } else {
      axios
        .post(`${process.env.REACT_APP_API_URL}/createVideo`, values)
        // .then((data) => console.log(data))
        .catch((err) => console.log(err))
    }

    axios
      .post(`${process.env.REACT_APP_API_URL}/videoList`, {
        page: 0,
        folder: '관리자',
      })
      .then((data) => {
        setVideoList(data.data.data2)
      })
      // .then((data) => console.log(data.data))
      .catch((err) => console.log(err))

    setShowWrite((show) => !show)
  }
  if (upload) {
    const encodeFileToBase64 = (fileBlob) => {
      const reader = new FileReader()
      reader.readAsDataURL(fileBlob)
      return new Promise((resolve) => {
        reader.onload = () => {
          setImageSrc(reader.result)
          resolve()
        }
      })
    }
    encodeFileToBase64(upload)
  }
  return (
    <Fragment>
      <div className='card__title'>
        <h1>갤러리 쓰기</h1>
      </div>
      <hr />
      <br />
      <br />
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, form }) => (
          <form className='material-form' onSubmit={handleSubmit}>
            <div className='row'>
              <div className='col-3'>
                <div style={{ border: 'solid 1px', height: '230px', width: '230px' }}>
                  <img
                    src={upload ? URL.createObjectURL(upload) : null}
                    alt={upload ? upload.name : null}
                    style={{ height: '230px', width: '230px' }}
                  />
                </div>
                <br />
                <div className='form__form-group'>
                  <span className='form__form-group-label'>이미지 업로드</span>
                  <div className='form__form-group-field'>
                    <Field name='thumbnail' component={imageThumbnail} setUpload={setUpload} />
                  </div>
                </div>
              </div>
              <div className='col-9'>
                <VideoTapsBorderd setRadioVal={setRadioVal} radioVal={radioVal} setFolderSta={setFolderSta} />
              </div>
            </div>
            <br />
            <div className='row'>
              <div className='col-12'>
                <Field
                  name='title'
                  component={renderTextField}
                  label='제목'
                  setTitle={setTitle}
                  // disabled
                />
              </div>
            </div>
            <Field
              name='contents'
              someArbitraryOtherProp={contents}
              render={(prop) => {
                prop.input.onChange(prop.someArbitraryOtherProp)
                return <input {...prop.input} hidden />
              }}
            />
            <div className='col-12'>
              <SummerNote setContents={setContents}></SummerNote>
            </div>
            <br />
            <div className='form__form-group'>
              <span className='form__form-group-label'>첨부파일</span>
              <div className='form__form-group-field'>
                <Field name='file' component={renderFileInputField} />
              </div>
            </div>
            <ButtonToolbar className='form__button-toolbar'>
              <Button
                color='primary'
                type='button'
                onClick={() => {
                  if (!radioVal) {
                    window.alert('폴더를 체크해 주세요')
                  } else {
                    if (window.confirm('등록하시겠습니까?')) {
                      setLoading(false)
                      form.submit()
                      window.alert('등록되었습니다.')
                      setOnOff(pre => !pre)
                    }
                  }
                }}
              >
                확인
              </Button>
              <Button
                type='button'
                color='danger'
                onClick={() => {
                  if (!title && !contents) {
                    setShowWrite((e) => !e)
                  } else {
                    if (window.confirm('입력하신 내용이 저장되지 않습니다. 취소하시겠습니까?')) {
                      setShowWrite((e) => !e)
                    }
                  }
                }}
              >
                취소
              </Button>
            </ButtonToolbar>
            <hr />
          </form>
        )}
      </Form>
    </Fragment>
  )
}

export default VideoWrite
