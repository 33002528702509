const BasicTableData = () => {
  // const header = [
  //   { id: 1, title: '#' },
  //   { id: 2, title: 'First Name' },
  //   { id: 3, title: 'Last Name' },
  //   { id: 4, title: 'Username' },
  //   { id: 5, title: 'Status' },
  // ];

  const headerResponsive = [
    { id: 1, title: '번호' },
    { id: 2, title: '제목' },
    { id: 3, title: '작성일' },
    { id: 4, title: '조회수' },
    { id: 5, title: '신청내역' },
  ];
 
  const rows = [];
  for (let i = 0; i < 10; i++) {
    rows.push({
        id: i+1,
        title:"제 "+(i+1) +"회 누가통증연구회 학술대회",
        regDate: "2021-01-01",
        views: i+1,
    });
  }

  const basicTableData = { tableHeaderResponsiveData: headerResponsive, tableRowsData: rows };
  return basicTableData;
};

export default BasicTableData;
