import React, {useState, useEffect} from 'react';
import { Route, Switch } from 'react-router-dom';
import MainWrapper from '../MainWrapper';
import Service from '../../Service/Route';
// import NotFound404 from '../../DefaultPage/404/index';
// import LockScreen from '../../Account/LockScreen/index';
import LogIn from '../../Account/LogIn/index';
// import LogInPhoto from '../../Account/LogInPhoto/index';
// import Register from '../../Account/Register/index';
// import RegisterPhoto from '../../Account/RegisterPhoto/index';
// import ResetPassword from '../../Account/ResetPassword/index';
// import ResetPasswordPhoto from '../../Account/ResetPasswordPhoto';
import WrappedRoutes from './WrappedRoutes';
import {useSelector, useDispatch} from 'react-redux'
import axios from "axios"
import {getAdmin} from "../../../redux/actions/adminActions"

axios.defaults.withCredentials = true;

const Router = () =>{ 
  //todo : 관리자만 관리자 페이지에 들어올 수 있도록 
  const dispatch = useDispatch()
  const isAdmin = useSelector(state => state.admin)
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/admincookie`)
      .then(() => {
        dispatch(getAdmin())
      })
      .catch(err => console.log(err))
  }, [])

return (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path="/" component={Service} />
        <Route path="/app" component={Service} />
        {/* <Route path="/404" component={NotFound404} />
        <Route path="/lock_screen" component={LockScreen} /> */}
        <Route path="/adminlogin" component={LogIn} />
        {/* <Route path="/log_in_photo" component={LogInPhoto} />
        <Route path="/register" component={Register} />
        <Route path="/register_photo" component={RegisterPhoto} />
        <Route path="/reset_password" component={ResetPassword} />
        <Route path="/reset_password_photo" component={ResetPasswordPhoto} /> */}
     
        {/* {<Route path="/admin" component={WrappedRoutes} /> } */}
        {isAdmin.admin && <Route path="/admin" component={WrappedRoutes} /> }
      </Switch>
    </main>
  </MainWrapper>
);
      }
export default Router;
