import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Notice from './Notice';
import NewsLetter from './NewsLetter'
import DetailNewsLetter from './DetailNewsLetter'
import Schedule from "./Schedule"
import DetailNotice from "./DetailNotice"
import Introduce from "./Schedule/Introduce"
import Registration from "./Schedule/Registration"
import Confirm from "./Schedule/Confirm"

export default () => (
  <Switch>
    <Route exact path="/app/notification/notice" component={Notice} />
    <Route exact path="/app/notification/notice/:number/:type/:length" component={DetailNotice} />
    <Route exact path="/app/notification/newsletter/:number/:type/:length" component={DetailNewsLetter} />
    <Route exact path="/app/notification/newsletter" component={NewsLetter} />
    <Route exact path="/app/notification/schedule" component={Schedule} />
    <Route exact path="/app/notification/schedule/:number/introduce" component={Introduce} />
    <Route exact path="/app/notification/schedule/:number/registration" component={Registration} />
    <Route exact path="/app/notification/schedule/:number/confirm" component={Confirm} />
  </Switch>
);
