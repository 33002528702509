/* eslint-disable */ 
import React, { useEffect, useState, useRef } from 'react';
import {
  Nav, NavItem, NavLink, TabContent, TabPane,Table,
} from 'reactstrap';
import classnames from 'classnames';
import styled from "styled-components"
import DatePicker from 'react-datepicker'

const Prebox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`



export const Sdate = ({handleDate, preStart}) => {
    const [startDate, setStartDate] = useState(preStart ? new Date(preStart) : null);
    return (
      <DatePicker 
        selected={startDate} 
        onChange={(date) => {
          setStartDate(date)
          // setPreStart(date)
          handleDate(date, "start")
        }} 
        placeholderText="시작일"
        dateFormat="yyyy-MM-dd"
        // minDate={new Date()}
      />
    );
}


export const Edate = ({handleDate, preStart, preEnd, modify, setModify}) => {
  const [startDate, setStartDate] = useState(modify && preEnd ? new Date(preEnd) : null);

  useEffect(() => {
    if (modify) {
      setStartDate(preEnd ? new Date(preEnd) : null)
      setModify(false)
    } else {
      setStartDate(preStart ? new Date(preStart) : null) 
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preStart])

  return (
    <DatePicker 
      selected={startDate} 
      onChange={(date) => {
        if (new Date(preStart).getTime() > new Date(date).getTime()){
            setStartDate(preStart)
            window.alert("일정 시작 날짜보다 이전 날짜를 선택할 수 없습니다.")
        } else {
          setStartDate(date)
          // setPreEnd(date)
          handleDate(date, "end")
        }
      }} 
      placeholderText="마감일"
      dateFormat="yyyy-MM-dd" 
      // minDate={new Date()} 
    />
  );
}

const Del = styled.span`
 cursor: pointer;
`

const Tabs = ({preRegi, setAmount, amount, setPreStart, setPreEnd, preStart, preEnd, modify, setModify}) => {
  const [memberDivid, setMemberDivid] = useState({member_type: "", money: ""})
  const [activeTab, setActiveTab] = useState('1');
  const [memberTypeValue, setMemberTypeValue] = useState("")
  const [moneyValue, setMoneyValue] = useState("")
  const [isClick, setIsClick] = useState(false)
  const [isClick2, setIsClick2] = useState(false)
  


  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const handleClick = (idx) => {
    let dummy = amount.slice()
    dummy.splice(idx, 1)
    setAmount(dummy)
  }

  const handleChange = (e, type) => {
    if (type === "member_type") {
      setMemberTypeValue(e.target.value)
      setMemberDivid({...memberDivid, [type]: e.target.value})
    } else {
      setMoneyValue(e.target.value)
      setMemberDivid({...memberDivid, [type]: e.target.value})
    }
  }

  const handleAdd = () => {
    setAmount(pre => [...pre, memberDivid]) 
    setMemberTypeValue("")
    setMoneyValue("")
  }

  const handleDate = (date, type) => {
    if (type === "start") {
      setPreStart(date)
      setPreEnd(date)
    } else setPreEnd(date)
  }

  const handleAmount = (e, i, type) => {
    if (type === "member") {
      let dummy = amount.slice()
      dummy[i].member_type = e.target.value
      setAmount(dummy)
    } else {
      let dummy = amount.slice()
      dummy[i].money = e.target.value
      setAmount(dummy)
    }
  }

  // const handlePreDate = (date, type) => {
  //   if (type === "start") {
  //     setPreStart(date)
  //     setPreEnd(date)
  //   } else setPreEnd(date)
  // }



  return (
    <>
      <div className="tabs__wrap">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '1' })}
              onClick={() => toggle('1')}
              style={{backgroundColor: preRegi === "X" ? "gray": null, cursor: preRegi === "X" ? "default" : "pointer", pointerEvents:  preRegi === "X" ? "none" : null}}
            >
              회원구분
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '2' })}
              onClick={() => toggle('2')}
              style={{backgroundColor: preRegi === "X" ? "gray": null, cursor: preRegi === "X" ? "default" : "pointer", pointerEvents:  preRegi === "X" ? "none" : null}}
            >
              사전등록 기간
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Table responsive className="table--bordered" style={{display: preRegi === "X" ? "none" : "block"}}>
              <thead>
                <tr>
                    <th>구분</th>
                    <th>금액</th>
                    <th>&nbsp;</th>
                    <th>삭제</th>
                </tr>
              </thead>
              <tbody>
                {amount.map((el, idx) => (
                      <tr key={el}>
                      <td onClick={() => setIsClick(true)}>
                        <input type="text" defaultValue={el.member_type} disabled={isClick ? null : "disabled"} onChange={(e) => handleAmount(e, idx, "member")} onBlur={() => setIsClick(false)}/>
                      </td>
                      <td onClick={() => setIsClick2(true)}>
                      <input type="text" defaultValue={el.money} disabled={isClick2 ? null : "disabled"} onChange={(e) => handleAmount(e, idx, "money")} onBlur={() => setIsClick2(false)}/>
                      </td>
                      <td></td>
                      <td><Del onClick={() => handleClick(idx)}>X</Del></td>
                    </tr>
                ))}
                  {/* <tr>
                    <td>정회원</td>
                    <td>100,000</td>
                    <td></td>
                    <td><Del onClick={handleClick}>X</Del></td>
                  </tr>
                  <tr>
                    <td>준회원</td>
                    <td>120,000</td>
                    <td></td>
                    <td>X</td>
                  </tr>
                  <tr>
                    <td>비회원</td>
                    <td>150,000</td>
                    <td></td>
                    <td>X</td>
                  </tr> */}
                  <tr>
                    <td><input type="text" value={memberTypeValue} onChange={(e)=>handleChange(e, "member_type")}/></td>
                    <td><input type="text" value={moneyValue} onChange={(e)=>handleChange(e, "money")} /></td>
                    <td><button type="button" onClick={handleAdd}>추가</button></td>
                    <td>X</td>
                  </tr>
              </tbody>
            </Table>
          </TabPane>
          <TabPane tabId="2">
            <Prebox>
                <Sdate handleDate={handleDate} preStart={preStart} />
                <Edate handleDate={handleDate} preStart={preStart} preEnd={preEnd} modify={modify} setModify={setModify}/>
            </Prebox>
             {/* <div className='row'>
              <div className='col-6'>
                <span className='form__form-group-label'>시작일</span>
                <div className='form__form-group-field'>
                  <Sdate start={props.oneData.startdate} />
                  <div className='form__form-group-icon'>
                    <CalendarBlankIcon />
                  </div>
                </div>
                <span className='form__form-group-label'>종료일</span>
                <div className='form__form-group-field'>
                  <Edate end={props.oneData.startdate} />
                  <div className='form__form-group-icon'>
                    <CalendarBlankIcon />
                </div>
              </div>
              </div>
            </div> */}
               
          </TabPane>
        </TabContent>
      </div>
    </>
  );
};

export default Tabs;
