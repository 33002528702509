import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import {getList} from "../../store/actions/searchList"
 

/* eslint-disable no-alert */
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export default (async function MemberSearch(values) {
  await sleep(500); // simulate server latency
  // const dispatch = useDispatch();
  // console.log(values)
  axios.post(`${process.env.REACT_APP_API_URL}/search`, {
    data: values
  })
    // .then(data => console.log("object"))
  .catch(() => window.alert(`입력된 값이 없습니다.`))
});
