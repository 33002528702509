import React from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';

const SidebarContent = ({
  onClick, sidebarCollapse,
}) => (
  <div className="sidebar__content">
    <ul className="sidebar__block">
      <SidebarLink
        title="회원 목록"
        icon="home"
        route="/admin/members"
        onClick={onClick}
      />
      <SidebarCategory title="게시물 관리" icon="diamond" sidebarCollapse={sidebarCollapse}>
        <SidebarLink title="게시판 관리" route="/admin/managements/articles" onClick={onClick} />
        <SidebarLink title="일정관리" route="/admin/managements/schedules" onClick={onClick} />
        <SidebarLink title="동영상관리" route="/admin/managements/videos" onClick={onClick} />
        <SidebarLink title="학술상관리" route="/admin/managements/academics" onClick={onClick} />
      </SidebarCategory>
      <SidebarLink title="회비 납부 및 관리" icon="envelope" route="/admin/payments" onClick={onClick} />
      <SidebarLink title="댓글 목록" icon="bubble" route="/admin/replies" onClick={onClick} />
      <SidebarLink title="일정 사전등록 및 관리" newLink icon="book" route="/admin/events" onClick={onClick} />
      <SidebarLink title="이미지 관리" route="/admin/image" onClick={onClick} />
    </ul>
  </div>
);

SidebarContent.propTypes = {
  // changeToDark: PropTypes.func.isRequired,
  // changeToLight: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  sidebarCollapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  sidebarCollapse: false,
  onClick: () => { },
};

export default SidebarContent;
