import React, {useEffect, useState} from "react"
import styled, {css} from "styled-components"
import { useHistory, useParams } from "react-router-dom";
import moment from "moment"
import axios from "axios"

import Banner from "../components/Banner"

import {mobile} from "../../../lib/viewport"
import NoticeBoard from "../../../components/NoticeBoard"

const Navigation = styled.div`
    width: 500px;
    height: 40px;

    display:flex;
    justify-content:center;

    margin: 50px auto 0px auto;

    ${mobile(css`
        width: calc(100% - 20px);
    `)}
`
const NavItem = styled.div`
    width: 33%;

    display:flex;
    justify-content:center;
    align-items:center;

    font-weight: 500;

    cursor:pointer;

    ${({ selected }) => {
      return selected ? `border-left: 1px solid black; border-top: 1px solid black; border-right: 1px solid black; background-color: #f2f3f5;` : 'border-bottom: 1px solid black; background-color: #e3e5e8;';
    }};
`

function Introduce({ match }) {
    const [boardInfo, setBoardInfo] = useState({})
    const params = useParams()

    useEffect(()=>{
        axios.get(`${process.env.REACT_APP_API_URL}/pick-research?id=${params.number}`).then((response)=>{
            setBoardInfo(response.data.data)
            // console.log("ㅁㅇㅁㅇ",response.data.data)
            let viewCount = response.data.data.view_count === null ? 1 : Number(response.data.data.view_count) + 1;
            // console.log("count,", viewCount)
            axios.get(`${process.env.REACT_APP_API_URL}/eventsCountUpdate?count=${viewCount}&num=${params.number}`).then(data2 => {
                // console.log(data2)
            }).catch((err) => console.log(err))
        })
    }, [])

    const history = useHistory();
    const { number } = match.params;
    return (
        <>
            <Banner title="schedule"></Banner>
            <Navigation>
                <NavItem selected={true} >
                    <span>안내</span>
                </NavItem>
                {
                    boardInfo.will_receive_pre_reg === "O" ? <><NavItem selected={false} onClick={() => { history.push(`/app/notification/schedule/${number}/registration`) }}>
                        <span>사전등록</span>
                    </NavItem>
                    <NavItem selected={false} onClick={() => { history.push(`/app/notification/schedule/${number}/confirm`) }}>
                        <span>등록 및 접수확인</span>
                    </NavItem> </> :<></>
                }
               
            </Navigation>
            <NoticeBoard data={boardInfo} pagination={false} comment={false} listButtonClick={() => { history.push("/app/notification/schedule")}} />

        </>
    )
}

export default Introduce
