import React from 'react';
import styled, { css } from 'styled-components'
import { mobile } from "../../../lib/viewport";

const ScheduleListNoneWrapper = styled.div`
    width: 265px;
    height: 75px;

    border-bottom: 1px solid #e0e1e5;

    display:flex;
    justify-content:space-between;
    align-items:center;

    & > p{
        margin: 0px auto;
        font-size: 13px;
    }

    ${mobile(css`
        width: 100%;
    `)}
`



function ScheduleListNone() {
    return (
        <ScheduleListNoneWrapper>
            <p>
                이후 일정이 없습니다.
           </p>
        </ScheduleListNoneWrapper>
    )
}


export default ScheduleListNone;
