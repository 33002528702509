import React, { useState } from 'react';
import {
  Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import ResponsiveTable from './ResponsiveTable';


const Tabs = () => {
  const [activeTab, setActiveTab] = useState('1');
  const [category, setCategory] = useState(1)

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className="tabs__wrap">
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => {
              toggle('1')
              setCategory(1)
            }}
          >
            의사회일정
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => {
              toggle('2')
              setCategory(2)
            }}
          >
            학술행사일정
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <ResponsiveTable read={true} category={category}/>
        </TabPane>
        <TabPane tabId="2">
          <ResponsiveTable read={true} category={category}/>
        </TabPane>
      </TabContent>
    </div>
  );
};

export default Tabs;
