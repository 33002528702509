import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { tablet, mobile, mobileNav } from '../lib/viewport';
import Logo from '../../../shared/img/top-logo@2x.png'
import { useHistory } from "react-router-dom"

import Menu from "../../../shared/img/m-menu-bt.png"

import close from '../../../shared/img/m-menu-close@2x.png'

import "./Header.scss"
// import Cookies from 'universal-cookie';

import axios from "axios"

const HeaderContainer = styled.div`
	width:100%;
	padding: 0px 4em;

	background-color: #ffffff;

	display: flex;

	box-shadow: 1px 8px 22px 2px rgba(0, 0, 0, 0.1);

	position: sticky;
	top:0px;
	left:0px;
	right:0px;

	z-index: 100;

	& > div:nth-child(1) {
		max-width: 1350px;
		width: 1350px;
		height: 5.5em;

		margin: 0 auto;

		display: flex;
		justify-content: space-between;
		align-items: center;
		${mobileNav(css`
			height: 4em;
		`)}
	}
	${mobileNav(css`
		padding: 0em 1.4em;
	`)}
`;



const Line = styled.div`
	background-color: #b1b3ba;
	width: 1.4px;

	margin: 2px 0.5em;
`;

const AuthWrapper = styled.div`
	width:110px;
	display: flex;
	${mobileNav(css`
		display:none;
	`)}
`;
const AuthTokenWrapper = styled.div`
	width:110px;
	display: flex;
	justify-content: flex-end;
	${mobileNav(css`
		display:none;
	`)}
`;

const AuthActionButton = styled.div`
	cursor: pointer;
	color: #b1b3ba;
`;

const Nav = styled.nav`
	display: flex;
	height: 100%;
	&:hover{
		& > .navigation_wrapper {
			opacity: 1;
			visibility:visible;
		}
	}
	${mobileNav(css`
		display:none;
	`)}
`;

const NavList = styled.div`
	width: 140px;
	height: 100%;

	${tablet(css`
        width: 120px;
    `)}

	margin: 0 auto;

	font-size: 17px;
	font-weight: 500;

	display: flex;
	justify-content:center;
	align-items:center;


	position: relative;

	
	&:hover{
		&::after{
			width : 58px;
			height: 1.5px;
			background-color: #36aa3a;
			content: " ";

			position: absolute;
			bottom: 16px;
			left:0px;
			right:0px;

			margin: 0 auto;
		}
		
	}	

	
`

const LogoImage = styled.img`
	width: 213px;
	height: 46px;

	cursor:pointer;
	object-fit: cover;

	${mobileNav(css`
		width: 150px;
		height: 28px;
	`)}

`
const NavigationWrapper = styled.div`
	width: 100%;
	height: 248px;
	background-color:#4fb853;

	position:absolute;
	top:71.5px;
	left:0px;
	right:0px;

	margin: 0 auto;

	opacity: 0;
	visibility: hidden;
	display:flex;

	justify-content:space-between;
	transition: opacity 0.3s ease 0s;


`

const Navigation = styled.div`
	width:700px;
	height:100%;

	display:flex;

	${tablet(css`
        width: 600px;
    `)}
`
const Width = styled.div`
	width: ${props=> props.width};
`
const NavigationList = styled.div`
	width:140px;
	height:100%;

	padding: 16px 20px;

	${tablet(css`
        width: 120px;
	`)}
	${({ selected }) => {
      return selected ? `background-color: #36aa3a;` : '';
	}}
	&:hover{
		background-color: #36aa3a;
	}
`
const NavigationListItem = styled.div`
	width:100%;
	height: 36px;
	display:flex;
	justify-content:center;
	align-items:center;
	color: #9bec9e;
	cursor:pointer;

	&:hover{
		color: white;
	}
`

const MobileNav = styled.img`
	width:22px;
	height:20px;
	display:none;
	${mobileNav(css`
		display: block;
	`)}

	cursor:pointer;
`
const MobileNavWrapper = styled.div`
	position:absolute;
	top:0px; left:0px; right:0px; bottom:0px;
	width:100%;
	height:100vh;


	background-color:#4fb853;

	${({ state }) => {
      return state ? `display:block;` : 'display:none;';
	}}
`

const MobileNavHeader = styled.div`
	width:100%;
	height: 50px;
	padding: 0px 12px;
	display:flex;
	justify-content:space-between;
	align-items:center;

	& > div:nth-child(1){
		display:flex;
		height:100%;
	}
`
const MobileNavBackImgWrapper = styled.div`
width: 30px;
height: 30px;
  	border-radius: 4px;
	  background-color: #429e45;
	  
	  display:flex;
	  justify-content:center;
	  align-items:center;
`
const MobileNavHeaderButton = styled.div`
	height:100%;
	padding: 0px 12px;

	color: white;

	display:flex;
	justify-content:center;
	align-items:center;

	cursor:pointer;
`

const MobileNavListWrapper = styled.div`
	display:flex;
	height:100%;
`

const MobileNavListItemWrapper = styled.div`
	width: 30%;
`
const MobileNavListItem = styled.div`
	height: 42px;
	display:flex;
	justify-content:center;
	align-items:center;
	color: white;

	cursor:pointer;

	${({ selected }) => {
      return selected ? `background-color: white; font-weight: bold; color: #378c3a` : '';
	}}

`
const MobileNavDetailListWrapper = styled.div`
	width: 70%;
	height:100%;
	background-color: white;
	${({ selected }) => {
      return selected ? `display:block;` : 'display:none;';
	}}
`

const MobileNavDetailListItem = styled.div`
	width:100%;
	height: 42px;
	border-bottom: 0.7px solid #e0e1e5;
	padding: 0px 24px;
	
	display:flex;
	align-items:center;

	font-size: 14px;

	cursor:pointer;
`

function Header() {
	const history = useHistory()
	const [mobileNavState, setMobileNavState] = useState(false)
	const [mobileNavClickState, setMobileNavClickState] = useState("연구소 소개");

	function ClickMobileNavigation(url){
		history.push(url)
		setMobileNavState(false)
	}
	function logout() { 
		let c = window.confirm("로그아웃을 하시겠습니까?"); 
		if ( c ) { 
			axios.get(`${process.env.REACT_APP_API_URL}/deladmincookie`, (data=>{
				return null
			}))
			localStorage.removeItem("accessToken"); 
			window.location.href = ""
		}
	}
	return (
		<HeaderContainer>
	   	    <div>
				<LogoImage src={Logo} alt="logo" onClick={()=> history.push("/")} />
				<Nav>
					<NavList className={history.location.pathname.substr(0,14) === "/app/introduce"? "selected nav1":"nav1" }>연구회 소개</NavList>
					<NavList className={history.location.pathname.substr(0,17) === "/app/notification"? "selected nav2":"nav2" }>연구회 공지</NavList>
					<NavList className={history.location.pathname.substr(0,13) === "/app/academic"? "selected nav3":"nav3" }>학술한마당</NavList>
					<NavList className={history.location.pathname.substr(0,14) === "/app/community"? "selected nav4":"nav4" }>연구회광장</NavList>
					<NavList className={history.location.pathname.substr(0,11) === "/app/mypage"? "selected nav5":"nav5" }>마이페이지</NavList>
					<NavigationWrapper className="navigation_wrapper">
						<Width width="186px"></Width>
						<Navigation className="navigation">
							<NavigationList selected={false}>
								<NavigationListItem onClick={() => history.push("/app/introduce/greeting")}>인사말씀</NavigationListItem>
								<NavigationListItem onClick={() => history.push("/app/introduce/regulations")}>연구회 회칙</NavigationListItem>
								<NavigationListItem onClick={() => history.push("/app/introduce/rule")}>연구회 규정</NavigationListItem>
								<NavigationListItem onClick={() => history.push("/app/introduce/member")}>임원 및 조직도</NavigationListItem>
								<NavigationListItem onClick={() => history.push("/app/introduce/guide")}>회원가입 안내</NavigationListItem>
								<NavigationListItem onClick={() => history.push("/app/introduce/location")}>오시는길</NavigationListItem>
							</NavigationList>
							<NavigationList selected={false}>
								<NavigationListItem onClick={() => history.push("/app/notification/notice")}>공지사항</NavigationListItem>
								<NavigationListItem onClick={() => history.push("/app/notification/newsletter")}>소식지</NavigationListItem>
								<NavigationListItem onClick={() => history.push("/app/notification/schedule")}>연구회 일정</NavigationListItem>
							</NavigationList>
							<NavigationList selected={false}>
								<NavigationListItem onClick={() => history.push("/app/academic/journal")}>학술지</NavigationListItem>
								<NavigationListItem onClick={() => history.push("/app/academic/award")}>학술상 현황</NavigationListItem>
								<NavigationListItem onClick={() => history.push("/app/academic/calendar")}>국내외일정</NavigationListItem>
							</NavigationList>
							<NavigationList selected={false}>
								<NavigationListItem onClick={() => history.push("/app/community/userboard")}>회원 게시판</NavigationListItem>
								<NavigationListItem onClick={() => history.push("/app/community/qna")}>질의응답</NavigationListItem>
								<NavigationListItem onClick={() => history.push("/app/community/data")}>보도자료</NavigationListItem>
								<NavigationListItem onClick={() => history.push("/app/community/video")}>동영상 강의</NavigationListItem>
							</NavigationList>
							<NavigationList selected={false}>
								<NavigationListItem onClick={() => history.push("/app/mypage/edit")}>내 정보 수정</NavigationListItem>
								<NavigationListItem onClick={() => history.push("/app/mypage/pwchange")}>비밀번호 수정</NavigationListItem>
								<NavigationListItem onClick={() => history.push("/app/mypage/dues")}>회비 납부</NavigationListItem>
								<NavigationListItem onClick={() => history.push("/app/mypage/duesHistory")}>결제 내역</NavigationListItem>
							</NavigationList>
						</Navigation>
						<Width width="83.5px"></Width>
					</NavigationWrapper>
				</Nav>
				{
					localStorage.getItem("accessToken") == null ? <AuthWrapper>
						<AuthActionButton onClick={()=> history.push("/app/auth/signin")}>LOGIN</AuthActionButton>
						<Line />
						<AuthActionButton onClick={()=> history.push("/app/auth/signup")}>JOIN</AuthActionButton>
					</AuthWrapper> : <AuthTokenWrapper>
						<AuthActionButton onClick={()=> { logout() }}>LOGOUT</AuthActionButton>
						<Line />
						<AuthActionButton onClick={()=> history.push("/app/mypage/edit")}>MYPAGE</AuthActionButton>
					</AuthTokenWrapper>
				}
				<MobileNav src={Menu} alt="menu" onClick={() => setMobileNavState(true)} />
				<MobileNavWrapper state={mobileNavState}>
					<MobileNavHeader>
						<div>
							{
								localStorage.getItem("accessToken") == null ? <>
									<MobileNavHeaderButton onClick={() => ClickMobileNavigation("/app/auth/signin")}>로그인</MobileNavHeaderButton>
									<MobileNavHeaderButton onClick={() => ClickMobileNavigation("/app/auth/signup")}>회원가입</MobileNavHeaderButton> 
								</>: <>
									<MobileNavHeaderButton onClick={()=> { logout() }}>로그아웃</MobileNavHeaderButton>
									<MobileNavHeaderButton onClick={()=> ClickMobileNavigation("/app/mypage/edit")}>마이페이지</MobileNavHeaderButton> 
								</>
							}
						</div>
						<MobileNavBackImgWrapper onClick={() => setMobileNavState(false)}>
							<img src={close} alt="exit" />					
						</MobileNavBackImgWrapper>	
					</MobileNavHeader>
					<MobileNavListWrapper>
						<MobileNavListItemWrapper>
							<MobileNavListItem selected={mobileNavClickState === "연구소 소개"} onClick={() => {setMobileNavClickState("연구소 소개")}}>연구소 소개</MobileNavListItem>
							<MobileNavListItem selected={mobileNavClickState === "연구회 공지"} onClick={() => {setMobileNavClickState("연구회 공지")}}>연구회 공지</MobileNavListItem>
							<MobileNavListItem selected={mobileNavClickState === "학술한마당"} onClick={() => {setMobileNavClickState("학술한마당")}}>학술한마당</MobileNavListItem>
							<MobileNavListItem selected={mobileNavClickState === "연구회광장"} onClick={() => {setMobileNavClickState("연구회광장")}}>연구회광장</MobileNavListItem>
							<MobileNavListItem selected={mobileNavClickState === "마이페이지"} onClick={() => {setMobileNavClickState("마이페이지")}}>마이페이지</MobileNavListItem>
						</MobileNavListItemWrapper>
						<MobileNavDetailListWrapper selected={mobileNavClickState === "연구소 소개"}>
							<MobileNavDetailListItem onClick={() => {ClickMobileNavigation("/app/introduce/greeting")}}>인사말씀</MobileNavDetailListItem>
							<MobileNavDetailListItem onClick={() => {ClickMobileNavigation("/app/introduce/regulations")}}>연구직 회칙</MobileNavDetailListItem>
							<MobileNavDetailListItem onClick={() => {ClickMobileNavigation("/app/introduce/rule")}}>연구회 규정</MobileNavDetailListItem>
							<MobileNavDetailListItem onClick={() => {ClickMobileNavigation("/app/introduce/member")}}>임원 및 조직도</MobileNavDetailListItem>
							<MobileNavDetailListItem onClick={() => {ClickMobileNavigation("/app/introduce/guide")}}>회원가입 안내</MobileNavDetailListItem>
							<MobileNavDetailListItem onClick={() => {ClickMobileNavigation("/app/introduce/location")}}>오시는 길</MobileNavDetailListItem>
						</MobileNavDetailListWrapper>
						<MobileNavDetailListWrapper selected={mobileNavClickState === "연구회 공지"}>
							<MobileNavDetailListItem onClick={() => {ClickMobileNavigation("/app/notification/notice")}}>공지사항</MobileNavDetailListItem>
							<MobileNavDetailListItem onClick={() => {ClickMobileNavigation("/app/notification/newsletter")}}>소식지</MobileNavDetailListItem>
							<MobileNavDetailListItem onClick={() => {ClickMobileNavigation("/app/notification/schedule")}}>연구회 일정</MobileNavDetailListItem>
						</MobileNavDetailListWrapper>
						<MobileNavDetailListWrapper selected={mobileNavClickState === "학술한마당"}>
							<MobileNavDetailListItem onClick={() => {ClickMobileNavigation("/app/academic/journal")}}>학술지</MobileNavDetailListItem>
							<MobileNavDetailListItem onClick={() => {ClickMobileNavigation("/app/academic/award")}}>학술상 현황</MobileNavDetailListItem>
							<MobileNavDetailListItem onClick={() => {ClickMobileNavigation("/app/academic/calendar")}}>국내외일정</MobileNavDetailListItem>
						</MobileNavDetailListWrapper>
						<MobileNavDetailListWrapper selected={mobileNavClickState === "연구회광장"}>
							<MobileNavDetailListItem onClick={() => {ClickMobileNavigation("/app/community/userboard")}}>회원 게시판</MobileNavDetailListItem>
							<MobileNavDetailListItem onClick={() => {ClickMobileNavigation("/app/community/qna")}}>질의응답</MobileNavDetailListItem>
							<MobileNavDetailListItem onClick={() => {ClickMobileNavigation("/app/community/data")}}>보도자료</MobileNavDetailListItem>
							<MobileNavDetailListItem onClick={() => {ClickMobileNavigation("/app/community/video")}}>동영상 강의</MobileNavDetailListItem>
						</MobileNavDetailListWrapper>
						<MobileNavDetailListWrapper selected={mobileNavClickState === "마이페이지"}>
							<MobileNavDetailListItem onClick={() => {ClickMobileNavigation("/app/mypage/edit")}}>내 정보 수정</MobileNavDetailListItem>
							<MobileNavDetailListItem onClick={() => {ClickMobileNavigation("/app/mypage/pwchange")}}>비밀번호 수정</MobileNavDetailListItem>
							<MobileNavDetailListItem onClick={() => {ClickMobileNavigation("/app/mypage/dues")}}>회비 납부</MobileNavDetailListItem>
							<MobileNavDetailListItem onClick={() => {ClickMobileNavigation("/app/mypage/duesHistory")}}>결제 내역</MobileNavDetailListItem>
						</MobileNavDetailListWrapper>
					</MobileNavListWrapper>

				</MobileNavWrapper>
				
			</div>
		</HeaderContainer>
	);
}

export default Header;
