import React, { useEffect, useState } from 'react'
import { Nav, NavItem, NavLink, TabContent, TabPane, Table, Button } from 'reactstrap'
import classnames from 'classnames'
import TextField from '@material-ui/core/TextField'
import { Field, Form } from 'react-final-form'
import axios from 'axios'

const renderTextField = ({
  input,
  label,
  meta: { touched, error },
  children,
  select,
  multiline,
  disabled,
  setFolderName,
  inputHidden,
  inputHidden2,
  tapType,
}) => (
  <TextField
    className='material-form__field'
    label={label}
    type={input.type}
    error={touched && error}
    value={input.value}
    select={select}
    multiline={multiline}
    onChange={(e) => {
      e.preventDefault()
      input.onChange(e.target.value)
      setFolderName(e.target.value)
    }}
    disabled={disabled}
    hidden={tapType === 'normal' ? inputHidden : inputHidden2}
  >
    {children}
  </TextField>
)

const modifyInput = ({ input, label, meta: { touched, error }, children, select, multiline, disabled, setFolderName, defaultVal }) => (
  <TextField
    className='material-form__field'
    label={label}
    type={input.type}
    error={touched && error}
    value={input.value}
    select={select}
    multiline={multiline}
    onChange={(e) => {
      e.preventDefault()
      input.onChange(e.target.value)
      setFolderName(e.target.value)
    }}
    disabled={disabled}
  >
    {children}
  </TextField>
)

const Tabs = ({ onSubmit, setRadioVal, radioVal, setFolderSta }) => {
  const [activeTab, setActiveTab] = useState('1')
  const [radioButtonShow, setRadioButtonShow] = useState(false)
  const [folderName, setFolderName] = useState('')
  const [inputHidden, setInputHidden] = useState(false)
  const [inputHidden2, setInputHidden2] = useState(false)
  const [buttonHidden, setButtonHidden] = useState(false)
  const [buttonHidden2, setButtonHidden2] = useState(false)
  const [getFolders, setGetFolders] = useState([])
  const [getHideFolders, setGetHideFolders] = useState([])
  const [folderLoading, setFolderLoading] = useState(false)
  const [folderNameche, setFolderNameche] = useState('')
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab)
  }
  const folderDel = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/folderType?num=2&name=${radioVal}`)
      .then(() => setFolderLoading((e) => !e))
      .catch((err) => console.log(err))
  }
  const folderHide = (input) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/folderType?num=4&name=${radioVal}&status=${input}`)
      .then(() => setFolderLoading((e) => !e))
      .catch((err) => console.log(err))
  }
  const folderAdd = (input) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/folderType?num=0&name=${folderName}&status=${input}`)
      .then(() => setFolderLoading((e) => !e))
      .catch((err) => console.log(err))
  }
  const folderModify = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/folderType?num=1&modifyName=${folderName}&name=${radioVal}`)
      .then(() => setFolderLoading((e) => !e))
      .catch((err) => console.log(err))
  }
  const folderNameCheck = (index) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/folderType?num=7&name=${index}`)
      .then((data) => setFolderNameche(data.data))
      .catch((err) => console.log(err))
  }
  useEffect(() => {
    folderNameCheck(folderName)
    axios
      .get(`${process.env.REACT_APP_API_URL}/folderType?num=3`)
      .then((data) => {
        setGetFolders(data.data)
      })
      .catch((err) => console.log(err))
    axios
      .get(`${process.env.REACT_APP_API_URL}/folderType?num=5`)
      .then((data) => {
        setGetHideFolders(data.data)
        // console.log(data.data)
      })
      .catch((err) => console.log(err))
  }, [folderName, radioButtonShow, folderLoading])
  useEffect(() => {
    setFolderSta(activeTab)
  }, [activeTab])
  return (
    <div className='tabs__wrap'>
      <Nav tabs>
        <NavItem>
          <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => toggle('1')}>
            카테고리 폴더
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => toggle('2')}>
            숨겨진폴더
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId='1'>
          <Form onSubmit={onSubmit}>
            {({ handleSubmit, form }) => (
              <form className='material-form' onSubmit={handleSubmit}>
                <div className='form__form-group-field'>
                  {getFolders.map((name) => (
                    <>
                      <div className='form__form-group-input-wrap'>
                        <label className='radio-btn'>
                          <input
                            className='radio-btn__radio'
                            name='academic'
                            type='radio'
                            value={name.folder_name}
                            onChange={(e) => {
                              setRadioVal(e.target.value)
                            }}
                          />
                          <span className='radio-btn__radio-custom'></span>
                          <span className='radio-btn__label'>{name.folder_name}</span>
                        </label>
                      </div>
                    </>
                  ))}
                </div>
                <br />
                <hr />
                <div className='col-4'>
                  <Field
                    name='newFolder'
                    component={renderTextField}
                    label='새 폴더 이름'
                    setFolderName={setFolderName}
                    inputHidden={inputHidden}
                    tapType='normal'
                  />
                </div>
                <div className='col-4'>
                  {inputHidden ? (
                    <Field
                      name='modifyFolderName'
                      component={modifyInput}
                      label='수정 내용'
                      initialValue={radioVal}
                      setFolderName={setFolderName}
                    />
                  ) : null}
                </div>
              </form>
            )}
          </Form>
          <div className='col-12' id='buttons'>
            <Button
              className='col-2'
              color='primary'
              onClick={() => {
                // console.log(folderNameche)
                if (!folderName) {
                  window.alert('폴더명을 입력해 주세요')
                } else if (folderNameche === 'yes') {
                  window.alert('중복된 폴더명이 존재합니다.')
                } else {
                  if (window.confirm('추가하시겠습니까?')) {
                    folderAdd(0)
                  }
                }
              }}
            >
              추가
            </Button>
            <Button
              className='col-2'
              color='warning'
              hidden={buttonHidden}
              onClick={() => {
                if (!radioVal) {
                  window.alert('수정할 폴더를 체크해 주세요')
                } else {
                  setInputHidden((e) => !e)
                  setButtonHidden((e) => !e)
                }
              }}
            >
              수정
            </Button>
            {buttonHidden ? (
              <>
                <Button
                  className='col-2'
                  color='success'
                  onClick={() => {
                    if (!radioVal) {
                      window.alert('수정할 폴더를 체크해 주세요')
                    } else if (folderNameche === 'yes') {
                      window.alert('중복된 폴더명이 존재합니다.')
                    } else {
                      if (window.confirm('변경하시겠습니까?')) {
                        setButtonHidden((e) => !e)
                        setInputHidden((e) => !e)
                        folderModify()
                      }
                    }
                  }}
                >
                  확인
                </Button>
                <Button
                  className='col-2'
                  color='warning'
                  onClick={() => {
                    setButtonHidden((e) => !e)
                    setInputHidden((e) => !e)
                  }}
                >
                  취소
                </Button>
              </>
            ) : null}
            <Button
              className='col-2'
              color='danger'
              hidden={buttonHidden}
              onClick={() => {
                if (window.confirm('삭제하시겠습니까?')) {
                  folderDel()
                  window.alert('삭제되었습니다.')
                }
              }}
            >
              삭제
            </Button>
            <Button
              className='col-3'
              onClick={() => {
                folderHide(1)
              }}
            >
              숨기기
            </Button>
          </div>
        </TabPane>
        <TabPane tabId='2'>
          <Form onSubmit={onSubmit}>
            {({ handleSubmit, form }) => (
              <form className='material-form' onSubmit={handleSubmit}>
                <div className='form__form-group-field'>
                  {getHideFolders.map((name) => (
                    <>
                      <div className='form__form-group-input-wrap'>
                        <label className='radio-btn'>
                          <input
                            className='radio-btn__radio'
                            name='academic'
                            type='radio'
                            value={name.folder_name}
                            onChange={(e) => {
                              setRadioVal(e.target.value)
                            }}
                          />
                          <span className='radio-btn__radio-custom'></span>
                          <span className='radio-btn__label'>{name.folder_name}</span>
                        </label>
                      </div>
                    </>
                  ))}
                </div>
                <br />
                <hr />
                <div className='col-4'>
                  <Field
                    name='newFolder'
                    component={renderTextField}
                    label='새 폴더 이름'
                    placeholder='새 폴더 추가'
                    setFolderName={setFolderName}
                    inputHidden2={inputHidden2}
                    tapType='hidden'
                  />
                </div>
                <div className='col-4'>
                  {inputHidden2 ? (
                    <Field
                      name='modifyFolderName'
                      component={modifyInput}
                      label='수정 내용'
                      initialValue={radioVal}
                      setFolderName={setFolderName}
                    />
                  ) : null}
                </div>
                <div className='col-12'>
                  <Button
                    className='col-2'
                    color='primary'
                    onClick={() => {
                      if (!folderName) {
                        window.alert('폴더명을 입력해 주세요')
                      } else if (folderNameche === 'yes') {
                        window.alert('중복된 폴더명이 존재합니다.')
                      } else {
                        if (window.confirm('추가하시겠습니까?')) {
                          folderAdd(1)
                        }
                      }
                    }}
                  >
                    추가
                  </Button>
                  <Button
                    className='col-2'
                    color='warning'
                    hidden={buttonHidden2}
                    onClick={() => {
                      if (!radioVal) {
                        window.alert('수정할 폴더를 체크해 주세요')
                      } else {
                        setInputHidden2((e) => !e)
                        setButtonHidden2((e) => !e)
                      }
                    }}
                  >
                    수정
                  </Button>
                  {buttonHidden2 ? (
                    <>
                      <Button
                        className='col-2'
                        color='success'
                        onClick={() => {
                          console.log(folderNameche)
                          if (!radioVal) {
                            window.alert('수정할 폴더를 체크해 주세요')
                          } else if (folderNameche === 'yes') {
                            window.alert('중복된 폴더명이 존재합니다.')
                          } else {
                            if (window.confirm('변경하시겠습니까?')) {
                              setButtonHidden2((e) => !e)
                              setInputHidden2((e) => !e)
                              folderModify()
                            }
                          }
                        }}
                      >
                        확인
                      </Button>
                      <Button
                        className='col-2'
                        color='warning'
                        onClick={() => {
                          setButtonHidden2((e) => !e)
                          setInputHidden2((e) => !e)
                        }}
                      >
                        취소
                      </Button>
                    </>
                  ) : null}
                  <Button
                    className='col-2'
                    color='danger'
                    hidden={buttonHidden2}
                    onClick={() => {
                      if (window.confirm('삭제하시겠습니까?')) {
                        folderDel()
                        window.alert('삭제되었습니다.')
                      }
                    }}
                  >
                    삭제
                  </Button>
                  <Button
                    className='col-3'
                    onClick={() => {
                      folderHide(0)
                    }}
                  >
                    숨기기 취소
                  </Button>
                </div>
              </form>
            )}
          </Form>
        </TabPane>
      </TabContent>
    </div>
  )
}

export default Tabs
