import React, {useState} from 'react';
import { Col, Container } from 'reactstrap';
import showResults from './showResults';
import ReplieSearch from './components/Replies';

const Replies = () => {
  return (
    <Container>
        <ReplieSearch onSubmit={showResults}></ReplieSearch>
        <Col md={12}>
          <hr />
        </Col>
    </Container>
  );
};

export default Replies;
