 /* eslint-disable */
import React, {useState, useEffect} from 'react';
import {useDispatch} from "react-redux"
import {getList} from "../../redux/actions/payWaiting"
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import showResults from '@/utils/showResults';
import Payments from './components/Payments';
import axios from "axios"

import JustifyTabsBorderedTop from './components/JustifyTabsBorderedTop';

const Replies = () => {
  // const { t } = useTranslation('common');
  // const [loading, setLoading] = useState(false)
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   axios.get(`${process.env.REACT_APP_API_URL}/infowaiting`)
  //     .then((res) => dispatch(getList(res.data)))
  //     .then(() => setLoading(true))
  //     .catch(err => console.log(err))
  // }, [])

  // if (!loading) return <div></div>;

  return (
    <Container>
      <Row>
      </Row>
        <Payments onSubmit={showResults}></Payments>
        <Col md={12}>
          <hr />
        </Col>
      <Row>
        <JustifyTabsBorderedTop />
      </Row>
    </Container>
  );
};

export default Replies;
