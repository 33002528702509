import React, { useState } from "react"
import styled, {css} from "styled-components"
import { useHistory } from "react-router-dom";
import axios from "axios";

import Banner from "../components/Banner"

import {mobile} from "../../../lib/viewport"

const Navigation = styled.div`
    width: 500px;
    height: 40px;

    display:flex;

    margin: 50px auto 0px auto;

    ${mobile(css`
        width: calc(100% - 20px);
    `)}

`

const Info = styled.div`
    width: 500px;

    display:flex;
    flex-direction:column;

    margin: 50px auto 100px auto;

    border-top: 1px solid black;

    ${mobile(css`
        width: calc(100% - 20px);
    `)}

`

const InfoItem = styled.div`
    height: 40px;
    background-color: #ffffff;
    padding: 0px 20px;
    display:flex;
    justify-content:space-between;
    align-items:center;
    border-bottom: 0.7px solid #cdd0d8; 

    font-size: 14px;
`


const NavItem = styled.div`
    width: 33%;

    display:flex;
    justify-content:center;
    align-items:center;

    font-weight: 500;

    cursor:pointer;

    ${({ selected }) => {
      return selected ? `border-left: 1px solid black; border-top: 1px solid black; border-right: 1px solid black; background-color: #f2f3f5;` : 'border-bottom: 1px solid black; background-color: #e3e5e8;';
    }}
`

const ConfirmWrapper = styled.div`
    margin-top: 50px;

    & > div:nth-child(1){
        margin-bottom: 64px;
    }
`

const InputWrapper = styled.div`
    display: flex;
    justify-content: flex-end;

    margin: 0 auto 0px auto;

    & > span{
        width: fit-content;
        margin-right: 24px;

        display:flex;
        align-items:center;

        font-weight: bold;
        font-size: 15px;
    }
    ${mobile(css`
        & > span{
            display: none;
        }
    `)}
`

const InputText = styled.input`
    width: 350px;
    height: 50px;

    ${({ borderRadius }) => {
      return borderRadius ? `border-radius: ${borderRadius};` : 'border-radius: 6px;';
    }}
    border: solid 0.7px #e0e1e5;
    background-color: #fff;

    padding: 0px 20px;
    font-size: 15px;

    outline:none;
`

const InputSubmitButton = styled.div`
    width: 350px;
    height: 50px;

    border-radius: 6px;
    border: solid 0.5px #000;
    background-color: #474747;

    color: white;
    font-size: 15px;

    display:flex;
    justify-content:center;
    align-items:center;

    margin: 10px 0px 0px auto;
`


const Form = styled.form`
    width: fit-content;

    margin: 0 auto 0px auto;
    padding-left: 4em;
    ${mobile(css`
        padding-left: 0;
    `)}
    
`



function Confirm({ match }) {
    const history = useHistory();
    const { number } = match.params;
    const [confirmData, setConfirmData] = useState({})
    const [confirmDataState, setConfirmmDataState] = useState(false)

    const [form, setForm] = useState({ name: "", license_number: "" })

    const inputOnChange = (e) => {
        const { name, value } = e.target   
        const nextInputs = {            
            ...form,  
            [name]: value,
        }
        setForm(nextInputs)       
    }

    function searchButton(){
        if (form.name === "" || form.license_number === "") {
            window.alert("빈칸을 모두 입력해주세요 .");
        } else {
            axios.post(`${process.env.REACT_APP_API_URL}/regis-check`, form).then(res=>{
                // console.log("test",res.data)
                setConfirmData(res.data)
                setConfirmmDataState(true)
            }).catch(e=>{
                console.log("e", e.response)
            })
        }
    }

    return (
        <>
            <Banner title="schedule"></Banner>
            <Navigation>
                <NavItem selected={false} onClick={() => { history.push(`/app/academic/schedule/${number}/introduce`) }}>
                    <span>안내</span>
                </NavItem>
                <NavItem selected={false} onClick={() => { history.push(`/app/academic/schedule/${number}/registration`) }}>
                    <span>사전등록</span>
                </NavItem>
                <NavItem selected={true} >
                    <span>등록 및 접수확인</span>
                </NavItem>
            </Navigation>

            <ConfirmWrapper>
                <Form>
                    <InputWrapper>
                        <span>성명</span>
                        <InputText name="name" value={form.name} type="text" placeholder="이름을 입력해주세요." borderRadius="6px 6px 0px 0px" onChange={inputOnChange} />
                    </InputWrapper>
                    <InputWrapper>
                        <span>면허번호</span>
                        <InputText name="license_number" value={form.license_number} type="text" placeholder="면허번호를 입력해주세요." borderRadius="0px 0px 6px 6px" onChange={inputOnChange} />
                    </InputWrapper>
                    <InputSubmitButton onClick={searchButton}>
                        <span>접수 조회</span>
                    </InputSubmitButton>
                </Form>
                
            </ConfirmWrapper>
            {
            confirmDataState ? <Info>
                <InfoItem>
                    <span>성명</span>
                    <span>{confirmData.name}</span>
                </InfoItem>
                <InfoItem>
                    <span>소속</span>
                    <span>{confirmData.organization}</span>
                </InfoItem>
                <InfoItem>
                    <span>금액</span>
                    <span>{confirmData.money}</span>
                </InfoItem>
                {/* <InfoItem>
                    <span>결제상태</span>
                    <span>결제대기</span>
                </InfoItem> */}
            </Info> : <></>
            }
           
        </>
    )
}

export default Confirm
