import React, { useState, useRef } from 'react'
import styled, { css } from 'styled-components'

import Banner from './components/Banner'
import Dropdown from '../../components/DropDown'

import { mobile } from '../../lib/viewport'
import axios from 'axios'
import checkIcon from '../../../../shared/img/check-selec.png'
import { useHistory } from 'react-router'
import Alert from '../../components/Alert'

const ContentsTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  word-break: keep-all;
`

const ContentsText = styled.div`
  font-size: 12px;
  word-break: keep-all;
  margin-bottom: 20px;
`

const Label = styled.label`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 2em;

  & > input {
    margin-right: 6px;
  }
`

const SignUpWrapper = styled.div`
  margin-top: 50px;

  & > div:nth-child(1) {
    margin-bottom: 64px;
  }
`

const InputWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  margin: 0 auto 0px auto;
  
  & > span {
    width: fit-content;
    margin-right: 24px;

    display: flex;
    align-items: center;

    font-weight: bold;
    font-size: 15px;
  }
  ${mobile(css`
  flex-direction:column;
    & > span {
      //display: none;
    }
  `)}
`

const InputText = styled.input`
  width: 350px;
  height: 50px;

  ${({ borderRadius }) => {
    return borderRadius ? `border-radius: ${borderRadius};` : 'border-radius: 6px;'
  }}
  border: solid 0.7px #e0e1e5;
  background-color: #fff;

  padding: 0px 20px;
  font-size: 15px;

  outline: none;
`

const InputSubmitButton = styled.div`
  max-width: 350px;
  min-width: 350px;
  height: 50px;

  border-radius: 6px;
  border: solid 0.5px #000;
  background-color: #474747;

  color: white;
  font-size: 15px;

  display: flex;
  justify-content: center;
  align-items: center;

  margin: 30px 0 0 auto;

  cursor: pointer;
`

const InputSelectWrapper = styled.div`
  min-width: 350px;
  height: 50px;

  border-radius: 6px;

  border: solid 0.7px #e0e1e5;
  background-color: #fff;

  font-size: 15px;

  outline: none;

  display: flex;
`

const InputSelectBox = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  & > img {
    margin-right: 10px;
  }
  color: #8c919b;
  ${({ selected }) => {
    return selected ? `border: 1px solid black; color: black; font-weight: bold;` : ''
  }}
  ${({ borderRadius }) => {
    return borderRadius ? `border-radius: ${borderRadius};` : 'border-radius: 6px;'
  }}
`

const InputDoubleWrapper = styled.div`
  width: 350px;
  height: 100%;
`

const Form = styled.form`
  width: fit-content;

  margin: 0 auto 300px auto;
  padding-right: 4em;
  ${mobile(css`
    padding-right: 0;
  `)}
`

const InputAddressTextWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const AddressFindButton = styled.div`
  width: 44px;
  height: 26px;
  border-radius: 8px;
  border: solid 0.5px #000;
  background-color: #474747;
  color: white;
  text-align: center;
  padding: 3px;
  font-size: 12px;

  cursor: pointer;

  position: absolute;
  right: 12px;
  top: 12px;
`
const InputError = styled.div`
  width: 350px;

  padding: 0px 6px;
  font-size: 12px;
  font-weight: 500;

  margin: 9px 0 9px auto;
  color: #ff4040;
`
function SignUp() {
  const inputRef = React.useRef(null)

  const history = useHistory()
  const [errorText, setErrorText] = useState('')
  const [dropDownState, setDropDownState] = useState(true)

  const [form, setForm] = useState({
    id: '',
    password: '',
    rPassword: '',
    username: '',
    licenseNumber: '',
    birthDate: '',
    gender: '남성',
    field: '',
    address: '',
    detailedAddress: '',
    email: '',
    phoneNumber: '',
    privacyConsent: false,
    adConsent: true,
  })
  const [errorForm, setErrorFrom] = useState({
    errorMessage: '',
    id: '',
    password: '',
    username: '',
    licenseNumber: '',
    birthDate: '',
    field: '',
    address: '',
    email: '',
    phoneNumber: '',
    privacyConsent: '',
  })
  const [confirm, setConfirm] = useState(false)

  const inputOnChange = (e) => {
    const { name, value } = e.target
    const nextInputs = {
      ...form,
      [name]: value,
    }
    setForm(nextInputs)
  }

  const phoneInputOnChange = (e) => {
    const { name, value } = e.target
    const regValue = value.replace('-', '')
    const nextInputs = {
      ...form,
      [name]: regValue,
    }
    setForm(nextInputs)
  }

  const checkboxOnChange = (e) => {
    const { name, checked } = e.target
    const nextInputs = {
      ...form,
      [name]: checked,
    }
    setForm(nextInputs)
  }

  const SearchAddress = (e) => {
    new window.daum.Postcode({
      oncomplete: function (data) {
        const nextInputs = {
          ...form,
          ['address']: data.address,
        }
        setForm(nextInputs)
        inputRef.current.focus()
      },
    }).open()
  }

  const selectedChange = (e) => {
    const nextInputs = {
      ...form,
      [e.name]: e.value,
    }
    setForm(nextInputs)
  }

  const submitUserInfo = () => {
    const list = [
      'id',
      'password',
      'rPassword',
      'username',
      'licenseNumber',
      'birthDate',
      'field',
      'address',
      'detailedAddress',
      'email',
      'phoneNumber',
      'privacyConsent',
    ]
    const set = {
      errorMessage: '',
      id: '',
      password: '',
      username: '',
      licenseNumber: '',
      birthDate: '',
      field: '',
      address: '',
      email: '',
      phoneNumber: '',
      privacyConsent: '',
    }
    const regPassword = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
    const regId = /^[a-zA-Z0-9]*$/
    const regEmail = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i
    const regL = /^[0-9]+$/

    let errorState = false
    let nextInputs = {
      ...set,
    }

    for (let i = 0; i < list.length; i++) {
      if (list[i] === 'privacyConsent') {
        if (form[list[i]] === false) {
          errorState = true
          nextInputs = {
            ...nextInputs,
            ['errorMessage']: '개인정보 수집을 동의해주세요.',
          }
          setDropDownState(true)
          setErrorText('개인정보 수집을 동의해주세요.')
          break
        } else {
          setDropDownState(false)
        }
      } else if (list[i] === 'id') {
        if (form[list[i]] === '') {
          errorState = true
          nextInputs = {
            ...nextInputs,
            ['id']: '아이디를 입력해주세요.',
          }
          setErrorText('아이디를 입력해주세요.')
          break
        } else if (form[list[i]].length < 6 || form[list[i]].length > 20) {
          errorState = true
          nextInputs = {
            ...nextInputs,
            ['id']: '아이디는 6자 이상 20자 이하로 생성해주세요.',
          }
          setErrorText('아이디는 6자 이상 20자 이하로 생성해주세요.')
          break
        } else if (!regId.test(form[list[i]])) {
          errorState = true
          nextInputs = {
            ...nextInputs,
            ['id']: '아이디는 영문과 숫자만 사용할 수 있습니다.',
          }
          setErrorText('아이디는 영문과 숫자만 사용할 수 있습니다.')
          break
        }
      } else if (list[i] === 'password') {
        if (form[list[i]] === '') {
          errorState = true
          nextInputs = {
            ...nextInputs,
            ['password']: '비밀번호를 입력해 주세요.',
          }
          setErrorText('비밀번호를 입력해 주세요.')
          break
        } else if (form[list[i]].length < 8 || form[list[i]].length > 16) {
          errorState = true
          nextInputs = {
            ...nextInputs,
            ['password']: '패스워드는 영문, 숫자 조합 8자 이상 16자 이하로 작성해주세요.',
          }
          setErrorText('패스워드는 영문, 숫자 조합 8자 이상 16자 이하로 작성해주세요.')
          break
        } else if (!regPassword.test(form[list[i]])) {
          errorState = true
          nextInputs = {
            ...nextInputs,
            ['password']: '패스워드는 영문, 숫자 조합 8자 이상 16자 이하로 작성해주세요.',
          }
          setErrorText('패스워드는 영문, 숫자 조합 8자 이상 16자 이하로 작성해주세요.')
          break
        }
      } else if (list[i] === 'rPassword') {
        if (form[list[i]] === '') {
          errorState = true
          nextInputs = {
            ...nextInputs,
            ['password']: '비밀번호를 한번 더 입력해 주세요.',
          }
          setErrorText('비밀번호를 입력해 주세요.')
          break
        } else if (form.password !== form.rPassword) {
          errorState = true
          nextInputs = {
            ...nextInputs,
            ['password']: '비밀번호가 일치하지 않습니다.',
          }
          setErrorText('비밀번호가 일치하지 않습니다.')
          break
        }
      } else if (list[i] === 'username' && form[list[i]] === '') {
        errorState = true
        nextInputs = {
          ...nextInputs,
          ['username']: '성함을 입력해 주세요.',
        }
        setErrorText('성함을 입력해 주세요.')
        break
      } else if (list[i] === 'licenseNumber') {
        if (form[list[i]] === '') {
          errorState = true
          nextInputs = {
            ...nextInputs,
            ['licenseNumber']: '면허번호를 입력해 주세요.',
          }
          setErrorText('면허번호를 입력해 주세요.')
          break
        } else if (!regL.test(form[list[i]])) {
          errorState = true
          nextInputs = {
            ...nextInputs,
            ['licenseNumber']: '올바른 면허번호를 입력해 주세요.',
          }
          setErrorText('올바른 면허번호를 입력해 주세요.')
          break
        } else if (form[list[i]].length > 50) {
          errorState = true
          nextInputs = {
            ...nextInputs,
            ['licenseNumber']: '올바른 면허번호를 입력해 주세요',
          }
          setErrorText('올바른 면허번호를 입력해 주세요.')
          break
        }
      } else if (list[i] === 'birthDate') {
        if (form[list[i]] === '') {
          errorState = true
          nextInputs = {
            ...nextInputs,
            ['birthDate']: '생년월일을 입력해 주세요.',
          }
          setErrorText('생년월일을 입력해 주세요.')
          break
        } else if (!regL.test(form[list[i]])) {
          errorState = true
          nextInputs = {
            ...nextInputs,
            ['birthDate']: '생년월일을 확인해주세요.',
          }
          setErrorText('생년월일을 확인해주세요.')
          break
        } else if (form.birthDate.length !== 8) {
          errorState = true
          nextInputs = {
            ...nextInputs,
            ['birthDate']: '생년월일을 제대로 입력해주세요. ex) 19841107',
          }
          setErrorText('생년월일을 제대로 입력해주세요. ex) 19841107')
          break
        } else if (!/^19+|^20+/.test(form[list[i]])) {
          errorState = true
          nextInputs = {
            ...nextInputs,
            ['birthDate']: '생년월일을 확인해주세요.',
          }
          setErrorText('생년월일을 확인해주세요.')
          break
        } else if (
          Number(form[list[i]].toString().slice(4, 6)) > 12 || Number(form[list[i]].toString().slice(4, 6)) <= 0 || Number(form[list[i]].toString().slice(6)) > 31 || Number(form[list[i]].toString().slice(6)) <= 0
        ) {
          errorState = true
          nextInputs = {
            ...nextInputs,
            ['birthDate']: '생년월일을 확인해주세요.',
          }
          setErrorText('생년월일을 확인해주세요.')
          break
        }
      } else if (list[i] === 'field' && form[list[i]].length > 50) {
        errorState = true
        nextInputs = {
          ...nextInputs,
          ['field']: '올바른 전문분야를 입력해 주세요.',
        }
        setErrorText('올바른 전문분야를 입력해 주세요.')
        break
      } else if (list[i] === 'address' && form[list[i]] === '') {
        errorState = true
        nextInputs = {
          ...nextInputs,
          ['address']: '주소를 입력해 주세요,',
        }
        setErrorText('주소를 입력해 주세요.')
        break
      } else if (list[i] === 'email') {
        if (form[list[i]] === '') {
          errorState = true
          nextInputs = {
            ...nextInputs,
            ['email']: '이메일주소를 입력해 주세요.',
          }
          setErrorText('이메일주소를 입력해 주세요.')
          break
        } else if (!regEmail.test(form[list[i]])) {
          errorState = true
          nextInputs = {
            ...nextInputs,
            ['email']: '올바른 이베일 주소를 입력해 주세요.',
          }
          setErrorText('올바른 이베일 주소를 입력해 주세요.')
          break
        }
      } else if (list[i] === 'phoneNumber' && form[list[i]] === '') {
        errorState = true
        nextInputs = {
          ...nextInputs,
          ['phoneNumber']: '휴대폰 번호를 입력해 주세요.',
        }
        setErrorText('휴대폰 번호를 입력해 주세요')
        break
      }
    }

    setErrorFrom(nextInputs)
    if (errorState === false) {
      let c = window.confirm('가입정보를 제출하시겠습니까?')
      if (c === true) {
        nextInputs = {
          ...nextInputs,
          ['errorMessage']: '',
        }
        setErrorFrom(nextInputs)

        axios
          .post(`${process.env.REACT_APP_API_URL}/signIn`, {
            userData: form,
          })
          .then(() => {
            setConfirm(true)
            setErrorText('회원가입 신청이 완료되었습니다. 관리자의 승인하에 로그인이 가능합니다. 감사합니다.')
          })
          .catch((err) => {
            if (err.response.data.message) {
              nextInputs = {
                ...nextInputs,
                ['errorMessage']: err.response.data.message,
              }
              setErrorFrom(nextInputs)
              setErrorText(err.response.data.message)
            } else {
              console.log(err.response)
            }
          })
      }
    }
  }
  function setupErrorText() {
    setErrorText('')
    if (confirm === true) {
      history.push('/app/auth/signin')
    }
  }

  return (
    <>
      <Alert openState={errorText === '' ? false : true} text={errorText} setupErrorText={() => setupErrorText()} />
      <Banner title='회원가입' subTitle='JOIN'></Banner>
      <SignUpWrapper>
        <Dropdown title='개인정보 수집 이용 동의' width='480px' borderLine openState={dropDownState}>
          <ContentsTitle>개인정보 수집 항목</ContentsTitle>
          <ContentsText>이름, 소속, 이메일</ContentsText>
          <ContentsTitle>개인정보 이용 목적</ContentsTitle>
          <ContentsText>
            2022 누가통증연구회 춘계 연수강좌 관련 소식 및 안내 정보 수신 동의 누가통증연구회 관련 소식 및 마케팅 활용을 위한 참가자 정보
            제공 이용 동의를 포함합니다.
          </ContentsText>
          <u>
            <ContentsTitle>[개인정보보호법]등 관련 법규에 의거하여 개인 정보 수집 및 활용에 동의합니다.</ContentsTitle>
          </u>
          <Label>
            <input checked={form.privacyConsent} type='checkbox' name='privacyConsent' onChange={checkboxOnChange} />
            네, 동의합니다.
          </Label>
        </Dropdown>
        <Form>
          <InputWrapper>
            <span>아이디</span>
            <InputText name='id' value={form.id} type='text' placeholder='아이디를 입력해주세요.' onChange={inputOnChange} />
          </InputWrapper>
          <InputError>{errorForm.id}</InputError>
          <InputWrapper>
            <span>비밀번호</span>
            <InputDoubleWrapper>
              <InputText
                name='password'
                value={form.password}
                type='password'
                placeholder='영문, 숫자 조합 8자리 이상 16자리 이하'
                borderRadius='6px 6px 0 0'
                onChange={inputOnChange}
              />
              <InputText
                name='rPassword'
                value={form.rPassword}
                type='password'
                placeholder='비밀번호 재입력'
                borderRadius='0 0 6px 6px'
                onChange={inputOnChange}
              />
            </InputDoubleWrapper>
          </InputWrapper>
          <InputError>{errorForm.password}</InputError>
          <InputWrapper>
            <span>성함</span>
            <InputText name='username' value={form.username} type='text' placeholder='성함을 입력해주세요.' onChange={inputOnChange} />
          </InputWrapper>
          <InputError>{errorForm.username}</InputError>
          <InputWrapper>
            <span>면허번호</span>
            <InputText
              name='licenseNumber'
              value={form.licenseNumber}
              type='text'
              placeholder='면허번호를 입력해주세요.'
              onChange={inputOnChange}
            />
          </InputWrapper>
          <InputError>{errorForm.licenseNumber}</InputError>
          <InputWrapper>
            <span>생년월일</span>
            <InputText
              name='birthDate'
              value={form.birthDate}
              type='text'
              placeholder='숫자만 입력(ex: 19841107)'
              onChange={inputOnChange}
            />
          </InputWrapper>
          <InputError>{errorForm.birthDate}</InputError>
          <InputWrapper>
            <span>성별</span>
            <InputSelectWrapper>
              <InputSelectBox
                onClick={() => selectedChange({ name: 'gender', value: '남성' })}
                borderRadius='6px 0px 0px 6px'
                selected={form.gender === '남성' ? true : false}
              >
                <img src={checkIcon} alt='check_icon' />
                <span>남성</span>
              </InputSelectBox>
              <InputSelectBox
                onClick={() => selectedChange({ name: 'gender', value: '여성' })}
                borderRadius='0 6px 6px 0'
                selected={form.gender === '여성' ? true : false}
              >
                <img src={checkIcon} alt='check_icon' />
                <span>여성</span>
              </InputSelectBox>
            </InputSelectWrapper>
          </InputWrapper>
          <InputError></InputError>
          <InputWrapper>
            <span>전문분야</span>
            <InputText name='field' value={form.field} type='text' placeholder='전문분야를 입력해주세요.' onChange={inputOnChange} />
          </InputWrapper>
          <InputError>{errorForm.field}</InputError>
          <InputWrapper>
            <span>주소</span>
            <InputDoubleWrapper>
              <InputAddressTextWrapper>
                <AddressFindButton onClick={() => SearchAddress()}>찾기</AddressFindButton>
                <InputText
                  disabled
                  name='address'
                  value={form.address}
                  type='text'
                  placeholder='주소 (우편번호)'
                  borderRadius='6px 6px 0 0'
                  onChange={inputOnChange}
                />
              </InputAddressTextWrapper>
              <InputText
                ref={inputRef}
                name='detailedAddress'
                value={form.detailedAddress}
                type='text'
                placeholder='상세주소 입력'
                borderRadius='0 0 6px 6px'
                onChange={inputOnChange}
              />
            </InputDoubleWrapper>
          </InputWrapper>
          <InputError>{errorForm.address}</InputError>
          <InputWrapper>
            <span>이메일</span>
            <InputText name='email' value={form.email} type='text' placeholder='이메일을 입력해주세요.' onChange={inputOnChange} />
          </InputWrapper>
          <InputError>{errorForm.email}</InputError>
          <InputWrapper>
            <span>휴대폰</span>
            <InputText
              name='phoneNumber'
              value={form.phoneNumber}
              type='text'
              placeholder='‘-’ 제외하고 숫자만 입력해주세요.'
              onChange={phoneInputOnChange}
            />
          </InputWrapper>
          <InputError>{errorForm.phoneNumber}</InputError>
          <InputWrapper>
            <span>광고성 메시지 수신 동의</span>
            <InputSelectWrapper>
              <InputSelectBox
                onClick={() => selectedChange({ name: 'adConsent', value: true })}
                borderRadius='6px 0px 0px 6px'
                selected={form.adConsent === true ? true : false}
              >
                <img src={checkIcon} alt='check_icon' />
                <span>동의</span>
              </InputSelectBox>
              <InputSelectBox
                onClick={() => selectedChange({ name: 'adConsent', value: false })}
                borderRadius='0 6px 6px 0'
                selected={form.adConsent === false ? true : false}
              >
                <img src={checkIcon} alt='check_icon' />
                <span>비동의</span>
              </InputSelectBox>
            </InputSelectWrapper>
          </InputWrapper>
          <InputError>{errorForm.errorMessage}</InputError>
          <InputSubmitButton onClick={submitUserInfo}>
            <span>가입하기</span>
          </InputSubmitButton>
        </Form>
      </SignUpWrapper>
    </>
  )
}

export default SignUp
