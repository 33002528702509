import React, { useEffect } from 'react';
import styled, { css } from "styled-components"

import Banner from './components/Banner';
import { mobile } from '../../lib/viewport';

// import Logo from '../../../../shared/img/luke_length.png'

const LocationWrapper = styled.div`
    width: 750px;

    margin: 0 auto;
    ${mobile(css`
        width: calc( 100% - 20px );
    `)}
`

const KakaoMap = styled.div`
    width:100%;
    height: 375px;

    background-color: grey;

    margin: 50px auto 16px auto;

    ${mobile(css`
        margin: 20px auto 16px auto;
    `)}
`
const InfoWrapper = styled.div`
    display:flex;
    align-items:center;

    margin-top: 24px;

    & > img{
        margin-right: 40px
    }
    & > div > b{
        font-size: 16px;
    }
    & > div > p{
        margin-bottom: 10px;
    }
    ${mobile(css`
        margin: 0px 14px;
        & > img{
            display:none;
        }
    `)};
`

const InfoContactsWrapper = styled.div`
    display:flex;

    & > div{
        display:flex;
        margin-right: 18px;
        & > b{
            margin-right: 6px;
        }
    }
    ${mobile(css`
       flex-direction:column;
    `)};
`

// const Image = styled.img`
//     width:120px;
//     height: 97px;
// `
function Location() {
    useEffect(() => {
        const container = document.getElementById("map");
    
        const options = {
          center: new window.kakao.maps.LatLng(36.31129922547215, 127.40136669844365),
          level: 6,
        };
    
        const map = new window.kakao.maps.Map(container, options);


        var markerPosition = new window.kakao.maps.LatLng(36.31129922547215, 127.40136669844365); 

        var marker = new window.kakao.maps.Marker({
            position: markerPosition
        });

        marker.setMap(map)
      }, []);

    return ( 
        <>            
            <Banner title="location"></Banner>
            <LocationWrapper>
                <KakaoMap id="map"></KakaoMap>
                <InfoWrapper>
                    {/* <Image src={Logo} alt="logo" /> */}
                    <div>
                        <b>대전광역시 중구 산성로 130(문화동)</b>
                        <p>점심시간  12:00 ~ 13:00 (토, 일, 공휴일 휴무)</p>
                        <InfoContactsWrapper>
                            <div>
                                <b>T</b>
                                <p>010-2163-7460</p>
                            </div>
                            <div>
                                <b>F</b>
                                <p>042-585-0129</p>
                            </div>
                            <div>
                                <b>E</b>
                                <p>luke@lukemedicalassociation.com</p>
                            </div>
                        </InfoContactsWrapper>
                    </div>
                </InfoWrapper>
            </LocationWrapper>
        </>
    );
}

export default Location;
