import React from 'react'
import { useTranslation } from 'react-i18next'
import { Card, CardBody, Col } from 'reactstrap'
import ModifyVideoTabs from './ModifyVideoTabs'
import showResults from '@/utils/showResults'

const ModifyVideoTapsBorderd = ({ setRadioVal, radioVal, setFolderSta, videoReadVal, folderSta }) => {
  // console.log(radioVal)
  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className='tabs tabs--justify tabs--bordered-top'>
            <ModifyVideoTabs
              onSubmit={showResults}
              setRadioVal={setRadioVal}
              radioVal={radioVal}
              setFolderSta={setFolderSta}
              videoReadVal={videoReadVal}
              folderSta={folderSta}
            />
          </div>
        </CardBody>
      </Card>
    </Col>
  )
}

export default ModifyVideoTapsBorderd
