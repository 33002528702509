import React, {useState, useEffect} from "react"
import styled, {css} from "styled-components";
import Alert from "../../components/Alert"

import Banner from './components/Banner';
import {mobile} from "../../lib/viewport";
import checkIcon from "../../../../shared/img/check-selec.png"
import axios from "axios"

const DuesListWrapper = styled.div`
    width: 900px;

    display:flex;
    flex-wrap:wrap;
    justify-content:center;
    align-items:center;

    margin: 50px auto 0px auto;

    ${mobile(css`
        width: calc(100% - 30px);

        flex-direction:column;
    `)}


`
const DuesList = styled.div`
    width:180px;
    height:180px;

    background-color:white; 
    margin: 8px 8px;
    padding: 16px 18px;
     box-shadow: 0px 2px 7.5px 0 rgba(0, 0, 0, 0.1);

    ${mobile(css`
        width: 100%;
        height: 100%;

        display:flex;
        align-items:center;
    `)}
`

const DuesListCricle = styled.div`
    width: 24px;
    height: 24px;
    border: solid 0.5px #e0e1e5;
    background-color: #fff;

    border-radius:100%;
    ${({selected})=>{
        return selected ? "background-color:#287eff;":"";
    }}

    display:flex;
    justify-content:center;
    align-items:center;


`

const DuesListContents = styled.div`
    display:flex;
    justify-content:flex-end;
    align-items:flex-end;
    flex-direction:column;
    margin-top:10px;

    ${mobile(css`

        width:100%;
        
        flex-direction:row;
        justify-content:center;
        align-items:center;
    `)}
`

const DuesListContentsType = styled.div`
  width: 60px;
  height: 30px;
  border-radius: 14px;
  border: solid 1px #287eff;
  background-color: #fff;

  display:flex;
  justify-content:center;
  align-items:center;

  margin-top:2px;
`
const Line = styled.div`
    width: 100%;
    height:0.7px;
    background-color: #dbdfe6;
    margin:10px 0px 4px 0px;

    ${mobile(css`
        display:none;
    `)}
`
const PaymentTypeWrapper = styled.div`
    width:460px;

    margin: 90px auto 40px auto;

    border-top: 1px solid black;

    ${mobile(css`
        width: calc(100% - 30px);
    `)}
`
const DuesListMoney = styled.div`
    font-size: 20px;
    font-weight: bold;
    margin-top: 4px;
    ${mobile(css`
        margin: 0 0 0 auto;
        width:fit-content;
    `)}
`
const PaymentTypeList = styled.div`
    width:100%;
    height: 50px;
    background-color: #e7e9ec;
    display:flex;
    justify-content: ${props=> props.justify};
    align-items:center;

    border-bottom: 0.7px solid #cdd0d8;

    color: black;
    padding: 0px 20px;
    & > p > b {
        margin-right: 4px;
    }
`
const PaymentTotalMoney = styled.div`
    width:100%;
    height: 48px;
    background-color: #ffffff;
    display:flex;
    justify-content:space-between;
    align-items:center;

    border-bottom: 0.7px solid #cdd0d8;
    
    padding: 0px 20px;
`
const MoneyButton = styled.div`
    width: 92px;
    height: 33px;

    border-radius: 10px;
    border: solid 0.7px #cdd0d8;
    background-color: #e7e9ec;

    margin: 0px 2px;
    
    display:flex;
    justify-content:center;
    align-items:center;

    ${({ selected }) => {
      return selected ? `border: 1px solid black; color: black; background-color: white;` : 'color: #989ea9;';
    }}
`

const PaymentInput = styled.input`
    width: 140px;
    height: 33px;
    border-radius: 10px;
    border: solid 0.7px #cdd0d8;
    background-color: #fff;

    text-align:center;

`

const SubmitButton = styled.div`

    width: 330.5px;
    height: 50px;

    margin: 0 auto 100px auto;
    border-radius: 8px;
    border: solid 0.7px #000;
    background-color: #474747;

    display:flex;
    justify-content:center;
    align-items:center;

    color: white;

    cursor:pointer;
    ${mobile(css`
        width: calc(100% - 30px);
    `)}
`

const DuesListMobile = styled.div`
    width:100%;
    height:100%;
    display:flex;
    flex-direction:column;
    align-items:flex-end;
    ${mobile(css`
        margin-left: 10px;
        width:fit-content;

        align-items:center;
    `)}
`

const DuesNone = styled.div`
    width:460px;

    margin: 120px auto 40px auto;
    text-align:center;
    font-size: 30px;

${mobile(css`
    width: calc(100% - 30px);
`)}
`

function Dues() {
    const [data, setData] = useState([])
    const [errorText, setErrorText] = useState("")
    const [payer, setPayer] = useState("")
    function setupErrorText() {
        setErrorText("")
    }
    const [selectedData, setSelectedData] = useState({money : 0});
    useEffect(()=>{
        axios.get(`${process.env.REACT_APP_API_URL}/showpayment`)
        .then(res => {
            if (res.data.data === "관리자가 아직 미입력한 상태입니다.") {
                setData([])
            } else {
                let arr = []
                for (let i = 0 ; i < res.data.data.length; i++) {
                    arr.push({...res.data.data[i], selected: false})
                }
                setData(arr)
            }
        })
        .catch(err => {
            console.log(err)
        })
    },[])

    function onClickList(e){
        const selectedState = !e.selected;
        const nextData = data.map(value => value.id_payment === e.id_payment ? {...e, selected: selectedState}: {...value, selected: false})
        if (selectedState === false) {
            setSelectedData({money : 0})
        } else {
            setSelectedData(e)
        }
        setData(nextData)       
    }

    function RequsetMoney() {
        if (selectedData.money !== 0) {
            if (payer !== "") {
                if (localStorage.getItem("accessToken")) {
                    axios.post(`${process.env.REACT_APP_API_URL}/verification`, {
                        token: localStorage.getItem("accessToken")
                    })
                    .then(res => {
                        axios.post(`${process.env.REACT_APP_API_URL}/payrequest`, {
                                payer: payer,
                                userid : res.data.data.userid,
                                pay_contents: selectedData.date +" / " +selectedData.contents,
                                money: selectedData.money
                            }).then(data2 => {
                                if (data2.data === "ok") {
                                    setErrorText("결제 요청이 완료되었습니다.")
                                }
                            }).catch(err => {
                                if (err.response.data){
                                    setErrorText(err.response.data)
                                } else {
                                    console.log(err.response)
                                }
                            })
                    })
                    .catch(err => {
                        if (err.response.data.message) {
                            window.alert(err.response.data.message)
                        } else {
                            console.log(err.response)
                        }
                    })
                }
            } else {
                setErrorText("송금인을 입력해주세요.")
            }
        } else {
            setErrorText("납부할 회비를 선택해주세요.")
        }
    }

    return (
        <>
            <Alert openState={errorText === "" ? false: true } text={errorText} setupErrorText={()=>setupErrorText()}/>
            <Banner title="dues"></Banner>
            {data.length === 1 && data[0].contents === "" && data[0].date === "" && data[0].money === "" ? <DuesNone>현재 납부할 회비가 없습니다.</DuesNone> : <><DuesListWrapper>
                {
                    data.map(value => {
                        return (
                            <DuesList onClick={() => {onClickList(value)}}>
                                <DuesListCricle selected={value.selected} >
                                {
                                    value.selected && <img src={checkIcon} alt="checkIcon" />
                                }
                                </DuesListCricle>
                                <DuesListContents>
                                    <DuesListMobile>
                                    <p>{value.date}</p>
                                    <DuesListContentsType>
                                        <span>{value.contents}</span>
                                    </DuesListContentsType>
                                    </DuesListMobile>
                                    <Line />
                                    <DuesListMoney>{value.money.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</DuesListMoney>
                                </DuesListContents>
                            </DuesList>
                        )
                    })
                }
            </DuesListWrapper>
            <PaymentTypeWrapper>
                    <PaymentTypeList justify="center">
                            <MoneyButton selected>무통장 입금</MoneyButton>
                            <MoneyButton>신용카드</MoneyButton>
                    </PaymentTypeList>
                    <PaymentTypeList justify="space-between">
                        <p>
                            <b>하나은행</b>
                            631-910021-49305 누가통증연구회
                        </p>
                        <PaymentInput type="text" placeholder="송금인 입력" onChange={(e) => { setPayer(e.target.value) }} />
                    </PaymentTypeList>
                    <PaymentTotalMoney>
                        <div>총 금액</div>
                         <DuesListMoney>{selectedData.money.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</DuesListMoney>
                    </PaymentTotalMoney>
                </PaymentTypeWrapper>
                <SubmitButton onClick={() => RequsetMoney()}>
                    결제요청
                </SubmitButton>
                </>}
        </>
    )
}

export default Dues