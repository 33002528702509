import axios from 'axios'

export default (async function showResults(values, setRendChange) {
  let val = {
    title: values.title,
    academicLink: values.academicLink,
    posterLink: values.posterLink,
    shortcutLink: values.shortcutLink,
    trainingLink: values.trainingLink,
    id: values.id_academic_article,
    status: values.status
  }
  const arr = values['input-group']
  if (arr && arr.length > 0) {
    const result = arr.map(num => ({
      "item": values[`item-${num}`],
      "details": values[`details-${num}`],
      "content_id": null,
      "status": values.status
    }))
    val = {
      title: values.title,
      academicLink: values.academicLink,
      posterLink: values.posterLink,
      shortcutLink: values.shortcutLink,
      trainingLink: values.trainingLink,
      id: values.id_academic_article,
      status: values.status,
      result
    }
  }
  // console.log({ val });
  axios.post(`${process.env.REACT_APP_API_URL}/modifyAcademic`, val)
    .then(data => {
      return data
    }).then((data2) => {
      val.content_id = values.id_academic_article
      if (val.result) {
        for (let i = 0; i < val.result.length; i++){
          val.result[i].content_id = values.id_academic_article
      }
      axios.post(`${process.env.REACT_APP_API_URL}/academicContent?num=1`, val)
      }
      return data2
    })
    .then(data3 => {
      if (values.academicFile) {
        let formData = new FormData()
        formData.append('academicFile', values.academicFile.file)
        axios.post(`${process.env.REACT_APP_API_URL}/fileUpload?num=3&no=${values.id_academic_article}`, formData)
          .then(data => {
            return null
          })
          .catch(err => console.log(err))
      }
      if (values.posterFile) {
        let formData = new FormData()
        formData.append('posterFile', values.posterFile.file)
        axios.post(`${process.env.REACT_APP_API_URL}/fileUpload?num=4&no=${values.id_academic_article}`, formData)
          .then(data => {
            return null
          })
          .catch(err => console.log(err))
      }
      if (values.shortcutFile) {
        let formData = new FormData()
        formData.append('shortcutFile', values.shortcutFile.file)
        axios.post(`${process.env.REACT_APP_API_URL}/fileUpload?num=5&no=${values.id_academic_article}`, formData)
          .then(data => {
            return null
          })
          .catch(err => console.log(err))
      }
      if (values.trainingFile) {
        let formData = new FormData()
        formData.append('trainingFile', values.trainingFile.file)
        axios.post(`${process.env.REACT_APP_API_URL}/fileUpload?num=6&no=${values.id_academic_article}`, formData)
          .then(data => {
            return null
          })
          .catch(err => console.log(err))
  }
    })
    .catch(err=>console.log(err))
})
