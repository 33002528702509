import React from 'react';
import styled from 'styled-components'
import moment from "moment"
import {useHistory} from "react-router"

const CommunityBoardWrapper = styled.div`
    width:100%;
    height: 45px;

    display:flex;
    justify-content:space-between;
    align-items:center;

    padding: 8px;

    transition: 0.2s ease;
    cursor:pointer;

    &:hover{
        background-color: #f2f3f5;
    }
`

const Title = styled.div`
    font-size: 14px;
    color: #0e0e0e;

    margin-right: 60px;

    overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
const Date = styled.div`
    color: #acafb5;
    font-size: 12px;
`

function CommunityBoard({data, length}) {
    const history = useHistory()

    function clickEvent(){
        if (data.type === "1") {
            history.push(`/app/notification/notice/${data.board_num}/${data.type}/${length}`)
        } else if (data.type === "2") {
            history.push(`/app/notification/newsletter/${data.board_num}/${data.type}/${length}`)
        } else if (data.type === "4") {
            history.push(`/app/community/userboard/${data.board_num}/${data.type}/${length}`)
        }
    }
    return ( 
        <CommunityBoardWrapper onClick={() => clickEvent()}>
            <Title>
                {data.title}
            </Title>
            <Date>
                {moment(data.c_date).format("YYYY-MM-DD")}
            </Date>
        </CommunityBoardWrapper>
    )
}

export default CommunityBoard
