import React, { useState } from "react";
import styled, { css } from "styled-components";
import Banner from "./components/Banner";
import { mobile } from "../../lib/viewport";
import ReactSummernote from "react-summernote";
import "react-summernote/dist/react-summernote.css"; // import styles
import "react-summernote/lang/summernote-ko-KR"; // you can import any other locale
import axios from "axios";
import "./write.scss";
import Alert from "../../components/Alert";
import { useHistory, useParams } from "react-router-dom";

// Import bootstrap(v3 or v4) dependencies
import "bootstrap/js/src/modal";
import "bootstrap/js/src/dropdown";
import "bootstrap/js/src/tooltip";
import "bootstrap/dist/css/bootstrap.css";

const WriteWrapper = styled.div`
  max-width: 1100px;

  margin: 50px auto 100px auto;

  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;

  ${mobile(css`
    margin: 20px auto 100px auto;
    max-width: 100%;
    width: calc(100% - 20px);
  `)}
`;

const WriteButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WriteButton = styled.div`
  width: 190px;
  height: 51px;
  margin: 0px 4px;
  border-radius: 5px;
  border: solid 0.5px #000;
  background-color: #474747;
  font-size: 14px;
  color: white;
  ${({ reversal }) => {
    return reversal ? `color: #000; background-color: white;` : "";
  }}
  display:flex;
  justify-content: center;
  align-items: center;

  margin-top: 10px;

  cursor: pointer;
`;

const WriteTitle = styled.div`
  width: 672.4px;
  height: 40px;

  background-color: white;

  padding: 4px 10px;
  border-top: solid 1px #cdd0d8;
  border-left: solid 1px #cdd0d8;
  border-right: solid 1px #cdd0d8;

  ${mobile(css`
    width: 100%;
  `)}
`;
const TitleInput = styled.input`
  text-decoration: none;
  outline: none;
  border: 0px;
  width: 100%;
  height: 100%;

  font-size: 13px;
`;

const FileWrapper = styled.div`
  width: 672.4px;
  height: 40px;
  background-color: #eaedf1;

  padding: 4px 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  ${mobile(css`
    width: 100%;
  `)}
`;

const FileInput = styled.input`
  width: 1px;
  height: 1px;
  visibility: visible;
  opacity: 0;
  position: absolute;
`;

const FileText = styled.div`
  border: solid 0.5px #cdd0d8;
  background-color: #fff;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 90px;
  height: 26px;

  margin-left: 14px;
  margin-right: 12px;

  cursor: pointer;
`;

const FileName = styled.div`
 width : calc(100% - 50px);
 height: 40px;
 display: -webkit-box;
 -webkit-box-orient: vertical;
 word-wrap: break-word;
 text-overflow: ellipsis;
 overflow: hidden;
`;
const FileLabel = styled.label`
  width : calc(100% - 50px);
  display: flex;
  justify-content: start;
  align-items: center;
`;
function Write() {
  const params = useParams();

  const [fileName, setFileName] = useState("");
  const [text, setText] = useState("");
  const [title, setTitle] = useState("");
  const [fileData, setFileData] = useState();
  const uploadFile = React.useRef(null);
  const [file, setFile] = useState(null);
  const [errorText, setErrorText] = useState("");
  const [confirm, setConfirm] = useState(false);
  const history = useHistory();

  function setupErrorText() {
    setErrorText("");
    if (confirm) {
      if (params.type === "qna") {
        window.location.href = "/app/community/qna";
      } else {
        window.location.href = "/app/community/userboard";
      }
    }
  }
  function Create() {
    if (title.length < 4 || title.length > 30) {
      setErrorText("제목은 4글자이상  30자이하로 입력해주세요.");
      return;
    }
    if (text.length < 4) {
      setErrorText("본문은 4글자이상 입력해주세요.");
      return;
    }
    if (localStorage.getItem("accessToken")) {
      axios
        .post(`${process.env.REACT_APP_API_URL}/verification`, {
          token: localStorage.getItem("accessToken"),
        })
        .then((data) => {
          let typeNumber = 4;
          if (params.type === "qna") {
            typeNumber = 5;
          }
          if (uploadFile.current.files.length !== 0) {
            //파일이 있을경우
            let formData = new FormData();

            axios
              .post(`${process.env.REACT_APP_API_URL}/notices`, {
                title: title,
                contents: text,
                is_important: 0, //공지가 아님
                type: typeNumber, //회원 글쓰기
                writer: data.data.data.userid,
                status: "1",
              })
              .then((data2) => {
                // console.log("data2",data2)
                formData.append("file", uploadFile.current.files[0]);
                axios
                  .post(
                    `${process.env.REACT_APP_API_URL}/fileUpload?no=${data2.data.id_notice}&num=1`,
                    formData,
                    {
                      headers: {
                        "Content-Type": "multipart/form-data",
                      },
                    }
                  )
                  .then((data3) => {
                    // console.log("data3", data3)
                    if (data3.data.success === true) {
                      setConfirm(true);
                      setErrorText("게시물을 업로드 하였습니다.");
                    }
                  });
              })
              .catch((err) => console.log(err));
          } else {
            //파일이 없을경우
            axios
              .post(`${process.env.REACT_APP_API_URL}/notices`, {
                title: title,
                contents: text,
                is_important: 0, //공지가 아님
                type: typeNumber, //회원 글쓰기
                writer: data.data.data.userid,
                status: "1",
              })
              .then((data2) => {
                setConfirm(true);
                setErrorText("게시물을 업로드 하였습니다.");
              })
              .catch((err) => console.log(err));
          }
        })
        .catch((err) => {
          console.log(err.response);
          if (err.response) {
            setErrorText(err.response.data.message);
          } else {
            console.log(err.response);
          }
        });
    }
  }

  const handleCancle = () => {
    history.goBack()
  }

  const onImageUpload = (fileList) => {
    // axios.post(`${process.env.REACT_APP_API_URL}/verification`, {
    //   token: localStorage.getItem("accessToken")
    // }).then(data => {
    //   const formData = new FormData()
    //   formData.append("sm_img", fileList[0])
    //   formData.append("id", data.data.data.userid)

    //   axios.post(`${process.env.REACT_APP_API_URL}/uploadimage`, formData, {
    //     headers: {
    //       'Content-Type': 'multipart/form-data'
    //     }
    //   }).then(res => console.log(res))
    //   .catch(err => console.log(err.response))
    // })
    // TODO: axios 호출로 server 폴더에 집어넣은 후에 나오는 경로를 insertImage에 넣는다.

    const formData = new FormData();
    formData.append("sm_img", fileList[0]);

    axios
      .post(`${process.env.REACT_APP_API_URL}/uploadimage`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          ReactSummernote.insertImage(
            `${process.env.REACT_APP_API_URL}/${res.data.path}`
          );
        };
        reader.readAsDataURL(fileList[0]);
      })
      .catch((err) => console.log(err.response));

    // const reader = new FileReader()
    // reader.onloadend = () => {
    //   ReactSummernote.insertImage(reader.result)
    // }
    // reader.readAsDataURL(fileList[0])
  };

  return (
    <>
      <Alert
        openState={errorText === "" ? false : true}
        text={errorText}
        setupErrorText={() => setupErrorText()}
      />
      {params.type === "userboard" ? (
        <Banner title="userboard"></Banner>
      ) : (
        <Banner title="qna"></Banner>
      )}
      <WriteWrapper>
        <WriteTitle>
          <TitleInput
            type="text"
            placeholder="글 제목을 입력해주세요."
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          />
        </WriteTitle>
        <ReactSummernote
          value={text}
          options={{
            lang: "ko-KR",
            height: 350,
            dialogsInBody: true,
            toolbar: [
              ["style", ["style"]],
              ["font", ["bold", "underline", "clear"]],
              // ['fontname', ['fontname']],
              ["para", ["ul", "ol", "paragraph"]],
              ["table", ["table"]],
              ["insert", ["link", "picture"]],
            ],
          }}
          onChange={(e) => {
            setText(e);
          }}
          onImageUpload={onImageUpload}
        />
        {/* <Controller
                  name="file"
                  control={control}
                  render={({ field }) => <FileInputField {...field} />}
                /> */}
        <FileWrapper>
          <b>첨부파일</b>
          <FileLabel>
            <FileInput
              ref={uploadFile}
              type="file"
              onChange={() => {
                if (uploadFile.current.files.length !== 0) {
                  setFileName(uploadFile.current.files[0].name);
                } else {
                  setFileName("");
                }
              }}
            />
            <FileText>파일 업로드</FileText>
            <FileName>{fileName}</FileName>
          </FileLabel>
        </FileWrapper>

        <WriteButtonWrapper>
          <WriteButton onClick={() => Create()}>확인</WriteButton>
          <WriteButton onClick={handleCancle} reversal>취소</WriteButton>
        </WriteButtonWrapper>
      </WriteWrapper>
    </>
  );
}

export default Write;
