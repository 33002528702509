import axios from "axios";
// import { isEmptyObject } from "jquery";

/* eslint-disable no-alert */
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export default (async function showResults(values, data, setEventList) {
  await sleep(500); // simulate server latency
  console.log(values);
  // const select = parseInt(data.select);
  const select2 = parseInt(data.select2);
  const fetchData = {
    event_type: select2,
    event_type_detail: select2 === 1 ? "연구회일정" : "학술행사일정",
    title: values.title,
    startdate: data.start,
    enddate: data.end,
    place: values.place,
    contents: data.contents, //서머노트 contents
    will_receive_pre_reg: data.preRegi,
    pre_reg_startdate: data.preStart,
    pre_reg_enddate: data.preEnd,
    reg_method: values.register,
    count: data.count,
    del : "no"
  }

  console.log(fetchData)
//파일 업로드
  // if (value.file) {
  //   let formData = new FormData()
  //   axios
  //     .post(`${process.env.REACT_APP_API_URL}/notices`, value)
  //     .then((data) => {
  //       setRendChange((e) => !e)
  //       return data
  //     })
  //     .then((data2) => {
  //       formData.append('file', value.file.file)
  //       axios.post(`${process.env.REACT_APP_API_URL}/fileUpload?no=${data2.data.id_notice}`, formData, {
  //         headers: {
  //           'Content-Type': 'multipart/form-data',
  //         },
  //       })
  //     })
  //     .catch((err) => console.log(err))
  // } else {
  //   axios
  //     .post(`${process.env.REACT_APP_API_URL}/notices`, value)
  //     .then((data) => setRendChange((e) => !e))
  //     .catch((err) => console.log(err))
  // }


  
  axios.post(`${process.env.REACT_APP_API_URL}/schedule-management`, {
    fetchData,
    amount: data.amount ? data.amount : []
  })
  .then(res => {
    if (values.file){
      let formData = new FormData()
      formData.append('file', values.file.file)
      axios.post(`${process.env.REACT_APP_API_URL}/fileUploadResearch?no=${res.data.event.id_event}`, formData,
      {
        headers: {
                    'Content-Type': 'multipart/form-data',
                 },
      })
      .catch((err) => console.log(err.response))
    }
    setEventList(res.data.event)
  })
  .catch(err => console.log(err.response))
 
  // console.log("values", values)
  // console.log(fetchData)
  // console.log("data", data)
  // console.log("int로 변환", select2)
  // console.log("number", Number(values.select2))
  // console.log("live", values.select2)
  // console.log(selectData1, selectData2);
  // if (selectData1 === 1 && selectData2 === 1) {
    // axios.post(`${process.env.REACT_APP_API_URL}/notices`, {
    // })
    //   .then(data => console.log(data))
    //   .catch(err => console.log(err))
  // }
});
