import React, { useState } from 'react';
import {
  Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import BoardHistroyTable from './BoardHistroyTable';
import ReplyHistoryTable from './ReplyHistoryTable';
import PaymentsHistoryTable from './PaymentsHistoryTable';


const Tabs = ({userid}) => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (
    <div className="tabs__wrap">
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => toggle('1')}
          >
            월 회비 납부 내역
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => toggle('2')}
          >
            게시물 히스토리
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '3' })}
            onClick={() => toggle('3')}
          >
            댓글 히스토리
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <PaymentsHistoryTable userid={userid}/>
        </TabPane>
        <TabPane tabId="2">
          <BoardHistroyTable userid={userid}/>
        </TabPane>
        <TabPane tabId="3">
          <ReplyHistoryTable userid={userid}/>
        </TabPane>
      </TabContent>
    </div>
  );
};

export default Tabs;
