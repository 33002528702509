import React, {useState} from 'react';
import { Route, Switch } from 'react-router-dom';
import { useHistory } from "react-router"
import Edit from './Edit';
import PwChange from './PwChange';
import Dues from './Dues';
import DuesHistory from './DuesHistory';
import { useSelector } from "react-redux"

function RouteMypage(){
  const history = useHistory()
  let adminState = null
  useSelector(state => { adminState = state.admin.admin }) 
  if (!adminState) {
    if (!localStorage.getItem("accessToken")) {
      history.push("/app/auth/signin")
      window.alert("로그인 후, 이용 가능합니다.")
    }
  }
  return (
    <>
  <Switch>
    <Route exact path="/app/mypage/edit" component={Edit} />
    <Route exact path="/app/mypage/pwchange" component={PwChange} />
    <Route exact path="/app/mypage/dues" component={Dues} />
    <Route exact path="/app/mypage/dueshistory" component={DuesHistory} />
  </Switch>
    </>
  );
}

export default RouteMypage