import React, { useEffect, useState } from 'react'
import { Nav, NavItem, NavLink, TabContent, TabPane, Table, Button } from 'reactstrap'
import classnames from 'classnames'
import TextField from '@material-ui/core/TextField'
import { Field, Form } from 'react-final-form'
import axios from 'axios'

const renderTextField = ({ input, label, meta: { touched, error }, children, select, multiline, disabled, setFolderName, inputHidden }) => (
  <TextField
    className='material-form__field'
    label={label}
    type={input.type}
    error={touched && error}
    value={input.value}
    select={select}
    multiline={multiline}
    onChange={(e) => {
      e.preventDefault()
      input.onChange(e.target.value)
      setFolderName(e.target.value)
    }}
    disabled={disabled}
    hidden={inputHidden}
  >
    {children}
  </TextField>
)
const modifyInput = ({ input, label, meta: { touched, error }, children, select, multiline, disabled, setFolderName, defaultVal }) => (
  <TextField
    className='material-form__field'
    label={label}
    type={input.type}
    error={touched && error}
    value={input.value}
    select={select}
    multiline={multiline}
    onChange={(e) => {
      e.preventDefault()
      input.onChange(e.target.value)
      setFolderName(e.target.value)
    }}
    disabled={disabled}
  >
    {children}
  </TextField>
)

const ModifyVideoTabs = ({ onSubmit, setRadioVal, radioVal, setFolderSta, videoReadVal, folderSta }) => {
  const [activeTab, setActiveTab] = useState('1')
  const [radioButtonShow, setRadioButtonShow] = useState(false)
  const [folderName, setFolderName] = useState('')
  const [inputHidden, setInputHidden] = useState(false)
  const [buttonHidden, setButtonHidden] = useState(false)
  const [getFolders, setGetFolders] = useState([])
  const [getHideFolders, setGetHideFolders] = useState([])
  const [folderLoading, setFolderLoading] = useState(false)
  const [test, setTest] = useState(true)
  const [test2, setTest2] = useState(true)
  // const [selectCheck, setSelectCheck] = useState(videoReadVal.folder_status)
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab)
  }
  const folerDel = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/folderType?num=2&name=${radioVal}`)
      .then(() => setFolderLoading((e) => !e))
      .catch((err) => console.log(err))
  }
  const folerHide = (input) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/folderType?num=4&name=${radioVal}&status=${input}`)
      .then(() => setFolderLoading((e) => !e))
      .catch((err) => console.log(err))
  }
  const folderAdd = (input) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/folderType?num=0&name=${folderName}&status=${input}`)
      .then(() => setFolderLoading((e) => !e))
      .catch((err) => console.log(err))
  }
  const folderModify = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/folderType?num=1&modifyName=${folderName}&name=${radioVal}`)
      .then(() => setFolderLoading((e) => !e))
      .catch((err) => console.log(err))
  }
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/folderType?num=3`)
      .then((data) => {
        setGetFolders(data.data)
        // console.log(data.data)
      })
      .catch((err) => console.log(err))
  }, [folderName, radioButtonShow, folderLoading])
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/folderType?num=5`)
      .then((data) => {
        setGetHideFolders(data.data)
        // console.log(data.data)
      })
      .catch((err) => console.log(err))
  }, [folderName, radioButtonShow, folderLoading])
  useEffect(() => {
    setFolderSta(activeTab)
  }, [activeTab])
  // useEffect(() => {
  //   setSelectCheck(folderSta)
  // },[folderSta])

  // console.log(radioVal)
  return (
    <div className='tabs__wrap'>
      <Nav tabs>
        <NavItem>
          <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => toggle('1')}>
            카테고리 폴더
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => toggle('2')}>
            숨겨진폴더
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId='1'>
          <Form onSubmit={onSubmit}>
            {({ handleSubmit, form }) => (
              <form className='material-form' onSubmit={handleSubmit}>
                <div className='form__form-group-field'>
                  {getFolders.map((name) => (
                    <>
                      <div className='form__form-group-input-wrap'>
                        <label className='radio-btn'>
                          <input
                            className='radio-btn__radio'
                            name='academic'
                            type='radio'
                            value={name.folder_name}
                            onChange={(e) => {
                              setTest(false)
                              setRadioVal(e.target.value)
                            }}
                            checked={videoReadVal.folder_name === name.folder_name && test ? 'checked' : null}
                          />
                          <span className='radio-btn__radio-custom'></span>
                          <span className='radio-btn__label'>{name.folder_name}</span>
                        </label>
                      </div>
                    </>
                  ))}
                </div>
                <br />
                <hr />
              </form>
            )}
          </Form>
        </TabPane>
        <TabPane tabId='2'>
          <Form onSubmit={onSubmit}>
            {({ handleSubmit, form }) => (
              <form className='material-form' onSubmit={handleSubmit}>
                <div className='form__form-group-field'>
                  {getHideFolders.map((name) => (
                    <>
                      <div className='form__form-group-input-wrap'>
                        <label className='radio-btn'>
                          <input
                            className='radio-btn__radio'
                            name='academic'
                            type='radio'
                            value={name.folder_name}
                            onChange={(e) => {
                              setTest2(false)
                              setRadioVal(e.target.value)
                            }}
                            checked={videoReadVal.folder_name === name.folder_name && test2 ? 'checked' : null}
                          />
                          <span className='radio-btn__radio-custom'></span>
                          <span className='radio-btn__label'>{name.folder_name}</span>
                        </label>
                      </div>
                    </>
                  ))}
                </div>
                <br />
                <hr />
              </form>
            )}
          </Form>
        </TabPane>
      </TabContent>
    </div>
  )
}

export default ModifyVideoTabs
