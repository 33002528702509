import React, { useState } from 'react';
// import PropTypes, { arrayOf } from 'prop-types';
// import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import {
  Card, CardBody, Col, Button, ButtonToolbar, Table, 
} from 'reactstrap';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import BasicTableData from './BasicTableData';
import { useHistory } from 'react-router-dom';
import AcademicWrite from "./AcademicWrite"
import ModifyAcademicWrite from "./ModifyAcademicWrite"
import submitAcademy from './submitAcademy'
import modifysubmitAcademy from './modifysubmitAcademy'
import { useEffect } from 'react'
import axios from 'axios'
import Pagination from './Pagination'
import moment from 'moment';
import {v4 as uuidv4} from "uuid"

const renderTextField = ({
  input, label, meta: { touched, error }, children, select, multiline, history,disabled
}) => (
  <TextField
    className="material-form__field"
    label={label}
    type={input.type}
    error={touched && error}
    value={input.value}
    select={select}
    multiline={multiline}
    disabled={disabled}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
      if (e.target.value === "1") {
        history.push("/admin/managements/articles")
      } else if (e.target.value === "2") {
        history.push("/admin/managements/schedules")
      } else if (e.target.value === "3") {
        history.push("/admin/managements/videos")
      }
    }}
  >
    {children}
  </TextField>
);


const headerResponsive = [
  { id: 1, title: '번호' },
  { id: 2, title: '제목' },
  { id: 3, title: '작성자' },
  { id: 4, title: '작성일' },
  { id: 5, title: '작성IP' },
  { id: 7, title: '첨부' },
  { id: 8, title: 'url' },
  { id: 9, title: '수정' },
  { id: 10, title: '삭제' },
]

const { tableHeaderResponsiveData, tableRowsData } = BasicTableData();
const AnimatedLineFormWithLabels = ({ onSubmit }) => {
  const [academyList, setAcademyList] = useState([])
  const [showModifyAcademy, setShowModifyAcademy] = useState(false)
  const [academyRead, setAcademyRead] = useState(0)
  const [academyReadVal, setAcademyReadVal] = useState("")
  const [academyContentRead, setAcademyContentRead] = useState("")
  const [loading, setLoading] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const [loading3, setLoading3] = useState(false)
  const [totalCount, setTotalCount] = useState(0)
  const [pageNum, setPageNum] = useState('')
  const history = useHistory();
  const [write, setWrite] = useState(false);
  const [statusChange, setStatusChange] = useState("")
  
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/academicList?selecNum=0&pageNum=${pageNum}`)
      .then(data => {
        setAcademyList(data.data.data2)
        // console.log(data.data.data2);
        setTotalCount(data.data.count)
        setLoading3(true)
      })
      .catch(err => console.log(err))
  }, [pageNum,loading3])
  const academyOneData = (id) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/academicList?selecNum=3&id=${id}`)
      .then((data) => setAcademyReadVal(data.data))
      .then(()=>setLoading(true))
      .catch((err) => console.log(err))
    axios
      .get(`${process.env.REACT_APP_API_URL}/academicList?selecNum=4&id=${id}`)
      .then((data) => setAcademyContentRead(data.data))
      .then(()=>setLoading2(true))
      .catch((err) => console.log(err))
  }
  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h1>학술상관리</h1>
          </div>
          <Form onSubmit={onSubmit}>
            {({ handleSubmit, form }) => (
              <form className="material-form" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-4">
                    <Field
                      name="select"
                      component={renderTextField}
                      select
                      label="구분"
                      defaultValue={4}
                      history={history}
                    >
                      <MenuItem className="material-form__option" value="1">게시판관리</MenuItem>
                      <MenuItem className="material-form__option" value="2">일정관리</MenuItem>
                      <MenuItem className="material-form__option" value="3">동영상관리</MenuItem>
                      <MenuItem className="material-form__option" value="4">학술상관리</MenuItem>
                    </Field>
                  </div>
                  <div className="col-4">
                    <Field
                      name="select2"
                      component={renderTextField}
                      select
                      label="하위 구분"
                      defaultValue={1}
                      disabled={true}
                    >
                      <MenuItem className="material-form__option" value="1">학술상현황</MenuItem>
                    </Field>
                  </div>
                  <div className="col-3">
                    <ButtonToolbar className="form__button-toolbar">
                      <Button color="success" type="button" onClick={() => {
                        setWrite((e) => !e)
                        setShowModifyAcademy(false)
                      }}>글쓰기</Button>
                    </ButtonToolbar>
                  </div>
                </div>
              </form>
            )}
          </Form>
          <br />
          {write && loading3 ? <AcademicWrite onSubmit={submitAcademy} setWrite={setWrite} setLoading3={setLoading3}/> : null}
          <hr/>
          <Table responsive className="table--bordered">
            <thead>
              <tr>
                {headerResponsive.map(item => (
                  <th key={item.id}>{item.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {academyList.map((item, idx) => (
                <>
                <tr key={uuidv4()}>
                  <td>{item.dataValues.id_academic_article}</td>
                  <td>{item.dataValues.title}</td>
                  <td>{item.dataValues.writer}</td>
                  <td>{moment(item.dataValues.c_date).format('YYYY-MM-DD / HH:mm:ss')}</td>
                  <td>{item.dataValues.ip}</td>
                  {item.dataValues.academic_file_link || item.dataValues.poster_file_link || item.dataValues.shortcut_file_link || item.dataValues.training_file_link? <td>O</td> : <td>X</td>}
                    <td>
                      {item.dataValues.status === 2 ? <Button onClick={()=> window.alert("삭제된 게시물입니다.")}>이동</Button>
                        : <Button onClick={() => {
                          history.push(`/app/academic/award`)
                        }}>이동</Button>}
                    </td>
                    <td><Button color="warning" onClick={() => {
                      setShowModifyAcademy(show => !show)
                      setAcademyRead(idx)
                      academyOneData(item.dataValues.id_academic_article)
                      setLoading(false)
                      setLoading2(false)
                      setWrite(false)
                  }}>수정</Button></td>
                  <td>
                    <div className='row'>
                        <TextField
                          className='material-form__field'
                          id='status'
                          defaultValue={item.dataValues.status}
                          select
                          onChange={(e) => {
                            setStatusChange(e.target.value)
                          }}
                        >
                          <MenuItem className='material-form__option' value='1'>
                            확인
                          </MenuItem>
                          <MenuItem className='material-form__option' value='2'>
                            삭제
                          </MenuItem>
                        </TextField>
                        &nbsp;&nbsp;
                        <Button
                          color='primary'
                          onClick={() => {
                            if (window.confirm('변경하시겠습니까?')) {
                              setLoading3(false)
                              const values = {
                                id: item.dataValues.id_academic_article,
                                status: statusChange,
                                num: 3,
                              }
                              axios
                                .post(`${process.env.REACT_APP_API_URL}/noticesStatusUpdate`, values)
                                .then((data) => {
                                  return null
                                })
                                .catch((err) => console.log(err))
                              axios.get(`${process.env.REACT_APP_API_URL}/academicList?selecNum=0&pageNum=${pageNum}`)
                                .then(data => {
                                  setAcademyList(data.data.data2)
                                  // console.log(data.data.data2);
                                  setTotalCount(data.data.count)
                                  setLoading3(true)
                                })
                                .catch(err => console.log(err))
                              window.alert("변경되었습니다.")
                            }
                          }}
                        >
                          확인
                        </Button>
                      </div>
                </td>
                </tr>
                  <td colSpan='9'>
                      {showModifyAcademy && loading && loading2 ? (
                      idx === academyRead ? (
                        <ModifyAcademicWrite
                          onSubmit={modifysubmitAcademy}
                          setWrite={setWrite}
                          academyReadVal={academyReadVal}
                          academyContentRead={academyContentRead}
                          setShowModifyAcademy={setShowModifyAcademy}
                          academyOneData={academyOneData}
                          setLoading3={setLoading3}
                          // setDisableset={setDisableset}
                          // videoReadVal={videoReadVal}
                          // setShowModifyWrite={setShowModifyWrite}
                          // showModifyWrite={showModifyWrite}
                          // setRadioVal={setRadioVal}
                          // radioVal={radioVal}
                          // setShowWrite={setShowWrite}
                          // setVideoList={setVideoList}
                        />
                      ) : null
                    ) : null}
                  </td>
                </>
              ))}
            </tbody>
          </Table>
          <Pagination total={totalCount} setPageNum={setPageNum} />
        </CardBody>
      </Card>
    </Col>
  );
};


export default AnimatedLineFormWithLabels;
