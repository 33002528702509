/* eslint-disable */
import React, {useEffect, useState} from 'react';
import {
  Table,Button,
} from 'reactstrap';
// import { Form } from 'react-bootstrap';
import DetailData from './DetailData';
import { v4 as uuidv4 } from 'uuid';
import Pagination from './Pagination'
import axios from "axios"
import { useHistory } from "react-router-dom";


const { tableHeaderResponsiveData, tableRowsData } = DetailData();

const DetailTable = ({memberList, total, handleDetailLow, eventId, onOff}) => {
  let history = useHistory();
  const [pageNum, setPageNum] = useState(1)
  const [reRender, setReRender] = useState(false)

  const handleStatus = (status, id) => {
    axios.get(`${process.env.REACT_APP_API_URL}/confirmpreadd?status=${status}&id=${id}`)
      .then(res => setReRender(pre => !pre))
      .then(() => window.alert(`'${status}' 상태로 변경되었습니다.`))
      .catch(err => console.log(err))
  }

  useEffect(() => [
    handleDetailLow(eventId, pageNum)
  ], [pageNum, reRender])

  return (
          <>
          <div className="card__title">
            <h1>신청내역</h1>
          </div>
          <Table responsive className="table--bordered">
            <thead>
              <tr>
                {tableHeaderResponsiveData.map(item => (
                  <th key={item.id}>{item.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {memberList.length === 0 ? (
                <td colspan="8" style={{textAlign:"center"}}>"신청한 내역이 없습니다"</td>
              )  
              : memberList.map(item => (
                <tr key={uuidv4()}>
                  <td>{item.applied_date}</td>
                  <td>{item.payment_sender}</td>
                  <td>{item.member_type}</td>
                  <td>{item.license_number}</td>
                  <td>{item.money}</td>
                  <td>{item.status}</td>
                  <td>
                    <Button onClick={() => history.push(`/admin/eventdetail/${item.id_event_signup}`)}>상세정보</Button>
                  </td>
                  <td>
                  {(item.status === "결제완료" || item.status === "결제취소" ) ? null : 
                  <>
                    <Button color="primary" onClick={() => handleStatus("결제완료", item.id_event_signup)}>완료</Button>
                    <Button color="danger" onClick={() => handleStatus("결제취소", item.id_event_signup)}>취소</Button>
                  </>
                  }
                  </td>
                </tr>
              ))}
       
            </tbody>
          </Table>
          <Pagination total={total} setPageNum={setPageNum} onOff={onOff}/>
        </>
  );
};

export default DetailTable;
