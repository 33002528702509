import React from "react";
import styled, { css } from "styled-components";
import { mobile } from "../../../lib/viewport";

const BannerWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  position: relative;

  background-color: white;

  & > div:nth-child(1) {
    display: flex;
    justify-content: center;
    align-items: center;

    flex-direction: column;

    margin: 50px 0px 70px 0px;
    z-index: 1;
  }
`;

const BannerTitle = styled.div`
  font-size: 46px;
  font-weight: 200;

  line-height: 1.4em;

  ${mobile(css`
    font-size: 28px;
  `)}
`;
const BannerSubTitle = styled.p`
  font-size: 14px;
  font-weight: 200;
`;

const BannerBackground = styled.img`
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;

  width: 100%;
  height: 100%;

  object-fit: cover;
`;
function Banner(props) {
  return (
    <BannerWrapper>
      <div>
        <BannerTitle>{props.title}</BannerTitle>
        <BannerSubTitle>{props.subTitle}</BannerSubTitle>
      </div>
      <BannerBackground src={"/img/banner/mypage.png"} alt="banner" />
    </BannerWrapper>
  );
}

export default Banner;
