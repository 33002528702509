import axios from 'axios'

export default (async function showResults(values, setRendChange) {
  // console.log({ values })
  let val = {
    title: values.title,
    academicLink: values.academicLink,
    posterLink: values.posterLink,
    shortcutLink: values.shortcutLink,
    trainingLink: values.trainingLink
  }
  // console.log({val});
  const arr = values['input-group']
  if (arr && arr.length > 0) {
    const result = arr.map(num => ({
      "item": values[`item-${num}`],
      "details": values[`details-${num}`],
      "content_id": null,
      "status":1
    }))
    val = {
      title: values.title,
      academicLink: values.academicLink,
      posterLink: values.posterLink,
      shortcutLink: values.shortcutLink,
      trainingLink: values.trainingLink,
      result
    }
    // console.log({result});
  }


  // console.log({ val });
  axios.post(`${process.env.REACT_APP_API_URL}/academic`, val)
    .then(data => {
      return data
    }).then((data2) => {
      val.content_id = data2.data.id_academic_article
      if (val.result) {
          for (let i = 0; i < val.result.length; i++){
            val.result[i].content_id = data2.data.id_academic_article
        }
        axios.post(`${process.env.REACT_APP_API_URL}/academicContent`, val)
      }
      return data2
    })
    .then(data3 => {
      if (values.academicFile) {
        let formData = new FormData()
        formData.append('academicFile', values.academicFile.file)
        axios.post(`${process.env.REACT_APP_API_URL}/fileUpload?num=3&no=${data3.data.id_academic_article}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
      }
      if (values.posterFile) {
        let formData = new FormData()
        formData.append('posterFile', values.posterFile.file)
        axios.post(`${process.env.REACT_APP_API_URL}/fileUpload?num=4&no=${data3.data.id_academic_article}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
      }
      if (values.shortcutFile) {
        let formData = new FormData()
        formData.append('shortcutFile', values.shortcutFile.file)
        axios.post(`${process.env.REACT_APP_API_URL}/fileUpload?num=5&no=${data3.data.id_academic_article}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
      }
      if (values.trainingFile) {
        let formData = new FormData()
        formData.append('trainingFile', values.trainingFile.file)
        axios.post(`${process.env.REACT_APP_API_URL}/fileUpload?num=6&no=${data3.data.id_academic_article}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
      }
    })
    .catch(err=>console.log(err))
})
