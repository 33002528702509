import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Field, Form } from 'react-final-form'
import { Button, ButtonToolbar } from 'reactstrap'
import TextField from '@material-ui/core/TextField'
import ModifySummerNote from './ModifySummerNote.jsx'
import ModifyFile from './ModifyFile'
import axios from 'axios'

const renderTextField = ({ input, label, meta: { touched, error }, children, select, multiline, disabled, checkBox, setTitle }) => (
  <TextField
    className='material-form__field'
    label={label}
    type={input.type}
    error={touched && error}
    value={input.value}
    select={select}
    multiline={multiline}
    checkBox={checkBox}
    onChange={(e) => {
      input.onChange(e.target.value)
      setTitle(e.target.value)
    }}
    disabled={disabled}
  >
    {children}
  </TextField>
)

const MemberDetail = (props) => {
  const { t } = useTranslation('common')
  const [contentsVal, setContents] = useState('')
  const [titleVal, setTitle] = useState('')
  const [modifyFile, setModifyFile] = useState('') 
  const [fileDel, setFileDel] = useState(false)
  const [checked, setChecked] = useState(false)
  useEffect(() => {
    if (props.boardReadVal.is_important === '0') {
      setChecked(false)
    } else setChecked(true)
  }, [props.boardReadVal.is_important])
  const selectNumber = `1, ${props.selectNum}`
  if (fileDel) {
      axios.post(`${process.env.REACT_APP_API_URL}/fileDel`, {
        type: 'notices',
        file_link: props.boardReadVal.file_link,
      })
      .then(() => props.setLoading3(true))
      .then(()=>setFileDel(false))
      .then(()=>props.boardRead(props.boardReadVal.id_notice))
  }
  return (
    <>
      <div className='card__title'>
        <h3 style={{ color: 'red' }}>글 수정 </h3>
      </div>
      <Form onSubmit={props.onSubmit}>
        {({ handleSubmit, form }) => (
          <form className='material-form' onSubmit={handleSubmit}>
            <div className='row'>
              <div className='col-4'>
                <InputComponent inTitle={props.boardReadVal.title} writeTitle={setTitle} />
              </div>
              <div className='col-2' style={{ paddingTop: '25px' }}>
                <NoticesCheckBox is_checked={props.boardReadVal.is_important} setChecked={setChecked} />
              </div>
            </div>
            <ModifySummerNote boardReadVal={props.boardReadVal.contents} setContents={setContents} />
            <br />
            <div className='form__form-group'>
              <span className='form__form-group-label'>첨부파일</span>
              <div className='form__form-group-field'>
                <Field name='file' component={ModifyFile} setModifyFile={setModifyFile} fileName={props.boardReadVal.original_fileName} setFileDel={setFileDel} setModifyRend={props.setModifyRend}/>
              </div>
            </div>
            <ButtonToolbar className='form__button-toolbar'>
              <Button
                color='primary'
                type='button'
                onClick={() => {
                  if (window.confirm('글을 수정하시겠습니까?')) {
                    const valueData = {
                      contents: contentsVal === '' ? props.boardReadVal.contents : contentsVal,
                      title: titleVal === '' ? props.boardReadVal.title : titleVal,
                      id_notice: props.boardReadVal.id_notice,
                      is_important: checked,
                      // file_link: props.boardReadVal.file_link,
                      file_link: props.boardReadVal.file_link,
                    }
                    if (!modifyFile) {
                      valueData.file_modify = 'no';
                      axios
                      .post(`${process.env.REACT_APP_API_URL}/noticesModify`, valueData)
                      // .then((data) => console.log(data))
                      .catch((err) => console.log(err))
                    } else {
                      valueData.file_modify = 'yes';
                      let formData = new FormData()
                      valueData.file = modifyFile
                      axios
                      .post(`${process.env.REACT_APP_API_URL}/noticesModify`, valueData)
                        .then((data) => {
                          return data;
                        })
                        .then((data2) => {
                        formData.append('file', valueData.file)
                        axios.post(`${process.env.REACT_APP_API_URL}/fileUpload?num=1&no=${props.boardReadVal.id_notice}`, formData, {
                            headers: {
                              'Content-Type': 'multipart/form-data',
                            },
                          })
                        })
                      .catch((err) => console.log(err))
                    }  
                    window.alert('수정되었습니다.')
                    props.setShowModifyWrite(false)
                    props.setLoading(false)
                    props.setDisableset((e) => !e)
                  }
                }}
              >
                확인
              </Button>
              <Button
                type='button'
                color='danger'
                onClick={() => {
                  props.setShowModifyWrite(false)
                  props.setDisableset((e) => !e)
                }}
              >
                취소
              </Button>
            </ButtonToolbar>
          </form>
        )}
      </Form>
      <hr />
    </>
  )
}

export default MemberDetail

const InputComponent = (props) => {
  return (
    <div className='MuiFormControl-root MuiTextField-root material-form__field'>
      <label
        className='MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled'
        data-shrink='true'
      >
        제목
      </label>
      <div className='MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl'>
        <input
          aria-invalid='false'
          type='text'
          className='MuiInputBase-input MuiInput-input'
          defaultValue={props.inTitle}
          onChange={(e) => {
            props.writeTitle(e.target.value)
          }}
        ></input>
      </div>
    </div>
  )
}

const NoticesCheckBox = (props) => {
  const [test, setTest] = useState(props.is_checked)
  useEffect(() => {
    if (props.is_checked === '0') setTest(false)
    else setTest(true)
  }, [props.is_checked])

  const handleChange = (e) => {
    setTest(e.target.checked)
    props.setChecked(e.target.checked)
    // console.log(e.target.checked)
  }
  return (
    <div>
      <div className='form__form-group form__form-group-field'>
        <div className='form__form-group-input-wrap'>
          <label className='checkbox-btn '>
            <input
              className='checkbox-btn__checkbox'
              type='checkbox'
              id='checkbox_one_button'
              name='checkbox_one_button'
              checked={test}
              onChange={handleChange}
            />
            <span className='checkbox-btn__checkbox-custom'>
              <svg className='mdi-icon ' width='24' height='24' fill='currentColor' viewBox='0 0 24 24'>
                <path d='M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z'></path>
              </svg>
            </span>
            <span className='checkbox-btn__label'>공지</span>
          </label>
        </div>
      </div>
    </div>
  )
}
