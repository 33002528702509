import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card, CardBody, Col, Badge, Table,Button
} from 'reactstrap';
import BasicTableData from './BasicTableData';
import { v4 as uuidv4 } from 'uuid';
import Pagination from './Pagination'
import moment  from 'moment'
import axios from "axios"
import {useHistory} from 'react-router-dom'

const { tableHeaderResponsiveData, tableRowsData} = BasicTableData();

const ResponsiveTable = ({replyData, setListNum, listNum, setPageNum, onOff, setOnoff, setDelRender}) => {
  let status
  const history = useHistory()
  const { t } = useTranslation('common');
  const handleRender = (e) => {
    setOnoff(pre => !pre)
    setListNum(e.target.value)
  }

  const handleLink = (id, type) => {
    axios.get(`${process.env.REACT_APP_API_URL}/boardnum?id=${id}`)
    .then((res) => {
      // console.log(res.data.data)
      history.push(`/app/community/${type === '4' ? "userboard" : "qna"}/${res.data.data.board_num}/${type}/${type === "4" ? replyData.bbs : replyData.qna}`)
    })
    .catch(err => console.log(err))  
  }

  const handleOption = (e) => {
    status = e.target.value
    // console.log(status)
  }

  const handleFetch = (id) => {
    if (window.confirm("변경 하시겠습니까?")){
      axios.get(`${process.env.REACT_APP_API_URL}/replystatuschange?id=${id}&status=${status}`)
       .then(() => setDelRender(pre => !pre))
       .catch(err =>  console.log(err))
     }
   }

  return (
        <>
          <div className="card__title">
            <h5 className="bold-text">검색결과</h5>
            {/* <h5 className="subhead">?건의 댓글이 검색되었습니다.</h5> */}
            <select value={listNum} onChange={handleRender}>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          <Table responsive className="table--bordered">
            <thead>
              <tr>
                {tableHeaderResponsiveData.map(item => (
                  <th key={item.id}>{item.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {replyData.data.map(item => (
                <tr key={uuidv4()}>
                  <td>{item.id_reply}</td>
                  <td>{item.status}</td>
                  <td>{item.category === 0 ? "회원게시판" : "질의응답"}</td>
                  <td width="400" style={{wordBreak: "break-all"}}>{item.contents}</td>
                  <td>{item.userid}</td>
                  <td width="200">{item.c_data.split("T")[0]} {item.c_data.split("T")[1].split(".")[0]}</td>
                  <td>{item.ip}</td>
                  {/* //todo : 글이동 링크 걸기 replyData.bbs / replyData.qna */}
                  <td>
                    {item.status === "삭제" ? <Button onClick={()=> window.alert("삭제된 댓글입니다.")}>이동</Button> 
                    : <Button onClick={() => handleLink(item.bbs_id, item.type)}> 이동 </Button>}
                    {/* <a href={`${process.env.REACT_APP_URL}/app/community/${item.type === "4" ? "userboard" : "qna"}/${item.board_num}/${item.type}/${item.type === "4" ? replyData.bbs : replyData.qna}`}>이동</a> */}
                  </td>
                  <td>
                  <select onChange={handleOption}>
                        <option value="정상" selected={item.status === "정상" ? "selected" : null}>정상</option>
                        <option value="삭제" selected={item.status === "삭제" ? "selected" : null}>삭제</option>
                      </select>
                      <Button color="danger" onClick={() => handleFetch(item.id_reply)} >확인</Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {replyData.total === 0 ? "검색 결과가 없습니다." 
          : <Pagination total={replyData.total} listNum={listNum} setPageNum={setPageNum} onOff={onOff}/>}
       </>
  );
};

export default ResponsiveTable;
