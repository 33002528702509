import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card, CardBody, Col, Badge, Table,Button,
} from 'reactstrap';
import PaymentsHistoryData from './BoardHistroyData';
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import TabPagination from './tabPagination';
import moment from "moment"
import {useHistory} from 'react-router-dom'

const { tableHeaderResponsiveData, tableRowsData } = PaymentsHistoryData();

const ResponsiveTable = ({userid}) => {
  const history =  useHistory()
  const { t } = useTranslation('common');
  let status
  const [loading, setLoading] = useState(false)
  const [waiting, setWaiting] = useState([])
  const [pageNum, setPageNum] = useState(1)
  const [total, setTotal] = useState(0)
  const [onOff, setOnOff] = useState(false)
  const [bbs, setBbs] = useState(0)
  const [qna, setQna] = useState(0)

  const handleOption = (e) => {
    status = e.target.value
    // console.log(status)
  }

  const handleFetch = (id) => {
    if (window.confirm("변경 하시겠습니까?")){
      axios.get(`${process.env.REACT_APP_API_URL}/bbsstatuschange?id=${id}&status=${status}`)
       .then(() => setOnOff(pre => !pre))
       .catch(err =>  console.log(err))
     }
   }

  const handleLink = (num, type) => {
    history.push(`/app/community/${type === "4" ? "userboard" : "qna"}/${num}/${type}/${type === "4" ? bbs : qna}`)
  }

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/bbclistperson?pagenum=${pageNum}&userid=${userid}`)
      .then((res) => {
        // console.log(res.data)
        setBbs(res.data.bbs)
        setQna(res.data.qna)
        setTotal(res.data.count)
        setWaiting(res.data.data)
      })
      .then(() => setLoading(true))
      .catch(err => console.log(err))
  }, [pageNum, onOff])

  if (!loading) return <div></div>;

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <Table responsive className="table--bordered">
            <thead>
              <tr>
                {tableHeaderResponsiveData.map(item => (
                  <th key={item.id}>{item.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {waiting.map(item => (
                <tr key={uuidv4()}>
                  <td>{item.id_notice}</td>
                  <td>{item.status === "1" ? "정상" : "삭제"}</td>
                  <td>{item.type === "5" ? "질의응답" : "회원게시판"}</td>
                  <td>{item.title}</td>
                  <td>{item.count}</td>
                  <td>{item.replyNum}</td> 
                  <td>{moment(item.c_date).format("YYYY-MM-DD / hh:mm:ss")}</td>
                  <td>{item.ip}</td>
                  <td>
                    {/* <a href={`${process.env.REACT_APP_URL}/app/community/${item.type === "4" ? "userboard" : "qna"}/${item.board_num}/${item.type}/${item.type === "4" ? bbs : qna}`}> */}
                      <Button onClick={() => handleLink(item.board_num, item.type)}>이동</Button>
                    {/* </a> */}
                  </td>
                  <td>
                    <select onChange={handleOption} style={{height: "50%"}}>
                        <option value="정상" selected={item.status === "1" ? "selected" : null}>정상</option>
                        <option value="삭제" selected={item.status === "0" ? "selected" : null}>삭제</option>
                    </select>
                    <Button color="danger" onClick={() => handleFetch(item.id_notice)}>삭제</Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {total === 0 ? "해당하는 정보가 없습니다." : <TabPagination setPageNum={setPageNum} total={total}/>}
        </CardBody>
      </Card>
    </Col>
  );
};

export default ResponsiveTable;