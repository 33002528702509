import React, {useState, useEffect} from "react"
import styled from "styled-components";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { v4 as uuidv4 } from 'uuid';

export default function Pagination({total, listNum, setPageNum, onOff}) {
    // listNum string type
    const [selectDivPage, setSelectDivPage] = useState(0);
    const [selectPage, setSelectPage] = useState(1);
    const limitPage = 5;
    const totalPage = Math.ceil(total / listNum)
    const dividPage = Math.ceil(totalPage / limitPage)
    const firstPage = limitPage * (selectDivPage + 1) - (limitPage - 1);
    let lastPage = limitPage * (selectDivPage + 1);
    if (totalPage < lastPage){
        lastPage = totalPage;
    }
   
    const handleLeftIdx = () => {
        if (selectPage === 1) return;
        if (selectPage === firstPage) {
          setSelectDivPage(selectDivPage - 1);
        }
        setSelectPage((pre) => pre - 1)
    }
    
    const handleRightIdx = () => {
        if (selectPage === totalPage) return;
        if (selectPage === lastPage && selectPage < totalPage && selectDivPage < dividPage) {
          setSelectDivPage(selectDivPage + 1);
        }
        setSelectPage((pre) => pre + 1)
    }

    const handleClick = (idx) => {
        setSelectPage(idx + 1 + selectDivPage * limitPage)
    }

    useEffect(() => {
        setSelectPage(1)
    }, [onOff])

    useEffect(() => {
        setPageNum(selectPage)
    },[setPageNum, selectPage])


    return (
        <Container>
            <FaAngleLeft style={{fontSize:"1.2rem", cursor:"pointer"}} onClick={handleLeftIdx}/>
                {
                    Array(lastPage % limitPage === 0 ? limitPage : lastPage % limitPage)
                    .fill(0)
                    .map((el, idx) => {
                        return (
                            <PageNum key={uuidv4()} isActive={idx + 1 + selectDivPage * limitPage === selectPage} onClick={() => handleClick(idx)}>
                                {idx + 1 + selectDivPage * limitPage}
                            </PageNum>
                        )
                    })
                }
            <FaAngleRight style={{fontSize:"1.2rem", cursor:"pointer"}} onClick={handleRightIdx}/>
        </Container>
    );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: gray;
  gap: 0.6rem;
`;

const PageNum = styled.div`
  width: 2rem;
  height: 2rem;
  text-align: center;
  line-height: 2rem;
  font-weight: bold;
  color: ${({ isActive }) => (isActive ? "white" : "#222222")};
  background-color: ${({ isActive }) => (isActive ? "gray" : "#efefef")};
  cursor: pointer;
`;