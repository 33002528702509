/* eslint-disable */
import React, {useState} from "react"
import styled from "styled-components"
import { v4 as uuidv4 } from 'uuid';
import axios from "axios"


const ManagementImage = () => {
    const [fileList, setFileList] = useState([])
    const [fetchFile, setFetchFile] = useState([])
    const [onOff, setOnOFf] = useState(false)

    const [botFileList, setBotFileList] = useState([])
    const [botFetchFile, setBotFetchFile] = useState([])
    const [botOnOff, setBotOnOFf] = useState(false)
    

    const handleImage = (e, type) => {
        if (type === "top"){
            setFetchFile(pre => [...pre, ...Array.from(e.target.files)])
            const list = e.target.files;
            const nowList = [...fileList]
    
            for(let i = 0; i < list.length; i++){
                const nowImageUrl = URL.createObjectURL(list[i]);
                nowList.push(nowImageUrl)
            }
            setFileList(nowList)
            setOnOFf(true)
        } else {
            setBotFetchFile(pre => [...pre, ...Array.from(e.target.files)])
            const list = e.target.files;
            const nowList = [...botFileList]
    
            for(let i = 0; i < list.length; i++){
                const nowImageUrl = URL.createObjectURL(list[i]);
                nowList.push(nowImageUrl)
            }
            setBotFileList(nowList)
            setBotOnOFf(true)
        }
    }

    const handleFetch = (type) => {
        if(window.confirm("변경하시겠습니까?")){
            const formData = new FormData()
    
            type === "top" ? fetchFile.map(el => formData.append("main", el)) : botFetchFile.map(el => formData.append("main", el)) 
    
            axios.post(`${process.env.REACT_APP_API_URL}/uploadmain?type=${type}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                 },
            })
             .then(res => console.log(res))
             .catch(err => console.log(err))
        }
    }

    const handleDel = (i, type) => {
        if (type === "top") {
            const dummy = [...fetchFile]
            dummy.splice(i, 1)
            setFetchFile(dummy)
    
            const showDummy = [...fileList]
            showDummy.splice(i, 1) 
            setFileList(showDummy)
    
            if (!fetchFile || !fileList) {
                setOnOFf(false)
            }
        } else {
            const dummy = [...botFetchFile]
            dummy.splice(i, 1)
            setBotFetchFile(dummy)
    
            const showDummy = [...botFileList]
            showDummy.splice(i, 1) 
            setBotFileList(showDummy)
    
            if (!botFetchFile || !botFileList) {
                setBotOnOFf(false)
            }
        }
    }

    return (
    <Container>
        <TopBox>
            <Title>Main 상단 이미지 등록</Title>
            <LabelWrapper>
                <LabelBox for="filetop">+</LabelBox>
                <LabelInput id="filetop" multiple type="file" accept="image/*" onChange={(e) => handleImage(e, "top")}/>
            </LabelWrapper>
            {fileList.map((el, i) => (
                <ImgBox key={uuidv4()}>
                    <Dot onClick={()=> handleDel(i, "top")}>삭제</Dot>
                    <img src={el} style={{width: "25vh", height: "20vh", objectFit: "contain"}}/>
                </ImgBox>
                )
            )}
           <Btn onClick={() => handleFetch("top")}>변경</Btn>
            
        </TopBox>

        <BottomBox>
        <Title>Main 하단 이미지 등록</Title>
            <LabelWrapper>
                <LabelBox for="filebot">+</LabelBox>
                <LabelInput id="filebot" multiple type="file" accept="image/*" onChange={(e) => handleImage(e, "bottom")}/>
            </LabelWrapper>
            {botFileList.map((el, i) => (
                <ImgBox key={uuidv4()}>
                    <Dot onClick={()=> handleDel(i, "bottom")}>삭제</Dot>
                    <img src={el} style={{width: "25vh", height: "20vh", objectFit: "contain"}}/>
                </ImgBox>
                )
            )}
            <Btn onClick={() => handleFetch("bottom")}>변경</Btn>
        </BottomBox>
    </Container>
    )
}

export default ManagementImage

const Container = styled.div`
    display: flex;
    justify-content: space-around;
`

const TopBox = styled.div`
`
const BottomBox = styled.div`
`

const LabelWrapper = styled.div`
    margin-top: 20px;
`

const LabelBox = styled.label`
    display: inline-block; 
    padding: .5em .75em; 
    color: #999; 
    font-size: inherit; 
    line-height: normal; 
    vertical-align: middle; 
    background-color: #fdfdfd; 
    cursor: pointer; 
    border: 1px solid #ebebeb; 
    border-bottom-color: #e2e2e2; 
    border-radius: .25em;
`

const LabelInput = styled.input`
    position: absolute; 
    width: 1px; 
    height: 1px; 
    padding: 0; 
    margin: -1px; 
    overflow: hidden; 
    clip:rect(0,0,0,0); 
    border: 0;
`
const Btn = styled.button`
    margin: 20px 0;
    background-color: #70bbfd;
    border: none;
    border-color: #70bbfd;
    border-radius: 5px;
    padding: 10px 25px;
    margin-bottom: 20px;
    transition: all 0.4s;
    font-size: 14px;
    color: #efefef;
    :hover {
        background-color: #47a1f0;
    }
`

const ImgBox = styled.div`
    width: 30vh;
    margin-bottom: 20px;
    position: relative;
    background-color: #dfe1e3;
    padding: 5px;
    text-align: center;
`

const Dot = styled.button`
    margin: 20px 0;
    background-color: #ff4861;
    border: none;
    border-color: #ff4861;
    border-radius: 5px;
    padding: 10px 25px;
    margin-bottom: 20px;
    transition: all 0.4s;
    font-size: 14px;
    color: #efefef;
    :hover {
        background-color: #ff0023;
    }
`

const Title = styled.h3`
    color: #646777;
`