import React, { useState, useEffect } from "react"
import styled, {css} from "styled-components"
import {mobile} from "../Service/lib/viewport"
import checkIcon from "../../shared/img/check-selec.png"
import axios from "axios"
import { useHistory } from "react-router-dom";

const Wrapper = styled.div`
    margin-top: 50px;

    & > div:nth-child(1){
        margin-bottom: 64px;
    }
`

const InputWrapper = styled.div`
    display: flex;
    justify-content: flex-end;

    margin: 0 auto 10px auto;

    & > span{
        width: fit-content;
        margin-right: 24px;

        display:flex;
        align-items:center;

        font-weight: bold;
        font-size: 15px;
    }
    ${mobile(css`
        justify-content: center;
        & > span{
            display: none;
        }
    `)}
`

const InputText = styled.input`
    width: 350px;
    height: 50px;

    ${({ borderRadius }) => {
      return borderRadius ? `border-radius: ${borderRadius};` : 'border-radius: 6px;';
    }}
    border: solid 0.7px #e0e1e5;
    background-color: #fff;

    padding: 0px 20px;
    font-size: 15px;

    outline:none;
`


const InputSelectWrapper = styled.div`
    min-width: 350px;
    height: 50px;

    border-radius: 6px;

    border: solid 0.7px #e0e1e5;
    background-color: #fff;

    font-size: 15px;

    outline:none;

    display: flex;
`

const InputSelectBox = styled.div`
    width: 50%;
    display: flex;
    justify-content:center;
    align-items:center;

    cursor:pointer;

    & > img{
        margin-right: 10px;
    }
    color: #8c919b;
    ${({ selected }) => {
      return selected ? `border: 1px solid black; color: black; font-weight: bold;` : '';
    }}
    ${({ borderRadius }) => {
      return borderRadius ? `border-radius: ${borderRadius};` : 'border-radius: 6px;';
    }}

`

const InputDoubleWrapper = styled.div`
    width: 350px;
    height: 100%;
`

const InputTripleWrapper = styled.div`
    width: 350px;
    height: 100%;
    & > input:nth-child(1){
        border-radius: 6px 6px 0px 0px;
    }
    & > input:nth-child(2){
        border-radius: 0px;
    }
    & > input:nth-child(3){
        border-radius: 0px 0px 6px 6px;
    }
`

const Form = styled.form`
    width: fit-content;

    margin: 0 auto 300px auto;
    padding-right: 2em;
    ${mobile(css`
        padding-right: 0;
    `)}
    
`

const InputAddressTextWrapper = styled.div`
    position: relative;
    width:100%;
    height:100%;
`

const AddressFindButton = styled.div`
    width: 44px;
    height: 26px;
    border-radius: 8px;
    border: solid 0.5px #000;
    background-color: #474747;
    color: white;
    text-align: center;
    padding: 3px;
    font-size: 12px;

    cursor: pointer;

    position: absolute;
    right: 12px;
    top: 12px;
`

const OverlapFindButton = styled.div`
    width: 60px;
    height: 26px;
    border-radius: 8px;
    border: solid 0.5px #000;
    background-color: #474747;
    color: white;
    text-align: center;
    padding: 3px;
    font-size: 12px;

    cursor: pointer;

    position: absolute;
    right: 12px;
    top: 12px;
`
const MoneyWrapper = styled.div`
    border-top: 1px solid black;
    margin-top: 50px;
    margin-bottom: 40px;
`
const MoneyWrapperItem = styled.div`
    width:100%;
    height: 48px;

    background-color: #f2f3f5;
    display:flex;
    justify-content:center;
    align-items:center;

    border-bottom: 0.7px solid #cdd0d8;

    & > label{
        width: 33%;
        display:flex;
        justify-content:center;
        align-items:center;
        margin: 0px;
        &>input{
            margin-right: 8px;
        }
    }

`

const MoneyItemBotton = styled.div`
    width:100%;
    height: 48px;

    display:flex;
    justify-content:space-between;
    align-items:center;
    
    padding: 0px 20px;

    background-color:#ffffff;

    & > div:nth-child(2){
        font-weight: bold;
        font-size: 22px;
    }
`

const InputButtonWrapper= styled.div`
    width:270px;
    height:100%;
    position:relative;

    display:flex;
    justify-content:space-between;

`

const MoneyButton = styled.div`
    width: 92px;
    height: 33px;

    border-radius: 10px;
    border: solid 0.7px #cdd0d8;
    background-color: #e7e9ec;

    margin: 0px 2px;
    
    display:flex;
    justify-content:center;
    align-items:center;

    ${({ selected }) => {
      return selected ? `border: 1px solid black; color: black; background-color: white;` : '';
    }}
`

const MoneyInput = styled.input`
    width: 188px;
    height: 33px;
    border-radius: 10px;
    border: solid 0.5px #cdd0d8;
    background-color: #fff;

    text-align:center;
`

const InputCheckbox = styled.label`
    width: 80px;
    margin:0px;
    display:flex;
    justify-content:flex-end;
    align-items:center;
    & > input{
        margin-right: 6px;
    }
`

function EventForm ({info, amount}) {
    let history = useHistory();
    let status

    const handleOption = (e) => {
        status = e.target.value
    }

    const handleFetch = () => {
        if (window.confirm("변경 하시겠습니까?")){
            axios.get(`${process.env.REACT_APP_API_URL}/confirmpreadd?status=${status}&id=${info.id_event_signup}`)
                .then(res => console.log(res))
                .catch(err => console.log(err))
        }
    }



    return (
        <>
            {/* <Banner title="schedule"></Banner> */}
            <Wrapper>
                <Form>
                    <InputWrapper>
                        <span>성명(국/영)</span>
                        <InputTripleWrapper>
                            <InputText name="username" readOnly type="text" placeholder="국문 성함" value={info.name}/>
                            <InputText name="engFirstName" readOnly value={info.first_name} type="text" placeholder="영문 이름 (First name)" />
                            <InputText name="engLastName" readOnly value={info.last_name} type="text" placeholder="영문 성 (Last name)" />
                        </InputTripleWrapper>
                    </InputWrapper>
                    
                    <InputWrapper>
                        <span>면허번호</span>
                        <InputButtonWrapper>
                            <OverlapFindButton>중복검사</OverlapFindButton>
                            <InputText readOnly name="license_number" type="text" value={info.license_number}/>
                        </InputButtonWrapper>
                        <InputCheckbox>
                            <input checked={info.license_number === "면허 없음" ? "checked" : null} disabled name="license_number" type="checkbox" />
                            면허 없음
                        </InputCheckbox>
                    </InputWrapper>
                    <InputWrapper>
                        <span>휴대폰</span>
                        <InputText name="phoneNumber" readOnly value={info.cellphone} type="text" placeholder="‘-’ 제외하고 숫자만 입력해주세요" />
                    </InputWrapper>
                    <InputWrapper>
                        <span>이메일</span>
                        <InputText name="email" readOnly value={info.email} type="text" placeholder="이메일을 입력해주세요" />
                    </InputWrapper>
                    <InputWrapper>
                        <span>소속(국문)</span>
                        <InputButtonWrapper>
                            <InputText value={info.organization} readOnly name="division" type="text" />
                        </InputButtonWrapper>
                        <InputCheckbox>
                            <input checked={info.organization === "무직(휴직)" ? "checked" : null} disabled type="checkbox" name="division" />
                            무직(휴직)
                        </InputCheckbox>
                    </InputWrapper>
                    <InputWrapper>
                        <span>근무형태</span>
                        <InputDoubleWrapper>
                            <InputSelectWrapper>
                                <InputSelectBox selected={info.work_type_id === 1 ? true : null}>
                                    <img src={checkIcon} alt="check_icon" />
                                    <span>교원(대학병원전문의)</span>
                                </InputSelectBox>
                                <InputSelectBox selected={info.work_type_id === 2 ? true : null}>
                                    <img src={checkIcon} alt="check_icon" />
                                    <span>봉직의</span>
                                </InputSelectBox>
                            </InputSelectWrapper>
                            <InputSelectWrapper>
                                <InputSelectBox selected={info.work_type_id === 3 ? true : null} >
                                    <img src={checkIcon} alt="check_icon" />
                                    <span>개원의</span>
                                </InputSelectBox>
                                <InputSelectBox selected={info.work_type_id === 4 ? true : null}>
                                    <img src={checkIcon} alt="check_icon" />
                                    <span>전임의</span>
                                </InputSelectBox>
                            </InputSelectWrapper>
                        </InputDoubleWrapper>
                    </InputWrapper>
                    <InputWrapper>
                        <span>주소</span>
                        <InputDoubleWrapper>
                            <InputAddressTextWrapper>
                                <AddressFindButton>찾기</AddressFindButton>
                                <InputText name="address" readOnly value={info.address} type="text" placeholder="주소 (우편번호)" borderRadius="6px 6px 0 0" />
                            </InputAddressTextWrapper>
                            <InputText type="text" readOnly value={info.address_detail} placeholder="상세주소 입력" borderRadius="0 0 6px 6px" />
                        </InputDoubleWrapper>
                    </InputWrapper>
                    <MoneyWrapper>
                        <MoneyWrapperItem>
                                    <label>
                                        <input type="radio" name="userType" disabled checked />
                                        {info.member_type}
                                    </label>
                        </MoneyWrapperItem>
                        <MoneyWrapperItem>
                            <MoneyButton selected={info.payment_method_id === 1 ? true : null}>무통장 입금</MoneyButton>
                            <MoneyButton selected={info.payment_method_id === 2 ? true : null}>신용카드</MoneyButton>
                        </MoneyWrapperItem>
                        <MoneyWrapperItem>
                            <MoneyInput type="text" disabled value={info.payment_sender} placeholder="송금인 입력" name="payment_sender" />
                        </MoneyWrapperItem>
                        <MoneyItemBotton>
                            <div>총 금액</div>
                            <div>{info.money}</div>
                        </MoneyItemBotton>
                    </MoneyWrapper>
                     <SelectBox>
                        <select value={status} onChange={handleOption}>
                            <option value="결제완료" selected={info.status === "결제완료" ? "selected" : null}>결제완료</option>
                            <option value="결제대기" selected={info.status === "결제대기" ? "selected" : null}>결제대기</option>
                            <option value="결제취소" selected={info.status === "결제취소" ? "selected" : null}>결제취소</option>
                        </select>
                        <Button onClick={handleFetch}>확인</Button>
                        <Cancel onClick={() => history.push('/admin/events')}>취소</Cancel>
                    </SelectBox>
                </Form> 
            </Wrapper>
        </>
    )
}

export default EventForm


const SelectBox = styled.div`
    text-align: end;
`

const Button = styled.button`
    padding: 10px 20px;
    margin-left: 10px;
    background-color: #222;
    color: #efefef;
`

const Cancel = styled(Button)`
    background-color: #efefef;
    color: #222;
`