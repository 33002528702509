import {GET_ADMIN} from "../actions/adminActions"

export default function adminReducer(state = {admin: false}, action){
    switch (action.type) {
        case GET_ADMIN: 
            return {...state, admin: true} 

        default:
            return state;
    }
}