import React from 'react';
import { Container, Row } from 'reactstrap';
import JustifyTabsBorderedTop from './components/JustifyTabsBorderedTop';


const Replies = () => {
  return (
    <Container>
      <Row>
        <JustifyTabsBorderedTop/>
      </Row>
      
    </Container>
  );
};

export default Replies;
