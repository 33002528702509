/* eslint-disable */
import React, { useState } from "react";
import styled, { css } from "styled-components";
import Banner from "./components/Banner";
import { mobile } from "../../lib/viewport";
import ReactSummernote from "react-summernote";
import "react-summernote/dist/react-summernote.css"; // import styles
import "react-summernote/lang/summernote-ko-KR"; // you can import any other locale
import axios from "axios";
import "./write.scss";
import Alert from "../../components/Alert";
import { useHistory, useParams } from "react-router-dom";

// Import bootstrap(v3 or v4) dependencies
import "bootstrap/js/src/modal";
import "bootstrap/js/src/dropdown";
import "bootstrap/js/src/tooltip";
import "bootstrap/dist/css/bootstrap.css";
import { useEffect } from "react";

const UpdateWrapper = styled.div`
  max-width: 1100px;

  margin: 50px auto 100px auto;

  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;

  ${mobile(css`
    margin: 20px auto 100px auto;
    max-width: 100%;
    width: calc(100% - 20px);
  `)}
`;

const UpdateButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const UpdateButton = styled.div`
  width: 190px;
  height: 51px;
  margin: 0px 4px;
  border-radius: 5px;
  border: solid 0.5px #000;
  background-color: #474747;
  font-size: 14px;
  color: white;
  ${({ reversal }) => {
    return reversal ? `color: #000; background-color: white;` : "";
  }}
  display:flex;
  justify-content: center;
  align-items: center;

  margin-top: 10px;

  cursor: pointer;
`;

const UpdateTitle = styled.div`
  width: 700px;
  height: 40px;

  background-color: white;

  padding: 4px 10px;
  border-top: solid 1px #cdd0d8;
  border-left: solid 1px #cdd0d8;
  border-right: solid 1px #cdd0d8;

  ${mobile(css`
    width: 100%;
  `)}
`;
const TitleInput = styled.input`
  text-decoration: none;
  outline: none;
  border: 0px;
  width: 100%;
  height: 100%;

  font-size: 13px;
`;

const FileWrapper = styled.div`
  width: 700px;
  height: 40px;
  background-color: #eaedf1;

  padding: 4px 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  ${mobile(css`
    width: 100%;
  `)}
`;

const FileInput = styled.input`
  width: 1px;
  height: 1px;
  visibility: visible;
  opacity: 0;
  position: absolute;
`;

const FileText = styled.div`
  border: solid 0.5px #cdd0d8;
  background-color: #fff;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 90px;
  height: 26px;

  margin-left: 14px;
  margin-right: 12px;

  cursor: pointer;
`;
const FileLabel = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
`;
function Update() {
  const [fileName, setFileName] = useState("");
  const [text, setText] = useState("");
  const [title, setTitle] = useState("");
  const [fileData, setFileData] = useState();
  const uploadFile = React.useRef(null);
  const [file, setFile] = useState(null);
  const [errorText, setErrorText] = useState("");
  const [confirm, setConfirm] = useState(false);
  const history = useHistory();

  const params = useParams();

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/boardNextPrev?boardNum=${Number(
          params.number
        )}&type=${params.type}`
      )
      .then((data) => {
        setTitle(data.data.data.title);
        setText(data.data.data.contents);
        // setFileData
      });
  }, []);

  function setupErrorText() {
    setErrorText("");
    if (confirm) {
      history.push(
        `/app/community/userboard/${params.number}/${params.type}/${params.length}`
      );
    }
  }
  function UpdateBoard() {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/boardNextPrev?boardNum=${Number(
          params.number
        )}&type=${params.type}`
      )
      .then((data) => {
        axios
          .post(`${process.env.REACT_APP_API_URL}/boardupdate`, {
            title: title,
            contents: text,
            id_notice: data.data.data.id_notice,
          })
          .then((data2) => {
            if (data2.data === "ok") {
              setErrorText("성공적으로 게시물을 수정하였습니다.");
              setConfirm(true);
            }
          });
      });
  }

  const onImageUpload = (fileList) => {
    const formData = new FormData();
    formData.append("sm_img", fileList[0]);

    axios
      .post(`${process.env.REACT_APP_API_URL}/uploadimage`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          ReactSummernote.insertImage(
            `${process.env.REACT_APP_API_URL}/${res.data.path}`
          );
        };
        reader.readAsDataURL(fileList[0]);
      })
      .catch((err) => console.log(err.response));
  };

  return (
    <>
      <Alert
        openState={errorText === "" ? false : true}
        text={errorText}
        setupErrorText={() => setupErrorText()}
      />
      <Banner title="userboard"></Banner>
      <UpdateWrapper>
        <UpdateTitle>
          <TitleInput
            type="text"
            placeholder="글 제목을 입력해주세요."
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            value={title}
          />
        </UpdateTitle>
        <ReactSummernote
          value={text}
          options={{
            lang: "ko-KR",
            height: 350,
            dialogsInBody: true,
            toolbar: [
              ["style", ["style"]],
              ["font", ["bold", "underline", "clear"]],
              // ['fontname', ['fontname']],
              ["para", ["ul", "ol", "paragraph"]],
              ["table", ["table"]],
              ["insert", ["link", "picture"]],
            ],
          }}
          onChange={(e) => {
            setText(e);
          }}
          onImageUpload={onImageUpload}
        />
        <UpdateButtonWrapper>
          <UpdateButton onClick={() => UpdateBoard()}>확인</UpdateButton>
          {params.type === "4" ? (
            <UpdateButton
              reversal
              onClick={() =>
                history.push(
                  `/app/community/userboard/${params.number}/4/${params.length}`
                )
              }
            >
              취소
            </UpdateButton>
          ) : (
            <UpdateButton
              reversal
              onClick={() =>
                history.push(
                  `/app/community/qna/${params.number}/5/${params.length}`
                )
              }
            >
              취소
            </UpdateButton>
          )}
        </UpdateButtonWrapper>
      </UpdateWrapper>
    </>
  );
}

export default Update;
