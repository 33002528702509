import React from 'react';
// import { useTranslation } from 'react-i18next';
import {
  Card, CardBody, Col,
} from 'reactstrap';
import Tabs from './Tabs';

const JustifyTabsBorderedTop = ({preRegi, setAmount, amount, setPreStart, setPreEnd, preStart, preEnd, modify, setModify}) => {
  // const { t } = useTranslation('common');

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="tabs tabs--justify tabs--bordered-top">
            <Tabs preRegi={preRegi} setAmount={setAmount} amount={amount} setPreStart={setPreStart} preStart={preStart} setPreEnd={setPreEnd} preEnd={preEnd} modify={modify} setModify={setModify}/>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default JustifyTabsBorderedTop;
