import moment from 'moment';

const year = moment().format('YYYY');
const mounth = moment().format('MM') - 1;
// const prevMounth = mounth - 1;
// const nextMounth = mounth + 1;

export default [
  {
    id: 1,
    start: new Date(year, mounth, 9),
    end: new Date(year, mounth, 9),
    type: 'regularEvent',
    title: '제 13회 누가통증연구회 워크숍 및 세미나',
  },
  {
    id: 2,
    start: new Date(year, mounth, 15),
    end: new Date(year, mounth, 15),
    type: "trainingCourse",
    title: '제 13회 누가통증연구회 워크숍 및 세미나',
  },

];
