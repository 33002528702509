const BasicTableData = () => {
  // const header = [
  //   { id: 1, title: '#' },
  //   { id: 2, title: 'First Name' },
  //   { id: 3, title: 'Last Name' },
  //   { id: 4, title: 'Username' },
  //   { id: 5, title: 'Status' },
  // ];

  const headerResponsive = [
    { id: 1, title: '시간' },
    { id: 2, title: '입금자' },
    { id: 3, title: '결제 내용' },
    { id: 4, title: '금액' },
    { id: 5, title: '현황' },
    { id: 6, title: '처리' },
  ];
 
  const rows = [];
  for (let i = 0; i < 10; i++) {
      rows.push({
        time: '2000-01-01 / 21:30:34',
        payer: `홍길동${i}`,
        paymentDetails: '2022.02 / 월회비',
        amount: parseInt(Math.random() * 1000),
        status: ['결제대기', '결제완료', '결제취소'][Math.floor((Math.random() * 3))],
        suc: '',
    });
  }

  const basicTableData = { tableHeaderResponsiveData: headerResponsive, tableRowsData: rows };
  return basicTableData;
};

export default BasicTableData;
