import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { mobile } from '../lib/viewport';
import DropDown from "../../../shared/img/sub-box-arrow-close.png"
import DropUp from "../../../shared/img/sub-box-arrow-open.png"
import Collapse from "@kunukn/react-collapse";
import "./DropDown.scss";

const DropdownWrapper = styled.div`
    height: 100%;

    box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.1);

    border-radius: 25px;

    margin:0 auto 18px auto;
    background-color: #fff;

    padding: 0 36px;

    ${({ open }) => {
      return open === true ? `border: solid 0.7px #000; padding-bottom: 24px;` : 'border: solid 0.5px #bfc1c9;';
    }}
    ${({ borderLine }) => {
      return borderLine === true ? `border: solid 0.7px #000;` : 'border: solid 0.5px #bfc1c9;';
    }}
    ${({ width }) => {
      return width ? `width: ${width};` : 'width: 600px;';
    }}

    ${mobile(css`
        width: ${({mobileWidth})=> mobileWidth? mobileWidth:"100%"};
    `)}
    ${({ mobileWidth }) => {
      return mobileWidth ? `${mobile(css`
        width: ${mobileWidth};
    `)}` : null;
    }}

    
`
const DropdownTitleWrapper = styled.div`
    width:100%;
    height: 63px;

    display:flex;
    justify-content:space-between;
    align-items:center;

`
const DropdownTitle = styled.div`
    color: #000;
    font-weight: bold;
    font-size: 16px;
`
const DropdownLine = styled.div`
    width:100%;
    height: 1.5px;
    background-color: #e0e1e5;
    margin: 0 auto 16px auto;
    ${mobile(css`
        padding: 0px;
    `)}
`
const Img = styled.img`
    width: 20px;
    height: 14px;
`
function Dropdown({ openState, title, children, width, borderLine, mobileWidth }) {
    const [state, setState] = useState(openState? openState: false)

    return (
        <DropdownWrapper open={state} width={width} borderLine={borderLine} mobileWidth={mobileWidth}>
            <DropdownTitleWrapper onClick={() => { setState(!state) }}>
                <DropdownTitle>{title}</DropdownTitle>
                {
                    state === true ? <Img src={DropDown} alt="dropdown-down" /> : <Img src={DropUp} alt="dropdown-up" /> 
                }
             </DropdownTitleWrapper>
            {
                <Collapse isOpen={state} className={
                    "app__collapse app__collapse--gradient " + (state ? "app__collapse--active" : "")
                  }>
                    <DropdownLine />
                    {children}
                </Collapse>
            }
        </DropdownWrapper>
    )
}

export default Dropdown
