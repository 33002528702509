/* eslint-disable */
import React, { useState } from 'react'
import styled, { css } from "styled-components"
import axios from "axios"

import Banner from './components/Banner'
import Checkbox from "./components/Checkbox"

import {useHistory} from "react-router-dom"

import Alert from "../../components/Alert"

const Form = styled.div`
    width: 300px;
    margin: 50px auto 160px auto;
`
const InputText = styled.input`
    width: 100%;
    height: 50px;

    border: solid 0.7px #e0e1e5;
    background-color: #fff;

    padding: 0px 20px;
    font-size: 15px;

    outline:none;

    &:focus{
        position:relative;
        box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.1);
        border: solid 0.6px rgba(0, 0, 0);
    }
`
const InputSubmitButton = styled.div`
    min-width: 100%;
    height: 50px;

    border: solid 0.5px #000;
    background-color: #474747;

    color: #fff;
    font-size: 15px;

    display:flex;
    justify-content:center;
    align-items:center;

    margin: 20px 0px 16px 0px;

    cursor:pointer;
`

const InputSignUpButton = styled.div`
    min-width: 100%;
    height: 50px;

    border: solid 0.5px #474747;
    background-color: #fff;

    color: #000;
    font-size: 15px;

    display:flex;
    justify-content:center;
    align-items:center;

    margin: 60px 0px 16px 0px;

    cursor:pointer;
`

const Label = styled.div`
    display:flex;
    align-items:center;

    cursor:pointer;

`

const AutoLoginText = styled.div`
    margin-left: 6px;
    font-size: 14px;
`
const FormBottomWrapper = styled.div`
    width:100%;
    display:flex;
    justify-content:space-between;
    align-items:center;
`

const Line = styled.div`
    width: 0.7px;
    height: 12px;
    background-color: #c1c4cb;
    margin: 0px 6px;
`

const AuthFindWrapper = styled.div`
    display:flex;
    align-items:center;

    & > span{
        cursor:pointer;
    }
`
function SignIn() {
    const [autoLogin, setAutoLogin] = useState(false)
    const history = useHistory();
    const [errorText, setErrorText] = useState("")

    const [form, setForm] = useState({userId:"",password:""})

    function setupErrorText() {
        setErrorText("")
    }
    
    const handleLogin = (e) => {
            if(form.userId === ""){
                setErrorText("아이디를 입력해 주세요.")
            } else {
                if (form.password === "") {
                    setErrorText("비밀번호를 입력해 주세요.")
                } else {
                    axios.post(`${process.env.REACT_APP_API_URL}/login?auto=${autoLogin}`, {
                        userInfo: form,
                    })
                    .then(data => {
                        if (data.data.message === "ok") {
                            localStorage.setItem("accessToken", data.data.accessToken)
                            history.push("/")
                        }
                    })
                    .catch(err => {
                        if (err.response.data != null){
                            setErrorText(err.response.data.message)
                            setForm({...form, password: ""})
                        } else {
                            console.log(err.response)
                        }
                    })
                }
        }
        
    }
    const inputOnChange = (e) => {
        const { name, value } = e.target   
        const nextInputs = {            
            ...form,  
            [name]: value,
        }
        setForm(nextInputs)       
    }
    return (
        <>
            <Alert openState={errorText === "" ? false: true } text={errorText} setupErrorText={()=>setupErrorText()}/>
            <Banner title="로그인" subTitle="LOGIN"></Banner>
            <Form>
                <InputText type="text" name="userId" placeholder="아이디를 입력해주세요." value={form.userId} onChange={inputOnChange} />
                <InputText type="password" name="password" placeholder="비밀번호를 입력해주세요." value={form.password} onChange={inputOnChange} 
                    onKeyPress={(e) => {
                        if(e.code === "NumpadEnter" || e.code === "Enter" ) handleLogin()
                    }} />

                <InputSubmitButton onClick={() => handleLogin()}>
                    <span role="button" aria-hidden="true">로그인</span>
                </InputSubmitButton>
                <FormBottomWrapper>
                    <Label>
                        <Checkbox state={autoLogin} onChange={(e) => { setAutoLogin(e) }} />
                        <AutoLoginText onClick={ () => { setAutoLogin(!autoLogin) }}>자동로그인</AutoLoginText>
                    </Label>
                    <AuthFindWrapper>
                        <span onClick={() => {history.push("/app/auth/find/id")}}>아이디찾기</span>
                        <Line />
                        <span onClick={() => {history.push("/app/auth/find/password")}}>비밀번호찾기</span>
                    </AuthFindWrapper>
                </FormBottomWrapper>
                <InputSignUpButton onClick={()=> history.push("/app/auth/signup")}>
                    <span>회원가입</span>
                </InputSignUpButton>
            </Form>
        </>
    )
}

export default SignIn;
