import React from 'react';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';

import Main from './pages/Main/index';
import Auth from './pages/Auth/route';
import Introduce from './pages/Introduce/route'
import MyPage from './pages/MyPage/route'
import Notification from './pages/Notification/route'
import Academic from "./pages/Academic/route"
import Community from "./pages/Community/route"

import Header from './components/Header';
import Footer from './components/Footer';
import './index.scss';
import axios from "axios"

const Container = styled.div`
	min-height: 100vh;

  margin: 0px;
  padding: 0px;

  background-color: #f2f3f5;

  position: relative;
`


function RouteMain(){ 
  if ( localStorage.getItem("accessToken") ) {
  axios.post(`${process.env.REACT_APP_API_URL}/verification`, {
    token: localStorage.getItem("accessToken")
  }).then(data => {
    return null
  }).catch(err => {
    if (err.response.data != null){
        if (err.response.data.message === "잘못된 토큰입니다.") {
          localStorage.removeItem("accessToken")
        }
    }
})
}
  
  return (
  <Container>
    <Header />
    <Switch>
      <Route exact path="/" component={Main} />
      <Route path="/app/auth" component={Auth} />
      <Route path="/app/introduce" component={Introduce} />
      <Route path="/app/mypage" component={MyPage} />
      <Route path="/app/notification" component={Notification} />
      <Route path="/app/academic" component={Academic} />
      <Route path="/app/community" component={Community} />
    </Switch>
    <Footer />
  </Container>
);
}


export default RouteMain