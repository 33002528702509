const BasicTableData = () => {
  // const header = [
  //   { id: 1, title: '#' },
  //   { id: 2, title: 'First Name' },
  //   { id: 3, title: 'Last Name' },
  //   { id: 4, title: 'Username' },
  //   { id: 5, title: 'Status' },
  // ];

  const headerResponsive = [
    { id: 1, title: '번호' },
    { id: 2, title: '상태' },
    { id: 3, title: '성함' },
    { id: 4, title: '아이디' },
    { id: 5, title: '면허번호' },
    { id: 7, title: '전화번호' },
    { id: 8, title: '가입일' },
    { id: 9, title: '마지막 접속' },
    { id: 10, title: '메모' },
    { id: 11, title: '상세' },
  ];
 
  const rows = [];
  for (let i = 0; i < 10; i++) {
      rows.push({
      id: i,
        status: ['정회원', '승인대기  ', '준회원', '탈퇴'][Math.floor((Math.random() * 4))],
        name: `홍길동${ i}`,
        userId: ['@dragon', '@hamster', '@cat'][Math.floor((Math.random() * 3))],
        lisenceNum: i,
        phoneNum: `010-1234-${parseInt(Math.random() * 1000 + 1000)}`,
        regDate: '2000-01-01',
        lastCon: '2000-01-01',
        memo: '',
        detail: '',
    });
  }

  const basicTableData = { tableHeaderResponsiveData: headerResponsive, tableRowsData: rows };
  return basicTableData;
};

export default BasicTableData;
