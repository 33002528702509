import React, { useState, useEffect, useRef } from 'react';
import Fade from 'react-reveal/Fade';

import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import styled, { css } from 'styled-components';
import axios from "axios"
import moment from "moment"
import {useHistory} from "react-router"

import bnPrev from '../../../../shared/img/bn-prev.svg'
import bnNext from '../../../../shared/img/bn-next.svg'
import ScheduleList from './components/ScheduleList'
import ScheduleListNone from './components/ScheduleListNone'
import Calendar from './components/Calendar'
import events from './components/events'
import CommunityBoard from "./components/CommunityBoard"
import { mobile, tablet } from "../../lib/viewport";

import bgMain from "../../../../shared/img/main/main-bn-img-01.png";
import Next from "../../../../shared/img/main/sponsor-next.png";
import sponsorImage from "../../../../shared/img/main/sponsor.png";
import { useSelector } from "react-redux";



import "./index.scss"

const MainContainer = styled.div`
    padding: 0px 4em;
    
    ${mobile(css`
            padding: 0px 1em;
     `)}
        
	& > div {
		padding: 25px 0%;

		max-width: 1350px;

		margin: 0 auto;

		display: flex;
		justify-content: space-between;
        align-items: center;
        
        flex-direction: column;
        ${mobile(css`
            padding: 1em 0px 0px 0px;
        `)}
       
	}
`;

const BannerWrapper = styled.div`
	width: 100%;
    border-radius: 20px;

    max-height: 365px;
    
    background-color: #fff;
    
    display:flex;
    justify-content:space-between;
    align-items:center;

    box-shadow: 1px 8px 22px 2px rgba(0, 0, 0, 0.1);

    margin-bottom: 25px;

    ${mobile(css`
        height: 100%;
        max-height: 100%;
     `)}
  
`;

const TextWrapper = styled.div`
    height:100%;
    margin-left:40px;
    margin-bottom:20px;
    margin-top:20px;
  
  & > h1{
        font-size:  3.4em;
        font-weight: bold;
        color: #000;
        @media (max-width: 1200px){
            font-size: 2.5em;
            line-height: 1;
        }
        ${mobile(css`
            width: 45%;
            font-size: 20px;
            line-height: 1.8;
            margin-bottom: 70px;
        `)}
    }
    & > p {
        @media (max-width: 1200px){
            font-size: 1em;
            line-height: 1;
        }
        ${mobile(css`
            width: 45%;
            font-size: 1em;
        `)}
    }
    @media (max-width: 960px){
        display:none;
    }
`

const Wrapper = styled.div`
    width: ${ props => props.width };
    height: 420px;
    min-height: 220px;
	border-radius: 20px;

    ${mobile(css`
        flex-direction: column;

        height: 100%;
    `)}
`;

const Box = styled.div`
    width:100%;
    height: 420px;
    min-height: 220px;

    padding: 1.8em 2.5em;
	border-radius: 20px;

	position: relative;
	background-color: #fff;

    box-shadow: 1px 8px 22px 2px rgba(0, 0, 0, 0.1);

    display:flex;
    flex-direction: ${ props => props.dir };

    ${mobile(css`
        flex-direction: column;

        height: 100%;
        padding: 1.2em 1.3em;

    `)}

`

const Line = styled.div`
    width: 30px;
    height: 3px;
    background-color: #36aa3a;

    margin: 14px 0px 14px 1px;
    @media (max-width: 1200px){
        margin: 7px 0px 10px 1px;
    }
    ${mobile(css`
        width: 20px;
        margin: 3px 0px 0px 1px;
    `)}
`
const BannerText = styled.div`
    font-weight: 400;
    line-height: 1.55;
    font-size: 16px;
    color: #3e3d3d;

    margin-top: 20px;
    @media (max-width: 1200px){
        margin-top: 10px;
        font-size: 1em;
        line-height: 1.3;
    }
    @media (max-width: 960px){
        display:none;
    }
`


const Flex = styled.div`
    width:100%;

    display:flex;
    justify-content:space-between;

    ${tablet(css`
        flex-direction: column;

        & > div{
            width:100%;
        }

        & > div:nth-child(1){
            margin-bottom: 25px;
        }
    `)}

`
const SwiperControl = styled.div`
    display:flex;
    align-items:center;

    margin-top: 20px;
    @media (max-width: 1200px){
    }
    ${mobile(css`
        display:none;
    `)}
`
const SwiperNumber = styled.div`
    margin-left: 26px;
    ${mobile(css`
        display:none;
    `)}
`

const Cricle = styled.div`
    width:18px;
    height:18px;

    display:flex;
    justify-content:center;
    align-items:center;

    border-radius:100%;

    border: solid 2px #e0e1e4;

    cursor:pointer;
`

const SwiperLine = styled.div`
    width:180px;
    height: 1px;
    background-color: #d6dae2;
    margin: 0 8px;
    position:relative;
`

const SwiperLinePos = styled.div`
    width:${props=> props.width};
    height: 1px;
    background-color: #4b4f56;
    position:absolute;
    top:0px;
    bottom:0px;
    left: ${props=> props.left};
`
const WrapperTitle = styled.div`
    color: #000000;
    font-size: 18px;
    font-weight: bold;

`
const CalendarWrapper = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;

    margin: 0 0 0 auto;

    ${mobile(css`
        margin: 20px auto 0px auto;
    `)}
`

const ScheduleWrapper = styled.div`
    
    ${mobile(css`
        width: 100%;
        margin: 0 auto;
    `)}
`

const Navigation = styled.div`
    width: 160px;
    height: 36px;

    border-radius: 20px;
    border: solid 0.5px #d6d6d6;
    background-color: #f2f3f5;

    display:flex;
    position : relative;

    margin-top: 4px;
    margin-bottom: 18px;

    & > p{
        width: 50%;
        height: 35px;
        color: #0e0e0e;
        font-size: 12px;

        display:flex;
        justify-content:center;
        align-items:center;

        position:relative;
        z-index: 2;

        cursor:pointer;
    }
`
const NavigationCricle = styled.div`
    width: 80px;
    height: 35px;

    border-radius: 20px;
    border: solid 0.5px #d6d6d6;
    background-color: #fff;

    position: absolute;

    ${({ pos }) => {
      return pos === 'left' ? `left: 0px;` : 'left: 80px;';
    }}
    transition: 0.2s;
`

const CommunityNavigationTitle = styled.div`
    font-size: 14px;

    cursor:pointer;
    ${({ selected }) => {
      return selected ? `color: #000; font-weight: bold;` : 'color: #acafb5;';
    }}
`

const CommunityNavigationLine = styled.div`
    margin : 0px 18px;
    width: 1px;
    height: 12px;
    background-color: #c9ced6;

`
const CommunityNavigation = styled.div`
    width:100%;
    display:flex;
    align-items:center;

`
const CommunityHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 16px;

`
const DetailButton = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;

    border: solid 1px #ced0d6;
    border-radius: 100%;

    min-width: 26px;
    min-height: 26px;

    position: relative;

    cursor:pointer;
    & > div{
        position: absolute;
        
    }
    & > .button_width{
        width: 14px;
        height: 2px;
        border-radius: 20px;

        background-color: #000;
       
    }

    & > .button_height{
        width: 14px;
        height: 2px;
        border-radius: 20px;

        background-color: #000;

        transform: rotate( 90deg )
    }
`
const CommunityBoardLine = styled.div`
    width:100%;
    height: 0.5px;
    background-color: #e0e1e5;
`


const SwiperItem = styled.div`
  height: fit-content;

  box-sizing: border-box;
  ${mobile(css`
    `)}
   
`
const Swiper2Item = styled.div`
  height: fit-content;

  box-sizing: border-box;
  ${tablet(css`
        margin: 0;
    `)}
   
`

const SwiperWrapper = styled.div`
    width: calc( 100% - 48px );
    height: 60px;

    position: relative;
    margin: 40px auto 80px auto;
    ${mobile(css`
        display:none;
    `)}  

    
`
const SwiperMobileWrapper = styled.div`
    width: calc( 100% - 48px );
    height: 60px;

    position: relative;
    margin: 40px auto 80px auto;

    display:none;
    ${mobile(css`
        display:block;
    `)}  
`

const SwiperBannerWrapper = styled.div`
     width:62%;
    height:100%;

    z-index:1;

    border-radius: 20px 20px 20px 20px;
    @media (max-width: 960px){
        width:100%;
    }

`

const ScheduleHeader = styled.div`
    width:100%;
    display:flex;
    justify-content:space-between;
    align-items:center;
    
`

const ScheduleHeaderPlus = styled.div`
    display:none;

    ${mobile(css`
        display:flex;
        align-items:center;
        cursor:pointer;
        & > img {
            margin-left: 6px;
        }
    `)}
`

const CommunityBoardNone = styled.div`
    height:100%;
    min-height: 140px;
    display:flex;
    justify-content:center;
    align-items:center;

    font-size: 20px;
    
    padding: 0px 0px 20px 0px;
`

const SwiperImage = styled.img`
    margin: 0px;
    width:100%;
    height:100%;
    border-radius: 20px 20px 20px 20px;
    max-height: 365px;

    ${
        mobile(css`
            max-height:100%;
            `)
    }

    object-fit :contain;

`
const SwiperSponsorImage = styled.img`
    width: 224px;
    height: 60px;

    ${tablet(css`
        width:100%;
    `)}
`
function Main() {
    const [pos, setPos] = useState('left')
    const [state, setState] = useState("group")
    const [calendarData, setCalendarData] = useState([])
    const [listData, setListData] = useState([])
    const [communityNavType, setCommunityNavType] = useState("공지사항")
    const [communityData, setCommunityData] = useState([])
    const swiperRef = useRef(null);
    const [activeIndex, setActiveIndex] = useState(1)
    const [bannerImageArr, setBannerImageArr] = useState([])
    const [sponsorImageArr, setSponsorImageArr] = useState([])

    const history = useHistory();

    let adminState = null
    useSelector(stateRedux => { adminState = stateRedux.admin.admin }) 
    const checkLogin = (index) => {
        if (!adminState) {
            if (!localStorage.getItem("accessToken")) {
              history.push("/app/auth/signin")
              window.alert("로그인 후, 이용 가능합니다.")
            } else {
                history.push(`/app/notification/academic/${index}/introduce`)
            }
        } else {
            history.push(`/app/notification/academic/${index}/introduce`)
        }
    }

    const goNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
      };
      const goPrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
        }
      };
    useEffect(async () => { 
        axios.get(`${process.env.REACT_APP_API_URL}/listimg?type=bottom`).then((response)=>{
            // console.log(response.data.data)
            setSponsorImageArr(response.data.data)
        })

        axios.get(`${process.env.REACT_APP_API_URL}/listimg?type=top`).then((response)=>{
            setBannerImageArr(response.data.data)
        })

        if (pos === 'left') {
            axios.get(`${process.env.REACT_APP_API_URL}/research-schedule?eventType=1`).then((response)=>{
                let arr = []
                for (let i = 0; i < response.data.data.length; i++) {
                    let start = response.data.data[i].startdate;
                    let end = response.data.data[i].enddate
                    if (moment(start).format("YYYY-MM-DD") !== moment(end).format("YYYY-MM-DD")) {
                        arr.push({
                            id: response.data.data[i].id_event,
                            start: new Date(moment(start).format("YYYY"), moment(start).format("MM") -1, moment(start).format("DD")),
                            end: new Date( moment(start).format("YYYY"), moment(start).format("MM") - 1, moment(start).format("DD")),
                            title: "",
                            count: response.data.data[i].count
                        })
                        arr.push({
                            id: response.data.data[i].id_event,
                            start: new Date(moment(end).format("YYYY"), moment(end).format("MM") -1, moment(end).format("DD")),
                            end: new Date( moment(end).format("YYYY"), moment(end).format("MM") - 1, moment(end).format("DD")),
                            title: "",
                            count: response.data.data[i].count
                        })
                    } else {
                        arr.push({
                            id: response.data.data[i].id_event,
                            start: new Date(moment(start).format("YYYY"), moment(start).format("MM") -1, moment(start).format("DD")),
                            end: new Date( moment(end).format("YYYY"), moment(end).format("MM") - 1, moment(end).format("DD")),
                            title: "",
                            count: response.data.data[i].count
                        })
                    }
                }
                setCalendarData(arr)
                setListData(response.data.data)
                console.log(response.data.data)
            })
        } else {
            axios.get(`${process.env.REACT_APP_API_URL}/research-schedule?eventType=2`).then((response)=>{
                let arr = []
                for (let i = 0; i < response.data.data.length; i++) {
                    let start = response.data.data[i].startdate;
                    let end = response.data.data[i].enddate
                    if (moment(start).format("YYYY-MM-DD") !== moment(end).format("YYYY-MM-DD")) {
                        arr.push({
                            id: response.data.data[i].id_event,
                            start: new Date(moment(start).format("YYYY"), moment(start).format("MM") -1, moment(start).format("DD")),
                            end: new Date( moment(start).format("YYYY"), moment(start).format("MM") - 1, moment(start).format("DD")),
                            title: "",
                            count: response.data.data[i].count
                        })
                        arr.push({
                            id: response.data.data[i].id_event,
                            start: new Date(moment(end).format("YYYY"), moment(end).format("MM") -1, moment(end).format("DD")),
                            end: new Date( moment(end).format("YYYY"), moment(end).format("MM") - 1, moment(end).format("DD")),
                            title: "",
                            count: response.data.data[i].count
                        })
                    } else {
                        arr.push({
                            id: response.data.data[i].id_event,
                            start: new Date(moment(start).format("YYYY"), moment(start).format("MM") -1, moment(start).format("DD")),
                            end: new Date( moment(end).format("YYYY"), moment(end).format("MM") - 1, moment(end).format("DD")),
                            title: "",
                            count: response.data.data[i].count
                        })
                    }
                }
                setCalendarData(arr)
                setListData(response.data.data)
                console.log(response.data.data)
            })
        }
    }, [pos]);
    
    useEffect(async ()=>{
        let num = 1;
        if (communityNavType === "공지사항") {
            num = 1;
        } else if (communityNavType === "소식지") {
            num = 2;
        } else if (communityNavType === "회원게시판") {
            num = 4;
        }

        axios.post(`${process.env.REACT_APP_API_URL}/boardList`, {
            status: 1,
            num: num,
            page: 1
        }).then((data)=>{
            setCommunityData(data.data.data2)
        })
    },[communityNavType])

    function NavigationChange(subject) {
        if (subject === 'studyGroup') {
            setPos('left')
        } else {
            setPos('right')
        }
    }
    function detailPage(){
        if (communityNavType === "공지사항") {
            history.push("/app/notification/notice")
        } else if (communityNavType === "소식지") {
            history.push("/app/notification/newsletter")
        } else if (communityNavType === "회원게시판") {
            history.push("/app/community/userboard")
        }
    }
    const params = {
        autoplay: {
          delay: 2500,
          disableOnInteraction: true,
        }, 
        spaceBetween: 40,
        slidesPerView: 4.5,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        
    }
    const params3 = {
        autoplay: {
          delay: 2500,
          disableOnInteraction: true,
        }, 
        spaceBetween: 40,
        slidesPerView: 1.5,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        
    }
    function onSlideChange(e){
        if ( swiperRef.current.swiper.activeIndex === bannerImageArr.length + 1) {
            setActiveIndex(1)
        } else if ( swiperRef.current.swiper.activeIndex === 0 ) {
            setActiveIndex(bannerImageArr.length)
        } else {
            setActiveIndex(swiperRef.current.swiper.activeIndex)
        }
    }
    const params2 = {
        autoplay: {
          delay: 5000,
          disableOnInteraction: true,
        }, 
        loop: true,     
        slidesPerView: 1,
        on: {
            slideChange: onSlideChange
        }
        // breakpoin
    }

    return (
        <MainContainer>
			<div>
                <Fade bottom>
				<BannerWrapper>
                    <TextWrapper>
					<p>LUKE MEDICAL SOCIETY OF PAIN RESEARCH</p>
                    <Line />
                    <h1>누가 통증연구회</h1>
                    <BannerText>
                        누가통증 연구회는 예수님의 제자이자 의사였던<br />
                        누가의 이웃 사랑 정신을 본받아 대한민국 의술 발전에<br /> 
                        기여하고 질병(통증)으로 고생하는 이웃들에게<br />
                        기쁨을 주고자 모여 함께 연구하는 학술 단체입니다.
                    </BannerText>
                    <SwiperControl>
                        <Cricle onClick={goPrev}>
                            <img src={bnPrev} alt="bnPrevImage" />
                        </Cricle>
                        <SwiperLine>
                            {
                            bannerImageArr.length !== 0 ? <SwiperLinePos width={ (180 / bannerImageArr.length) + "px"} left={(((activeIndex - 1) * 180) / bannerImageArr.length) + "px"}></SwiperLinePos> : <></>
                            }
                        </SwiperLine>
                        <Cricle onClick={goNext}>
                            <img src={bnNext} alt="bnNextImage" />
                        </Cricle>
                    </SwiperControl>
                    {
                        bannerImageArr.length !== 0 ? <SwiperNumber>
                        <b>{activeIndex}</b> / {bannerImageArr.length}
                    </SwiperNumber> : <SwiperNumber>
                        <b>1</b> / 1
                    </SwiperNumber>
                    }
                    </TextWrapper>
                    <SwiperBannerWrapper className="banner-swiper">
                        {
                bannerImageArr.length !== 0 ? <Swiper {...params2} ref={swiperRef} >
                        {
                        bannerImageArr.length !== 0 ? bannerImageArr.map((v)=>{
                            return (<SwiperItem>
                                <SwiperImage src={process.env.REACT_APP_API_URL+'/'+v.file_link} alt="banner_image" />
                            </SwiperItem>)
                        }):<></>
                    }
                    </Swiper> : <SwiperItem>
                            <SwiperImage src={bgMain} alt="banner_image2" />
                         </SwiperItem> 
}
                    </SwiperBannerWrapper>
                   
				</BannerWrapper>
                </Fade>
                <Flex>
                    <Wrapper width="53%" dir="row">
                    <Fade bottom>
                        <Box dir="row">
                        <ScheduleWrapper>
                            <ScheduleHeader>
                                <WrapperTitle>SCHEDULE</WrapperTitle>
                                <ScheduleHeaderPlus onClick={ () => history.push("/app/notification/schedule")}>
                                    <span>더보기</span>
                                    <img src={Next} alt="swiper1" width="100%" />
                                </ScheduleHeaderPlus>
                            </ScheduleHeader>
                            <Line />
                            {
                                listData.length < 4 ? (<>
                                    {listData.map((v)=>{
                                        return <ScheduleList data={v} 
                                                onClick={(index)=>{
                                                    if (v.event_type_detail !== "학술행사일정"){
                                                        history.push(`/app/notification/schedule/${index}/introduce`)
                                                    } else {
                                                        checkLogin(index)
                                                    }
                                                }} />
                                    })}
                                    <ScheduleListNone /> 
                                </>): listData.slice(0,4).map((v)=>{
                                    return <ScheduleList data={v} 
                                        onClick={(index)=>{
                                            if (v.event_type_detail !== "학술행사일정"){
                                                history.push(`/app/notification/schedule/${index}/introduce`)
                                            } else {
                                                checkLogin(index)
                                            }
                                        }} />
                                })
                            }
                        </ScheduleWrapper>
                        <CalendarWrapper>
                            <Navigation>
                                <NavigationCricle pos={pos} />
                                <p onClick={() => NavigationChange('studyGroup')}>연구회</p>
                                <p onClick={() => NavigationChange('conference')}>학술행사</p>
                            </Navigation>
                            <Calendar events={calendarData} small 
                                onClick={(index)=>{ 
                                    console.log(index)
                                    console.log(calendarData)
                                    if (calendarData[0].count === "정기행사" || calendarData[0].count === "연수강좌") {
                                        history.push(`/app/notification/schedule/${index}/introduce`)
                                    } else {
                                        checkLogin(index)
                                    }
                                }} />
                        </CalendarWrapper>
                        </Box>
                        </Fade>
                    </Wrapper>
                    <Wrapper width="45%" dir="column">
                        <Fade bottom>
                        <Box dir="column">
                        <CommunityHeader>
                            <CommunityNavigation>
                                <CommunityNavigationTitle selected={communityNavType === "공지사항"} onClick={()=>{setCommunityNavType("공지사항")}}>공지사항</CommunityNavigationTitle>
                                <CommunityNavigationLine />
                                <CommunityNavigationTitle selected={communityNavType === "소식지"} onClick={()=>{setCommunityNavType("소식지")}}>소식지</CommunityNavigationTitle>
                                <CommunityNavigationLine />
                                <CommunityNavigationTitle selected={communityNavType === "회원게시판"} onClick={()=>{setCommunityNavType("회원게시판")}}>회원게시판</CommunityNavigationTitle>
                            </CommunityNavigation>
                            <DetailButton onClick={() => detailPage()}>
                                <div className="button_width"></div>
                                <div className="button_height"></div>
                            </DetailButton>
                        </CommunityHeader>
                        {
                            communityData.length === 0 ? <CommunityBoardNone>게시물이 존재하지 않습니다.</CommunityBoardNone>:communityData.slice(0,7).map((v)=>{
                                return (
                                    <>
                                        <CommunityBoard data={v} length={communityData.length} />
                                        <CommunityBoardLine />
                                    </>
                                )
                            })
                        }
                          </Box>
                          </Fade>
				    </Wrapper>
                </Flex>
                {
                    sponsorImageArr.length === 0 ? <></> : <Fade bottom>
                <SwiperWrapper>
                    <Swiper {...params}>
                        {
                            sponsorImageArr.map(v=>{
                                return (
                                    <Swiper2Item>
                                        <SwiperSponsorImage src={process.env.REACT_APP_API_URL+'/'+v.file_link} alt="swiper1" />
                                    </Swiper2Item>
                                )
                            })
                       
                        }
                    </Swiper>
                   
                </SwiperWrapper>
                <SwiperMobileWrapper>
                    <Swiper {...params3}>
                        {
                            sponsorImageArr.map(v=>{
                                return (
                                    <Swiper2Item>
                                        <SwiperSponsorImage src={process.env.REACT_APP_API_URL+'/'+v.file_link} alt="swiper1" />
                                    </Swiper2Item>
                                )
                            })
                       
                        }
                    </Swiper>
                </SwiperMobileWrapper>
                </Fade>
                }
			</div>
		</MainContainer>
    );
}

export default Main;
