import axios from 'axios'
/* eslint-disable no-alert */
const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

export default (async function showResults(values, setRendChange) {
  const valueData = { ...values }
  valueData.writer = '관리자'
  if (values.selectNum === '1, 1' || values.selectNum === '1, ') {
    valueData.type = 1
    if (valueData.file) {
      let formData = new FormData()
      axios
        .post(`${process.env.REACT_APP_API_URL}/notices`, valueData)
        .then((data) => {
          setRendChange((e) => !e)
          return data
        })
        .then((data2) => {
          formData.append('file', valueData.file.file)
          axios.post(`${process.env.REACT_APP_API_URL}/fileUpload?num=1&no=${data2.data.id_notice}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
        })
        .catch((err) => console.log(err))
    } else {
      axios
        .post(`${process.env.REACT_APP_API_URL}/notices`, valueData)
        .then((data) => setRendChange((e) => !e))
        .catch((err) => console.log(err))
    }
  } else if (values.selectNum === '1, 2') {
    valueData.type = 2
    if (valueData.file) {
      let formData = new FormData()
      axios
        .post(`${process.env.REACT_APP_API_URL}/notices`, valueData)
        .then((data) => {
          setRendChange((e) => !e)
          return data
        })
        .then((data2) => {
          formData.append('file', valueData.file.file)
          axios.post(`${process.env.REACT_APP_API_URL}/fileUpload?num=1&no=${data2.data.id_notice}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
        })
        .catch((err) => console.log(err))
    } else {
      axios
        .post(`${process.env.REACT_APP_API_URL}/notices`, valueData)
        .then((data) => setRendChange((e) => !e))
        .catch((err) => console.log(err))
    }
    // console.log('소식지')
  } else if (values.selectNum === '1, 3') {
    valueData.type = 3
    if (valueData.file) {
      let formData = new FormData()
      axios
        .post(`${process.env.REACT_APP_API_URL}/notices`, valueData)
        .then((data) => {
          setRendChange((e) => !e)
          return data
        })
        .then((data2) => {
          formData.append('file', valueData.file.file)
          axios.post(`${process.env.REACT_APP_API_URL}/fileUpload?num=1&no=${data2.data.id_notice}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
        })
        .catch((err) => console.log(err))
    } else {
      axios
        .post(`${process.env.REACT_APP_API_URL}/notices`, valueData)
        .then((data) => setRendChange((e) => !e))
        .catch((err) => console.log(err))
    }
    // console.log('학술지')
  } else if (values.selectNum === '1, 4') {
    valueData.type = 4
    if (valueData.file) {
      let formData = new FormData()
      axios
        .post(`${process.env.REACT_APP_API_URL}/notices`, valueData)
        .then((data) => {
          setRendChange((e) => !e)
          return data
        })
        .then((data2) => {
          formData.append('file', valueData.file.file)
          axios.post(`${process.env.REACT_APP_API_URL}/fileUpload?num=1&no=${data2.data.id_notice}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
        })
        .catch((err) => console.log(err))
    } else {
      axios
        .post(`${process.env.REACT_APP_API_URL}/notices`, valueData)
        .then((data) => setRendChange((e) => !e))
        .catch((err) => console.log(err))
    }
    // console.log('회원게시판')
  } else if (values.selectNum === '1, 5') {
    valueData.type = 5
    if (valueData.file) {
      let formData = new FormData()
      axios
        .post(`${process.env.REACT_APP_API_URL}/notices`, valueData)
        .then((data) => {
          setRendChange((e) => !e)
          return data
        })
        .then((data2) => {
          formData.append('file', valueData.file.file)
          axios.post(`${process.env.REACT_APP_API_URL}/fileUpload?num=1&no=${data2.data.id_notice}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
        })
        .catch((err) => console.log(err))
    } else {
      axios
        .post(`${process.env.REACT_APP_API_URL}/notices`, valueData)
        .then((data) => setRendChange((e) => !e))
        .catch((err) => console.log(err))
    }
    // console.log('질의응답')
  } else if (values.selectNum === '1, 6') {
    valueData.type = 6
    if (valueData.file) {
      let formData = new FormData()
      axios
        .post(`${process.env.REACT_APP_API_URL}/notices`, valueData)
        .then((data) => {
          setRendChange((e) => !e)
          return data
        })
        .then((data2) => {
          formData.append('file', valueData.file.file)
          axios.post(`${process.env.REACT_APP_API_URL}/fileUpload?num=1&no=${data2.data.id_notice}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
        })
        .catch((err) => console.log(err))
    } else {
      axios
        .post(`${process.env.REACT_APP_API_URL}/notices`, valueData)
        .then((data) => setRendChange((e) => !e))
        .catch((err) => console.log(err))
    }
    // console.log('보도자료')
  }
})
