import React from 'react';
import PropTypes from 'prop-types';
import { renderComponentField } from './FormField';
import { Button } from 'reactstrap'

export const FileInputField = React.forwardRef(({ onChange, name, value, setModifyFile, fileName, setFileDel }, ref) => {
  // console.log(value)
  const handleChange = (e) => {
    e.preventDefault();
    const files = [...e.target.files];
    onChange({ file: files[0], name: files[0].name });
    setModifyFile(files[0])
  };

  return (
    <div className="form__form-group-file">
      <label htmlFor={name}>파일첨부</label>
      {value ? <span>{value.name}</span> : <File1 fileName={fileName} setFileDel={setFileDel}/> }
      <input
        type="file"
        name={name}
        id={name}
        onChange={handleChange}
        ref={ref}
      />
    </div>
  );
});
function File1({ fileName,setFileDel }) {
  return (
  <>
      {fileName ? <><span>{fileName}</span>&nbsp;<Button color="danger" size="sm"
        onClick={() => {
          if (window.confirm("삭제하시겠습니까?")) {
            setFileDel(true)
            window.alert("삭제되었습니다.")
        }
      }}
      >삭제</Button> </> : <span>파일없음</span>}
  </>
  );
}

FileInputField.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      name: PropTypes.string,
    }),
    PropTypes.string,
  ]),
};

FileInputField.defaultProps = {
  value: null,
};

export default renderComponentField(FileInputField);
