import React from 'react';
import styled, { css } from 'styled-components'

import moment from "moment"
import ScheduleArrow from '../../../../../shared/img/schedule-more-arrow.svg'
import { mobile } from "../../../lib/viewport";


const ScheduleListWrapper = styled.div`
    width: 265px;
    height: 75px;

    border-bottom: 1px solid #e0e1e5;

    padding: 10px;

    display:flex;
    justify-content:space-between;
    align-items:center;

    transition: 0.2s ease;

    cursor:pointer;

    &:hover{
        background-color: #f2f3f5;
    }

    ${mobile(css`
        width: 100%;
        justify-content:flex-start;
    `)}

    & > div{
        display:flex;
    }
`

const DateWrapper = styled.div`
    margin-right: 14px;
    margin-bottom: 2px;
    display:flex;
    align-items:center;

    flex-direction: column;

    color: #36aa3a;

    & > div{
        font-size: 10px;
        font-weight: bold;
    }
    & > b{
        font-size: 22px;
        font-weight: bold;

        margin: 0px;

        line-height: 20px;
    }
`
const LocationText = styled.div`
    font-size: 11px;
    line-height:1;
`

const ScheduleListTitle = styled.b`
    font-size: 14px;

    overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const ScheduleArrowImg = styled.img`
    width: 15px;

    ${mobile(css`
        display: none;
    `)}
`
const FlexCenter = styled.div`
    display:flex;
    justify-content:center;
    align-items:flex-start;

    flex-direction:column;
`

function ScheduleList({data, onClick}) {
    return (
        <ScheduleListWrapper onClick={() => onClick(data.id_event)}>
            <div>
            <DateWrapper>
                <div>{moment(data.enddate).format("YY")}.{moment(data.startdate).format("MM")}</div>
                <b>{moment(data.enddate).format("DD")}</b>
            </DateWrapper>
            <FlexCenter>
                <ScheduleListTitle>{data.title}</ScheduleListTitle>
                <LocationText>{data.place}</LocationText>
            </FlexCenter>
            </div>
            <ScheduleArrowImg src={ScheduleArrow} alt="" />
        </ScheduleListWrapper>
    )
}


export default ScheduleList;
