import React, { useState, useEffect, useCallback } from 'react';
import styled, { css } from "styled-components";
import axios from "axios"
import moment from "moment"
import Banner from './components/Banner';
import { mobile } from '../../lib/viewport';
import Calendar from './components/Calendar';
import MobileCalendar from "./components/MobileCalendar"
import events from "./components/events";
import List from "../../components/List";
import { useHistory } from 'react-router';

const Navigation = styled.div`
    width: 180px;
    height: 40px;

    border-radius: 20px;
    border: solid 0.5px #d6d6d6;
    background-color: #f2f3f5;

    display:flex;
    position : relative;

    margin: 50px auto 30px auto;

    & > p{
        width: 50%;
        height: 39px;
        color: #0e0e0e;
        font-size: 12px;

        display:flex;
        justify-content:center;
        align-items:center;

        position:relative;
        z-index: 2;

        cursor:pointer;
    }
    ${mobile(css`
        margin: 20px auto 20px auto;
    `)}
`
const NavigationCricle = styled.div`
    width: 90px;
    height: 39px;

    border-radius: 20px;
    border: solid 0.5px #d6d6d6;
    background-color: #fff;

    position: absolute;

    ${({ state }) => {
      return state === 'calendar' ? `left: 0px;` : 'left: 50%;';
    }}
    transition: 0.2s;
`

const Image = styled.img`
    width:fit-content;

    margin: 0 auto;
    padding: 0px 10px 40px 10px;

    display:block;

    ${mobile(css`
        width:100%;
    `)}
`
const ScheduleWrapper = styled.div`
    width: 945px;
    margin: 50px auto 200px auto;
    margin-top: 50px;
    display:block;
    ${mobile(css`
        display:none;
    `)}
`
const MobileScheduleWrapper = styled.div`
    width: calc( 100% - 20px );
    margin: 30px auto 100px auto;

    display:none;
    ${mobile(css`
        display:block;
    `)}
`

const ListWrapper = styled.div`
    width: 945px;
    margin: 50px auto 80px auto;

    ${mobile(css`
        width: calc( 100% - 40px );
    `)}
`

function Schedule() {
    const [state, setState] = useState('calendar')
    const [calendarData, setCalendarData] = useState([])
    const [scheduleList, setScheduleList] = useState([])
    const history = useHistory()


    const [totalPageLength, setTotalPageLength] = useState(1)
    const [pageNumber, setPageNumber] = useState(1)
    function NavigationChange(subject) {
        if (subject === 'calendar') {
            setState('calendar')
        } else {
            setState('list')
        }
    }
    
    function changePageEvent(index){
        console.log(index);
        setPageNumber(index);
    }
    useEffect(async () => { 
        // console.log("렌더링이 완료될 때마다 실행합니다."); 
        if (state === "calendar") {
            axios.get(`${process.env.REACT_APP_API_URL}/research-schedule?eventType=1`).then((response)=>{
                let arr = []
                for (let i = 0; i < response.data.data.length; i++) {
                    let start = response.data.data[i].startdate;
                    let end = response.data.data[i].enddate
                    if (moment(start).format("YYYY-MM-DD") !== moment(end).format("YYYY-MM-DD")) {
                        arr.push({
                            id: response.data.data[i].id_event,
                            start: new Date(moment(start).format("YYYY"), moment(start).format("MM") -1, moment(start).format("DD")),
                            end: new Date( moment(start).format("YYYY"), moment(start).format("MM") - 1, moment(start).format("DD")),
                            title: response.data.data[i].title,
                            count: response.data.data[i].count
                        })
                        arr.push({
                            id: response.data.data[i].id_event,
                            start: new Date(moment(end).format("YYYY"), moment(end).format("MM") -1, moment(end).format("DD")),
                            end: new Date( moment(end).format("YYYY"), moment(end).format("MM") - 1, moment(end).format("DD")),
                            title: response.data.data[i].title,
                            count: response.data.data[i].count
                        })
                    } else {
                        arr.push({
                            id: response.data.data[i].id_event,
                            start: new Date(moment(start).format("YYYY"), moment(start).format("MM") -1, moment(start).format("DD")),
                            end: new Date( moment(end).format("YYYY"), moment(end).format("MM") - 1, moment(end).format("DD")),
                            title: response.data.data[i].title,
                            count: response.data.data[i].count
                        })
                    }
                }
                // console.log(arr)
                setCalendarData(arr)
            })
        } else {
            axios.get(`${process.env.REACT_APP_API_URL}/research-schedule?eventType=1&page=${pageNumber}`).then((data)=>{
                setScheduleList(data.data.data)
                setTotalPageLength(data.data.count)
                // console.log(data)
            })
        }
    }, [state, pageNumber]);

    return ( 
        <>            
            <Banner title="schedule"></Banner>
            <Navigation>
                <NavigationCricle state={state} />
                <p onClick={() => NavigationChange('calendar')}>달력</p>
                <p onClick={() => NavigationChange('list')}>목록</p>
            </Navigation>
            {
                state === "calendar" ? (
                    <>
                        <ScheduleWrapper >
                            <Calendar data={calendarData} onClick={(index)=>{history.push(`/app/notification/schedule/${index}/introduce`)}}></Calendar>
                        </ScheduleWrapper>
                        <MobileScheduleWrapper>
                            <MobileCalendar data={calendarData} onClick={(index)=>{history.push(`/app/notification/schedule/${index}/introduce`)}}></MobileCalendar>
                        </MobileScheduleWrapper>
                    </>
                ) : <ListWrapper>
                        <List changePageEvent={changePageEvent} data={scheduleList} slide={5} nowPage={pageNumber} length={totalPageLength} onClick={(index)=>{history.push(`/app/notification/schedule/${index}/introduce`)}} />
                    </ListWrapper>
            }
        </>
    );
}

export default Schedule;
