import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card, CardBody, Col, Badge, Table,Button,
} from 'reactstrap';
import MemberSearchData from './MemberSearchData';
import Pagination from './Pagination';
import axios from "axios"
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'


const renderTextField1 = ({ input, label, meta: { touched, error }, children, select, multiline, history }) => (
  <TextField
    className='material-form__field'
    label={label}
    type={input.type}
    error={touched && error}
    value={input.value}
    select={select}
    multiline={multiline}
    onChange={(e) => {
      e.preventDefault()
      input.onChange(e.target.value)
      if (e.target.value === '1') {
        history.push('/managements/articles')
      } else if (e.target.value === '3') {
        history.push('/managements/videos')
      } else if (e.target.value === '4') {
        history.push('/managements/academics')
      }
    }}
  >
    {children}
  </TextField>
)
const renderTextField2 = ({ input, label, meta: { touched, error }, children, handleSelect, select, multiline }) => (
  <TextField
    className='material-form__field'
    label={label}
    type={input.type}
    error={touched && error}
    value={input.value}
    select={select}
    multiline={multiline}
    onChange={(e) => {
      e.preventDefault()
      input.onChange(e.target.value)
      handleSelect(e.target.value)
    }}
  >
    {children}
  </TextField>
)

const { tableRowsData } = MemberSearchData();

const MemberTable = (props) => {
  const { t } = useTranslation('common');

  const memberDetail = (result) => {
    props.memberDetailFunc(result)
  }

  const memberSearchFunc = (result) => {
    props.memberInfo(result)
    // axios.get(`${process.env.REACT_APP_API_URL}/userinfo?id=${result.id_user_info}`)
    //   .then(res => props.memberInfo(res.data.data))
    //   .then(() => props.setLoading(true))
    //   .catch(err => console.log(err))
  }

  const headerResponsive = [
    { id: 1, title: '번호' },
    { id: 2, title: '상태' },
    { id: 3, title: '성함' },
    { id: 4, title: '아이디' },
    { id: 5, title: '면허번호' },
    { id: 7, title: '전화번호' },
    { id: 8, title: '가입일' },
    { id: 9, title: '마지막 접속' },
    { id: 10, title: '메모' },
    { id: 11, title: '상세' },
  ];


  const handleList = (e, callback) => {
    props.setListNum(e.target.value);
    callback()
  }
  return (
    <>
          <div className="card__title">
              <h1>검색결과</h1>
              <h5 className="subhead">{props.total}명의 회원이 검색되었습니다.</h5>
          </div>
          <TextField className='material-form__field' id='status' defaultValue="20" select onChange={(e) => handleList(e, () => props.searchList(props.searchValue, e.target.value, 1))}>
              <MenuItem className='material-form__option' value='20'>
                20
              </MenuItem>
              <MenuItem className='material-form__option' value='10'>
                10
              </MenuItem>
              <MenuItem className='material-form__option' value='50'>
                50
              </MenuItem>
              <MenuItem className='material-form__option' value='100'>
                100
              </MenuItem>
            </TextField>


          {/* <select onChange={(e) => handleList(e, ()=> props.searchList(props.searchValue, e.target.value, 1))} >
            <option value='20' selected>20</option>
            <option value='10'>10</option>
            <option value='50'>50</option>
            <option value='100'>100</option>
          </select> */}


          <Table responsive className="table--bordered">
            <thead>
              <tr>
                {headerResponsive.map(item => (
                  <th key={item.id}>{item.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {props.userList.map((item) => (
                <tr key={item.id_user_info}>
                  <td>{item.id_user_info}</td>
                  <td>{item.member_type}</td>
                  <td>{item.name}</td>
                  <td>{item.userid}</td>
                  <td>{item.license_number}</td>
                  <td>{item.cellphone}</td>
                  <td>{item.join_date}</td>
                  <td>{item.last_date}</td>
                  <td>{item.memo}</td>
                  <td><Button onClick={() => { memberDetail(true);memberSearchFunc(item) }}>상세보기</Button></td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination listNum={props.listNum} total={props.total} searchList={props.searchList} searchValue={props.searchValue} />
    </>
  );
};

export default MemberTable;