import React, { useEffect, useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import ResponsiveTable from "./ResponsiveTable";
import StatementTable from "./StatementTable";
import axios from "axios";

const Tabs = () => {
  const [activeTab, setActiveTab] = useState("1");
  // const [waiting, setWaiting] = useState([])
  // const [loading, setLoading] = useState(false)
  const [isClicked, setIsClicked] = useState(false);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className="tabs__wrap">
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => {
              toggle("1");
              setIsClicked((pre) => !pre);
            }}
          >
            회비 승인대기
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => {
              toggle("2");
              setIsClicked((pre) => !pre);
            }}
          >
            회비 결제내역
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <ResponsiveTable isClicked={isClicked} />
        </TabPane>
        <TabPane tabId="2">
          <StatementTable isClicked={isClicked} />
        </TabPane>
      </TabContent>
    </div>
  );
};

export default Tabs;
