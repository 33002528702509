/* eslint-disable */
import React, { useState } from "react";
import styled, { css } from "styled-components";
import { mobile } from "../lib/viewport";
import fileIcon from "../../../shared/img/board-file-icon_2.png";
import next from "../../../shared/img/sub-box-arrow-open.png";
import prev from "../../../shared/img/sub-box-arrow-close.png";
import listIcon from "../../../shared/img/board-view-list.png";
import moment from "moment";
import axios from "axios";
import profileIcon from "../../../shared/img/mypage/profile.png";

const NoticeBoardWrapper = styled.div`
  width: 945px;
  margin: 50px auto 100px auto;

  border-top: 0.7px solid black;

  ${mobile(css`
    width: calc(100% - 20px);
    margin: 20px auto 40px auto;
  `)}
`;

const Line = styled.div`
  background-color: #c3c8d2;

  width: ${(props) => props.width};
  height: ${(props) => props.height};

  margin: ${(props) => props.margin};

  background-color: ${(props) => props.color};
`;

const Header = styled.div`
  padding: 16px 4px 12px 4px;
  ${mobile(css`
    padding: 12px 4px 12px 4px;
  `)}
`;

const HeaderTitle = styled.h1`
  width: 540px;
  font-size: 24px;

  word-break: keep-all;
  line-height: 1.3;

  margin-bottom: 18px;

  color: #000000;

  ${mobile(css`
    width: 95%;
    font-size: 14px;
  `)}
`;

const HeaderInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > div {
    & > b {
      margin-right: 6px;
    }
    display: flex;
    align-items: center;
  }
  & > div:nth-child(2) {
    & > div {
      cursor: pointer;
    }
  }
`;

const Contents = styled.div`
  width: 100%;
  min-height: 210px;
  padding: 30px 0px 40px 0px;
`;

const ContentsImage = styled.img`
  width: 100%;
`;

const ContentsText = styled.div``;

const FileWrapper = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  height: 48px;

  padding: 0px 20px;
  background-color: #eaedf1;
  border-bottom: 0.5px solid #c3c8d2;

  margin-bottom: 24px;
  & > b {
    margin-right: 28px;
  }
  & > div {
    cursor: pointer;
  }
  & > div > u {
    color: #696e78;
  }
`;

const FileIcon = styled.img`
  width: 14px;
  height: 18px;
  margin-right: 6px;
`;

const CommentInputWrapper = styled.div`
  margin: 20px 0px;
  width: 100%;
  height: 170px;

  border-radius: 15px;
  border: solid 0.7px #cdd0d8;
  background-color: #fff;

  padding: 20px 30px;
`;

const CommentInput = styled.textarea`
  width: 100%;
  height: 55px;
  outline: none;
  resize: none;
  border: 0px;
  margin-top: 10px;
`;

const ReplyInput = styled.input`
  width: 100%;
  height: 45px;
  outline: none;
  resize: none;
  border: 0px;
  margin-left: 10px;
  padding: 4px 6px;
`;
const ReplyButton = styled.div`
  width: 60px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: bold;
  border-radius: 20px;
  border: solid 0.7px #cdd0d8;
  background-color: #fff;
  padding-top: 3px;
  cursor: pointer;
`;

const ReplyWrapper = styled.div`
  width: 100%;
  padding: 10px 36px 10px 60px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  border-bottom: 0.7px solid #c3c8d2;
`;

const CommentButton = styled.div`
  width: 68px;
  height: 36px;

  border-radius: 20px;
  border: solid 0.7px #cdd0d8;
  background-color: #fff;
  & > b {
    font-size: 16px;
  }

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  margin: 0 0 0 auto;
`;

const CommentInputHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CommentListHeader = styled.div`
  width: 1005;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0px 10px;
`;
const CommentListTitle = styled.div`
  font-size: 16px;
  & > b {
    color: #69788c;
    color: black;
    margin-right: 2px;
  }
`;

const SearchTypeWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SearchType = styled.div`
  cursor: pointer;
  ${({ selected }) => {
    return selected ? `color: #000; font-weight: bold` : "color: #a5aab4";
  }}
`;

const CommentList = styled.div`
  width: 100%;
  margin-bottom: 12px;
`;

const CommentWrapper = styled.div`
  width: 100%;
  padding: ${(props) => props.padding};
  background-color: ${(props) => props.bgColor};
  display: flex;

  border-bottom: 0.7px solid #c3c8d2;
`;

const ProfileImage = styled.img`
  width: 48px;
  height: 48px;
  border: solid 0.7px #cdd0d8;
  border-radius: 100%;
`;
const ReplyProfileImage = styled.img`
  width: 36px;
  height: 36px;
  border: solid 0.7px #cdd0d8;
  border-radius: 100%;
`;

const CommentContents = styled.div`
  width: calc(100% - 30px);
  margin-left: 20px;
  & > p {
    margin-top: 2px;
    word-break: break-all;
  }
`;

const CommentFooter = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
`;
const CommentFooterText = styled.span`
  font-size: 12px;
  color: #8a8e95;
  ${({ cursor }) => {
    return cursor ? `cursor:pointer` : "";
  }}
`;
const PaginationWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
`;
const Pagination = styled.div`
  display: flex;
  height: 48px;

  cursor: pointer;
`;
const PaginationArrow = styled.div`
  width: 120px;
  height: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0px 20px;
  background-color: #eaedf1;
`;

const PaginationContents = styled.div`
  height: 100%;
  padding: 0px 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ListButton = styled.div`
  width: 200px;
  height: 51px;
  border-radius: 7px;
  border: solid 0.7px #000;
  background-color: #474747;

  margin: 50px auto 0px auto;

  color: white;

  display: flex;
  justify-content: center;
  align-items: center;

  & > img {
    margin-right: 6px;
  }

  cursor: pointer;
`;

const CommentNone = styled.div`
  width: 100%;
  height: 210px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 22px;
`;
function timeForToday(value) {
  const today = new Date();
  const timeValue = new Date(value);

  const betweenTime = Math.floor(
    (today.getTime() - timeValue.getTime()) / 1000 / 60
  );
  if (betweenTime < 1) return "방금전";
  if (betweenTime < 60) {
    return `${betweenTime}분전`;
  }

  const betweenTimeHour = Math.floor(betweenTime / 60);
  if (betweenTimeHour < 24) {
    return `${betweenTimeHour}시간전`;
  }

  const betweenTimeDay = Math.floor(betweenTime / 60 / 24);
  if (betweenTimeDay < 365) {
    return `${betweenTimeDay}일전`;
  }

  if (`${Math.floor(betweenTimeDay / 365)}` === "NaN") {
    return "알 수 없음";
  }

  return `${Math.floor(betweenTimeDay / 365)}년전`;
}

function Comment({
  ReplyDelete,
  ReplyCreate,
  data,
  myData,
  ReplyUpdate,
  category,
}) {
  const [state, setState] = useState(false);
  const [alterState, setAlterState] = useState(false);
  const [replyText, setReplyText] = useState("");
  const [alterReplyText, setAlterReplyText] = useState("");
  return (
    <>
      <CommentWrapper padding="24px 36px" bgColor="#f2f3f5">
        {data.profile ? (
          <ProfileImage
            src={`${process.env.REACT_APP_API_URL}/${data.profile}`}
            alt="profile_img"
          />
        ) : (
          <ProfileImage src={profileIcon} alt="profile_img" />
        )}
        <CommentContents>
          <b>{data.name ? data.name : ""}</b>
          <p>{data.status === "정상" ? data.contents : "삭제된 댓글입니다."}</p>
          <CommentFooter>
            <CommentFooterText>{timeForToday(data.c_data)}</CommentFooterText>
            <Line width="1px" height="10px" margin="0px 10px" />
            <CommentFooterText cursor onClick={() => setState(!state)}>
              답글쓰기
            </CommentFooterText>
            {myData.userid === data.userid && data.status === "정상" && (
              <>
                <Line width="1px" height="10px" margin="0px 10px" />
                <CommentFooterText
                  cursor
                  onClick={() => setAlterState(!alterState)}
                >
                  수정
                </CommentFooterText>
                <Line width="1px" height="10px" margin="0px 10px" />
                <CommentFooterText
                  cursor
                  onClick={() => ReplyDelete(data.id_reply, category)}
                >
                  삭제
                </CommentFooterText>
              </>
            )}
          </CommentFooter>
        </CommentContents>
      </CommentWrapper>
      {data.rere.map((v) => {
        return (
          <CommentReply
            ReplyDelete={(id, category) => ReplyDelete(id, category)}
            category={category}
            ReplyUpdate={(id, text, category, callback) =>
              ReplyUpdate(id, text, category, callback)
            }
            data={v}
            myData={myData}
          />
        );
      })}
      {alterState && (
        <ReplyWrapper padding="24px 36px 20px 60px" bgColor="#ffffff">
          {data.profile ? (
            <ReplyProfileImage
              src={`${process.env.REACT_APP_API_URL}/${data.profile}`}
              alt="profile_img"
            />
          ) : (
            <ReplyProfileImage src={profileIcon} alt="profile_img" />
          )}
          <ReplyInput
            onChange={(e) => {
              setAlterReplyText(e.target.value);
            }}
            value={alterReplyText}
            placeholder="수정할 텍스트를 입력해주세요."
          />
          <ReplyButton
            onClick={() => {
              ReplyUpdate(data.id_reply, alterReplyText, category, () => {
                setAlterReplyText("");
                setAlterState(false);
              });
            }}
          >
            <span>수정</span>
          </ReplyButton>
        </ReplyWrapper>
      )}
      {state && (
        <ReplyWrapper padding="24px 36px 20px 60px" bgColor="#ffffff">
          {data.profile ? (
            <ReplyProfileImage
              src={`${process.env.REACT_APP_API_URL}/${data.profile}`}
              alt="profile_img"
            />
          ) : (
            <ReplyProfileImage src={profileIcon} alt="profile_img" />
          )}{" "}
          <ReplyInput
            onChange={(e) => {
              setReplyText(e.target.value);
            }}
            value={replyText}
            placeholder="답글을 입력해주세요."
          />
          <ReplyButton
            onClick={() =>
              ReplyCreate(data.reply_group, replyText, category, () => {
                setReplyText("");
                setState(false);
              })
            }
          >
            <span>등록</span>
          </ReplyButton>
        </ReplyWrapper>
      )}
    </>
  );
}

function CommentReply({ ReplyDelete, data, myData, ReplyUpdate, category }) {
  const [alterState, setAlterState] = useState(false);
  const [alterReplyText, setAlterReplyText] = useState("");
  return (
    <>
      <CommentWrapper padding="24px 36px 20px 60px" bgColor="#ebedef">
        {data.profile ? (
          <ProfileImage
            src={`${process.env.REACT_APP_API_URL}/${data.profile}`}
            alt="profile_img"
          />
        ) : (
          <ProfileImage src={profileIcon} alt="profile_img" />
        )}
        <CommentContents>
          <b>{data.name ? data.name : ""}</b>
          <p>{data.status === "정상" ? data.contents : "삭제된 답글입니다."}</p>
          <CommentFooter>
            <CommentFooterText>{timeForToday(data.c_data)}</CommentFooterText>
            {myData.userid === data.userid && data.status === "정상" && (
              <>
                <Line width="1px" height="10px" margin="0px 10px" />
                <CommentFooterText
                  cursor
                  onClick={() => setAlterState(!alterState)}
                >
                  수정
                </CommentFooterText>
                <Line width="1px" height="10px" margin="0px 10px" />
                <CommentFooterText
                  cursor
                  onClick={() => ReplyDelete(data.id_reply, category)}
                >
                  삭제
                </CommentFooterText>
              </>
            )}
          </CommentFooter>
        </CommentContents>
      </CommentWrapper>
      {alterState && (
        <ReplyWrapper padding="24px 36px 20px 60px" bgColor="#ffffff">
          {data.profile ? (
            <ReplyProfileImage
              src={`${process.env.REACT_APP_API_URL}/${data.profile}`}
              alt="profile_img"
            />
          ) : (
            <ReplyProfileImage src={profileIcon} alt="profile_img" />
          )}
          <ReplyInput
            onChange={(e) => {
              setAlterReplyText(e.target.value);
            }}
            value={alterReplyText}
            placeholder="수정할 텍스트를 입력해주세요."
          />
          <ReplyButton
            onClick={() => {
              ReplyUpdate(data.id_reply, alterReplyText, category, () => {
                setAlterReplyText("");
                setAlterState(false);
              });
            }}
          >
            <span>수정</span>
          </ReplyButton>
        </ReplyWrapper>
      )}
    </>
  );
}

function NoticeBoard({
  ReplyUpdate,
  BoardDelete,
  ReplyDelete,
  alter,
  ReplyCreate,
  setReplyCategory,
  myData,
  commentData,
  nextData,
  prevData,
  data,
  pagination,
  listButtonClick,
  comment,
  onClick,
}) {
  const [commentInputValue, setCommentInputValue] = useState("");
  const [commentListSearchType, setCommentListSearchType] = useState("등록순");
  function download(url) {
    var filename = url.substring(url.lastIndexOf("/") + 1).split("?")[0];
    var xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.onload = function () {
      var a = document.createElement("a");
      a.href = window.URL.createObjectURL(xhr.response); // xhr.response is a blob
      a.download = filename; // Set the file name.
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      a = null;
    };
    xhr.open("GET", url);
    xhr.send();
  }

  //   const newDown = (link, fileName) => {
  //     axios
  //       .get(`${process.env.REACT_APP_API_URL}/download?link=${link}`, {
  //         responseType: "blob",
  //       })
  //       .then((res) => {
  //         const url = window.URL.createObjectURL(new Blob([res.data]));
  //         const link = document.createElement("a");
  //         link.href = url;
  //         link.setAttribute("download", fileName);
  //         document.body.appendChild(link);
  //         link.click();
  //         link.parenNode.removeChild(link);
  //       })
  //       .catch((err) => console.log(err));
  //   };
  return (
    <NoticeBoardWrapper>
      <Header>
        <HeaderTitle>{data.title ? data.title : ""}</HeaderTitle>
        <HeaderInfo>
          <div>
            <b>작성일</b>
            <div>{moment(data.c_date).format("YYYY-MM-DD")}</div>
            <Line width="1px" height="10px" margin="0px 12px 1px 12px;" />
            <b>조회수</b>
            <div>
              {data.view_count
                ? data.view_count === null || data.view_count === "NaN"
                  ? 1
                  : Number(data.view_count) + 1
                : data.count === null || data.count === "NaN"
                ? 1
                : Number(data.count) + 1}
            </div>
          </div>
          <div>
            {myData && myData.name === data.writer ? (
              <>
                <div onClick={() => alter()}>수정</div>
                <Line width="1px" height="10px" margin="0px 12px 1px 12px;" />
                <div onClick={() => BoardDelete(data.id_notice)}>삭제</div>
              </>
            ) : (
              <></>
            )}
          </div>
        </HeaderInfo>
      </Header>
      <Line width="100%" height="0.5px" />
      <Contents dangerouslySetInnerHTML={{ __html: data.contents }}></Contents>
      {data.file_link !== null ? (
        <>
          <Line width="100%" height="0.7px" color="black" />
          <FileWrapper>
            <b>첨부파일</b>
            <div>
              <FileIcon src={fileIcon} alt="file_icon" />
              <u
                onClick={() =>
                  download(process.env.REACT_APP_API_URL + "/" + data.file_link)
                }
              >
                {data.original_fileName ? data.original_fileName : ""}
              </u>
              {/* <u
                onClick={() => newDown(data.file_link, data.original_fileName)}
              >
                {data.original_fileName ? data.original_fileName : ""}
              </u> */}
            </div>
          </FileWrapper>
        </>
      ) : (
        <></>
      )}
      {comment && (
        <>
          <CommentInputWrapper>
            <CommentInputHeader>
              <b>{myData ? myData.name : ""}</b>
              <span>{commentInputValue.length} / 800</span>
            </CommentInputHeader>
            <CommentInput
              onChange={(e) => {
                setCommentInputValue(e.target.value);
              }}
              value={commentInputValue}
              placeholder="저작권 등 다른 사람의 권리를 침해하거나 명예를 훼손하는 게시물은 이용약관 및 관련 법률에 의해 제재를 받을 수 있습니다.&#13;&#10;건전한 토론 문화와 양질의 댓글 문화를 위해, 타인에게 불쾌감을 주는 욕설 또는 계층/민족, 종교 등을 비하하는 단어들은 제재의 대상이 될 수 있습니다."
            />
            <CommentButton
              onClick={() => {
                ReplyCreate(
                  0,
                  commentInputValue,
                  commentListSearchType,
                  () => {}
                );
                setCommentInputValue("");
              }}
            >
              <b>등록</b>
            </CommentButton>
          </CommentInputWrapper>
          <CommentListHeader>
            <CommentListTitle>
              <b>댓글</b> {commentData ? commentData.length : 0}
            </CommentListTitle>
            <SearchTypeWrapper>
              <SearchType
                selected={commentListSearchType === "등록순"}
                onClick={() => {
                  setCommentListSearchType("등록순");
                  setReplyCategory("등록순");
                }}
              >
                등록순
              </SearchType>
              <Line width="1px" height="10px" margin="0px 10px" />
              <SearchType
                selected={commentListSearchType === "최신순"}
                onClick={() => {
                  setCommentListSearchType("최신순");
                  setReplyCategory("최신순");
                }}
              >
                최신순
              </SearchType>
            </SearchTypeWrapper>
          </CommentListHeader>
          <CommentList>
            {commentData.length !== 0 ? (
              commentData.map((v) => {
                return (
                  <>
                    <Comment
                      ReplyDelete={(id, category) => ReplyDelete(id, category)}
                      ReplyCreate={(replyType, replyText, category, callback) =>
                        ReplyCreate(replyType, replyText, category, callback)
                      }
                      ReplyUpdate={ReplyUpdate}
                      data={v}
                      myData={myData}
                      category={commentListSearchType}
                    />
                  </>
                );
              })
            ) : (
              <CommentNone>댓글이 존재하지 않습니다.</CommentNone>
            )}
          </CommentList>
        </>
      )}
      {pagination && (
        <PaginationWrapper>
          {prevData !== false ? (
            <Pagination onClick={() => onClick(prevData)}>
              <PaginationArrow>
                <div>이전글</div>
                <img src={prev} alt="file_icon" />
              </PaginationArrow>
              <PaginationContents>{prevData.title}</PaginationContents>
            </Pagination>
          ) : (
            <></>
          )}
          {nextData !== false ? (
            <Pagination onClick={() => onClick(nextData)}>
              <PaginationArrow>
                <div>다음글</div>
                <img src={next} alt="file_icon" />
              </PaginationArrow>
              <PaginationContents>{nextData.title} </PaginationContents>
            </Pagination>
          ) : (
            <></>
          )}
        </PaginationWrapper>
      )}
      {listButtonClick && (
        <ListButton onClick={() => listButtonClick()}>
          <img src={listIcon} alt="file_icon" />
          <span>목록</span>
        </ListButton>
      )}
    </NoticeBoardWrapper>
  );
}

export default NoticeBoard;
