import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card, CardBody, Col,
} from 'reactstrap';
import Tabs from './Tabs';
import axios from "axios";
import { useDispatch } from 'react-redux';
import {getList} from '../../../redux/actions/payWaiting'

const JustifyTabsBorderedTop = () => {
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   console.log("hi")
  //   axios.get(`${process.env.REACT_APP_API_URL}/infowaiting`)
  //     .then((res) => dispatch(getList(res.data)))
  //     .catch(err => console.log(err))
  // }, [])

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h1>납부내역</h1>
          </div>
          <div className="tabs tabs--justify tabs--bordered-top">
            <Tabs />
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default JustifyTabsBorderedTop;
