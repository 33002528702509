/* eslint-disable */
import React, {useState} from 'react';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { NavLink} from 'react-router-dom';
import { useHistory } from "react-router";
import PropTypes from 'prop-types';
import { Alert, Button } from 'reactstrap';
import PasswordField from '@/shared/components/form/Password';
import renderCheckBoxField from '../form/CheckBox';
import axios from 'axios'
import { AxisItemLocation } from '@amcharts/amcharts4/.internal/charts/axes/Axis';
import { useDispatch } from 'react-redux';
import {getAdmin} from '../../../redux/actions/adminActions'
axios.defaults.withCredentials = true;


const LogInForm = ({
  onSubmit, errorMessage, errorMsg, fieldUser, typeFieldUser, form,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [data, setData] = useState({id: "", password: ""})
  const handleInput = (e, type) => {
    setData((pre) => {
      return {...pre, [type]: e.target.value}
    })
  }

  const handleFetch = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/adminlogin`, data)
    .then(res => {
      axios.post(`${process.env.REACT_APP_API_URL}/adminverification`, {token: res.data.accessToken})
      .then(() => dispatch(getAdmin()))
      .then(res2 => history.push('/admin'))
    })
    .catch(err => history.push('/'))
  }

  const handleEnter = (e) => {
    if(e.code === "Enter" || e.code === "NumpadEnter"){
      handleFetch()
    }
  }

  return (
  <Form onSubmit={onSubmit}>
    {({ handleSubmit }) => (
      <form className="form login-form" onSubmit={handleSubmit}>
        <Alert
          color="danger"
          // isOpen={!!errorMessage || !!errorMsg}
        >
          이 페이지는 관리자 페이지 입니다.
        </Alert>
        <div className="form__form-group">
          <span className="form__form-group-label">UserID</span>
          <div className="form__form-group-field">
            {/* <div className="form__form-group-icon"> */}
              {/* <AccountOutlineIcon /> */}
            {/* </div> */}
            <input type="text" className="input-without-border-radius" onChange={(e) => handleInput(e, "id")}></input>
            {/* <Field
              name="username"
              component="input"
              type={typeFieldUser}
              placeholder={fieldUser}
              className="input-without-border-radius"
            /> */}
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Password</span>
          <div className="form__form-group-field">
          <input type="password" className="input-without-border-radius" onKeyUp={handleEnter} onChange={(e) => handleInput(e, "password")}></input>
            {/* <Field
              name="password"
              component={PasswordField}
              placeholder="Password"
              className="input-without-border-radius"
              keyIcon
            /> */}
          </div>
        </div>
        {/* <div className="form__form-group">
          <div className="form__form-group form__form-group-field">
            <Field
              name="remember_me"
              render={renderCheckBoxField}
              label="Remember me"
              type="checkbox"
            />
          </div>
        </div> */}
        <div className="account__btns">
                {/* <NavLink className="account__btn btn btn-primary" to="/members" type="submit"> */}
                  <button type="button" className="account__btn btn btn-primary" onClick={handleFetch}>로그인</button>
                {/* </NavLink> */}
        </div>
      </form>
    )}
  </Form>
)};

LogInForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  errorMsg: PropTypes.string,
  fieldUser: PropTypes.string,
  typeFieldUser: PropTypes.string,
  form: PropTypes.string.isRequired,
};

LogInForm.defaultProps = {
  errorMessage: '',
  errorMsg: '',
  fieldUser: 'Username',
  typeFieldUser: 'text',
};

export default connect(state => ({
  errorMsg: state.user.error,
}))(LogInForm);