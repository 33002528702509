import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Greeting from './Greeting';
import Guide from './Guide';
import Member from './Member';
import Location from './Location';
import Regulations from './Regulations';
import Rule from './Rule'

export default () => (
  <Switch>
    <Route exact path="/app/introduce/greeting" component={Greeting} />
    <Route exact path="/app/introduce/regulations" component={Regulations} />
    <Route exact path="/app/introduce/rule" component={Rule} />
    <Route exact path="/app/introduce/member" component={Member} />
    <Route exact path="/app/introduce/guide" component={Guide} />
    <Route exact path="/app/introduce/location" component={Location} />
  </Switch>
);
