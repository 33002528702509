import React from "react"
import styled,{css} from "styled-components"

import {mobile} from "../lib/viewport"
import next from "../../../shared/img/board-page-arrow-next.png"
import prev from "../../../shared/img/board-page-arrow-prev.png"
import moment from "moment"
import { useHistory } from "react-router"

//#region styles
const ListWrapper = styled.div`
    width:100%;

    ${mobile(css`
        width: calc(100% - 30px);
        margin: 0 auto;
    `)}
`
const TablePaginationWrapper = styled.div`
    width:fit-content;
    max-width: 400px;
    margin: 36px auto 0px auto;

    display:flex;
    & > div{
        margin: 0px 2px;
    }

`
const TablePaginationArrow = styled.div`
    width: 33px;
    height: 27px;
    display:flex;
    justify-content:center;
    align-items:center;

    border-radius: 4px;
    border: solid 0.7px #ccced7;
    background-color: #f2f3f5;

    cursor:pointer;
`

const TablePaginationItem = styled.div`
    width: 27px;
    height: 27px;
    border-radius: 4px;

    ${({ selected }) => {
      return selected ? `color: #000000; border: solid 0.7px #000000;` : 'color: #8e909c; border: solid 0.7px #ccced7;';
    }}

    display:flex;
    justify-content:center;
    align-items:center;

    background-color:#ffffff;

    cursor:pointer;
`
const ListItem = styled.div`
    width:100%;
    height: 160px;
    padding: 20px 0px;

    border: solid 0px;
    display:flex;
    align-items:center;

    border-bottom: solid 0.7px #e0e1e5;

    cursor:pointer;

    &:hover{
        /* border: solid 0.7px #000; */
        background-color: #eaebed;
    }
    & > img{
        margin-right: 20px;
    }

    ${mobile(css`
        padding: 4px 12px;
        height:100%;
    `)}
`


const ListItemTitle = styled.div`
    font-size: 20px;
    margin: 2px 0px 10px 0px;

    ${mobile(css`
        font-size: 16px;
        word-break:keep-all;
        margin: 2px 0px 4px 0px;
    `)}
`

const ListItemInfo = styled.div`
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    padding: 20px 0px;
`
const Image = styled.img`
    width: 135px;
    height:95px;

    ${mobile(css`
        width: 120px;
        height: 80px;
    `)}
`
const ListNone = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    margin: 0 auto;
    width:100%;
    height: 210px;
    font-size: 20px;

`

//#endregion

function VideoList({ data, slide, length, nowPage, onClick }) {
    const history = useHistory()
    return (
        <ListWrapper>
             {
                 data.length > 0 ? data.map((value)=>{
                     return (
                         <ListItem key={value.id_video} onClick={() => history.push(`/app/community/video/${value.id_video}/${length}`)}>
                             <Image src={value.thumbnail} alt="" />
                            <ListItemInfo>
                                <div>{moment(value.c_date).format("YYYY-MM-DD")}</div>
                                <ListItemTitle>{value.title}</ListItemTitle>
                                {/* <p dangerouslySetInnerHTML={{__html: value.contents}}></p>  */}
                            </ListItemInfo> 
                         </ListItem>
                     )
                 }) : <ListNone>게시물이 없습니다.</ListNone>
             }  
            <TablePaginationWrapper>
                <TablePaginationArrow>
                    <img src={prev} alt="pagination arrow" />
                </TablePaginationArrow>
                {
                    [...Array(Math.ceil(length / slide)).keys()].map((index, item)=>{
                        return (
                            <TablePaginationItem key={index} selected={item+1 === nowPage}>
                                {item + 1}
                            </TablePaginationItem>
                        )
                    })
                }
            <TablePaginationArrow>
                <img src={next} alt="pagination arrow" />
            </TablePaginationArrow>
        </TablePaginationWrapper>
        </ListWrapper>
    )
}

export default VideoList;
