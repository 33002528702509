import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card, CardBody, Col, Badge, Table,Button,
} from 'reactstrap';
import PaymentsHistoryData from './PaymentsHistoryData';
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import TabPagination from './tabPagination';

const { tableHeaderResponsiveData, tableRowsData } = PaymentsHistoryData();

const ResponsiveTable = ({userid}) => {
  let payStatus
  const [loading, setLoading] = useState(false)
  const [waiting, setWaiting] = useState([])
  const [pageNum, setPageNum] = useState(1)
  const [total, setTotal] = useState(0)
  const [onOff, setOnOff] = useState(false)

  const handleOption = (e) => {
    payStatus = e.target.value
    // console.log(payStatus)
  }

  const handleFetch = (item) => {
   if (window.confirm("변경 하시겠습니까?")){
     axios.post(`${process.env.REACT_APP_API_URL}/changestatementperson`, {...item, pay_status: payStatus ? payStatus : item.pay_status})
      .then(() => setOnOff(pre => !pre))
      .catch(err =>  console.log(err))
    }
  }

  useEffect(() => {
    // console.log(onOff)
    axios.get(`${process.env.REACT_APP_API_URL}/infostatementperson?pagenum=${pageNum}&userid=${userid}`)
      .then((res) => {
        setTotal(res.data.data.count)
        setWaiting(res.data.data.rows)
      })
      .then(() => setLoading(true))
      .catch(err => console.log(err))
  }, [pageNum, onOff])

  if (!loading) return <div></div>;

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <Table responsive className="table--bordered">
            <thead>
              <tr>
                {tableHeaderResponsiveData.map(item => (
                  <th key={item.id}>{item.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {waiting.map(item => (
                <tr key={uuidv4()}>
                  <td>{item.c_date}</td>
                  <td>{item.payer}</td>
                  <td>{item.pay_contents}</td>
                  <td>{item.money}</td>
                  <td>{item.pay_status}</td>
                  <td>
                    {/* <Form.Select aria-label="Default select example" onChange={handleOption}> */}
                      {/* <option value="결제완료" selected={ payStatus === "결제완료" ? "selected" : null}>결제완료</option>
                      <option value="결제대기" selected={ payStatus === "결제대기" ? "selected" : null}>결제대기</option>
                      <option value="결제취소" selected={ payStatus === "결제취소" ? "selected" : null}>결제취소</option> */}
                    <select onChange={handleOption}>
                      <option value="결제완료" selected={item.pay_status === "결제완료" ? "selected" : null}>결제완료</option>
                      <option value="결제대기" selected={item.pay_status === "결제대기" ? "selected" : null}>결제대기</option>
                      <option value="결제취소" selected={item.pay_status === "결제취소" ? "selected" : null}>결제취소</option>
                    </select>
                    {/* </Form.Select> */}
                    <Button color="primary" onClick={() => handleFetch(item)}>확인</Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {total === 0 ? "납부내역이 없습니다" : <TabPagination setPageNum={setPageNum} total={total}/>}
        </CardBody>
      </Card>
    </Col>
  );
};

export default ResponsiveTable;