import React, {useEffect, useState} from "react"
import Banner from './components/Banner';

import NoticeBoard from "../../components/NoticeBoard"
import { useHistory, useParams } from "react-router-dom";

import axios from "axios"

function DetailVideo() {
    const history = useHistory();
    const params = useParams()
    const [boardData, setBoardData] = useState({});
    const [prev, setPrev] = useState({});
    const [next, setNext] = useState({});

    const [change, setChange] = useState(false);
    const [myData, setMyData] = useState({})
    const [commentData, setCommentData] = useState();

    function onChangePage(value) { history.replace(`/app/community/video/${value.board_num}/${params.length}`); setChange(!change)}
    useEffect(() => { 
        axios.get(`${process.env.REACT_APP_API_URL}/videoRead?num=${params.number}`).then((data)=>{
            if (Number(params.number) === 1) {
                setPrev(false)
            } else {
                axios.get(`${process.env.REACT_APP_API_URL}/videoRead?num=${Number(params.number) - 1}`).then((data2)=>{
                    setPrev(data2.data.data)
                }).catch((err) => console.log(err))
            }
            if (Number(params.number) === Number(params.length)) {
                setNext(false)
            } else {
                axios.get(`${process.env.REACT_APP_API_URL}/videoRead?num=${Number(params.number) + 1}`).then((data2)=>{
                    setNext(data2.data.data)
                }).catch((err) => console.log(err))
            }
            let count = data.data.data.count === null ? 1 : data.data.data.count + 1;

            axios.get(`${process.env.REACT_APP_API_URL}/videoCountUpdate?count=${count}&num=${Number(params.number)}`).then(data2 => {
                    // console.log(data2)
                    return null
            })
            
            setBoardData(data.data.data)
        })

        if (localStorage.getItem("accessToken")) {
            axios.post(`${process.env.REACT_APP_API_URL}/verification`, {
                token: localStorage.getItem("accessToken")
            })
            .then(data => {
                setMyData(data.data.data)
            })
            .catch(err => {
                if (err.response) {
                    console.log(err.response)
                } else {
                    console.log(err.response)
                }
            })
        }
    }, [change]);

    return (
        <>
            <Banner title="video"></Banner>
            <NoticeBoard myData={myData} commentData={commentData} onClick={(value) => onChangePage(value)} data={boardData} pagination={false} comment={false} listButtonClick={() => { history.push("/app/community/video")}} />
        </>
    )
}

export default DetailVideo