import React, { useState } from "react"
import styled, { css } from "styled-components" 
import axios from "axios"

import Banner from './components/Banner';
import Alert from "../../components/Alert"

import { mobile } from "../../lib/viewport";
import { useHistory } from "react-router";


const Form = styled.form`
    width: fit-content;

    margin: 60px auto 160px auto;
    padding-right: 4em;
    ${mobile(css`
        width: 100%;
        padding-right: 0;
    `)}
`

const InputWrapper = styled.div`
    display: flex;
    justify-content: flex-end;

    margin: 0 auto 10px auto;

    & > span{
        width: fit-content;
        margin-right: 24px;

        display:flex;
        align-items:center;

        font-weight: bold;
        font-size: 15px;
    }
    ${mobile(css`
        justify-content: center;
        & > span{
            display: none;
        }
    `)}
`

const InputText = styled.input`
    width: 350px;
    height: 50px;

    ${({ borderRadius }) => {
      return borderRadius ? `border-radius: ${borderRadius};` : 'border-radius: 6px;';
    }}
    border: solid 0.7px #e0e1e5;
    background-color: #fff;

    padding: 0px 20px;
    font-size: 15px;

    outline:none;
`

const InputDoubleWrapper = styled.div`
    width: 350px;
    height: 100%;
`

const InputButton = styled.div`
    max-width: 350px;
    min-width: 350px;
    height: 50px;

    border-radius: 6px;
    border: solid 0.5px #000;
    background-color: #474747;

    color: white;
    font-size: 15px;

    display:flex;
    justify-content:center;
    align-items:center;

    margin-top: 12px;

    cursor:pointer;
`

function PwChange() {
    const [form, setForm] = useState({ password: "", newPassword: "", newRPassword: "" })
    const history = useHistory("")
    const [modalUrlState, setModalUrlState] = useState(false)
    

    const inputOnChange = (e) => {
        const { name, value } = e.target   
        const nextInputs = {            
            ...form,  
            [name]: value,
        }
        setForm(nextInputs)       
    }
    const [errorText, setErrorText] = useState("")
    function setupErrorText() {
        setErrorText("")
        if (modalUrlState) {
            history.push("/");
        }
    }
    function passwordChange() {
        const regPassword = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
        // console.log(form, form.newPassword === form.newRPassword)
        if (form.password === "") {
            setErrorText("비밀번호를 입력해 주세요.")
        } else if (form.password.length > 16) {
            setErrorText("비밀번호는 16자 이하입니다.")
        } else if (form.newPassword === "") {
            setErrorText("새 비밀번호를 입력해 주세요.")
        } else if (form.newPassword.length > 16) {
            setErrorText("비밀번호는 16자 이하로 입력해 주세요")
        } else if (form.newPassword.length < 7) {
            setErrorText("비밀번호는 8자 이상입니다.")
        } else if (!regPassword.test(form.newPassword)) {
            console.log(!regPassword.test(form.newPassword))
            setErrorText("비밀번호는 영어와 숫자를 혼합해야 합니다.")
        } else if (form.license_number === "") {
            setErrorText("새 비밀번호를 재입력해 주세요")
        } else if (form.newPassword !== form.newRPassword) {
            setErrorText("새 비밀번호가 일치하지 않습니다.")
        } else {
            if (localStorage.getItem("accessToken")) {
                axios.post(`${process.env.REACT_APP_API_URL}/verification`, {
                    token: localStorage.getItem("accessToken")
                })
                .then(data => {
                    axios.post(`${process.env.REACT_APP_API_URL}/changepassword`, {password: form.password, newPassword: form.newPassword, userid: data.data.data.userid}).then(data2 => {
                            // if (data2.statusText === "OK") {
                                setModalUrlState(true);
                                setErrorText(data2.data)
                                setForm({ password: "", newPassword: "", newRPassword: "" })
                            // }
                        }).catch(err => {
                            if (err.response.data){
                                setErrorText(err.response.data)
                            } else {
                                console.log(err.response)
                            }
                        })
                })
                .catch(err => {
                    if (err.response) {
                        setErrorText(err.response.data.message)
                    } else {
                        console.log(err.response)
                    }
                })
            }
        }
    }
    return (
        <>
            <Alert openState={errorText === "" ? false: true } text={errorText} setupErrorText={()=>setupErrorText()}/>
            <Banner title="pwchange"></Banner>
            <Form>
                <InputWrapper>
                    <span>비밀번호 변경</span>
                    <InputDoubleWrapper>
                        <InputText name="password" value={form.password} type="password" placeholder="현재 비밀번호 입력" borderRadius="6px 6px 0 0" onChange={inputOnChange} />
                        <InputText name="newPassword" value={form.newPassword} type="password" placeholder="새 비밀번호 입력" borderRadius="0" onChange={inputOnChange} />
                        <InputText name="newRPassword" value={form.newRPassword} type="password" placeholder="새 비밀번호 재입력" borderRadius="0 0 6px 6px" onChange={inputOnChange} />
                    </InputDoubleWrapper>
                </InputWrapper>
                <InputWrapper>
                    <InputButton onClick={() => {passwordChange()}}>
                        <span>변경완료</span>
                    </InputButton>
                </InputWrapper>
            </Form> 
        </>
    )
}

export default PwChange