import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import memberSearch from './memberSearch';
import AnimatedLineFormWithLabels from './components/AnimatedLineFormWithLabels';
import axios from "axios"

const Members = () => {
  const { t } = useTranslation('common');
  const [userList, setUserList] = useState([])
  const [total, setTotal] = useState(0)
  const [listNum, setListNum] = useState("20")
  const [searchValue, setSearchValue] = useState({})
  // const [hide, setHide] = useState(false)
  const searchList = (value, list=20, curPage=1) => {
    setSearchValue(value)
    axios.post(`${process.env.REACT_APP_API_URL}/search`, {
      data: value,
      pageNum: curPage,
      limit: Number(list)
    })
    .then(data => {
      // console.log(data.data.list)
      if (data.data.list.length === 0){
        window.alert("일치하는 정보가 없습니다.")
      } else {
        setUserList(data.data.list)
        setTotal(data.data.count)
      }
    })
    .catch(err => {
      console.log(err.response)
      if (err.response.data.message === "검색어가 없습니다."){
        window.alert(err.response.data.message)
      } else {
        console.log(err.response)
      }
    })
  }

  return (
    <Container>
      <Row>
        <AnimatedLineFormWithLabels onSubmit={searchList} searchValue={searchValue} searchList={searchList} userList={userList} total={total} setListNum={setListNum} listNum={listNum}/>
      </Row>
      <Row>
        <Col md={12}>
          <hr />
        </Col>
      </Row>
    </Container>
  );
};

export default Members;
