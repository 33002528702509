import React, { useEffect, useState } from "react"
import styled,{css} from "styled-components";
import Banner from './components/Banner';
import Table from '../../components/Table';
import {mobile} from "../../lib/viewport"
import axios from "axios"

const DuesHistoryWrapper = styled.div`
    width: 945px;
    margin: 50px auto 200px auto;
    margin-top: 50px;

    ${mobile(css`
        width:100%;
    `)}
`

function DuesHistory() {
    const [resData, setResData] = useState([]);
    const [totalPage, setTotalPage] = useState(1);
    const [tableData, setTableData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);

    useEffect(()=>{
        if (localStorage.getItem("accessToken")) {
            axios.post(`${process.env.REACT_APP_API_URL}/verification`, {
                token: localStorage.getItem("accessToken")
            })
            .then(data => {
                axios.get(`${process.env.REACT_APP_API_URL}/paymentlist?userid=${data.data.data.userid}`).then((data2)=>{
                    setResData(data2.data.final)
                    setTotalPage(data2.data.final.length)
                    setTableData(data2.data.final.slice((pageNumber -1 ) * 8, 8 * pageNumber))
                })
            })
            .catch(err => {
                if (err.response) {
                    console.log(err.response)
                } else {
                    console.log(err.response)
                }
            })
        }
    },[pageNumber])
    function changePageEvent(index){
        // console.log(index)
        setPageNumber(index);
    }
    return (
        <>
            <Banner title="dueshistory"></Banner>
            <DuesHistoryWrapper>
                <Table changePageEvent={changePageEvent} length={totalPage} nowPage={pageNumber} slide={8} data={tableData}
                 columnInfo={
                    { 
                      year: { name:"년도", width: "10%", position: "center" }, 
                      pay_contents: { name:"결 제 내 용", width: "52%", position: "flex-start" }, 
                      money: { name:"금액", width: "12%", position: "center" }, 
                      pay_status: { name:"현황", width: "12%", position: "center" }, 
                      c_date: { name:"납부일", width: "12%", position: "center" } 
                    } 
                } 
                mobileData={["title", "c_date", "money"]}
                
                >
                </Table>
            </DuesHistoryWrapper>
        </>
    )
}

export default DuesHistory