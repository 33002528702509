import React, { useState } from 'react';
import styled, { css } from "styled-components";

import Banner from './components/Banner';

import { mobile } from '../../lib/viewport';
import bg from "../../../../shared/img/introduce/organization-bg-obj.png"
import chart from "../../../../shared/img/introduce/organization-chart-img.png"

const Navigation = styled.div`
    width: 240px;
    height: 36px;

    border-radius: 20px;
    border: solid 0.5px #d6d6d6;
    background-color: #f2f3f5;

    display:flex;
    position : relative;

    margin: 50px auto 30px auto;

    & > p{
        width: 50%;
        height: 35px;
        color: #0e0e0e;
        font-size: 12px;

        display:flex;
        justify-content:center;
        align-items:center;

        position:relative;
        z-index: 2;

        cursor:pointer;
    }
    ${mobile(css`
        margin: 20px auto 20px auto;
    `)}
`
const NavigationCricle = styled.div`
    width: 120px;
    height: 35px;

    border-radius: 20px;
    border: solid 0.5px #d6d6d6;
    background-color: #fff;

    position: absolute;

    ${({ state }) => {
      return state === 'OrganizationChart' ? `left: 0px;` : 'left: 50%;';
    }}
    transition: 0.2s;
`

const Image = styled.img`
    width:800px;
    height: 1200px;

    margin: 0 auto;
    padding: 0px 10px 40px 10px;

    display:block;

    ${mobile(css`
        width:100%;
        height:fit-content;
    `)}

    position:relative;
    z-index:1;
`
const Wrapper = styled.div`
    width:100%;
    height:100%;

    position: relative;
`

const BackgroundImage = styled.img`
    position:absolute;
    bottom:0px;
    right:0px;
`

function Member() {
    const [state, setState] = useState('OrganizationChart')

    function NavigationChange(subject) {
        if (subject === 'OrganizationChart') {
            setState('OrganizationChart')
        } else {
            setState('PastPresidents')
        }
    }

    return ( 
        <>            
            <Banner title="member"></Banner>
            <Navigation>
                <NavigationCricle state={state} />
                <p onClick={() => NavigationChange('OrganizationChart')}>조직도</p>
                <p onClick={() => NavigationChange('PastPresidents')}>역대회장</p>
            </Navigation>
            <Wrapper>
            {
                state === "OrganizationChart" ? <Image src={chart} alt="OrganizationChartImg" /> : <Image src="https://via.placeholder.com/900x1400" alt="PastPresidentsImg" />
            }
                <BackgroundImage src={bg} alt="bgImage" />
            </Wrapper>
        </>
    );
}

export default Member;
