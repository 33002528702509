import React from 'react';
import styled, { css } from "styled-components";
import Banner from './components/Banner';
import { mobile } from '../../lib/viewport';

import hanaBankIcon from "../../../../shared/img/introduce/hana-bank-icon.png"
import DownloadIcon from "../../../../shared/img/introduce/sub-down-icon.png"
import icon1 from "../../../../shared/img/introduce/sub-join-icon-img-01.png"
import icon2 from "../../../../shared/img/introduce/sub-join-icon-img-02.png"
import icon3 from "../../../../shared/img/introduce/sub-join-icon-img-03.png"
import icon4 from "../../../../shared/img/introduce/sub-join-icon-img-04.png"


const GuideWrapper = styled.div`
    max-width: 900px;

    margin: 50px auto 0px auto;

    display:flex;
    justify-content:center;
    align-items:center;

    flex-wrap:wrap;

    ${mobile(css`
        margin: 20px auto 20px auto;
    `)}

`
const GuideItem = styled.div`
    max-width: 380px;
    min-width: 380px;
    height: 180px;

    padding: 24px 36px;

    margin: 0px 12px 24px 12px;

    border-radius: 30px;
    border: solid 1px #c3c3c3;
    background-color: #fff;

    display:flex;
    justify-content:flex-start;
    align-items:center;
    ${mobile(css`
        justify-content: center;
        min-width: calc( 100% - 20px );
        padding: 24px 20px;
        margin: 0px 12px 10px 12px;
    `)}

    transition: 0.2s;

    &:hover{
        box-shadow: 0px 5px 10px 0 rgba(0, 0, 0, 0.1);
        border: solid 0.5px #000;
    }
`

const GuideItemImageWrapper = styled.div`
    margin-right: 36px;
`
const GuideItemImage = styled.img`
    width: 50px;
    height: 50px;
    margin-bottom: 6px;
`
const GuideItemTitle = styled.div`
    width:fit-content;
    font-size: 14px;
    
    font-weight: bold;
    color: #000;

    text-align:center;

    margin: 0 auto;
`

const GuideItemText = styled.div`
    line-height: ${props => props.lineHeight};
    margin: ${props => props.margin};
    font-size: 12px;
    font-weight: 500;
    word-break:keep-all;
`

const DownloadWrapper = styled.div`
    width: fit-content;
    height: 28px;

    border-radius: 5px;
    border: solid 1px #c3c3c3;
    background-color: #fff;

    margin-top: 8px;

    display:flex;

    cursor:pointer;
`
const DownloadButton = styled.div`
    width:fit-content;
    height:100%;

    padding: 0px 9px 0px 9px;
    font-size: 12px;

    display:flex;
    align-items:center;

    box-sizing:border-box;

`

const DownloadButtonImage = styled.div`
    width:30px;
    height: 100%;
    
    display:flex;
    justify-content:center;
    align-items:center;

    border-left: 1px solid #c3c3c3;
`

const GuideContactWrapper = styled.div`
    display:flex;
    align-items:center;
    line-height: 1.2;

    & > b{
        width: 12px;
    }
    & > p{
        font-size: 12px;
    }
`
const GuideBankNumber = styled.div`
    font-size: 12px;
    font-weight: bold;
`

const Down = styled.a`
    text-decoration: none;
    color: #222222;
`
function Guide() {
    return ( 
        <>            
            <Banner title="guide"></Banner>
            <GuideWrapper>
                <GuideItem>
                    <GuideItemImageWrapper>
                        <GuideItemImage src={icon1} alt="guide-image" />
                        <GuideItemTitle>
                            자격
                        </GuideItemTitle>
                    </GuideItemImageWrapper>
                    <div>
                        <GuideItemText lineHeight="1.95">
                            연구회 목적에 함께하는<br />
                            누가통증연구회 정회원 2명 이상의<br /> 
                            추천을 받고 총회의 인준을 받은 <br />
                            의사에 한해 가입 자격이 주어집니다.
                        </GuideItemText>
                    </div>
                </GuideItem>
                <GuideItem>
                    <GuideItemImageWrapper>
                        <GuideItemImage src={icon2} alt="guide-image" />
                        <GuideItemTitle>
                            제출서류
                        </GuideItemTitle>
                    </GuideItemImageWrapper>
                    <div>
                        <GuideItemText lineHeight="1.95">
                            누가통증연구회 정회원 2인의<br />
                            작성된 동의서를 홈페이지 회원 가입 후<br /> 
                            메일로 첨부하여 보내주시길 바랍니다.
                        </GuideItemText>
                        <DownloadWrapper>
                            <Down href={`${process.env.REACT_APP_API_URL}/uploads/누가통증연구회_추천서.hwp`} download><DownloadButton>추천서 다운로드 </DownloadButton></Down>
                            <DownloadButtonImage>
                                <Down href={`${process.env.REACT_APP_API_URL}/uploads/누가통증연구회_추천서.hwp`} download><img src={DownloadIcon} alt="guide-img" /></Down>
                            </DownloadButtonImage>
                        </DownloadWrapper>
                    </div>
                </GuideItem>
                <GuideItem>
                    <GuideItemImageWrapper>
                        <GuideItemImage src={icon3} alt="guide-image" />
                        <GuideItemTitle>
                                연락처
                        </GuideItemTitle>
                    </GuideItemImageWrapper>
                    <div>
                        <GuideItemText lineHeight="1.3" margin="0px 0px 8px 0px">
                            [35002]<br />
                            대전광역시 중구 산성로 130(문화동)
                        </GuideItemText>
                        <GuideContactWrapper>
                            <b>H</b>
                            <p>010-2163-7460</p>
                        </GuideContactWrapper>
                        <GuideContactWrapper>
                            <b>F</b>
                            <p>042-585-0129</p>
                        </GuideContactWrapper>
                    </div>
                </GuideItem>
                <GuideItem>                    
                    <GuideItemImageWrapper>
                        <GuideItemImage src={icon4} alt="guide-img" />
                        <GuideItemTitle>
                                회비납부
                        </GuideItemTitle>
                    </GuideItemImageWrapper>
                    <div>
                        <GuideItemText lineHeight="1.9" margin="0px 0px 8px 0px">
                            누가 통증연구회의<br />
                            월회비는 <b>25만원</b> 입니다.
                        </GuideItemText>
                        <img src={hanaBankIcon} alt="guide-bank" />
                        <GuideBankNumber>631-910021-49305 누가통증연구회</GuideBankNumber>
                    </div>
                </GuideItem>
            </GuideWrapper>
        </>
    );
}

export default Guide;
