import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Field, Form } from 'react-final-form'
import { Card, CardBody, Col, Button, ButtonToolbar } from 'reactstrap'
import TextField from '@material-ui/core/TextField'
import renderFileInputField from './ModifyFileInput'
import axios from 'axios'

const renderTextField = ({ input, label, meta: { touched, error }, children, select, multiline, disabled }) => (
  <TextField
    className='material-form__field'
    label={label}
    type={input.type}
    error={touched && error}
    value={input.value}
    select={select}
    multiline={multiline}
    onChange={(e) => {
      e.preventDefault()
      input.onChange(e.target.value)
    }}
    disabled={disabled}
  >
    {children}
  </TextField>
)

const AcademicWrite = ({ onSubmit,setWrite,academyReadVal,academyContentRead,setShowModifyAcademy,academyOneData,setLoading3 }) => {
  const [radioVal, setRadioVal] = useState(undefined)
  const [academicFileOrLink, setAcademicFileOrLink] = useState(0)
  const [trainingFileOrLink, setTrainingFileOrLink] = useState(0)
  const [shortCutFileOrLink, setShortCutFileOrLink] = useState(0)
  const [posterFileOrLink, setPosterFileOrLink] = useState(0)
  const [loading, setLoading] = useState(false)
  const [initVal, setInitVal] = useState(true)
  const [inputGroup, setInputGroup] = useState([])
  const [academicFileDel, setAcademicFileDel] = useState(false)
  const [trainingFileDel, setTrainingFileDel] = useState(false)
  const [shortCutFileDel, setShortCutFileDel] = useState(false)
  const [posterFileDel, setPosterFileDel] = useState(false)
  let dummy = []
  const globalRegex = new RegExp(`${process.env.REACT_APP_API_URL}/uploads`);
  const addInputGroup = () => { 
    setInitVal(false)
    if (inputGroup.length === 0) { 
      setInputGroup([0])
    } else {
      const lastNum = inputGroup[inputGroup.length - 1]
      setInputGroup([...inputGroup, lastNum + 1])
    }
  }
  if (academicFileDel) {
      axios.post(`${process.env.REACT_APP_API_URL}/fileDel`, {
        type: 'academic_articles',
        type2: 'academic',
        file_link: academyReadVal.academic_file_link,
      })
      // .then(() => props.setLoading3(true))
      .then(()=>setAcademicFileDel(false))
      .then(()=>academyOneData(academyReadVal.id_academic_article))
  } else if (trainingFileDel) {
      axios.post(`${process.env.REACT_APP_API_URL}/fileDel`, {
          type: 'academic_articles',
          type2: 'training',
          file_link: academyReadVal.training_file_link,
        })
        // .then(() => props.setLoading3(true))
        .then(()=>setTrainingFileDel(false))
        .then(()=>academyOneData(academyReadVal.id_academic_article))
  } else if (shortCutFileDel) {
      axios.post(`${process.env.REACT_APP_API_URL}/fileDel`, {
          type: 'academic_articles',
          type2: 'shortcut',
          file_link: academyReadVal.shortcut_file_link,
        })
        // .then(() => props.setLoading3(true))
        .then(()=>setShortCutFileDel(false))
        .then(()=>academyOneData(academyReadVal.id_academic_article))
  } else if (posterFileDel) {
      axios.post(`${process.env.REACT_APP_API_URL}/fileDel`, {
          type: 'academic_articles',
          type2: 'poster',
          file_link: academyReadVal.poster_file_link,
        })
        // .then(() => props.setLoading3(true))
        .then(()=>setPosterFileDel(false))
        .then(()=>academyOneData(academyReadVal.id_academic_article))
  }

  const removeInputGroup = () => {
    // console.log(radioVal);
    setInputGroup(inputGroup.filter(num => num !== radioVal))
  }
  // useEffect(() => console.log({inputGroup}), [inputGroup])
  useEffect(() => { 
    for (let i = 0; i < academyContentRead.length; i++){
      dummy.push(i)
    }  
    setInputGroup(dummy)
    setLoading(true)
  }, [])
  if (!loading) return null;
  
  return (
    <Fragment>
      <div className='card__title'>
        <h1>학술쓰기</h1>
      </div>
      <hr />
      <br />
      <br />
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, form }) => (
          <form className='material-form' onSubmit={handleSubmit}>
            <div className='row'>
              <div className='col-12'>
                <Field name='title' component={renderTextField} label='제목' initialValue={academyReadVal.title} />
              </div>
            </div>
            <div className='row justify-content-end'>
              <Button
                className='col-3'
                onClick={addInputGroup}
              >
                추가
              </Button>
              <Button
                className='col-3'
                color='danger'
                onClick={removeInputGroup}
              >
                삭제
              </Button>
            </div>
            {inputGroup.map((num, idx) => (
              <div className='row'>
                <div className='col-1' style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                  <div className='form__form-group-input-wrap'>
                    <label className='radio-btn'>
                      <input
                        className='radio-btn__radio'
                        name='status'
                        type='radio'
                        onChange={() => {
                          setRadioVal(num)
                        }}
                      />
                      <span className='radio-btn__radio-custom'></span>
                      <span className='radio-btn__label'></span>
                    </label>
                  </div>
                </div>
                <div className='col-4'>
                  <div className="form__form-group-field">
                    <Field name={`item-${num}`} component={renderTextField} label='항목' initialValue={initVal ? academyContentRead[idx].item : null} />
                    {/* <Field name={`item-${num}`} component={renderTextField} label='항목' initialValue={!inputGroup.length === idx + 1 ? academyContentRead[idx].item : null} /> */}
                  </div>
                </div>
                <div className='col-6'>
                  <div className="form__form-group-field">
                    <Field name={`details-${num}`} component={renderTextField} label='세부내역' initialValue={initVal ? academyContentRead[idx].details : null} />
                  </div>
                </div>
              </div>
            ))}

            {/* ----------------------------- 학술대회 ------------------------------------- */}
            <div className='row' style={{ border: 'solid 1px #e1e1e1', display: 'flex' }}>
              <div className='col-3' style={{ textAlign: 'center', marginTop: '2%' }}>
                <span>학술대회</span>
              </div>
                <Button Button color="primary" style={{ marginTop: '1.5%' }} onClick={() => {
                  setAcademicFileOrLink(1)
              }}>파일</Button>
            {academicFileOrLink === 1 ? <div className='col-6' style={{ marginTop: '1.5%' }}>
                  {globalRegex.test(academyReadVal.academic_file_link) ? <>
                  <div className='form__form-group-field'>
                    <div className='row'>
                      <Field name='academicFile' component={renderFileInputField} fileName={academyReadVal.academic_file_link.replace(`${process.env.REACT_APP_API_URL}/uploads`, "")} setFileDel={setAcademicFileDel}/>
                    </div> 
                  </div>
                  </> : <Field name='academicFile' component={renderFileInputField} />}
            </div> : null}
            <Button color="primary" style={{ marginTop: '1.5%' }} onClick={() => {
              setAcademicFileOrLink(2)
            }}>링크</Button>
            {academicFileOrLink === 2 ? <div className='col-6' style={{ marginTop: '1%' }} >
                {!globalRegex.test(academyReadVal.academic_file_link) ? <Field name='academicLink' component={renderTextField} label='링크' initialValue={academyReadVal.academic_file_link} /> : <Field name='academicLink' component={renderTextField} label='링크' /> }
              </div> : null}
            </div>
            
            {/* ----------------------------- 연수교육 ------------------------------------- */}
            <div className='row' style={{ border: 'solid 1px #e1e1e1', display: 'flex' }}>
              <div className='col-3' style={{ textAlign: 'center', marginTop: '2%' }}>
                <span>연수교육</span>
              </div>
                <Button Button color="primary" style={{ marginTop: '1.5%' }} onClick={() => {
                  setTrainingFileOrLink(1)
              }}>파일</Button>
                {trainingFileOrLink === 1 ? <div className='col-6' style={{ marginTop: '1.5%' }}>
                      {globalRegex.test(academyReadVal.training_file_link) ? <>
                      <div className='form__form-group-field'>
                        <div className='row'>
                          <Field name='trainingFile' component={renderFileInputField} fileName={academyReadVal.training_file_link.replace(`${process.env.REACT_APP_API_URL}/uploads`, "")} setFileDel={setTrainingFileDel}/>
                        </div> 
                      </div>
                      </> : <Field name='trainingFile' component={renderFileInputField} />}
                </div> : null}
                <Button color="primary" style={{ marginTop: '1.5%' }} onClick={() => {
                  setTrainingFileOrLink(2)
                }}>링크</Button>
                {trainingFileOrLink === 2 ? <div className='col-6' style={{ marginTop: '1%' }} >
                    {!globalRegex.test(academyReadVal.training_file_link) ? <Field name='trainingLink' component={renderTextField} label='링크' initialValue={academyReadVal.training_file_link} /> : <Field name='trainingLink' component={renderTextField} label='링크' /> }
                  </div> : null}
            </div>
       
            <Field name='id_academic_article' component="text" initialValue={academyReadVal.id_academic_article} hidden/>
            <Field name='status' component="text" initialValue={academyReadVal.status} hidden />
            
             {/* ----------------------------- 바로가기 ------------------------------------- */}
            <div className='row' style={{ border: 'solid 1px #e1e1e1', display: 'flex' }}>
              <div className='col-3' style={{ textAlign: 'center', marginTop: '2%' }}>
                <span>바로가기</span>
              </div>
              <Button color="primary" style={{ marginTop: '1.5%' }} onClick={() => {
                setShortCutFileOrLink(1)
              }}>파일</Button>
              {shortCutFileOrLink === 1 ? <div className='col-6' style={{ marginTop: '1.5%' }}>
                {globalRegex.test(academyReadVal.shortcut_file_link) ? <>
                  <div className='form__form-group-field'>
                    <div className='row'>
                      <Field name='shortcutFile' component={renderFileInputField} fileName={academyReadVal.shortcut_file_link.replace(`${process.env.REACT_APP_API_URL}/uploads`, "")} setFileDel={setShortCutFileDel}/>
                    </div> 
                  </div>
                </> : <Field name='shortcutFile' component={renderFileInputField} />}
              </div> : null}
              <Button color="primary" style={{ marginTop: '1.5%' }} onClick={() => {
                setShortCutFileOrLink(2)
              }}>링크</Button>
              {shortCutFileOrLink === 2 ? <div className='col-6' style={{ marginTop: '1%' }} >
                {!globalRegex.test(academyReadVal.shortcut_file_link) ? <Field name='shortcutLink' component={renderTextField} label='링크' initialValue={academyReadVal.shortcut_file_link} /> : <Field name='shortcutLink' component={renderTextField} label='링크' />}
              </div> :null }
            </div>

            {/* ----------------------------- 포스터 ------------------------------------- */}
            <div className='row' style={{ border: 'solid 1px #e1e1e1', display: 'flex' }}>
              <div className='col-3' style={{ textAlign: 'center', marginTop: '2%' }}>
                <span>포스터</span>
              </div>
              <Button color="primary" style={{ marginTop: '1.5%' }} onClick={() => {
                setPosterFileOrLink(1)
              }}>파일</Button>
              {posterFileOrLink === 1 ? <div className='col-6' style={{ marginTop: '1.5%' }}>
                {globalRegex.test(academyReadVal.poster_file_link) ? <>
                  <div className='form__form-group-field'>
                    <div className='row'>
                      <Field name='posterFile' component={renderFileInputField} fileName={academyReadVal.poster_file_link.replace(`${process.env.REACT_APP_API_URL}/uploads`, "")} setFileDel={setPosterFileDel}/>
                    </div> 
                  </div>
                </> : <Field name='posterFile' component={renderFileInputField} />}
              </div> : null}
              <Button color="primary" style={{ marginTop: '1.5%' }} onClick={() => {
                setPosterFileOrLink(2)
              }}>링크</Button>
              {posterFileOrLink === 2 ? <div className='col-6' style={{ marginTop: '1%' }} >
                {!globalRegex.test(academyReadVal.poster_file_link) ? <Field name='posterLink' component={renderTextField} label='링크' initialValue={academyReadVal.poster_file_link} /> : <Field name='posterLink' component={renderTextField} label='링크' />}
              </div> :null }
            </div>
            <br />
            {/* <Test val={academyReadVal.psoster_file_link}/> */}
            <Field
              name='input-group'
              someArbitraryOtherProp={inputGroup}
              hidden
              render={(prop) => {
                prop.input.onChange(prop.someArbitraryOtherProp)
                return <input {...prop.input} value="hidden" hidden />
              }}
            />
            <ButtonToolbar className='form__button-toolbar'>
              <Button color='primary' type='button' onClick={() => {
                if (window.confirm("등록하시겠습니까?")) {
                  form.submit()
                  window.alert("등록되었습니다.")
                  setShowModifyAcademy(show => !show)
                  setLoading3(false)
                }
              }}>
                확인
              </Button>
              <Button type='button' color='danger' onClick={() => {
                if (window.confirm("취소하시겠습니까?")) {
                  setShowModifyAcademy(show=>!show)
                }
              }}>
                취소
              </Button>
            </ButtonToolbar>
            <hr/>
          </form>
        )}
      </Form>
    </Fragment>
  )
}
function Test({ val }) {
  return (
    <div className="form__form-group-field">
      <div className="row">
        <div className="form__form-group-input-wrap">
          <div className="form__form-group-file">
            <label htmlFor="posterFile">파일첨부</label>
            <input type="file" name="posterFile" id="posterFile"/>
          </div>
        </div>
        <span>${val}</span>
      </div>
    </div>
  )
}

export default AcademicWrite
