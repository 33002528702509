import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card, CardBody, Col, Badge, Table,Button,
} from 'reactstrap';
import ReplyHistoryData from './ReplyHistoryData';
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import TabPagination from './tabPagination';
import moment from "moment"
import {useHistory} from 'react-router-dom'

//todo 링크걸기
const { tableHeaderResponsiveData, tableRowsData } = ReplyHistoryData();

const ResponsiveTable = ({userid}) => {
  const history = useHistory()
  let status
  const [loading, setLoading] = useState(false)
  const [waiting, setWaiting] = useState([])
  const [pageNum, setPageNum] = useState(1)
  const [total, setTotal] = useState(0)
  const [onOff, setOnOff] = useState(false)
  const [bbs, setBbs] = useState(0)
  const [qna, setQna] = useState(0)

  const handleOption = (e) => {
    status = e.target.value
    // console.log(status)
  }

  const handleFetch = (id) => {
   if (window.confirm("변경 하시겠습니까?")){
     axios.get(`${process.env.REACT_APP_API_URL}/replystatuschange?id=${id}&status=${status}`)
      .then(() => setOnOff(pre => !pre))
      .catch(err =>  console.log(err))
    }
  }

  useEffect(() => {
    // console.log(onOff)
    axios.get(`${process.env.REACT_APP_API_URL}/replylistperson?pagenum=${pageNum}&userid=${userid}`)
      .then((res) => {
        // console.log("리플", res.data)
        setBbs(res.data.bbs)
        setQna(res.data.qna)
        setTotal(res.data.data.count)
        setWaiting(res.data.data.rows)
      })
      .then(() => setLoading(true))
      .catch(err => console.log(err))
  }, [pageNum, onOff])

  if (!loading) return <div></div>;

  const handleMove = (id, type) => {
    axios.get(`${process.env.REACT_APP_API_URL}/boardnum?id=${id}`)
      .then((res) => {
        // console.log(res.data.data)
        history.push(`/app/community/${type === '4' ? "userboard" : "qna"}/${res.data.data.board_num}/${type}/${type === "4" ? bbs : qna}`)
      })
      .catch(err => console.log(err))  
  }
  
  // <a href={`${process.env.REACT_APP_URL}/app/community/${item.type === "4" ? "userboard" : "qna"}/${item.board_num}/${item.type}/${item.type === "4" ? bbs : qna}`}>   
  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <Table responsive className="table--bordered">
            <thead>
              <tr>
                {tableHeaderResponsiveData.map(item => (
                  <th key={item.id}>{item.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
            {waiting.map(item => (
                <tr key={uuidv4()}>
                  <td>{item.id_reply}</td>
                  <td>{item.status}</td>
                  <td>{item.category === 0 ? "회원게시판" : "질의응답"}</td>
                  <td>{item.bbs_id}</td>
                  <td>{item.reply_group_order === 0 ? "댓글" : "답글"}</td>
                  <td width="500" style={{wordBreak: "break-all"}}>{item.contents}</td>
                  <td>{moment(item.c_data).format("YYYY-MM-DD / HH:mm:ss")}</td>
                  <td>{item.ip}</td>
                  <td>
                    {item.status === "삭제" ? <Button onClick={() => window.alert("삭제된 게시물입니다.")}>이동</Button>
                      : <Button onClick={() => handleMove(item.bbs_id, item.type, )}>이동</Button>}
                  </td>
                  <td>
                    {/* <div style={{display: "flex", gap: "5px"}}> */}
                      <select onChange={handleOption}>
                        <option value="정상" selected={item.status === "정상" ? "selected" : null}>정상</option>
                        <option value="삭제" selected={item.status === "삭제" ? "selected" : null}>삭제</option>
                      </select>
                      <Button color="danger" onClick={() => handleFetch(item.id_reply)} >확인</Button>
                    {/* </div> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {total === 0 ? "해당하는 정보가 없습니다." : <TabPagination setPageNum={setPageNum} total={total}/>}
        </CardBody>
      </Card>
    </Col>
  );
};

export default ResponsiveTable;