import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'

import Banner from './components/Banner'

import { mobile } from '../../lib/viewport'
import checkIcon from '../../../../shared/img/check-selec.png'
import profileIcon from '../../../../shared/img/mypage/profile.png'
import axios from 'axios'
import Alert from '../../components/Alert'
import { useHistory } from 'react-router'

const Form = styled.form`
  width: fit-content;

  margin: 0px auto 160px auto;
  padding-right: 4em;
  ${mobile(css`
    width: 100%;
    padding-right: 0;
  `)}
`

const InputAddressTextWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const AddressFindButton = styled.div`
  width: 44px;
  height: 26px;
  border-radius: 8px;
  border: solid 0.5px #000;
  background-color: #474747;
  color: white;
  text-align: center;
  padding: 3px;
  font-size: 12px;

  cursor: pointer;

  position: absolute;
  right: 12px;
  top: 12px;
`
const InputWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  margin: 0 auto 10px auto;

  & > span {
    width: fit-content;
    margin-right: 24px;

    display: flex;
    align-items: center;

    font-weight: bold;
    font-size: 15px;
  }
  ${mobile(css`
    justify-content: center;
    flex-direction: column;
    align-items: center;

    & > span {
      display: none;
    }
  `)}
`

const InputText = styled.input`
  width: 350px;
  height: 50px;

  ${({ borderRadius }) => {
    return borderRadius ? `border-radius: ${borderRadius};` : 'border-radius: 6px;'
  }}
  border: solid 0.7px #e0e1e5;
  background-color: #fff;

  padding: 0px 20px;
  font-size: 15px;

  outline: none;
`

const InputFixedText = styled.input`
  width: 350px;
  height: 50px;

  ${({ borderRadius }) => {
    return borderRadius ? `border-radius: ${borderRadius};` : 'border-radius: 6px;'
  }}
  border: solid 0.7px #e0e1e5;
  background-color: #ebedef;

  padding: 0px 20px;
  font-size: 15px;

  outline: none;

  cursor: pointer;
`

const InputEditButton = styled.div`
  min-width: 184px;
  height: 50px;

  border-radius: 6px;
  border: solid 0.5px #000;
  background-color: #474747;

  color: white;
  font-size: 15px;

  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0px 4px;

  cursor: pointer;
`

const InputCancelButton = styled.div`
  min-width: 184px;
  height: 50px;

  border-radius: 6px;
  border: solid 0.5px #000;
  background-color: #ffffff;

  color: #474747;
  font-size: 15px;

  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0px 4px;

  cursor: pointer;
`

const InputSelectWrapper = styled.div`
  min-width: 350px;
  height: 50px;

  border-radius: 6px;

  border: solid 0.7px #e0e1e5;
  ${({ fixed }) => {
    return fixed ? `background-color: #ebedef;` : 'background-color: #ffffff; cursor:pointer;'
  }}

  font-size: 15px;

  outline: none;

  display: flex;
`

const InputSelectBox = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  & > img {
    margin-right: 10px;
  }
  color: #8c919b;
  ${({ selected }) => {
    return selected ? `border: 1px solid black; color: black; font-weight: bold;` : ''
  }}
  ${({ borderRadius }) => {
    return borderRadius ? `border-radius: ${borderRadius};` : 'border-radius: 6px;'
  }}
`

const InputDoubleWrapper = styled.div`
  width: 350px;
  height: 100%;
`

const InputButtonWrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 48px;

  ${mobile(css`
    flex-direction: column;
    & > div {
      width: 350px;
      margin-bottom: 10px;
    }
  `)}
`
const FixedForm = styled.div`
  margin-bottom: 45px;
  width: 100%;
  height: 100%;
`
const ProfileWrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;

  margin: 50px auto 40px auto;
`

const ProfileImage = styled.img`
  width: 110px;
  height: 110px;

  border-radius: 100%;

  background-color: white;
`

const ProfileChangeButton = styled.label`
  width: 80px;
  height: 30px;

  border-radius: 5px;
  border: solid 0.5px #000;
  background-color: #474747;

  margin-top: 12px;
  cursor: pointer;
  color: white;

  display: flex;
  justify-content: center;
  align-items: center;
`
const ProfileInput = styled.input`
  opacity: 0;
  visibility: none;
  width: 0.11px;
  height: 0.1px;
`

function Edit() {
  const [form, setForm] = useState({
    userid: '',
    name: '',
    license_number: '',
    birthday: '',
    gender: '남성',
    specialized_field: '',
    address_main: '',
    address_detail: '',
    email: '',
    cellphone: '',
    advertisement_agree: '1',
  })
  const uploadPhoto = React.useRef(null)
  const [photo, setPhoto] = useState(false)
  const [nowPhoto, setNowPhoto] = useState(false)
  const [errorText, setErrorText] = useState('')
  const [modalUrlState, setModalUrlState] = useState(false)
  const history = useHistory('')

  useEffect(() => {
    if (localStorage.getItem('accessToken')) {
      axios
        .post(`${process.env.REACT_APP_API_URL}/verification`, {
          token: localStorage.getItem('accessToken'),
        })
        .then((data) => {
          let arr = Object.keys(form)
          let formObject = {}
          for (let i = 0; i < arr.length; i++) {
            formObject = {
              ...formObject,
              [arr[i]]: data.data.data[arr[i]],
            }
          }
          setForm(formObject)
          if (data.data.data.profile_image_link) {
            setNowPhoto(`${process.env.REACT_APP_API_URL}/${data.data.data.profile_image_link}`)
          }
        })
    }
  }, [])

  const inputOnChange = (e) => {
    const { name, value } = e.target
    const nextInputs = {
      ...form,
      [name]: value,
    }
    setForm(nextInputs)
  }

  const selectedChange = (e) => {
    const nextInputs = {
      ...form,
      [e.name]: e.value,
    }
    setForm(nextInputs)
  }

  function setupErrorText() {
    setErrorText('')
    if (modalUrlState) {
      history.push('/')
    }
  }

  function PhotoUpdate() {
    if (uploadPhoto.current.files.length !== 0) {
      setPhoto(uploadPhoto.current.files[0])
      const reader = new FileReader()
      // 이미지가 로드가 된 경우
      reader.onload = (e) => {
        setPhoto(e.target.result)
      }
      // reader가 이미지 읽도록 하기
      reader.readAsDataURL(uploadPhoto.current.files[0])
    } else {
      setPhoto(false)
    }
  }

  function Update() {
    const regEmail = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i
    const regL = /^[0-9]+$/
    if (form.address_detail.length > 30) {
      setErrorText('상세주소는 30자 이하로 입력해 주세요.')
    } else if (form.email === '') {
      setErrorText('이메일을 입력해 주세요.')
    } else if (!regEmail.test(form.email)) {
      setErrorText('올바른 이메일 형식이 아닙니다.')
    } else if (form.cellphone === '' || form.cellphone.length > 14) {
      setErrorText('전화번호를 올바르게 입력해 주세요.')
    } else if (!regL.test(form.cellphone)) {
      setErrorText('전화번호를 올바르게 입력해 주세요.')
    } else {
      let c = window.confirm('프로필을 정말 수정하시겠습니까?')
      if (c) {
        const fd = new FormData()
        if (uploadPhoto.current.files.length !== 0) {
          fd.append(`img`, uploadPhoto.current.files[0])
        }
        fd.append('userid', form.userid)
        fd.append('address_main', form.address_main)
        fd.append('address_detail', form.address_detail)
        fd.append('email', form.email)
        fd.append('cellphone', form.cellphone)
        fd.append('advertisement_agree', form.advertisement_agree)
        axios
          .post(`${process.env.REACT_APP_API_URL}/changemyinfo`, fd)
          .then((data) => {
            axios
              .post(`${process.env.REACT_APP_API_URL}/verification`, {
                token: localStorage.getItem('accessToken'),
              })
              .then((data2) => {
                let arr = Object.keys(form)
                let formObject = {}
                for (let i = 0; i < arr.length; i++) {
                  formObject = {
                    ...formObject,
                    [arr[i]]: data2.data.data[arr[i]],
                  }
                }
                setForm(formObject)
              })
            if (data.data === 'ok') {
              setModalUrlState(true)
              setErrorText('성공적으로 프로필을 수정하였습니다.')
            }
          })
          .catch((err) => {
            if (err.response) {
              console.log(err.response)
            } else {
              console.log(err.response)
            }
          })
      }
    }
  }

  function Cancel() {
    let c = window.confirm('프로필 수정을 취소하시겠습니까?')
    if (c) {
      if (localStorage.getItem('accessToken')) {
        axios
          .post(`${process.env.REACT_APP_API_URL}/verification`, {
            token: localStorage.getItem('accessToken'),
          })
          .then((data) => {
            let arr = Object.keys(form)
            let formObject = {}
            for (let i = 0; i < arr.length; i++) {
              formObject = {
                ...formObject,
                [arr[i]]: data.data.data[arr[i]],
              }
            }
            setForm(formObject)
            // console.log(arr, data, formObject)
          })
      }
    }
  }
  return (
    <>
      <Alert openState={errorText === '' ? false : true} text={errorText} setupErrorText={() => setupErrorText()} />
      <Banner title='edit'></Banner>
      <Form>
        <ProfileWrapper>
          {photo ? (
            <ProfileImage src={photo} alt='profile_img' ref={uploadPhoto} />
          ) : nowPhoto ? (
            <ProfileImage src={nowPhoto} alt='profile_img' ref={uploadPhoto} />
          ) : (
            <ProfileImage src={profileIcon} alt='profile_img' ref={uploadPhoto} />
          )}
          <ProfileChangeButton>
            사진변경
            <ProfileInput
              onChange={() => {
                PhotoUpdate()
              }}
              type='file'
              ref={uploadPhoto}
            />
          </ProfileChangeButton>
        </ProfileWrapper>
        <FixedForm>
          <InputWrapper>
            <span>아이디</span>
            <InputFixedText name='userid' value={form.userid} type='text' placeholder='아이디를 입력해주세요.' disabled />
          </InputWrapper>
          <InputWrapper>
            <span>성함</span>
            <InputFixedText name='name' value={form.name} type='text' placeholder='성함을 입력해주세요.' disabled />
          </InputWrapper>
          <InputWrapper>
            <span>면허번호</span>
            <InputFixedText name='license_number' value={form.license_number} type='text' placeholder='면허번호를 입력해주세요.' disabled />
          </InputWrapper>
          <InputWrapper>
            <span>생년월일</span>
            <InputFixedText name='birthday' value={form.birthday} type='text' placeholder='숫자만 입력(ex: 19841107)' disabled />
          </InputWrapper>
          <InputWrapper>
            <span>성별</span>
            <InputSelectWrapper fixed>
              <InputSelectBox borderRadius='6px 0px 0px 6px' selected={form.gender === '남성' ? true : false}>
                <img src={checkIcon} alt='check_icon' />
                <span>남성</span>
              </InputSelectBox>
              <InputSelectBox borderRadius='0 6px 6px 0' selected={form.gender === '여성' ? true : false}>
                <img src={checkIcon} alt='check_icon' />
                <span>여성</span>
              </InputSelectBox>
            </InputSelectWrapper>
          </InputWrapper>
          <InputWrapper>
            <span>전문분야</span>
            <InputFixedText
              name='specialized_field'
              value={form.specialized_field}
              type='text'
              placeholder='전문분야를 입력해주세요.'
              disabled
            />
          </InputWrapper>
        </FixedForm>
        <InputWrapper>
          <span>주소</span>
          <InputDoubleWrapper>
            <InputAddressTextWrapper>
              <AddressFindButton>찾기</AddressFindButton>
              <InputText
                disabled
                name='address_main'
                value={form.address_main}
                type='text'
                placeholder='주소 (우편번호)'
                borderRadius='6px 6px 0 0'
                onChange={inputOnChange}
              />
            </InputAddressTextWrapper>
            <InputText
              name='address_detail'
              value={form.address_detail}
              type='text'
              placeholder='상세주소 입력'
              borderRadius='0 0 6px 6px'
              onChange={inputOnChange}
            />
          </InputDoubleWrapper>
        </InputWrapper>
        <InputWrapper>
          <span>이메일</span>
          <InputText name='email' value={form.email} type='text' placeholder='이메일을 입력해주세요.' onChange={inputOnChange} />
        </InputWrapper>
        <InputWrapper>
          <span>휴대폰</span>
          <InputText
            name='cellphone'
            value={form.cellphone}
            type='text'
            placeholder='‘-’ 제외하고 숫자만 입력해주세요.'
            onChange={inputOnChange}
          />
        </InputWrapper>
        <InputWrapper>
          <span>광고성 메시지 수신 동의</span>
          <InputSelectWrapper>
            <InputSelectBox
              onClick={() => selectedChange({ name: 'advertisement_agree', value: '1' })}
              borderRadius='6px 0px 0px 6px'
              selected={form.advertisement_agree === '1' ? true : false}
            >
              <img src={checkIcon} alt='check_icon' />
              <span>동의</span>
            </InputSelectBox>
            <InputSelectBox
              onClick={() => selectedChange({ name: 'advertisement_agree', value: '0' })}
              borderRadius='0 6px 6px 0'
              selected={form.advertisement_agree === '0' ? true : false}
            >
              <img src={checkIcon} alt='check_icon' />
              <span>비동의</span>
            </InputSelectBox>
          </InputSelectWrapper>
        </InputWrapper>
        <InputButtonWrapper>
          <InputEditButton onClick={() => Update()}>
            <span>수정</span>
          </InputEditButton>
          <InputCancelButton onClick={() => Cancel()}>
            <span>취소</span>
          </InputCancelButton>
        </InputButtonWrapper>
      </Form>
    </>
  )
}

export default Edit
