const BasicTableData = () => {
  // const header = [
  //   { id: 1, title: '#' },
  //   { id: 2, title: 'First Name' },
  //   { id: 3, title: 'Last Name' },
  //   { id: 4, title: 'Username' },
  //   { id: 5, title: 'Status' },
  // ];

  const headerResponsive = [
    { id: 1, title: '번호' },
    { id: 2, title: '상태' },
    { id: 3, title: '게시판' },
    { id: 4, title: '게시판 번호' },
    { id: 5, title: '구분' },
    { id: 7, title: '댓글내용' },
    { id: 8, title: '작성시간' },
    { id: 9, title: '작성IP' },
    { id: 10, title: 'URL' },
    { id: 11, title: '삭제' },
  ];
 
  const rows = [];
  for (let i = 0; i < 1000; i++) {
      rows.push({
      id: (i + 1),
      status: ['삭제', '게시'][Math.floor((Math.random() * 2))],
      board1: ['회원게시판', '질의응답'][Math.floor((Math.random() * 2))],
      boardNo: (i + 1),
      board2: '댓글',
      replyContent: '가나다라마바사',
      regDate: '2021-10-1',
      regIp: i,
      url: '',
      del: '',
    });
  }

  const basicTableData = { tableHeaderResponsiveData: headerResponsive, tableRowsData: rows };
  return basicTableData;
};

export default BasicTableData;
