import React, {useState, useEffect} from 'react';
import PropTypes, { arrayOf } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import renderIntervalDatePickerField from '@/shared/components/form/IntervalDatePicker';
import renderRadioButtonField from '@/shared/components/form/RadioButton';
import {
  Card, CardBody, Col, Button, ButtonToolbar,
} from 'reactstrap';
import TextField from '@material-ui/core/TextField';
import styled from "styled-components"
import MenuItem from '@material-ui/core/MenuItem';
import DatePicker from 'react-datepicker'
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
import {sub} from "date-fns"



const renderTextField = ({
  input, label, meta: { touched, error }, children, select, multiline,
}) => (
  <TextField
    className="material-form__field"
    label={label}
    type={input.type}
    error={touched && error}
    value={input.value}
    select={select}
    multiline={multiline}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
  >
    {children}
  </TextField>
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  select: PropTypes.bool,
  children: PropTypes.node,
  multiline: PropTypes.bool,
};

renderTextField.defaultProps = {
  meta: null,
  select: false,
  children: [],
  multiline: false,
};

const Dot = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  top: 40px; 
  right: 10px;
  border-radius: 50%;
  border: 1px solid gray;
  background-color: ${({isActived}) => isActived ? "gray" : "white"};
  cursor: pointer; 
`

const CustomInput = styled.input`
 outline: none;
 border: none;
 height: 20px;
 border-bottom: 1px solid #f2f4f7;
 :focus {
   border-bottom: 1px solid #4ce1b6;
 }
`

export const DateInput = ({handleDate, item}) => {
  const [startDate, setStartDate] = useState(item.date ? new Date(item.date) : null);
  
  return (
    <DatePicker 
      selected={startDate} 
      onChange={(date) => {
        setStartDate(date)
        handleDate(date, "date")
      }} 
      placeholderText="날짜를 선택하세요."
      dateFormat="yyyy.MM"
      minDate={sub(new Date(), {months: 1})}
      customInput={<CustomInput />}
      showMonthYearPicker
    />
  );
}

export const PaymentBox = ({item, idx, setSelectValue, selectValue}) => { 
  const handleDate = (date, type) => {
    const year = new Date(date).getFullYear()
    const month = new Date(date).getMonth() + 1;
    item[type] = `${year}.${month}`
  }

  const handleChange = (e, type) => { 
    item[type] = e.target.value
  }


  return (
    <div className='testBox' style={{height: "300px", widht: "100%", position: "relative", padding: "30px 0"}}>
      <Dot isActived={selectValue === idx} onClick={() => setSelectValue(idx)}/>
      {/* <input type="radio" name="payment" checked={selectValue === radioValue ? "checked" : null} value={radioValue} style={{position: "absolute", top: "10px", right: "10px"}} /> */}
      <div style={{width: "100%", height: "100%",  verticalAlign:"middle", backgroundColor: "#efefef", borderRadius: "10px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
        <div className="date_contents" style={{ padding: "30px", borderBottom: "1px solid gray" }}>
          <div style={{display: "flex", gap: "5px"}}>
            <span>날짜</span>
            <DateInput handleDate={handleDate} item={item} />
          </div>
          <div style={{ display: "flex", gap: "5px", marginTop: "10px" }}>
            <div>내용</div>
            <CustomInput type="text" defaultValue={item.contents} onChange={(e)=>handleChange(e, "contents")} />
          </div>
        </div>
        <div style={{ margin: "5px" }}>
          <CustomInput type="text" defaultValue={item.money} onChange={(e)=>handleChange(e, "money")}/>
          <span style={{ margin: "5px" }}>원</span>
        </div>
      </div>
  </div>
  )
}



const Payments = ({ onSubmit }) => {
  // test
  const [addMoney, setAddMoney] = useState([{date: "", contents: "", money: ""}])
  const [selectValue, setSelectValue] = useState(null)
  const fetchData = {date: "", contents: "", money: ""}
  const tempData = addMoney.slice()
  const testFunc = () => {
    if (addMoney.length < 3) {
      setAddMoney(pre => [...pre, fetchData])
    }
  }
  const handleDel = () => {
    if (selectValue === null) return;
    if (addMoney.length === 1) return
 
    let dummy = addMoney.slice()
    dummy.splice(selectValue, 1)
    setAddMoney(dummy)
    setSelectValue(null)
  }
 const handleFetch = () => {
  //  console.log(tempData)
   setAddMoney(tempData)
 
   axios.post(`${process.env.REACT_APP_API_URL}/payment`, {
     data: tempData
   })
   .then((res) => window.alert("저장되었습니다."))
   .catch(err => console.log(err.response))
 }

 useEffect(() => {
  axios.get(`${process.env.REACT_APP_API_URL}/showpayment`)
  .then(res => setAddMoney(res.data.data))
  .catch(err => console.log(err))
 }, [])

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h1>회비납부</h1>
          </div>
          <Form onSubmit={onSubmit}>
            {({ handleSubmit, form }) => (
              <form className="material-form" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-12">
                  <ButtonToolbar className="form__button-toolbar" style={{float:"right"}}>
                      <Button color="warning" type="button" onClick={testFunc}>추가</Button>
                      <Button color="primary" type="button" onClick={handleFetch}>확인</Button>
                      <Button type="button" color="danger" onClick={handleDel}>삭제</Button>
                  </ButtonToolbar>
                  </div>
                  </div>
                <div className="row" style={{border:"solid 3px #e1e1e1", display: "flex", justifyContent:"space-around"}}>
                  {/* <div className="col-4">
                    <div className="form__form-group" > */}
                      {/* style={{ backgroundColor:"#e1e1e1", verticalAlign:"middle"}} */}
                      {/* <div className="form__form-group-field" >   */}
                        {/* <div style={{display:"flex", justifyContent:"space-around", paddingTop: "10px"}}> */}
                          {tempData.map((item, idx) => (
                            <PaymentBox key={uuidv4()} item={item} idx={idx} selectValue={selectValue} setSelectValue={setSelectValue} />
                          ))}
                          
                          {/* <PaymentBox />
                          <PaymentBox />
                          <PaymentBox /> */}
                        {/* </div>                     */}
                      {/* </div> */}
                      {/* 여기서부터 테스트 */}
                      {/* <div style={{ display: "flex", gap: "20px" }}>
                      {addMoney.map(el => (
                        <div className='testBox' style={{ position: "relative", backgroundColor: "#efefef", borderRadius: "10px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                          <div style={{ border: "1px solid white", borderRadius: "50%", width: "20px", height: "20px", position: "absolute", top: "10px", right: "10px", backgroundColor: "white" }}></div>
                          <div className="date_contents" style={{ padding: "30px", borderBottom: "1px solid gray" }}>
                            <div style={{ display: "flex", gap: "20px" }}>
                              <div>날짜</div>
                              <input type="text" />
                            </div>
                            <div style={{ display: "flex", gap: "20px", marginTop: "10px" }}>
                              <div>내용</div>
                              <input type="text" />
                            </div>
                          </div>
                          <div style={{ margin: "5px" }}>
                            <input type="text" />
                            <span style={{ margin: "5px" }}>원</span>
                          </div>
                        </div>
                      ))}  
                      </div> */}
                      {/* 테스트 끝 */}
                      {/* </div> 
                    </div> */}
                  </div>
              </form>
            )}
          </Form>
        </CardBody>
      </Card>
    </Col>
  );
};


Payments.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default Payments;
