import React, { useState, useEffect } from 'react';
import {
  Card, CardBody, Col, Table,Button,
} from 'reactstrap';
import BasicTableData from './BasicTableData';
import DetailTable from './DetailTable'; 
import axios from "axios"
import Pagination from './Pagination'
import { v4 as uuidv4 } from 'uuid';

const { tableHeaderResponsiveData, tableRowsData} = BasicTableData();

const ResponsiveTable = (props) => {
  const [view, setView] = useState(false);
  const [pageNum, setPageNum] = useState(1)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [memberList, setMemberList] = useState([])
  const [memberTotal, setMemberTotal] = useState(0)
  const [eventId, setEventId] = useState(null)
  const [test, setTest] = useState(false)
  const [onOff, setOnOff] = useState(false)

  const handleDetail = (id, page = 1) => {
    axios.get(`${process.env.REACT_APP_API_URL}/listpreadd?event_id=${id}&pagenum=${page}`)
      .then(res => {
        setMemberList(res.data.data)
        setMemberTotal(res.data.count)
        setEventId(id)
        setOnOff(pre => !pre)
      })
      .then(() => setTest(true))
      .catch(err => console.log(err))
  }

  const handleDetailLow = (id, page = 1) => {
    axios.get(`${process.env.REACT_APP_API_URL}/listpreadd?event_id=${id}&pagenum=${page}`)
      .then(res => {
        setMemberList(res.data.data)
        setMemberTotal(res.data.count)
        setEventId(id)
      })
      .then(() => setTest(true))
      .catch(err => console.log(err))
  }
  

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/showschedule?category=${props.category}&pagenum=${pageNum}&limit=10`)
      .then((res) => {
        console.log(res.data)
        setList(res.data.data2)
        setTotal(res.data.count)
      })
      .then(() => setLoading(true))
      .catch(err => console.log(err))
  }, [pageNum, props.category])

  if (!loading) return null

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <Table responsive className="table--bordered">
            <thead>
              <tr>
                {tableHeaderResponsiveData.map(item => (
                  <th key={item.id}>{item.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {list.map(item => (
                <tr key={uuidv4()}>
                  <td>{item.id_event}</td>
                  <td>{item.title}</td>
                  <td>{item.c_date}</td>
                  <td>{item.view_count}</td>
                  <td>
                    <Button onClick={() => {handleDetail(item.id_event); setView(props.read)}}>신청내역</Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <br/>
          <br />
          <Pagination total={total} setPageNum={setPageNum}/>
          <hr />
          {view ? test && <DetailTable onOff={onOff} memberList={memberList} total={memberTotal} handleDetailLow={handleDetailLow} eventId={eventId}/> : null}
        </CardBody>
      </Card>
    </Col>
  );
};

export default ResponsiveTable;
