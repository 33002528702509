import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

import styled from "styled-components"

import "./Calender.scss"

import bnPrev from '../../../../../shared/img/bn-prev.svg'
import bnNext from '../../../../../shared/img/bn-next.svg'

const localizer = momentLocalizer(moment);

const formats = {
  dayFormat: (date, culture) => localizer.format(date, 'DD', culture),
};

const Img = styled.img`
  width: 7px;
`

const CalendarComponent = ({events, onClick}) => {
  const eventStyleGetter = (event) => {
    let color = ""
    switch (event.count) {
      case "정기행사":
        color = '#7ee563';
        break;
      case "연수강좌":
        color = '#178dfc';
        break;
      case "학술대회":
        color = '#d763e5';
        break;
      case "학술집담회":
        color = '#fc9317';
        break;
      case "워크숍 및 세미나":
        color = '#fc177d';
        break;
      default:
        color = '#178dfc';
    }

    const style = {
      backgroundColor:color,
      border: 'none',

      width: '8px',
      height: '8px',

      padding: '0px 0px',
      margin: '2px auto 0px auto',
    };

    return {
      style,
    };
  };

 
  return (
      <div className="calendar calendar--small">
        <Calendar
          style={{ width: 320 }}
          localizer={localizer}
          events={events}
          views={['month']}
          formats={formats}
          step={60}
          timeslots={1}
          showMultiDayTimes
          defaultDate={new Date(moment().format('MMMM DD YY'))}
          eventPropGetter={eventStyleGetter}
          messages={{
            previous: <Img src={bnPrev} alt="bnPrevImage" />,
            next: <Img src={bnNext} alt="bnNextImage" />,
            today: <></>,
          }}
          onSelectEvent={event => onClick(event.id)}
        />
      </div>
  );
};

CalendarComponent.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date),
  })).isRequired,
};

CalendarComponent.defaultProps = {
};

export default CalendarComponent;
