/*eslint-disable*/
import React, { useEffect, useState } from "react"
import Banner from './components/Banner';

import NoticeBoard from "../../components/NoticeBoard"
import { useHistory, useParams } from "react-router-dom";

import axios from "axios"
import Alert from "../../components/Alert"

function DetailQnA() {
    const history = useHistory();
    const params = useParams()
    const [boardData, setBoardData] = useState({});
    const [prev, setPrev] = useState({});
    const [next, setNext] = useState({});

    const [change, setChange] = useState(false);

    const [myData, setMyData] = useState({})
    const [commentData, setCommentData] = useState([]);
    const [errorText, setErrorText] = useState("")

    const [state, setState] = useState(false)

    function setupErrorText() {
        setErrorText("")
        if (state) {
            history.push(`/app/community/qna`);
        }
    }

    function onChangePage(value) { history.replace(`/app/community/qna/${value.board_num}/5/${params.length}`); setChange(!change)}

    useEffect(() => { 
        axios.get(`${process.env.REACT_APP_API_URL}/boardNextPrev?boardNum=${Number(params.number)}&type=${params.type}`).then((data)=>{

            if (Number(params.number) === 1) {
                setPrev(false)
            } else {
                axios.get(`${process.env.REACT_APP_API_URL}/boardNextPrev?boardNum=${Number(params.number) - 1}&type=${params.type}`).then(data2=>{
                    setPrev(data2.data.data)
                }).catch((err) => console.log(err))
            }
            if (Number(params.number) === Number(params.length)) {
                setNext(false)
            } else {
                axios.get(`${process.env.REACT_APP_API_URL}/boardNextPrev?boardNum=${Number(params.number) + 1}&type=${params.type}`).then(data2=>{
                    setNext(data2.data.data)
                }).catch((err) => console.log(err))
            }
            let count = data.data.data.count === null ? 1 : data.data.data.count + 1;
            axios.get(`${process.env.REACT_APP_API_URL}/noticesCountUpdate?count=${count}&num=${data.data.data.id_notice}`).then(data2 => {
                    // console.log(data2)
                    return null
            })
            
            axios.get(`${process.env.REACT_APP_API_URL}/replylist?category=1&id=${params.number}`).then(data2 => {
                setCommentData(data2.data.data)
            })
            setBoardData(data.data.data)
        })
        if (localStorage.getItem("accessToken")) {
            axios.post(`${process.env.REACT_APP_API_URL}/verification`, {
                token: localStorage.getItem("accessToken")
            })
            .then(data => {
                setMyData(data.data.data)
            })
            .catch(err => {
                if (err.response) {
                    console.log(err.response)
                } else {
                    console.log(err.response)
                }
            })
        }
        axios.get(`${process.env.REACT_APP_API_URL}/boardNextPrev?boardNum=${Number(params.number)}&type=${params.type}`).then(data => {
            axios.get(`${process.env.REACT_APP_API_URL}/replylist?category=1&id=${data.data.data.id_notice}`).then(data2 => {
                setCommentData(data2.data.data)
            })
        })
        .catch(err => {
            if (err.response) {
                console.log(err.response)
            } else {
                console.log(err.response)
            }
        })
    }, [change]);
    function ReplyRefresh(category){
        if (localStorage.getItem("accessToken")) {
            axios.get(`${process.env.REACT_APP_API_URL}/boardNextPrev?boardNum=${Number(params.number)}&type=${params.type}`).then(data => {
                if (category === "등록순") {
                    axios.get(`${process.env.REACT_APP_API_URL}/replylist?category=1&id=${data.data.data.id_notice}`).then(data2 => {
                        setCommentData(data2.data.data)
                    })
                } else {
                    axios.get(`${process.env.REACT_APP_API_URL}/replylist?category=1&id=${data.data.data.id_notice}&sort=최신`).then(data2 => {
                        setCommentData(data2.data.data)
                    })
                }
            })
            .catch(err => {
                if (err.response) {
                    console.log(err.response)
                } else {
                    console.log(err.response)
                }
            })
        }
    }

    function ReplyCreate (replyType, replyText, category, callback) {
        if (replyText === "") {
            setErrorText("내용을 입력해주세요.")
        } else {
            axios.get(`${process.env.REACT_APP_API_URL}/boardNextPrev?boardNum=${Number(params.number)}&type=${params.type}`).then((data1)=>{
                axios.post(`${process.env.REACT_APP_API_URL}/verification`, {
                    token: localStorage.getItem("accessToken")
                })
                .then(data => {
                    if (replyType === 0) {
                    axios.post(`${process.env.REACT_APP_API_URL}/reply`, {type: params.type, board_num: Number(params.number), bbs_id: data1.data.data.id_notice, contents: replyText, userid: data.data.data.userid, category:1 }).then(data2 => {
                        ReplyRefresh(category)
                        callback()
                    })
                    } else {
                        axios.post(`${process.env.REACT_APP_API_URL}/reply`, {type: params.type, board_num: Number(params.number), reply_group: replyType, bbs_id: data1.data.data.id_notice, contents: replyText, userid: data.data.data.userid, category:1 }).then(data2 => {
                            ReplyRefresh(category)
                            callback()
                        }) 
                    }
                })
                .catch(err => {
                    if (err.response.data.message) {
                        setErrorText(err.response.data.message)
                    } else {
                        console.log(err.response)
                    }
                })
            })
        }
    }
    function ReplyUpdate (id,text,category, callback) {
        if (text !== ""){
            let c = window.confirm("댓글을 수정하겠습니까?");
            if (c) {
                axios.post(`${process.env.REACT_APP_API_URL}/updatereply`,{
                    id: id,
                    contents: text
                }).then(data=>{
                    ReplyRefresh(category)
                    callback()
                })
            }
        } else {
            setErrorText("수정할 내용을 작성해주세요.")
        }
    }
    function ReplyDelete (id, category) {
        let c = window.confirm("댓글을 삭제하시겠습니까?");
        if (c) {
            axios.get(`${process.env.REACT_APP_API_URL}/delreply?id=${id}`).then((data)=>{
                setErrorText("성공적으로 댓글을 삭제하였습니다.")
                ReplyRefresh(category)
            })
        }
    }
    function BoardDelete (id) {
        let c = window.confirm("게시물을 삭제하시겠습니까?");

        if (c) {
            axios.post(`${process.env.REACT_APP_API_URL}/noticesStatusUpdate`, {
                id_notice:id,
                status: 2 
            }).then((data) => {
                setState(true)

                setErrorText("성공적으로 게시물을 삭제하였습니다.")
            }).catch((err) => console.log(err))
        }
    }

    return (
        <>
            <Alert openState={errorText === "" ? false: true } text={errorText} setupErrorText={()=>setupErrorText()}/>
            <Banner title="qna"></Banner>
            <NoticeBoard ReplyUpdate={(v,text,category,callback)=>ReplyUpdate(v,text,category,callback)} BoardDelete={(id) =>BoardDelete(id)} ReplyDelete={(id, category)=>ReplyDelete(id, category)} ReplyCreate={(type,text, category,callback)=>ReplyCreate(type,text,category,callback)} alter={() => history.push(`/app/community/update/${params.number}/5/${params.length}`)} setReplyCategory={(value) => {ReplyRefresh(value)}} myData={myData} commentData={commentData} onClick={(value) => onChangePage(value)} data={boardData} nextData={next} prevData={prev} pagination={true} comment={true} listButtonClick={() => { history.push("/app/community/qna")}} />
        </>
    )
}

export default DetailQnA