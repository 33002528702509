import React, {useEffect, useState} from 'react';
import EventForm from './EventForm'
import axios from "axios"

const EventDetail = ({match}) => {
    const {id} = match.params;
    const [info, setInfo] = useState({})
    const [loading, setLoading] = useState(false)
    const [amount, setAmount] = useState([])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/eventdetail?id=${id}`)
            .then(res => {
                setInfo(res.data.data)
                setAmount(res.data.amount)
            })
            .then(() => setLoading(true))
            .catch(err => console.log(err))
    }, [])

    if (!loading) return null

    return (
        <EventForm info={info} amount={amount}/>
    );
} 

export default EventDetail