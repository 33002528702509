import React, {useState, useEffect} from 'react';
import styled, { css } from "styled-components";
import Banner from './components/Banner';
import { mobile } from '../../lib/viewport';
import axios from "axios"

const AwardWrapper = styled.div`
    max-width: 1100px;

    margin: 50px auto 100px auto;

    display:flex;
    justify-content:center;
    align-items:center;

    flex-wrap:wrap;

    ${mobile(css`
        margin: 20px auto 100px auto;
        max-width:100%;
        width: calc(100% - 20px);
    `)}

`
 
const AwardList= styled.div`
    width:100%;
    border-top: 1px solid black;
`

const AwardListHeader = styled.div`
    width:100%;
    min-height: 50px;
    height:100%;
    background-color: white;
    border-bottom: 1px solid #cfd1d7;

    display:flex;
    justify-content:flex-start;
    align-items:center;

    padding: 0px 20px;

    font-size: 16px;
`

const AwardListContents = styled.div`
    width:100%;
    min-height: 50px;
    display:flex;
`
const AwardListContentsType = styled.div`
    min-width: 120px;
    max-width: 120px;
    background-color:#e7e9eb;

    word-break:break-all;

    padding: 4px 20px;
    display:flex;
    justify-content:flex-end;
    align-items:center;

    font-size: 14px;
`
const AwardListContentsText = styled.div`
    display:flex;
    justify-content:flex-start;
    align-items:center;
    flex-wrap:wrap;

    padding: 0px 20px;
    ${mobile(css`
        width:100%;
    `)}

`

const AwardListLinkButton= styled.a`
    min-width: 75px;
    height: 33px;
    border-radius: 15px;
    border: solid 0.7px #e0e1e5;
    background-color: #fff;

    display:flex;
    justify-content:center;
    align-items:center;

    color: black;

    margin: 0px 4px;
    cursor:pointer;
    ${mobile(css`
        margin-top: 4px;
        margin-bottom: 4px;
    `)}
`
function Award() {
    const [resData, setResData] = useState([])
    const [res2Data, setRes2Data] = useState({})
    useEffect(()=>{
        axios.get(`${process.env.REACT_APP_API_URL}/academicList?selecNum=1`)
        .then(data => {
            setResData(data.data)
        })
        .catch(err => console.log(err))
    },[])
    return ( 
        <>            
            <Banner title="award"></Banner>
            <AwardWrapper>
                {
                    resData.map((value,index)=>{
                        console.log(value)
                        return (
                            <AwardList key={value.dataValues.id_academic_article}>
                                <AwardListHeader>{value.dataValues.title}</AwardListHeader>
                                {value.contents.map(value2=>{
                                    return (
                                        <AwardListContents>
                                            <AwardListContentsType>{value2.item}</AwardListContentsType>
                                            <AwardListContentsText>{value2.details}</AwardListContentsText>
                                        </AwardListContents>
                                    )
                                })}
                                <AwardListContents>
                                    <AwardListContentsType>링크</AwardListContentsType>
                                    <AwardListContentsText>
                                        {value.dataValues.academic_file_link && <AwardListLinkButton href={value.dataValues.academic_file_link} target="_blank" download>학술대회</AwardListLinkButton>}
                                        {value.dataValues.training_file_link && <AwardListLinkButton href={value.dataValues.training_file_link} target="_blank" download>연수교육</AwardListLinkButton>}
                                        {value.dataValues.shortcut_file_link && <AwardListLinkButton href={value.dataValues.shortcut_file_link} target="_blank" download>바로가기</AwardListLinkButton>}
                                        {value.dataValues.poster_file_link && <AwardListLinkButton href={value.dataValues.poster_file_link} target="_blank" download>포스터</AwardListLinkButton>}
                                    </AwardListContentsText>
                                </AwardListContents>
                            </AwardList>
                        )
                    })
                }
            </AwardWrapper>
        </>
    );
}

export default Award;
