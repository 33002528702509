/* eslint-disable */
import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { useHistory, useParams } from "react-router-dom";

import Banner from "../components/Banner";
import Dropdown from "../../../components/DropDown";

import { mobile } from "../../../lib/viewport";

import checkIcon from "../../../../../shared/img/check-selec.png";
import Alert from "../../../components/Alert";

import axios from "axios";
import moment from "moment";

const Navigation = styled.div`
  width: 500px;
  height: 40px;

  display: flex;

  margin: 50px auto 0px auto;

  ${mobile(css`
    width: calc(100% - 20px);
  `)}
`;
const NavItem = styled.div`
  width: 33%;

  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 500;

  cursor: pointer;

  ${({ selected }) => {
    return selected
      ? `border-left: 1px solid black; border-top: 1px solid black; border-right: 1px solid black; background-color: #f2f3f5;`
      : "border-bottom: 1px solid black; background-color: #e3e5e8;";
  }}
`;

const ContentsTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  word-break: keep-all;
`;

const ContentsText = styled.div`
  font-size: 12px;
  word-break: keep-all;
  margin-bottom: 20px;
`;

const Label = styled.label`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 2em;

  & > input {
    margin-right: 6px;
  }
`;

const Wrapper = styled.div`
  margin-top: 50px;

  & > div:nth-child(1) {
    margin-bottom: 64px;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  margin: 0 auto 10px auto;

  & > span {
    width: fit-content;
    margin-right: 24px;

    display: flex;
    align-items: center;

    font-weight: bold;
    font-size: 15px;
  }
  ${mobile(css`
    justify-content: center;
    & > span {
      display: none;
    }
  `)}
`;

const InputText = styled.input`
  width: 350px;
  height: 50px;

  ${({ borderRadius }) => {
    return borderRadius
      ? `border-radius: ${borderRadius};`
      : "border-radius: 6px;";
  }}
  border: solid 0.7px #e0e1e5;
  background-color: #fff;

  padding: 0px 20px;
  font-size: 15px;

  outline: none;
`;

const InputSubmitButton = styled.div`
  width: 150px;
  height: 50px;

  border-radius: 6px;
  border: solid 0.5px #000;
  background-color: #474747;

  color: white;
  font-size: 15px;

  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0px 6px;

  cursor: pointer;
`;

const InputCancelButton = styled.div`
  width: 150px;
  height: 50px;

  border-radius: 6px;
  border: solid 0.5px #000;
  background-color: #ffffff;

  color: #474747;
  font-size: 15px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  margin: 0px 6px;
`;

const InputSelectWrapper = styled.div`
  min-width: 350px;
  height: 50px;

  border-radius: 6px;

  border: solid 0.7px #e0e1e5;
  background-color: #fff;

  font-size: 15px;

  outline: none;

  display: flex;
`;

const InputSelectBox = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  & > img {
    margin-right: 10px;
  }
  color: #8c919b;
  ${({ selected }) => {
    return selected
      ? `border: 1px solid black; color: black; font-weight: bold;`
      : "";
  }}
  ${({ borderRadius }) => {
    return borderRadius
      ? `border-radius: ${borderRadius};`
      : "border-radius: 6px;";
  }}
`;

const InputDoubleWrapper = styled.div`
  width: 350px;
  height: 100%;
`;

const InputTripleWrapper = styled.div`
  width: 350px;
  height: 100%;
  & > input:nth-child(1) {
    border-radius: 6px 6px 0px 0px;
  }
  & > input:nth-child(2) {
    border-radius: 0px;
  }
  & > input:nth-child(3) {
    border-radius: 0px 0px 6px 6px;
  }
`;

const Form = styled.form`
  width: fit-content;

  margin: 0 auto 300px auto;
  padding-right: 2em;
  ${mobile(css`
    padding-right: 0;
  `)}
`;

const InputAddressTextWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const AddressFindButton = styled.div`
  width: 44px;
  height: 26px;
  border-radius: 8px;
  border: solid 0.5px #000;
  background-color: #474747;
  color: white;
  text-align: center;
  padding: 3px;
  font-size: 12px;

  cursor: pointer;

  position: absolute;
  right: 12px;
  top: 12px;
`;

const OverlapFindButton = styled.div`
  width: 60px;
  height: 26px;
  border-radius: 8px;
  border: solid 0.5px #000;
  background-color: #474747;
  color: white;
  text-align: center;
  padding: 3px;
  font-size: 12px;

  cursor: pointer;

  position: absolute;
  right: 12px;
  top: 12px;
`;
const MoneyWrapper = styled.div`
  border-top: 1px solid black;
  margin-top: 50px;
  margin-bottom: 40px;
`;
const MoneyWrapperItem = styled.div`
  width: 100%;
  height: 48px;

  background-color: #f2f3f5;
  display: flex;
  justify-content: center;
  align-items: center;

  border-bottom: 0.7px solid #cdd0d8;

  & > label {
    width: 33%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
    & > input {
      margin-right: 8px;
    }
  }
`;

const MoneyItemBotton = styled.div`
  width: 100%;
  height: 48px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0px 20px;

  background-color: #ffffff;

  & > div:nth-child(2) {
    font-weight: bold;
    font-size: 22px;
  }
`;

const InputButtonWrapper = styled.div`
  width: 270px;
  height: 100%;
  position: relative;

  display: flex;
  justify-content: space-between;
`;

const MoneyButton = styled.div`
  width: 92px;
  height: 33px;

  border-radius: 10px;
  border: solid 0.7px #cdd0d8;
  background-color: #e7e9ec;

  margin: 0px 2px;

  display: flex;
  justify-content: center;
  align-items: center;

  ${({ selected }) => {
    return selected
      ? `border: 1px solid black; color: black; background-color: white;`
      : "";
  }}
`;

const MoneyInput = styled.input`
  width: 188px;
  height: 33px;
  border-radius: 10px;
  border: solid 0.5px #cdd0d8;
  background-color: #fff;

  text-align: center;
`;

const InputCheckbox = styled.label`
  width: 80px;
  margin: 0px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  & > input {
    margin-right: 6px;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 40px auto 0px auto;
`;

const InputError = styled.div`
  width: 480px;

  padding: 0px 6px;
  font-size: 12px;
  font-weight: 500;

  margin: 9px 0 9px auto;
  color: #ff4040;

  ${mobile(css`
    width: 95%;
    margin: 0 auto;
  `)}
`;
function Registration({ match }) {
  const history = useHistory();
  const params = useParams();

  const { number } = match.params;
  const inputRef = React.useRef(null);

  const [form, setForm] = useState({
    division: "",
    username: "",
    engFirstName: "",
    engLastName: "",
    license_number: "",
    workType: "교원(대학병원전문의)",
    address: "",
    detailedAddress: "",
    email: "",
    phoneNumber: "",
    privacyConsent: false,
    payment_method: 1,
    userType: "",
    payment_sender: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [dropDownState, setDropDownState] = useState(true);

  const [eventAmount, setEventAmount] = useState([]);

  const [money, setMoney] = useState(0);

  const [checkLicense, setCheckLicense] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [boardInfo, setBoardInfo] = useState({});

  const [backUrlState, setBackUrlState] = useState(false);

  const [licenseDisabled, setLicenseDisabled] = useState(false);
  const [divisionDisabled, setDivisionDisabled] = useState(false);

  function setupErrorText() {
    setErrorText("");
    if (confirm) {
      history.push(`/app/academic/schedule/${params.number}/introduce`);
    }
    if (backUrlState) {
      history.push(`/app/academic/schedule/${params.number}/introduce`);
    }
  }
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/eventamount?id=${params.number}`)
      .then((data) => {
        setEventAmount(data.data.data);
      });
    axios
      .get(`${process.env.REACT_APP_API_URL}/pick-research?id=${params.number}`)
      .then((response) => {
        let startDate = moment(response.data.data.pre_reg_startdate);
        let endDate = moment(response.data.data.pre_reg_enddate);
        let now = moment().format("YYYY-MM-DD");

        if (
          !(
            moment(now).isSameOrBefore(endDate.format("YYYY-MM-DD")) &&
            moment(now).isSameOrAfter(startDate.format("YYYY-MM-DD"))
          )
        ) {
          setBackUrlState(true);
          setErrorText("사전등록 기간이 아닙니다.");
        } else {
          setBoardInfo(response.data.data);
        }
      });
  }, []);
  const inputOnChange = (e) => {
    const { name, value } = e.target;
    const nextInputs = {
      ...form,
      [name]: value,
    };
    if (name === "license_number") {
      setCheckLicense(false);
    }
    setForm(nextInputs);
  };
  const checkboxOnChange = (e) => {
    const { name, checked } = e.target;
    const nextInputs = {
      ...form,
      [name]: checked,
    };
    setForm(nextInputs);
  };
  const checkLicenseEvent = () => {
    let licenseTextRegex = /^[0-9]+$/;
    if (form.license_number !== "" && !licenseDisabled) {
      if (!licenseTextRegex.test(form.license_number)) {
        setErrorText("올바른 면허번호를 입력해주십시오");
      } else {
        axios
          .post(`${process.env.REACT_APP_API_URL}/check-license`, {
            license_number: form.license_number,
            event_id: params.number,
          })
          .then((data) => {
            setCheckLicense(true);
            setErrorText("사용가능한 면허번호입니다.");
          })
          .catch((err) => {
            if (err.response) {
              // setErrorMessage(err.response)
              setErrorText(err.response.data);
            } else {
              console.log(err.response);
            }
          });
      }
    } else if (!licenseDisabled) {
      setErrorText("면허번호를 입력해주세요.");
    }
  };
  const SearchAddress = (e) => {
    new window.daum.Postcode({
      oncomplete: function (data) {
        const nextInputs = {
          ...form,
          ["address"]: data.address,
        };
        setForm(nextInputs);
        inputRef.current.focus();
      },
    }).open();
  };

  const inputCheckChange = (e) => {
    const { name, checked } = e.target;
    let nextInputs = {};
    if (name === "license_number") {
      if (checked === true) {
        setLicenseDisabled(true);
        nextInputs = {
          ...form,
          [name]: "면허 없음",
        };
      } else {
        setLicenseDisabled(false);
        nextInputs = {
          ...form,
          [name]: "",
        };
      }
    }
    if (name === "division") {
      if (checked === true) {
        setDivisionDisabled(true);
        nextInputs = {
          ...form,
          [name]: "무직(휴직)",
        };
      } else {
        setDivisionDisabled(false);
        nextInputs = {
          ...form,
          [name]: "",
        };
      }
    }

    setForm(nextInputs);
    // console.log(form)
  };

  const selectedChange = (e) => {
    const nextInputs = {
      ...form,
      [e.name]: e.value,
    };
    setForm(nextInputs);
  };

  const phoneInputOnChange = (e) => {
    const { name, value } = e.target;
    const regValue = value.replace("-", "");
    const nextInputs = {
      ...form,
      [name]: regValue,
    };
    setForm(nextInputs);
  };

  const submitPre = () => {
    const list = [
      "username",
      "engFirstName",
      "engLastName",
      "license_number",
      "phoneNumber",
      "email",
      "division",
      "workType",
      "address",
      "detailedAddress",
      "userType",
      "payment_sender",
      "privacyConsent",
      "payment_method",
    ];

    const regEmail =
      /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
    const regL = /^[0-9]+$/;
    let errorState = false;

    for (let i = 0; i < list.length; i++) {
      // console.log(form[list[i]])
      if (form[list[i]] === "" && list[i] === "username") {
        errorState = true;
        setErrorText("성명을 입력해 주십시오");
        break;
      } else if (form[list[i]].length > 16 && list[i] === "username") {
        errorState = true;
        setErrorText("성명은 16글자 이하로 작성해야 합니다.");
        break;
      } else if (form[list[i]] === "" && list[i] === "engFirstName") {
        errorState = true;
        setErrorText("영문 이름을 입력해 주십시오");
        break;
      } else if (form[list[i]].length > 30 && list[i] === "engFirstName") {
        errorState = true;
        setErrorText("영문이름은 30글자 이하로 작성해야 합니다.");
        break;
      } else if (form[list[i]] === "" && list[i] === "engLastName") {
        errorState = true;
        setErrorText("영문 성을 입력해 주십시오");
        break;
      } else if (form[list[i]].length > 30 && list[i] === "engLastName") {
        errorState = true;
        setErrorText("영문이름은 30글자 이하로 작성해야 합니다.");
        break;
      } else if (form[list[i]] === "" && list[i] === "phoneNumber") {
        errorState = true;
        setErrorText("휴대폰 번호를 입력해 주십시오.");
        break;
      } else if (!regL.test(form[list[i]]) && list[i] === "phoneNumber") {
        errorState = true;
        setErrorText("휴대폰 번호는 숫자만 입력해 주세요.");
        break;
      } else if (form[list[i]].length > 16 && list[i] === "phoneNumber") {
        errorState = true;
        setErrorText("올바른 휴대폰 번호를 입력해 주십시오.");
        break;
      } else if (form[list[i]] === "" && list[i] === "email") {
        errorState = true;
        setErrorText("이메일을 입력해 주십시오.");
        break;
      } else if (form[list[i]] === "" && list[i] === "license_number") {
        errorState = true;
        setErrorText("면허 번호를 입력해주세요.");
        break;
      } else if (form[list[i]].length > 30 && list[i] === "license_number") {
        errorState = true;
        setErrorText("올바른 면허 번호를 입력해주세요.");
        break;
      } else if (form[list[i]] === "" && list[i] === "division") {
        errorState = true;
        setErrorText("소속을 입력해 주십시오.");
        break;
      } else if (form[list[i]].length > 30 && list[i] === "division") {
        errorState = true;
        setErrorText("소속은 30글자 이하로 입력해 주십시오.");
        break;
      } else if (list[i] === "userType" && form[list[i]] === "") {
        errorState = true;
        setErrorText("보내는 회원의 타입을 선택해주세요.");
        break;
      } else if (form[list[i]] === "" && list[i] === "payment_sender") {
        errorState = true;
        setErrorText("송금인 이름을 입력해 주십시오");
        break;
      } else if (form[list[i]].length > 20 && list[i] === "payment_sender") {
        errorState = true;
        setErrorText("송금인 이름은 20글자 이하로 작성해 주세요.");
        break;
      } else if (list[i] === "privacyConsent") {
        if (form[list[i]] === false) {
          errorState = true;
          setErrorText("개인정보 수집을 동의해주세요.");
          setDropDownState(true);
          break;
        } else {
          setDropDownState(false);
        }
      } else if (list[i] === "email") {
        if (!regEmail.test(form[list[i]])) {
          errorState = true;
          setErrorText("올바른 이메일을 입력해주세요.");
          break;
        }
      } else if (form[list[i]] === "" && list[i] === "address") {
        errorState = true;
        setErrorText("주소를 입력해 주십시오.");
        break;
      } else if (form[list[i]].length > 50 && list[i] === "detailedAddress") {
        errorState = true;
        setErrorText("올바른 상세주소를 입력해 주십시오.");
        break;
      }
    }
    let startDate = moment(boardInfo.pre_reg_startdate);
    let endDate = moment(boardInfo.pre_reg_enddate);
    let now = moment().format("YYYY-MM-DD");

    if (errorState === false) {
      if (
        !(
          moment(now).isSameOrBefore(endDate.format("YYYY-MM-DD")) &&
          moment(now).isSameOrAfter(startDate.format("YYYY-MM-DD"))
        )
      ) {
        errorState = true;
        setErrorText("사전등록 기간이 아닙니다.");
      }
    }
    if (
      errorState === false &&
      checkLicense === false &&
      form.license_number !== "면허 없음"
    ) {
      errorState = true;
      setErrorText("면허 중복검사를 해주세요.");
    }
    if (!errorState) {
      let alert = window.confirm("등록하시겠습니까?");
      if (alert === true) {
        axios
          .post(`${process.env.REACT_APP_API_URL}/pre-registration`, {
            data: { ...form, event_id: params.number, money: money },
          })
          .then((data) => {
            // if (data.statusText === "OK") {
            setConfirm(true);
            setErrorText("사전등록을 성공하였습니다.");
            // }
          })
          .catch((err) => {
            if (err.response.data) {
              // setErrorMessage(err.response)
              setErrorText(err.response.data);
            } else {
              console.log(err.response);
            }
          });
      }
    }
  };

  return (
    <>
      <Alert
        openState={errorText === "" ? false : true}
        text={errorText}
        setupErrorText={() => setupErrorText()}
      />
      <Banner title="schedule"></Banner>
      <Navigation>
        <NavItem
          selected={false}
          onClick={() => {
            history.push(`/app/notification/schedule/${number}/introduce`);
          }}
        >
          <span>안내</span>
        </NavItem>
        <NavItem selected={true}>
          <span>사전등록</span>
        </NavItem>
        <NavItem
          selected={false}
          onClick={() => {
            history.push(`/app/notification/schedule/${number}/confirm`);
          }}
        >
          <span>등록 및 접수확인</span>
        </NavItem>
      </Navigation>

      <Wrapper>
        <Form>
          <InputWrapper>
            <span>성명(국/영)</span>
            <InputTripleWrapper>
              <InputText
                name="username"
                value={form.username}
                type="text"
                placeholder="국문 성함"
                onChange={inputOnChange}
              />
              <InputText
                name="engFirstName"
                value={form.engFirstName}
                type="text"
                placeholder="영문 이름 (First name)"
                onChange={inputOnChange}
              />
              <InputText
                name="engLastName"
                value={form.engLastName}
                type="text"
                placeholder="영문 성 (Last name)"
                onChange={inputOnChange}
              />
            </InputTripleWrapper>
          </InputWrapper>

          <InputWrapper>
            <span>면허번호</span>
            <InputButtonWrapper>
              <OverlapFindButton onClick={() => checkLicenseEvent()}>
                중복검사
              </OverlapFindButton>
              <InputText
                disabled={licenseDisabled ? "disabled" : null}
                name="license_number"
                value={form.license_number}
                type="text"
                onChange={inputOnChange}
              />
            </InputButtonWrapper>
            <InputCheckbox>
              <input
                name="license_number"
                type="checkbox"
                onClick={inputCheckChange}
              />
              면허 없음
            </InputCheckbox>
          </InputWrapper>
          <InputWrapper>
            <span>휴대폰</span>
            <InputText
              name="phoneNumber"
              value={form.phoneNumber}
              type="text"
              placeholder="‘-’ 제외하고 숫자만 입력해주세요"
              onChange={phoneInputOnChange}
            />
          </InputWrapper>
          <InputWrapper>
            <span>이메일</span>
            <InputText
              name="email"
              value={form.email}
              type="text"
              placeholder="이메일을 입력해주세요"
              onChange={inputOnChange}
            />
          </InputWrapper>
          <InputWrapper>
            <span>소속(국문)</span>
            <InputButtonWrapper>
              <InputText
                disabled={divisionDisabled ? "disabled" : null}
                name="division"
                value={form.division}
                type="text"
                onChange={inputOnChange}
              />
            </InputButtonWrapper>
            <InputCheckbox>
              <input
                type="checkbox"
                name="division"
                onClick={inputCheckChange}
              />
              무직(휴직)
            </InputCheckbox>
          </InputWrapper>
          <InputWrapper>
            <span>근무형태</span>
            <InputDoubleWrapper>
              <InputSelectWrapper>
                <InputSelectBox
                  onClick={() =>
                    selectedChange({
                      name: "workType",
                      value: "교원(대학병원전문의)",
                    })
                  }
                  borderRadius="6px 0px 0px 0px"
                  selected={
                    form.workType === "교원(대학병원전문의)" ? true : false
                  }
                >
                  <img src={checkIcon} alt="check_icon" />
                  <span>교원(대학병원전문의)</span>
                </InputSelectBox>
                <InputSelectBox
                  onClick={() =>
                    selectedChange({ name: "workType", value: "봉직의" })
                  }
                  borderRadius="0 6px 0 0"
                  selected={form.workType === "봉직의" ? true : false}
                >
                  <img src={checkIcon} alt="check_icon" />
                  <span>봉직의</span>
                </InputSelectBox>
              </InputSelectWrapper>
              <InputSelectWrapper>
                <InputSelectBox
                  onClick={() =>
                    selectedChange({ name: "workType", value: "개원의" })
                  }
                  borderRadius="0px 0px 0px 6px"
                  selected={form.workType === "개원의" ? true : false}
                >
                  <img src={checkIcon} alt="check_icon" />
                  <span>개원의</span>
                </InputSelectBox>
                <InputSelectBox
                  onClick={() =>
                    selectedChange({ name: "workType", value: "전임의" })
                  }
                  borderRadius="0 0px 6px 0px"
                  selected={form.workType === "전임의" ? true : false}
                >
                  <img src={checkIcon} alt="check_icon" />
                  <span>전임의</span>
                </InputSelectBox>
              </InputSelectWrapper>
            </InputDoubleWrapper>
          </InputWrapper>
          <InputWrapper>
            <span>주소</span>
            <InputDoubleWrapper>
              <InputAddressTextWrapper>
                <AddressFindButton onClick={() => SearchAddress()}>
                  찾기
                </AddressFindButton>
                <InputText
                  disabled
                  name="address"
                  value={form.address}
                  type="text"
                  placeholder="주소 (우편번호)"
                  borderRadius="6px 6px 0 0"
                  onChange={inputOnChange}
                />
              </InputAddressTextWrapper>
              <InputText
                ref={inputRef}
                name="detailedAddress"
                value={form.detailedAddress}
                type="text"
                placeholder="상세주소 입력"
                borderRadius="0 0 6px 6px"
                onChange={inputOnChange}
              />
            </InputDoubleWrapper>
          </InputWrapper>
          <MoneyWrapper>
            <MoneyWrapperItem>
              {eventAmount.map((value) => {
                return (
                  <label>
                    <input
                      type="radio"
                      name="userType"
                      onClick={() => {
                        setMoney(value.money);
                        selectedChange({
                          name: "userType",
                          value: value.member_type,
                        });
                      }}
                    />
                    {value.member_type}
                  </label>
                );
              })}
            </MoneyWrapperItem>
            <MoneyWrapperItem>
              <MoneyButton
                selected={form.payment_method === 1}
                onClick={() =>
                  selectedChange({ name: "payment_method", value: 1 })
                }
              >
                무통장 입금
              </MoneyButton>
              <MoneyButton selected={form.payment_method === 2}>
                신용카드
              </MoneyButton>
            </MoneyWrapperItem>
            <MoneyWrapperItem>
              <MoneyInput
                type="text"
                value={form.payment_sender}
                placeholder="송금인 입력"
                name="payment_sender"
                onChange={inputOnChange}
              />
            </MoneyWrapperItem>
            <MoneyItemBotton>
              <div>총 금액</div>
              <div>
                {money
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </div>
            </MoneyItemBotton>
          </MoneyWrapper>

          <Dropdown
            title="개인정보 수집 이용 동의"
            width="480px"
            borderLine
            openState={dropDownState}
            mobileWidth="350px"
          >
            <ContentsTitle>개인정보 수집 항목</ContentsTitle>
            <ContentsText>이름, 소속, 이메일</ContentsText>
            <ContentsTitle>개인정보 이용 목적</ContentsTitle>
            <ContentsText>
              2022 누가통증연구회 춘계 연수강좌 관련 소식 및 안내 정보 수신 동의
              누가통증연구회 관련 소식 및 마케팅 활용을 위한 참가자 정보 제공
              이용 동의를 포함합니다.
            </ContentsText>
            <u>
              <ContentsTitle>
                [개인정보보호법]등 관련 법규에 의거하여 개인 정보 수집 및 활용에
                동의합니다.
              </ContentsTitle>
            </u>
            <Label>
              <input
                checked={form.privacyConsent}
                type="checkbox"
                name="privacyConsent"
                onChange={checkboxOnChange}
              />
              네, 동의합니다.
            </Label>
          </Dropdown>
          <Dropdown title="참고사항" width="480px" mobileWidth="350px">
            송금인과 신청인이 다를 경우 필히 송금인을 기재하여 주십시오.
            <br />
            대한의사협회 평점 보고를 위하여 의사면허번호를 필히 기재하여
            주십시오.
            <br />
            <br />
            회비 납부 안내
            <br />
            연회비를 납부하지 않으신 회원님들은 사전등록이 불가하므로 카드 결제
            시 등록비와 연회비가 합산되어 결제됩니다. <br />
            문의: 누가통증연구회 사무국장 차민우( phone. 010-2163-7460 )<br />
            <br />
            사전등록 환불 안내
            <br />
            사전등록 환불은 2022년 3월 16일(수) 24:00 까지 신청서를 작성하시어
            보내주신 분들에 한하여 환불하여 드립니다. [환불 신청서]
            <br />
            <br />
            연회비 및 무통장 입금 사전등록비 납입 안내
            <br />
            하나은행 631-910021-49305 누가통증연구회
            <br />
          </Dropdown>
          <InputError>{errorMessage}</InputError>
          <ButtonWrapper>
            <InputSubmitButton onClick={submitPre}>
              <span>등록</span>
            </InputSubmitButton>
            <InputCancelButton
              onClick={() => {
                let confirmD = window.confirm("취소하시겠습니까?");
                if (confirmD) {
                  history.push("/app/notification/schedule");
                }
              }}
            >
              <span>취소</span>
            </InputCancelButton>
          </ButtonWrapper>
        </Form>
      </Wrapper>
    </>
  );
}

export default Registration;
