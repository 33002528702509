import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Field, Form } from 'react-final-form'
import { Card, CardBody, Col, Button, ButtonToolbar } from 'reactstrap'
import TextField from '@material-ui/core/TextField'
import renderFileInputField from '@/shared/components/form/FileInput'
import axios from 'axios'

const renderTextField = ({ input, label, meta: { touched, error }, children, select, multiline, disabled }) => (
  <TextField
    className='material-form__field'
    label={label}
    type={input.type}
    error={touched && error}
    value={input.value}
    select={select}
    multiline={multiline}
    onChange={(e) => {
      e.preventDefault()
      input.onChange(e.target.value)
    }}
    disabled={disabled}
  >
    {children}
  </TextField>
)

const AcademicWrite = ({ onSubmit,setWrite,setLoading3 }) => {
  const [radioVal, setRadioVal] = useState(undefined)
  const [academicFileOrLink, setAcademicFileOrLink] = useState(0)
  const [trainingFileOrLink, setTrainingFileOrLink] = useState(0)
  const [shortCutFileOrLink, setShortCutFileOrLink] = useState(0)
  const [posterFileOrLink, setPosterFileOrLink] = useState(0)
  const [inputGroup, setInputGroup] = useState([])
  
  const addInputGroup = () => {
    // console.log(inputGroup);
    if (inputGroup.length === 0) { 
      setInputGroup([0])
    } else {
      const lastNum = inputGroup[inputGroup.length - 1]
      setInputGroup([...inputGroup, lastNum + 1])
    }
  }

  const removeInputGroup = () => {
    setInputGroup(inputGroup.filter(num => num !== radioVal))
  }

  // useEffect(() => console.log({inputGroup}), [inputGroup])

  return (
    <Fragment>
      <div className='card__title'>
        <h1>학술쓰기</h1>
      </div>
      <hr />
      <br />
      <br />
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, form }) => (
          <form className='material-form' onSubmit={handleSubmit}>
            <div className='row'>
              <div className='col-12'>
                <Field name='title' component={renderTextField} label='제목' />
              </div>
            </div>
            <div className='row justify-content-end'>
              <Button
                className='col-3'
                onClick={addInputGroup}
              >
                추가
              </Button>
              <Button
                className='col-3'
                color='danger'
                onClick={removeInputGroup}
              >
                삭제
              </Button>
            </div>
            {inputGroup.map(num => (
              <div className='row'>
                <div className='col-1' style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                  <div className='form__form-group-input-wrap'>
                    <label className='radio-btn'>
                      <input
                        className='radio-btn__radio'
                        name='status'
                        type='radio'
                        onChange={() => {
                          setRadioVal(num)
                        }}
                      />
                      <span className='radio-btn__radio-custom'></span>
                      <span className='radio-btn__label'></span>
                    </label>
                  </div>
                </div>
                <div className='col-4'>
                  <div className="form__form-group-field">
                    
                    <Field name={`item-${num}`} component={renderTextField} label='항목' />
                    
                  </div>
                </div>
                <div className='col-6'>
                  <div className="form__form-group-field">
                    
                    <Field name={`details-${num}`} component={renderTextField} label='세부내역' />
                    
                  </div>
                </div>
              </div>
            ))}
            <div className='row' style={{ border: 'solid 1px #e1e1e1', display: 'flex' }}>
              <div className='col-3' style={{ textAlign: 'center', marginTop: '2%' }}>
                <span>학술대회</span>
              </div>
              <Button color="primary" style={{ marginTop: '1.5%' }} onClick={() => {
                setAcademicFileOrLink(1)
              }}>파일</Button>
              {academicFileOrLink === 1? <div className='col-2' style={{ marginTop: '1.5%' }}>
                <div className='form__form-group-field'>
                  <Field name='academicFile' component={renderFileInputField} />
                </div>
              </div> : null}
              <Button color="primary" style={{ marginTop: '1.5%' }} onClick={() => {
                setAcademicFileOrLink(2)
              }}>링크</Button>
              {academicFileOrLink === 2 ? <div className='col-7' style={{ marginTop: '1%' }} >
                <Field name='academicLink' component={renderTextField} label='링크' />
              </div> :null }
            </div>

            <div className='row' style={{ border: 'solid 1px #e1e1e1', display: 'flex' }}>
              <div className='col-3' style={{ textAlign: 'center', marginTop: '2%' }}>
                <span>연수교육</span>
              </div>
              <Button color="primary" style={{ marginTop: '1.5%' }} onClick={() => {
                setTrainingFileOrLink(1)
              }}>파일</Button>
              {trainingFileOrLink === 1? <div className='col-2' style={{ marginTop: '1.5%' }}>
                <div className='form__form-group-field'>
                  <Field name='trainingFile' component={renderFileInputField} />
                </div>
              </div> : null}
              <Button color="primary" style={{ marginTop: '1.5%' }} onClick={() => {
                setTrainingFileOrLink(2)
              }}>링크</Button>
              {trainingFileOrLink === 2 ? <div className='col-7' style={{ marginTop: '1%' }} >
                <Field name='trainingLink' component={renderTextField} label='링크' />
              </div> :null }
            </div>

            <div className='row' style={{ border: 'solid 1px #e1e1e1', display: 'flex' }}>
              <div className='col-3' style={{ textAlign: 'center', marginTop: '2%' }}>
                <span>바로가기</span>
              </div>
              <Button color="primary" style={{ marginTop: '1.5%' }} onClick={() => {
                setShortCutFileOrLink(1)
              }}>파일</Button>
              {shortCutFileOrLink === 1? <div className='col-2' style={{ marginTop: '1.5%' }}>
                <div className='form__form-group-field'>
                  <Field name='shortcutFile' component={renderFileInputField} />
                </div>
              </div> : null}
              <Button color="primary" style={{ marginTop: '1.5%' }} onClick={() => {
                setShortCutFileOrLink(2)
              }}>링크</Button>
              {shortCutFileOrLink === 2 ? <div className='col-7' style={{ marginTop: '1%' }} >
                <Field name='shortcutLink' component={renderTextField} label='링크' />
              </div> :null }
            </div>

            <div className='row' style={{ border: 'solid 1px #e1e1e1', display: 'flex' }}>
              <div className='col-3' style={{ textAlign: 'center', marginTop: '2%' }}>
                <span>포스터</span>
              </div>
              <Button color="primary" style={{ marginTop: '1.5%' }} onClick={() => {
                setPosterFileOrLink(1)
              }}>파일</Button>
              {posterFileOrLink === 1? <div className='col-2' style={{ marginTop: '1.5%' }}>
                <div className='form__form-group-field'>
                  <Field name='posterFile' component={renderFileInputField} />
                </div>
              </div> : null}
              <Button color="primary" style={{ marginTop: '1.5%' }} onClick={() => {
                setPosterFileOrLink(2)
              }}>링크</Button>
              {posterFileOrLink === 2 ? <div className='col-7' style={{ marginTop: '1%' }} >
                <Field name='posterLink' component={renderTextField} label='링크' />
              </div> :null }
            </div>
            <br />
            <Field
              name='input-group'
              someArbitraryOtherProp={inputGroup}
              hidden
              render={(prop) => {
                prop.input.onChange(prop.someArbitraryOtherProp)
                return <input {...prop.input} value="hidden" hidden />
              }}
            />
            <ButtonToolbar className='form__button-toolbar'>
              <Button color='primary' type='button' onClick={() => {
                if (window.confirm("등록하시겠습니까?")) {
                  setLoading3(false)
                  form.submit()
                  window.alert("등록되었습니다.")
                  setWrite((e)=>!e)
                }
              }}>
                확인
              </Button>
              <Button type='button' color='danger' onClick={() => {
                if (window.confirm("취소하시겠습니까?")) {
                  setWrite((e)=>!e)
                }
              }}>
                취소
              </Button>
            </ButtonToolbar>
          </form>
        )}
      </Form>
    </Fragment>
  )
}

export default AcademicWrite
