import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SignIn from './SignIn';
import SignUp from './SignUp';
import FindId from './FindId';
import FindPassword from './FindPassword';

export default () => (
  <Switch>
    <Route exact path="/app/auth/signin" component={SignIn} />
    <Route exact path="/app/auth/signup" component={SignUp} />
    <Route exact path="/app/auth/find/id" component={FindId} />
    <Route exact path="/app/auth/find/password" component={FindPassword} />
  </Switch>
);
