import React, { useState } from "react"
import styled, {css} from "styled-components"
import {mobile} from "../lib/viewport"

const AlertWrapper = styled.div`
    width:100vw;
    height:100vh;

    display: flex;
    justify-content:center;
    align-items:center;

    position:fixed;
    top:0; left:0; right:0; bottom:0;

    z-index: 300;
`
const AlertShadow = styled.div`
    width:100vw;
    height:100vh;

    position:absolute;
    top:0; left:0; right:0; bottom:0;

    background-color: black;
    opacity:0.6;
`

const AlertBox = styled.div`
    width: 300px;
    height: 180px;
    margin: 0 auto;
    border-radius: 15px;
    box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.3);

    background-color: #fff;
    ${
        mobile(css`
            width: calc( 100% - 30px );
        `)
    }
    z-index: 2;

    & > p{
        font-size: 12px;
    }
`

const AlertTextWrapper = styled.div`
    width:100%;
    height: calc( 100% - 40px );

    display:flex;
    justify-content:center;
    align-items:center;
    padding: 0px 20px;

    word-break:keep-all;

`
const AlertBottom = styled.div`
    width:100%;
    height: 40px;
    display:flex;
    align-items:center;
    justify-content:center;
    color: white;

    border-radius: 0px 0px 15px 15px;
    border: solid 0.5px #000;
  background-color: #474747;

  cursor:pointer;
`
function Alert({openState, text, setupErrorText}) {
    function Confirm() {
        setupErrorText()
    }
    return (
        ( openState ) && <AlertWrapper>
           <AlertBox>
                <AlertTextWrapper>
                    <p>{text}</p>
                </AlertTextWrapper>
                <AlertBottom onClick={() => Confirm()}>확 인</AlertBottom>
           </AlertBox>
           <AlertShadow onClick={() => Confirm()}></AlertShadow>
       </AlertWrapper>
    )   
}

export default Alert;
