import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Field, Form } from 'react-final-form'
import { Card, CardBody, Col, Button, ButtonToolbar } from 'reactstrap'
import TextField from '@material-ui/core/TextField'
import ModifySummerNote from './ModifySummerNote.jsx'
import imageThumbnail from './FileInputImage'
import ModifyVideoTapsBorderd from './ModifyVideoTapsBorderd'
import axios from 'axios'
import ModifyFile from './ModifyFile'

const renderTextField = ({ input, label, meta: { touched, error }, children, select, multiline, disabled }) => (
  <TextField
    className='material-form__field'
    label={label}
    type={input.type}
    error={touched && error}
    value={input.value}
    select={select}
    multiline={multiline}
    onChange={(e) => {
      e.preventDefault()
      input.onChange(e.target.value)
    }}
    disabled={disabled}
  >
    {children}
  </TextField>
)

const ModifyVideoWrite = ({ setShowWrite, setVideoList, videoReadVal, showModifyWrite, setRadioVal, radioVal, setShowModifyWrite,setLoading,setLoading2,boardOneData }) => {
  const [upload, setUpload] = useState('')
  const [contents, setContents] = useState('')
  const [imageSrc, setImageSrc] = useState('')
  const [folderSta, setFolderSta] = useState(videoReadVal.folder_status)
  const [modifyFile, setModifyFile] = useState('') 
  const [fileDel, setFileDel] = useState(false)
  // const [, setContents] = useState('')
  useEffect(() => {
    setRadioVal(videoReadVal.folder_name)
  }, [])
  const onSubmit = (values) => {
    values.thumbnail = !imageSrc ? videoReadVal.thumbnail : imageSrc
    values.folder_name = radioVal
    values.status = videoReadVal.status
    values.id_video = videoReadVal.id_video
    // videoReadVal.folder_status = folderSta === '1' ? 1 : 0
    values.contents = contents === '' ? videoReadVal.contents : contents
    values.file = modifyFile
    // console.log(values)
    if (modifyFile) {
      values.file_modify = 'yes';
      let formData = new FormData()
      axios
        .post(`${process.env.REACT_APP_API_URL}/modifyVideo`, values)
        // .then((data) => {
        //   return data
        // })
        .then((data2) => {
          formData.append('file', values.file)
          axios.post(`${process.env.REACT_APP_API_URL}/fileUpload?num=2&no=${values.id_video}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
        })
        .catch((err) => console.log(err))
    } else {
      values.file_modify = 'no';
      axios
        .post(`${process.env.REACT_APP_API_URL}/modifyVideo`, values)
        .then((data) => {
          return null
        })
        .catch((err) => console.log(err))
    }
    axios
      .post(`${process.env.REACT_APP_API_URL}/videoList`, {
        page: 0,
        folder: '관리자',
      })
      .then((data) => {
        setVideoList(data.data.data2)
      })
      // .then((data) => console.log(data.data))
      .catch((err) => console.log(err))
    // setShowWrite((show) => !show)
  }
  if (upload) {
    const encodeFileToBase64 = (fileBlob) => {
      const reader = new FileReader()
      reader.readAsDataURL(fileBlob)
      return new Promise((resolve) => {
        reader.onload = () => {
          setImageSrc(reader.result)
          resolve()
        }
      })
    }
    encodeFileToBase64(upload)
  }
  if (fileDel) {
      axios.post(`${process.env.REACT_APP_API_URL}/fileDel`, {
        type: 'videos',
        file_link: videoReadVal.file_link,
      })
      .then(()=>setFileDel(false))
      .then(()=>boardOneData(videoReadVal.id_video))
  }
  return (
    <Fragment>
      <div className='card__title'>
        <h1>갤러리 쓰기</h1>
      </div>
      <hr />
      <br />
      <br />
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, form }) => (
          <form className='material-form' onSubmit={handleSubmit}>
            <div className='row'>
              <div className='col-3'>
                <div style={{ border: 'solid 1px', height: '230px', width: '230px' }}>
                  <img
                    src={upload ? URL.createObjectURL(upload) : videoReadVal.thumbnail}
                    alt=''
                    style={{ height: '230px', width: '230px' }}
                  />
                </div>
                <br/>
                <div className='form__form-group'>
                  <span className='form__form-group-label'>이미지 업로드</span>
                  <div className='form__form-group-field'>
                    <Field name='thumbnail' component={imageThumbnail} setUpload={setUpload} />
                  </div>
                </div>
              </div>
              <div className='col-9'>
                <ModifyVideoTapsBorderd
                  setRadioVal={setRadioVal}
                  radioVal={radioVal}
                  setFolderSta={setFolderSta}
                  videoReadVal={videoReadVal}
                  folderSta={folderSta}
                />
              </div>
            </div>
            <br />
            <div className='row'>
              <div className='col-12'>
                <Field
                  name='title'
                  component={renderTextField}
                  label='제목'
                  initialValue={videoReadVal.title}
                  // disabled
                />
              </div>
            </div>
            <Field
              name='contents'
              someArbitraryOtherProp={contents}
              render={(prop) => {
                prop.input.onChange(prop.someArbitraryOtherProp)
                return <input {...prop.input} hidden />
              }}
            />
            <div className='col-12'>
              <ModifySummerNote boardReadVal={videoReadVal.contents} setContents={setContents} />
            </div>
            <br />
            <div className='form__form-group'>
              <span className='form__form-group-label'>첨부파일</span>
              <div className='form__form-group-field'>
                <Field name='file' component={ModifyFile} setModifyFile={setModifyFile} fileName={videoReadVal.original_fileName} setFileDel={setFileDel}/>
              </div>
            </div>
            <ButtonToolbar className='form__button-toolbar'>
              <Button
                color='primary'
                type='button'
                onClick={() => {
                  if (!radioVal) {
                    window.alert('폴더를 체크해 주세요')
                  } else {
                    if (window.confirm('수정하시겠습니까?')) {
                      setLoading(false)
                      setLoading2(false)
                      form.submit()
                      window.alert('수정되었습니다.')
                      setShowModifyWrite((e) => !e)
                    }
                  }
                }}
              >
                확인
              </Button>
              <Button
                type='button'
                color='danger'
                onClick={() => {
                  if (!videoReadVal.title && !videoReadVal.contents) {
                    setShowModifyWrite((e) => !e)
                  } else {
                    if (window.confirm('입력하신 내용이 저장되지 않습니다. 취소하시겠습니까?')) {
                      setShowModifyWrite((e) => !e)
                    }
                  }
                }}
              >
                취소
              </Button>
            </ButtonToolbar>
            <hr />
          </form>
        )}
      </Form>
    </Fragment>
  )
}

export default ModifyVideoWrite
