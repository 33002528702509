import moment from 'moment';

const year = moment().format('YYYY');
const mounth = moment().format('MM') - 1;
// const prevMounth = mounth - 1;
// const nextMounth = mounth + 1;

export default [
  {
    id: 1,
    start: new Date(year, mounth, 9),
    end: new Date(year, mounth, 9),
  },
];
