import React,{useState} from "react"
import styled from "styled-components"
import { useHistory } from "react-router-dom";
import axios from "axios"
import Banner from "./components/Banner"
import Alert from "../../components/Alert"

const FindNavigation = styled.div`
    width: 300px;
    height: 50px;

    display:flex;

    margin: 50px auto 0px auto;

`

const NavItem = styled.div`
    width: 50%;

    display:flex;
    justify-content:center;
    align-items:center;

    font-weight: 500;

    cursor:pointer;

    ${({ selected }) => {
      return selected ? `border-left: 1px solid black; border-top: 1px solid black; border-right: 1px solid black; background-color: #f2f3f5;` : 'border-bottom: 1px solid black; background-color: #e3e5e8;';
    }}
`
const InputText = styled.input`
    width: 100%;
    height: 50px;

    border: solid 0.7px #e0e1e5;
    background-color: #fff;

    padding: 0px 20px;
    font-size: 15px;

    outline:none;

    &:focus{
        position:relative;
        box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.1);
        border: solid 0.6px rgba(0, 0, 0);
    }
`
const InputButton = styled.div`
    min-width: 100%;
    height: 50px;

    border: solid 0.5px #000;
    background-color: #474747;

    color: #fff;
    font-size: 15px;

    display:flex;
    justify-content:center;
    align-items:center;

    margin: 20px 0px 16px 0px;

    cursor:pointer;
`

const Form = styled.div`
    width: 300px;
    margin: 24px auto 160px auto;
`

const IdWrapper = styled.div`
    height: 100px;

    display:flex;
    justify-content:center;
    align-items:center;

    flex-direction:column;


    & > p {
        font-size: 12px;
        text-align:center;
        line-height: 1.2;
    }
    & > div {
        font-size: 22px;
    }
`

function FindId() {
    const history = useHistory();
    const [form, setForm] = useState({name:"",license_number:""})
    const [findIdText, setFindId] = useState("")
    const [errorText, setErrorText] = useState("")
    function setupErrorText() {
        setErrorText("")
    }
    const inputOnChange = (e) => {
        const { name, value } = e.target   
        const nextInputs = {            
            ...form,  
            [name]: value,
        }
        setForm(nextInputs)       
    }
    function idFind() {
        if (form.name === "") {
            setErrorText("성함을 입력해 주세요.");
        } else {
            if (form.license_number === "") {
                setErrorText("면허번호를 입력해 주세요.");
            } else {
                axios.post(`${process.env.REACT_APP_API_URL}/findid`, form)
                .then(data => {
                    setFindId(data.data.userid)
                })
                .catch(err => {
                    if (err.response.data){
                        setErrorText(err.response.data);
                    } else {
                        console.log(err.response)
                    }
                })
            }
        }
    }
    return (
        <>
            <Alert openState={errorText === "" ? false: true } text={errorText} setupErrorText={()=>setupErrorText()}/>
            <Banner title="아이디/비밀번호 찾기" subTitle="find ID/PASS"></Banner>
            <FindNavigation>
                <NavItem selected={true}>
                    <span>아이디 찾기</span>
                </NavItem>
                <NavItem selected={false} onClick={() => { history.push(`/app/auth/find/password`) }}>
                    <span>비밀번호 찾기</span>
                </NavItem>
            </FindNavigation>
            <Form>
                {
                    findIdText === "" ? <>
                        <InputText value={form.name} onChange={inputOnChange} type="text" name="name" placeholder="성함을 입력해주세요." />
                        <InputText value={form.license_number} onChange={inputOnChange} type="text" name="license_number" placeholder="면허번호를 입력해주세요." />
                        <InputButton onClick={()=>idFind()}>
                            <span>아이디 찾기</span>
                        </InputButton>
                    </> : <>
                        <IdWrapper>
                            <p>회원님의 아이디는</p>
                            <div>{findIdText}</div>
                        </IdWrapper>
                        <InputButton onClick={() => {history.push("/app/auth/signin")}}>
                            <span>로그인 하기</span>
                        </InputButton>
                    </>
                }
            </Form>
        </>
    )
}

export default FindId
