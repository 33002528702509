import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import showResults from './showResults';
import Academics from './components/Academics';

const Members = () => {
  const { t } = useTranslation('common');
  return (
    <Container>
      <Academics onSubmit={showResults} />
      <Row>
        <Col md={12}>
          <hr />
        </Col>
      </Row>
    </Container>
  );
};

export default Members;

