import React from "react"
import styled from "styled-components"
import next from "../../../shared/img/board-page-arrow-next.png"
import prev from "../../../shared/img/board-page-arrow-prev.png"
import moment from "moment"

const ListWrapper = styled.div`
    width:100%;

`
const TablePaginationWrapper = styled.div`
    width:fit-content;
    max-width: 400px;
    margin: 36px auto 0px auto;

    display:flex;
    & > div{
        margin: 0px 2px;
    }

`
const TablePaginationArrow = styled.div`
    width: 33px;
    height: 27px;
    display:flex;
    justify-content:center;
    align-items:center;

    border-radius: 4px;
    border: solid 0.7px #ccced7;
    background-color: #f2f3f5;

    cursor:pointer;
`

const TablePaginationItem = styled.div`
    width: 27px;
    height: 27px;
    border-radius: 4px;

    ${({ selected }) => {
      return selected ? `color: #000000; border: solid 0.7px #000000;` : 'color: #8e909c; border: solid 0.7px #ccced7;';
    }}

    display:flex;
    justify-content:center;
    align-items:center;

    background-color:#ffffff;

    cursor:pointer;
`
const ListItem = styled.div`
    width:100%;
    height: 100%;
    margin-bottom: 30px;
    padding: 20px 32px 30px 32px;

    border-radius: 15px;
    background-color: #fff;

    box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.1);
    border: solid 0px;

    &:hover{
        border: solid 0.7px #000;
    }
`
const ListItemType = styled.div`
    width: fit-content;
    height: 32px;

    border-radius: 20px;
    border: solid 1px #e0e1e5;

    padding: 0px 14px;

    display:flex;
    justify-content:center;
    align-items:center;

    color: ${props=> props.color};
`

const ListItemTitle = styled.div`
    font-size: 20px;
    margin: 2px 0px 20px 0px;
`

const ListItemInfo = styled.div`
    display:flex;
    align-items:center;
`
const ListItemInfoType = styled.div`
    width: 30px;
    height: 20px;
    border: solid 1px #e0e1e5;
    border-radius: 4px;
    background-color: #fff;

    font-size: 9px;

    display:flex;
    justify-content:center;
    align-items:center;

    margin-right: 8px;
`

const DataNone = styled.div`
    width:100%;
    height: 210px;

    display:flex;
    justify-content:center;
    align-items:center;

    font-size: 20px;
`
function List({data, slide, length, nowPage, onClick, changePageEvent}){
    return (
        <ListWrapper>
             {
                 data.length === 0 ? <DataNone>일정이 없습니다.</DataNone> : data.map((v)=>{
                     return (
                         <ListItem onClick={() => onClick && onClick(v.id_event)}>
                             {
                                 v.count === "연수강좌" ? <ListItemType color="#ab5cfa">연수강좌</ListItemType> : <></>
                             }
                             {
                                 v.count === "정기행사" ? <ListItemType color="#178dfc">정기행사</ListItemType> : <></>
                             }
                             {
                                 v.count === "학술대회" ? <ListItemType color="#d763e5">학술대회</ListItemType> : <></>
                             }
                             {
                                 v.count === "학술집담회" ? <ListItemType color="#fc9317">학술집담회</ListItemType> : <></>
                             }
                             {
                                 v.count === "워크숍 및 세미나" ? <ListItemType color="#fc177d">워크숍 및 세미나</ListItemType> : <></>
                             }
                            <ListItemTitle>{v.title}</ListItemTitle>
                            <ListItemInfo>
                                <ListItemInfoType>일정</ListItemInfoType>
                                <p>{ moment(v.startdate).format("YYYY-MM-DD") === moment(v.enddate).format("YYYY-MM-DD") ? moment(v.startdate).format("YYYY-MM-DD") : moment(v.startdate).format("YYYY-MM-DD") + '~' +moment(v.enddate).format("YYYY-MM-DD")}</p>    
                            </ListItemInfo> 
                            <ListItemInfo>
                                <ListItemInfoType>장소</ListItemInfoType>
                                <p>{v.place}</p>    
                            </ListItemInfo> 
                            <ListItemInfo>
                                <ListItemInfoType>접수</ListItemInfoType>
                                {/* 수정코드 */}
                                <p>온라인접수 { v.will_receive_pre_reg === "X" ? <></>: `( 사전등록기간 : ${moment(v.pre_reg_startdate).format('YYYY-MM-DD')} ~ ${moment(v.pre_reg_enddate).format('YYYY-MM-DD')} )` }</p>    
                                {/* 원본코드 */}
                                {/* <p>온라인접수 { v.pre_reg_startdate === null ? "": `( 사전등록기간 : ${moment(v.pre_reg_startdate).format('YYYY-MM-DD')} ~ ${moment(v.pre_reg_enddate).format('YYYY-MM-DD')} )` }</p>     */}
                            </ListItemInfo> 
                         </ListItem>
                     )
                 })
             }   

            <TablePaginationWrapper>
                <TablePaginationArrow onClick={() => {
                            nowPage > 1 ? changePageEvent(nowPage - 1) : null
                }}>
                    <img src={prev} alt="pagination arrow" />
                </TablePaginationArrow>
                {
                    [...Array(Math.ceil(length / slide)).keys()].map((item)=>{
                        return (
                            <TablePaginationItem selected={item+1 === nowPage}  onClick={() => {
                                changePageEvent(item + 1)
                              }}>
                                {item + 1}
                            </TablePaginationItem>
                        )
                    })
                }
            <TablePaginationArrow onClick={() => { Math.ceil(length / slide) > nowPage ? changePageEvent(nowPage + 1) : null }}>
                <img src={next} alt="pagination arrow" />
            </TablePaginationArrow>
        </TablePaginationWrapper>
        </ListWrapper>
    )
}

export default List;
